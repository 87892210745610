import React, { useContext, forwardRef } from 'react'
import { ViewStyle } from 'react-native'
import { Input as ElementsInput } from 'react-native-elements'
import { ThemeContext } from 'styled-components/native'

const BaseTextInput = (
  {
    hasError,
    style = {},
    inputContainerStyle = {},
    inputStyle = {},
    errorStyle = {},
    ...rest
  }: TextInputProps,
  ref
) => {
  const theme: any = useContext(ThemeContext)

  const resolvedInputContainerStyle: ViewStyle = {
    borderWidth: 1,
    ...inputContainerStyle
  }

  const containerStyle = {
    paddingLeft: 0,
    paddingRight: 0,
    ...style
  }

  if (hasError) {
    inputContainerStyle.borderColor = theme.colors.danger
  }

  return (
    <ElementsInput
      containerStyle={containerStyle}
      inputContainerStyle={resolvedInputContainerStyle}
      errorStyle={errorStyle}
      inputStyle={[
        {
          padding: 4
        },
        inputStyle
      ]}
      ref={ref}
      {...rest}
    />
  )
}

export interface TextInputProps {
  placeholder: string
  value: string
  onChangeText: (text: string) => void
  onBlur?: (event: any) => void
  onFocus?: (event: any) => void
  style?: ViewStyle
  inputStyle?: ViewStyle
  inputContainerStyle?: ViewStyle
  errorStyle?: ViewStyle
  multiline?: boolean
  hasError?: boolean
  renderErrorMessage?: boolean
}

export default forwardRef(BaseTextInput)
