import { gql, useMutation } from '@apollo/client'

const saveImageMutation = gql`
  mutation($cloudinaryId: String!) {
    createImage(cloudinaryId: $cloudinaryId) {
      id
    }
  }
`

const useCreateImageMutation = (options = {}) => {
  const [createImage]: any[] = useMutation(saveImageMutation, options)
  return createImage
}

export default useCreateImageMutation
