import gql from 'graphql-tag'
import { useMutation } from '@apollo/client'

// TODO: setup custom useMutation hook once we have sentry and toast notifications(if we want them)
const deleteDocumentMutation = gql`
  mutation deleteDocument($id: String!) {
    deleteDocument(id: $id)
  }
`

// TODO: implement error handling once we have a system in place
const useDeleteDocumentMutation = (options = {}) => {
  const [deleteDocument]: any[] = useMutation(deleteDocumentMutation, options)
  return deleteDocument
}

export default useDeleteDocumentMutation
