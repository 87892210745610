import React from 'react'
import Constants from 'expo-constants'
//import * as Sentry from 'sentry-expo'
import * as Sentry from "@sentry/browser";
import useCachedResources from './src/hooks/useCachedResources'
import AppRoot from './src/AppRoot'
// @ts-ignore
import pkg from './package.json'

//if (Constants?.manifest?.extra?.sentry?.enabled) {
  Sentry.init({
    dsn: Constants?.manifest?.extra?.sentry?.dsn,
    environment: Constants?.manifest?.extra?.sentry?.environment,
    release: pkg.version,
    //debug: true,
    tracesSampleRate: 1.0,
    integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    // enableAutoSessionTracking: true,
    // sessionTrackingIntervalMillis: 100,
    //enableAutoPerformanceTracking: true,
    // enableNdkScopeSync: true,
    // enableNative: true,
    // enableInExpoDevelopment: 
    //   Constants?.manifest?.extra?.BUILD_ENV === 'development'
  })
//}

export default function App() {
  const isLoadingComplete = useCachedResources()

  if (!isLoadingComplete) {
    return null
  } else {
    return <AppRoot />
  }
}
