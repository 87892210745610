import React, { FC } from 'react'

import { Image } from 'react-native'
import styled from 'styled-components/native'

import ContentDrop, { CustomDropProps } from './ContentDrop'

import DropImageIcon from '../../../images/drop-image.svg'
import useTranslation from '../../../hooks/useTranslation'

const HeroImageDrop: FC<CustomDropProps> = props => {
  const { t } = useTranslation()

  return (
    <ContentDrop
      {...props}
      acceptFormat="image/png, image/jpeg"
      dropZoneContent={{
        imageIcon: <DropImageIcon />,
        title: t('media:dropHeroImage')
      }}
      removeContentModal={{
        title: t('media:removeHero'),
        description: t('media:removeHeroConfirmation')
      }}
      onRenderUrl={uri => (
        <PreviewImage resizeMode="contain" source={{ uri }} />
      )}
    />
  )
}

const PreviewImage = styled(Image)`
  ${({ theme: { sizes } }) => `
    height: ${sizes[6] + sizes[5]};
    width: 100%;
  `}
`

export default HeroImageDrop
