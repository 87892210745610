import React, { useRef } from 'react'
import { View, TouchableOpacity } from 'react-native'

import { Flex } from '../../../components/FlexBox'
import { TableInner, CellRendererProps } from '../../../components/common/Table'
import useTranslation from '../../../hooks/useTranslation'
import TitleCell from '../../../components/cell/TitleCellContent'
import useTableRefetch from '../../../hooks/useTableRefetch'

import { UrlLink } from '../../urlShortener/components/ShortUrlsTable'
import CopyShortUrlButton from '../../urlShortener/components/CopyShortUrlButton'
import EditIcon from '../../../components/icon/EditIcon'
import TrashIcon from '../../../components/icon/TrashIcon'
import { formatToEst } from '../utils/dateHelpers'

const fullName = ({ firstName, lastName }) => {
  return firstName && lastName ? `${firstName} ${lastName}` : ''
}

const getTitle = (text: string | undefined) => ({
  title: text ?? 'Unknown'
})

interface PermissionsTableProps {
  tokens: never[]
  onSelect: (item: any) => void
  onDelete: (item: any) => void
  refetch: () => void
  fetchMore: () => void
  loading: boolean
}
const PermissionsTable = ({
  tokens,
  onSelect,
  onDelete,
  refetch,
  fetchMore,
  loading
}: PermissionsTableProps) => {
  const { t } = useTranslation()
  const tableRef = useRef()

  useTableRefetch(refetch)

  return (
    <Flex flex={1} flexDirection="row" mx={4} my={3}>
      <Flex flex={1} style={{ maxHeight: 650, minWidth: 760 }}>
        <TableInner
          ref={tableRef}
          columns={columns(t, onDelete, onSelect)}
          items={tokens}
          error={undefined}
          loading={loading}
          sortBy={false} // No sorting yet
          sortDir={false}
          handleOnSort={() => {}}
          queryFilters={{}} // No filters yet
          setQueryFilters={() => {}}
          fetchMoreResults={fetchMore}
        />
      </Flex>
    </Flex>
  )
}

function columns(t, onDelete, onSelect) {
  let columns = [
    {
      header: t('microsites:columns:actions'),
      width: 100,
      Cell: ({ item }: CellRendererProps<any>) => {
        return (
          <View
            style={{
              width: '100%',
              flexDirection: 'row',
              alignItems: 'center'
            }}
          >
            <TouchableOpacity
              style={{ marginRight: 10 }}
              onPress={() => onSelect(item.id)}
            >
              <EditIcon />
            </TouchableOpacity>
            <TouchableOpacity onPress={() => onDelete(item.id)}>
              <TrashIcon />
            </TouchableOpacity>
          </View>
        )
      }
    },
    {
      header: t('microsites:columns:firstName'),
      width: 150,
      Cell: ({ item }: CellRendererProps<any>) => {
        return <TitleCell item={getTitle(item.firstName)} />
      }
    },
    {
      header: t('microsites:columns:lastName'),
      width: 150,
      Cell: ({ item }: CellRendererProps<any>) => {
        return <TitleCell item={getTitle(item.lastName)} />
      }
    },
    {
      header: t('microsites:columns:email'),
      Cell: ({ item }: CellRendererProps<any>) => {
        return <TitleCell item={getTitle(item.emailAddress)} />
      }
    },
    {
      header: t('microsites:columns:microsite'),
      Cell: ({ item }: CellRendererProps<any>) => {
        return <TitleCell item={getTitle(item?.community?.name)} />
      }
    },
    {
      header: t('microsites:columns:expiration'),
      Cell: ({ item }: CellRendererProps<any>) => {
        return (
          <TitleCell
            item={getTitle(
              `${formatToEst(item.expirationDate, 'MM-dd-yy HH:mm')} EST`
            )}
          />
        )
      }
    },
    {
      header: t('microsites:columns:url'),
      width: 300,
      Cell: ({ item }: CellRendererProps<any>) => {
        return (
          <View
            style={{
              width: 300,
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-between'
            }}
          >
            <View style={{ flex: 1 }}>
              <UrlLink
                url={item?.shortUrl?.sourceUrl}
                numberOfLines={1}
                ellipsizeMode="tail"
              />
            </View>
            <View style={{ marginLeft: 8 }}>
              <CopyShortUrlButton url={item?.shortUrl?.sourceUrl} />
            </View>
          </View>
        )
      }
    },
    {
      header: t('microsites:columns:invitedBy'),
      Cell: ({ item }: CellRendererProps<any>) => {
        return <TitleCell item={getTitle(fullName(item.createdBy)) as any} />
      }
    }
  ]
  return columns
}

export default PermissionsTable
