import React from 'react'
import { TouchableOpacity } from 'react-native'
import { useTheme } from 'styled-components/native'
import { CaretIcon } from '../../components/icon/CaretIcon'
import { IEditorState, ISectionState } from './types'
import { FormikProps } from 'formik'

function swapIndicesInPlace<T>(
  array: T[],
  indexA: number,
  indexB: number
): T[] {
  ;[array[indexA], array[indexB]] = [array[indexB], array[indexA]]
  return array
}

interface IReorderBlockControlProps {
  section: ISectionState
  sectionIndex: number
  blockIndex: number
  form: FormikProps<IEditorState>
}

export const ReorderBlockControls: React.FC<IReorderBlockControlProps> = ({
  section,
  sectionIndex,
  blockIndex,
  form
}) => {
  const { colors } = useTheme()
  const isFirst = blockIndex === 0
  const isLast = blockIndex === section.blocks.length - 1
  return (
    <>
      <TouchableOpacity
        disabled={isFirst}
        onPress={() => {
          swapIndicesInPlace(section.blocks, blockIndex, blockIndex - 1)
          form.setFieldValue(`sections.${sectionIndex}.blocks`, section.blocks)
        }}
      >
        <CaretIcon
          color={isFirst ? colors.disabled : 'black'}
          size={24}
          rotate={180}
        />
      </TouchableOpacity>
      <TouchableOpacity
        disabled={isLast}
        onPress={() => {
          swapIndicesInPlace(section.blocks, blockIndex, blockIndex + 1)
          form.setFieldValue(`sections.${sectionIndex}.blocks`, section.blocks)
        }}
      >
        <CaretIcon color={isLast ? colors.disabled : 'black'} size={24} />
      </TouchableOpacity>
    </>
  )
}

interface IReorderSectionControlProps {
  sections: ISectionState[]
  sectionIndex: number
  form: FormikProps<IEditorState>
}

export const ReorderSectionControls: React.FC<IReorderSectionControlProps> = ({
  sections,
  sectionIndex,
  form
}) => {
  const { colors } = useTheme()
  if (sectionIndex === 0) {
    return null
  }

  const isFirst = sectionIndex === 1 // 0 is the mandatory title/hero section
  const isLast = sectionIndex === sections.length - 1
  return (
    <>
      <TouchableOpacity
        disabled={isFirst}
        onPress={() => {
          swapIndicesInPlace(sections, sectionIndex, sectionIndex - 1)
          form.setFieldValue(`sections`, sections)
        }}
      >
        <CaretIcon
          color={isFirst ? colors.disabled : 'black'}
          size={24}
          rotate={180}
        />
      </TouchableOpacity>
      <TouchableOpacity
        disabled={isLast}
        onPress={() => {
          swapIndicesInPlace(sections, sectionIndex, sectionIndex + 1)
          form.setFieldValue(`sections`, sections)
        }}
      >
        <CaretIcon color={isLast ? colors.disabled : 'black'} size={24} />
      </TouchableOpacity>
    </>
  )
}
