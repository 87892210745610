import styled from 'styled-components/native'

const Placeholder = styled.Text(({ theme: { space, fontSizes, colors } }) => ({
  marginLeft: space[2],
  marginTop: space[2] - 2,
  marginBottom: space[1] - 2,
  fontSize: fontSizes[4] - 2,
  color: colors.headerText
}))

export default Placeholder
