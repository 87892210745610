import React, { useState, useCallback, useRef } from 'react'
import { StyleProp, ViewStyle } from 'react-native'
import {
  ContentBlock,
  DefaultDraftBlockRenderMap,
  Editor,
  EditorState,
  RichUtils,
  AtomicBlockUtils
} from 'draft-js'
import { Flex } from '../FlexBox'
import useToggle from '../../hooks/useToggle'
import useFeatureFlag from '../../hooks/useFeatureFlag'
import styled, { useTheme } from 'styled-components/native'
import EditorButton from './EditorButton'
import EditorDropdown from './EditorDropdown'
import LinkModal from './LinkModal'
import { Foundation } from '@expo/vector-icons'
import { H3, H4 } from '../common/Text'
import { Map } from 'immutable'

import 'draft-js/dist/Draft.css'
import './draft-js.css'
import { DropdownOption } from '../common/Dropdown'
import InlineImageUpload from './InlineImageUpload'
import InlineVideoUpload from './InlineVideoUpload'
import { getBlockRendererFn } from './blockRendererComponents'
import useThrottledCallback from '../../hooks/useThrottledCallback'

// This function assigns the 'draftBlockQuote' css class to block quotes.
const blockStylingFn = (contentBlock: ContentBlock): string => {
  const type = contentBlock.getType()
  if (type === 'blockquote') {
    return 'draftBlockQuote'
  }

  return ''
}

export interface RichTextEditorProps {
  style?: React.CSSProperties
  editorState: EditorState
  onChange: (state: EditorState) => void
  placeholder?: string
  hasError?: boolean
  onBlur?: (e: React.SyntheticEvent) => void
  styling?: (contentBlock: ContentBlock) => string
  scrollToEnd?: (timeout?: number) => void
}

const EditorContainer = styled(Flex)`
  width: 100%;
  background: #fff;
  border-radius: 2px;
  border-color: rgba(0, 0, 0, 0.24);
  border-width: 1px;
  overflow: hidden;
  padding: 2px 2px 20px 2px;
  min-height: 250px;
  resize: both;
`

const RichTextEditor = ({
  style = {},
  editorState,
  onChange,
  placeholder,
  hasError,
  onBlur,
  styling,
  scrollToEnd
}: RichTextEditorProps) => {
  const updatedMi6Ui = useFeatureFlag('updatedMi6Ui')
  const editor = useRef(null)
  const theme = useTheme()
  const [currentHeight, setCurrentHeight] = useState(
    style?.height || theme.breakpoints.phone
  )
  const { turnOn, turnOff, isOn } = useToggle(false)
  const [linkModalUrl, setLinkModalUrl] = useState('')

  const toggleBold = () => {
    onChange(RichUtils.toggleInlineStyle(editorState, 'BOLD'))
  }

  const toggleItalic = () => {
    onChange(RichUtils.toggleInlineStyle(editorState, 'ITALIC'))
  }

  const toggleBlockFormatting = formatType => {
    onChange(RichUtils.toggleBlockType(editorState, formatType))
  }

  const handleToggleBlockFormattingOL = useCallback(
    () => toggleBlockFormatting('ordered-list-item'),
    [editorState]
  )

  const handleToggleBlockFormattingUL = useCallback(
    () => toggleBlockFormatting('unordered-list-item'),
    [editorState]
  )
  const getEditorState = () => {
    return editorState
  }

  const blockRendererFn = getBlockRendererFn(
    editor.current,
    getEditorState,
    onChange
  )

  const addInlineMedia = (type, data) => {
    const contentState = editorState.getCurrentContent()

    const contentStateWithEntity = contentState.createEntity(
      type,
      'IMMUTABLE',
      data
    )
    const entityKey = contentStateWithEntity.getLastCreatedEntityKey()
    const newEditorState = EditorState.set(editorState, {
      currentContent: contentStateWithEntity
    })

    onChange(AtomicBlockUtils.insertAtomicBlock(newEditorState, entityKey, ' '))
  }

  const promptLink = () => {
    setLinkModalUrl('')
    turnOn()
  }

  const submitLink = () => {
    turnOff()

    const contentState = editorState.getCurrentContent()
    const contentStateWithEntity = contentState.createEntity(
      'LINK',
      'MUTABLE',
      { url: linkModalUrl }
    )
    const entityKey = contentStateWithEntity.getLastCreatedEntityKey()
    const newEditorState = EditorState.set(editorState, {
      currentContent: contentStateWithEntity
    })

    onChange(
      RichUtils.toggleLink(
        newEditorState,
        newEditorState.getSelection(),
        entityKey
      )
    )
  }

  const removeLink = () => {
    const selection = editorState.getSelection()
    if (!selection.isCollapsed()) {
      onChange(RichUtils.toggleLink(editorState, selection, null))
    }
  }

  let finalStyle: React.CSSProperties = { ...style }
  if (hasError) {
    finalStyle.borderColor = theme.colors.danger
  }

  const activeInlineStyles = editorState.getCurrentInlineStyle()
  const activeBlockStyle = RichUtils.getCurrentBlockType(editorState)
  const selection = editorState.getSelection()
  const linkableSelection =
    selection.getStartKey() === selection.getEndKey() &&
    selection.getStartOffset() !== selection.getEndOffset()
  const unlinkableSelection =
    selection.getStartKey() !== selection.getEndKey() ||
    selection.getStartOffset() !== selection.getEndOffset()

  const textFormattingOptions: DropdownOption[] = [
    { label: 'Heading', value: 'heading' },
    { label: 'Sub-Heading', value: 'sub-heading' },
    { label: 'Body Text', value: 'unstyled' },
    { label: 'Quote', value: 'blockquote' }
  ]

  const blockRenderMap = Map({
    heading: {
      element: H3
    },
    'sub-heading': {
      element: H4
    }
  })

  const extendedBlockRenderMap = DefaultDraftBlockRenderMap.merge(
    blockRenderMap
  )

  const onHeightChange = (height: number) => {
    if (height !== currentHeight) {
      setCurrentHeight(height)
      scrollToEnd && scrollToEnd()
    }
  }

  const throttledOnHeightChange = useThrottledCallback(onHeightChange, 500)

  return (
    <EditorContainer
      flexDirection="column"
      style={finalStyle as StyleProp<ViewStyle>}
      onLayout={evt => {
        throttledOnHeightChange(evt?.nativeEvent?.layout?.height)
      }}
    >
      <Flex
        flexDirection="row"
        flexWrap="wrap"
        style={{
          position: 'relative',
          zIndex: 2
        }}
      >
        <EditorDropdown
          value={activeBlockStyle}
          onSelect={toggleBlockFormatting}
          options={textFormattingOptions}
          placeholder=""
        />
        <EditorButton
          icon={
            <Foundation
              name="bold"
              size={24}
              color={
                activeInlineStyles.has('BOLD') ? theme.colors.primary : 'black'
              }
            />
          }
          onPress={toggleBold}
        />
        <EditorButton
          icon={
            <Foundation
              name="italic"
              size={24}
              color={
                activeInlineStyles.has('ITALIC')
                  ? theme.colors.primary
                  : 'black'
              }
            />
          }
          onPress={toggleItalic}
        />
        <EditorButton
          icon={
            <Foundation
              name="list-bullet"
              size={24}
              color={
                activeBlockStyle === 'unordered-list-item'
                  ? theme.colors.primary
                  : 'black'
              }
            />
          }
          onPress={handleToggleBlockFormattingUL}
        />
        <EditorButton
          icon={
            <Foundation
              name="list-number"
              size={24}
              color={
                activeBlockStyle === 'ordered-list-item'
                  ? theme.colors.primary
                  : 'black'
              }
            />
          }
          onPress={handleToggleBlockFormattingOL}
        />
        <EditorButton
          disabled={!linkableSelection}
          icon={<Foundation name="link" size={24} color="black" />}
          onPress={promptLink}
        />
        <EditorButton
          disabled={!unlinkableSelection}
          icon={<Foundation name="unlink" size={24} color="black" />}
          onPress={removeLink}
        />

        {updatedMi6Ui && (
          <InlineImageUpload
            onAddImage={data => {
              addInlineMedia('image', data)
            }}
          />
        )}

        {updatedMi6Ui && (
          <InlineVideoUpload
            onAddVideo={data => {
              addInlineMedia(data.type, data)
            }}
          />
        )}
      </Flex>

      <Flex width="100%" height="1px" bg="border" my={2} />

      <Editor
        ref={editor}
        editorState={editorState}
        onChange={onChange}
        placeholder={placeholder}
        onBlur={onBlur}
        blockStyleFn={styling ?? blockStylingFn}
        blockRenderMap={!styling ? extendedBlockRenderMap : undefined}
        blockRendererFn={blockRendererFn}
      />

      <LinkModal
        isOpen={isOn}
        onClose={turnOff}
        url={linkModalUrl}
        onUrlChange={setLinkModalUrl}
        onSubmit={submitLink}
      />
    </EditorContainer>
  )
}

export * from './utils'
export default RichTextEditor
