import { useEffect, useMemo, useState } from 'react'
import gql from 'graphql-tag'
import { useQuery } from '@apollo/client'

const gatheringSearchQuery = gql`
  query eventSearch(
    $term: String
    $before: DateTime
    $after: DateTime
    $beforeId: String
    $first: Int
    $selectedId: String
  ) {
    eventSearch(
      term: $term
      before: $before
      after: $after
      beforeId: $beforeId
      first: $first
      selectedId: $selectedId
    ) {
      id
      name
      nameInternal
      startDate
      groups {
        id
        name
      }
      salesforceId
    }
  }
`

const useGatheringSearchQuery = (
  term: string,
  selectedId?: string | null,
  isPageBuilder?: boolean
) => {
  const initialBeforeDate = useMemo(
    () => (isPageBuilder ? undefined : new Date()),
    [isPageBuilder]
  )
  const afterDate = useMemo(() => (term ? undefined : new Date()), [term])
  const [isFirstFetch, setIsFirstFetch] = useState(true)
  const [beforeDate, setBeforeDate] = useState<Date | undefined>(
    initialBeforeDate
  )
  const { data, loading, refetch, fetchMore } = useQuery(gatheringSearchQuery, {
    skip: !term && !isFirstFetch,
    variables: {
      term,
      before: term ? undefined : beforeDate,
      after: isPageBuilder && !term ? afterDate : undefined,
      selectedId,
      isPageBuilder
    },
    onCompleted: () => {
      setIsFirstFetch(false)
    }
  })

  // if fetching a new search term, then search all start dates including future
  useEffect(() => {
    if (term) {
      setBeforeDate(undefined)
    } else {
      setBeforeDate(initialBeforeDate)
    }
  }, [term])

  const events = data && data.eventSearch ? data.eventSearch : []
  const mappedData = useMemo(
    () =>
      events.map(item => ({
        value: item.id,
        label: item.name,
        custom: {
          salesforceId: item.salesforceId,
          nameInternal: item.nameInternal,
          date: item.startDate,
          groups: item.groups
        }
      })),
    [events]
  )

  const fetchMoreGatherings = () => {
    if (loading || events.length === 0) return
    fetchMore({
      variables: {
        term,
        selectedId,
        before: events[events.length - 1].startDate,
        beforeId: events[events.length - 1].id
      } as any,
      updateQuery: (prev, { fetchMoreResult }) => {
        if (fetchMoreResult.eventSearch.length === 0) return prev

        return {
          ...prev,
          eventSearch: [...prev.eventSearch, ...fetchMoreResult.eventSearch]
        }
      }
    })
  }

  return { data: mappedData, refetch, fetchMore: fetchMoreGatherings }
}

export default useGatheringSearchQuery
