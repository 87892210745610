import React, { FC, useState } from 'react'
import styled from 'styled-components/native'
import { IQueueJob } from './queries/queue'
import { Text } from '../../components/common/Text'
import Button from '../../components/common/Button'
import { Foundation } from '@expo/vector-icons'
import { Flex } from '../../components/FlexBox'

const unixTimestampToDate = (timestamp: number) => {
  const timestampString = timestamp.toString()
  const timestampFloat = parseFloat(timestampString)
  const timestampMs = timestampFloat
  return new Date(timestampMs)
}

const isEmptyObject = (obj: object) => {
  return Object.keys(obj).length === 0 && obj.constructor === Object
}

const PipeDivider: FC = () => {
  return <span style={{ color: '#999' }}>|</span>
}

const JobCard = styled.View`
  flex: 1;
  padding: 10px;
  background-color: ${props =>
    props.failed ? props.theme.colors.failure : props.theme.colors.primary};
  border-radius: 8px;
`

const JobHeader = styled.TouchableOpacity`
  flex-direction: row;
  align-items: center;
`

const CopyToClipboardButton: React.FC<{ text: string }> = ({ text }) => {
  return (
    <Button
      style={{ maxWidth: 240, marginTop: 16 }}
      buttonStyle={{ padding: 4 }}
      label=" Copy to Clipboard"
      onPress={() => {
        navigator.clipboard.writeText(text)
      }}
      icon={<Foundation name="clipboard-notes" size={16} color="white" />}
    />
  )
}

export const Job: FC<{ job: IQueueJob }> = ({ job }) => {
  const [expanded, setExpanded] = useState(false)

  const isExpandable = !isEmptyObject(job.data) || !!job.failedReason

  return (
    <JobCard failed={!!job.failedReason}>
      <JobHeader
        onPress={() => {
          setExpanded(!expanded)
        }}
      >
        <Flex
          flex={1}
          flexDirection="row"
          alignItems="center"
          justifyContent={'space-between'}
        >
          <Text>
            {isExpandable ? (expanded ? '▼ ' : '▶ ') : ''}
            <b>{job.name}</b> <PipeDivider /> {job.id}
          </Text>
          <Flex flexDirection="row" alignItems="center">
            <Text>{unixTimestampToDate(job.timestamp).toLocaleString()}</Text>
          </Flex>
        </Flex>
      </JobHeader>
      {expanded && (
        <>
          {isExpandable && (
            <div>
              <CopyToClipboardButton text={JSON.stringify(job.data)} />
              <pre style={{ whiteSpace: 'pre-wrap' }}>
                {JSON.stringify(job.data, null, 2)}
              </pre>
            </div>
          )}
          {job.failedReason && (
            <>
              <Text>
                <b>Failed reason:</b>
              </Text>
              <div
                style={{
                  wordBreak: 'break-word',
                  fontFamily: 'monospace'
                }}
              >
                {job.failedReason}
              </div>
            </>
          )}
        </>
      )}
    </JobCard>
  )
}
