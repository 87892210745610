import gql from 'graphql-tag'
import { useQuery } from '@apollo/client'
import { useNavigation } from '@react-navigation/core'
import useTranslation from '../../../hooks/useTranslation'
import useToast, { ToastType } from '../../../hooks/useToast'

const getDocument = gql`
  query Document($id: String!) {
    document(id: $id) {
      id
      title
      subtitle
      body
      type
      published
      publishDate
      shareable
      image {
        id
        cloudinaryId
        fileName
      }
      file {
        id
        cloudinaryId
        fileName
        scanStatus
        rawConvertStatus
      }
      communities {
        id
      }
      groups {
        id
      }
      people {
        id
        firstName
        lastName
        employerName
      }
      events {
        id
        name
        startDate
        groups {
          id
          name
        }
      }
      event {
        id
      }
      topics {
        id
      }
      personTags(includeUnpublishedPeople: true) {
        person {
          id
          firstName
          lastName
        }
        source {
          __typename
          id
          title
        }
      }
    }
  }
`

const useDocumentQuery = id => {
  const navigation: any = useNavigation()
  const { t } = useTranslation()
  const { showToast } = useToast()
  const { data, loading } = useQuery(getDocument, {
    fetchPolicy: 'network-only',
    variables: { id },
    skip: !id,
    onError: () => {
      showToast(t('library:document:errorLoading'), ToastType.ERROR)
      navigation.replace('LibraryList')
    }
  })

  return { data, loading }
}

export default useDocumentQuery
