import React, { useContext } from 'react'
import Button from '../common/Button'
import { ThemeContext } from 'styled-components/native'

interface EditorButtonProps {
  label?: string
  icon: any
  disabled?: boolean
  onPress: () => void
}

const EditorButton = ({ disabled, onPress, ...rest }: EditorButtonProps) => {
  const theme: any = useContext(ThemeContext)

  return (
    <Button
      buttonStyle={{
        backgroundColor: 'transparent',
        opacity: disabled ? 0.2 : 1,
        // @ts-ignore yes, cursor prop does work
        cursor: disabled ? 'default' : 'pointer'
      }}
      titleStyle={{ color: theme.colors.text }}
      onPress={disabled ? () => null : onPress}
      {...rest}
    />
  )
}

export default EditorButton
