import { useRecoilState, SetterOrUpdater } from 'recoil'
import featureFlagAtom from '../recoil/atoms/featureFlagAtom'

export interface FeatureFlagHelpers {
  setFeatureFlags: SetterOrUpdater<object>
  featureFlags: object
}

const useFeatureFlagSystem = (): FeatureFlagHelpers => {
  const [featureFlags, setFeatureFlags] = useRecoilState(featureFlagAtom) as [
    object,
    SetterOrUpdater<object>
  ]

  return { setFeatureFlags, featureFlags }
}

export default useFeatureFlagSystem
