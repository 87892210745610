import React, { useState } from 'react'
import { View, Platform } from 'react-native'
import Dropdown, {
  DropdownOption,
  DropdownProps,
  DropdownValue
} from '../Dropdown'
import useDebounce from '../../../hooks/useDebounce'
import styled from 'styled-components/native'
// eslint-disable-next-line no-restricted-imports
import styledWeb from 'styled-components'

const StylableView = Platform.OS === 'web' ? styledWeb.div : styled(View)

const Container = StylableView`
  display: flex;
  flex-direction: row;
  width: 100%;
`
// Disabled filter so the API can perform all the work
const filterNone = () => true

const AutoCompleteDropdown = ({
  options,
  onSelectValue,
  value,
  placeholder,
  disableFiltering = false,
  loading = false,
  styles,
  formatOptionLabel,
  shortMultiSelect,
  ...other
}: AutoCompleteDropdownProps) => {
  const [queryString, setQueryString] = useState('')
  const debouncedQueryString = useDebounce(queryString, 400)

  return (
    <Container>
      <Dropdown
        {...other}
        options={options}
        value={value}
        onInputChange={value => setQueryString(value)}
        placeholder={placeholder}
        onBlur={() => {}}
        onSelect={onSelectValue}
        style={styles}
        isClearable
        filterOption={disableFiltering ? filterNone : undefined}
        noOptionsMessage={
          debouncedQueryString
            ? loading
              ? 'Loading...'
              : 'No options found'
            : `Search ${placeholder}...`
        }
        shortMultiSelect={shortMultiSelect}
        formatOptionLabel={formatOptionLabel}
      />
    </Container>
  )
}

interface AutoCompleteDropdownProps {
  options: DropdownOption[]
  value: DropdownValue | null
  placeholder: string
  onSelectValue: (value: string) => void
  disableFiltering?: boolean
  loading?: boolean
  styles?: any
  formatOptionLabel?: DropdownProps['formatOptionLabel']
  isMulti?: boolean
  shortMultiSelect?: boolean
}

export default AutoCompleteDropdown
