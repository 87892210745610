import { gql, useQuery } from '@apollo/client'
import React, { useCallback, useMemo, useState } from 'react'
import { View, ViewStyle, StyleProp } from 'react-native'
import Dropdown from '../Dropdown'
import useDebounce from '../../../hooks/useDebounce'
import styled from 'styled-components/native'
import usePersonSearchQuery from './hooks/usePersonSearchQuery'
import AnonIcon from '../../../images/anonym.svg'
import { formatPerson } from '../../../utils/format'
import { Text } from '../Text'
import useTranslation from '../../../hooks/useTranslation'

const Container = styled(View)`
  display: flex;
  flex-direction: row;
  width: 100%;
`

const currentPersonQuery = gql`
  query getPerson($id: String!) {
    person(id: $id, includeUnpublishedPeople: true) {
      id
      firstName
      lastName
      taggedMedia {
        __typename
        id
        title
      }
      taggedDocuments {
        __typename
        id
        title
      }
      employerName
      emailAddresses {
        email
      }
      salesforceId
    }
  }
`

const PersonTagDropdown = ({
  personId,
  sourceId,
  onValueChange,
  autoFilled,
  excludeIds,
  hideSourceId,
  containerStyle
}: PersonTagDropdownProps) => {
  const [queryString, setQueryString] = useState('')
  const debouncedQueryString = useDebounce(queryString, 400)
  const { data: personOptions, loading: loadingSearch } = usePersonSearchQuery(
    debouncedQueryString
  )

  const { t } = useTranslation()
  const { data: currentPersonData } = useQuery(currentPersonQuery, {
    variables: { id: personId },
    skip: !personId,
    fetchPolicy: 'cache-and-network'
  })

  const currentPerson =
    currentPersonData && currentPersonData.person
      ? currentPersonData.person
      : null

  const sourceOptions = useMemo(() => {
    if (!currentPerson) {
      return []
    }

    const options = [
      ...currentPerson.taggedMedia,
      ...currentPerson.taggedDocuments
    ]
      .sort((elem1, elem2) => (elem1.createdAt > elem2.createdAt ? 1 : -1))
      .map(({ id, title, __typename }) => ({
        value: id,
        label: `${__typename}: ${title}`,
        custom: {
          sourceType: __typename.toLowerCase()
        }
      }))

    return options
  }, [currentPerson])

  const onPersonValueChange = useCallback(
    newPersonId => {
      onValueChange(newPersonId ? { personId: newPersonId } : null)
    },
    [onValueChange]
  )

  const onSourceValueChange = useCallback(
    newSourceId => {
      const selectedSourceOption = sourceOptions.find(
        ({ value }) => value === newSourceId
      )
      const newSourceValue = selectedSourceOption && {
        sourceId: selectedSourceOption.value,
        sourceType: selectedSourceOption.custom.sourceType
      }
      onValueChange({ personId, source: newSourceValue })
    },
    [personId, sourceOptions, onValueChange]
  )

  const currentOption = currentPerson
    ? {
        value: currentPerson.id,
        label: formatPerson(currentPerson)
      }
    : null

  const filteredOptions = personOptions.filter(
    ({ value }) => !excludeIds.includes(value)
  )

  return (
    <Container style={containerStyle}>
      <Dropdown
        options={[
          {
            value: '',
            label: (
              <Text styles={{ fontStyle: 'italic', fontSize: 11 }}>
                {t('forms:personTagDropdown:hint')}
              </Text>
            ),
            isDisabled: true
          },
          ...(currentOption ? [currentOption] : []),
          ...filteredOptions
        ]}
        value={personId}
        onInputChange={value => setQueryString(value)}
        placeholder={'Featured Guest'}
        onBlur={() => {}}
        leftIcon={<AnonIcon />}
        onSelect={onPersonValueChange}
        style={{
          minWidth: '30%',
          borderRadius: 16,
          ...(autoFilled ? { borderColor: '#2F2' } : {})
        }}
        isClearable
        noOptionsMessage={
          debouncedQueryString
            ? loadingSearch
              ? 'Loading...'
              : 'No options found'
            : 'Search person...'
        }
      />

      {!hideSourceId && (
        <Dropdown
          options={sourceOptions}
          value={sourceId}
          placeholder={'Source (if applicable)'}
          onSelect={onSourceValueChange}
          style={{
            minWidth: '60%',
            marginLeft: 10,
            borderRadius: 16,
            backgroundColor: '#fff'
          }}
          isClearable
          isDisabled={!sourceOptions.length}
        />
      )}
    </Container>
  )
}

export interface PersonTag {
  personId: string
  sourceId?: string
}

interface PersonTagDropdownProps {
  hideSourceId?: boolean
  personId?: any
  sourceId?: any
  onValueChange: (
    selected: {
      personId: string
      source?: { sourceId: string; sourceType: string }
    } | null
  ) => void
  autoFilled?: boolean
  excludeIds: string[]
  containerStyle?: StyleProp<ViewStyle>
}

export default PersonTagDropdown
