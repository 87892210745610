import { gql } from '@apollo/client'

const hostMediaFragment = gql`
  fragment hostMediaFragment on HostMedia {
    id
    hostId
    name
    url
    type
    host
    status
    transcode
    createdAt
  }
`

export default hostMediaFragment
