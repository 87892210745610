import gql from 'graphql-tag'
import { useMutation } from '@apollo/client'

const createHostMediaMutation = gql`
  mutation createHostMedia(
    $hostId: String!
    $host: MediaHostEnum!
    $name: String
    $type: MediaTypeEnum!
    $status: MediaStatusEnum!
    $transcode: MediaStatusEnum!
    $url: String
    $duration: Float
    $height: Int
    $width: Int
  ) {
    createHostMedia(
      hostId: $hostId
      name: $name
      host: $host
      type: $type
      status: $status
      transcode: $transcode
      url: $url
      duration: $duration
      height: $height
      width: $width
    ) {
      id
      hostId
      type
      host
      name
      status
      transcode
      url
      duration
      height
      width
    }
  }
`

const useCreateHostMediaMutation = (options = {}) => {
  const [createHostMedia]: any[] = useMutation(createHostMediaMutation, options)
  return createHostMedia
}

export default useCreateHostMediaMutation
