import gql from 'graphql-tag'
import { useQuery } from '@apollo/client'
import { useMemo } from 'react'
import { formatPerson } from '../../../../utils/format'

const searchQuery = gql`
  query searchPeople($term: String!) {
    search(
      query: $term
      adminSearch: true
      includeNonMembers: true
      includeUnpublishedPeople: true
      index: ["person"]
      first: 20
    ) {
      requestId
      total
      hits {
        ... on Person {
          id
          firstName
          lastName
          employerName
          emailAddresses {
            email
          }
          salesforceId
        }
      }
    }
  }
`

const usePersonSearchQuery = (term?: string) => {
  const { data, loading, refetch } = useQuery(searchQuery, {
    variables: {
      term
    },
    skip: !term
  })

  const people = data && data.search ? data.search.hits : []
  const mappedData = useMemo(
    () =>
      people
        .filter(person => !!person)
        .map(item => ({
          value: item.id,
          label: formatPerson(item)
        }))
        .sort((opt1, opt2) => (opt1.label < opt2.label ? -1 : 1)),
    [people]
  )

  return { data: mappedData, refetch, loading }
}

export default usePersonSearchQuery
