import React, { useState } from 'react'
import { Text, TouchableOpacity, View, ViewStyle } from 'react-native'
import styled from 'styled-components/native'

import useTranslation from '../../../hooks/useTranslation'
import MediaUploader, { MediaUploaderProps } from './MediaUploader'

interface MultipleMediaUploaderProps {
  fullMediaProps: MediaUploaderProps
  highlightsMediaProps: MediaUploaderProps
}

const borderColor = 'rgba(0, 0, 0, 0.24)'

const GlobalContainer = styled(View)`
  width: 500px;
  min-height: 290px;
`

const UploaderContainer = styled(View)`
  ${({ show, theme: { radii, borderWidths, colors } }) => `
        position: absolute;
        top: 0px;
        bottom: 0px;
        left: 0px;
        right: 0px;
        width: 450px;
        min-height: 265px;
        z-index: ${show ? 10 : 0};
        background-color: ${colors.background};
        opacity: ${show ? 1 : 0};
        border-width: ${borderWidths[1]}px;
        border-top-width: 0px;
        border-color: ${borderColor};
        border-bottom-left-radius: ${radii[1]};
        border-bottom-right-radius: ${radii[1]};
        justify-content: center;
        align-items: center;
    `}
`

const TabsContainer = styled(View)`
  flex-direction: row;
  justify-content: space-between;
  width: 450px;
`

const Tab = styled(TouchableOpacity).attrs(() => ({
  activeOpacity: 1
}))`
  ${({ active, theme: { radii, borderWidths } }) => `
    width: 50%;
    border-width: ${borderWidths[1]}px;
    border-bottom-width: ${active ? 0 : borderWidths[1]}px;
    border-top-left-radius: ${radii[1]}px;
    border-top-right-radius: ${radii[1]}px;
    border-color: ${borderColor};
    justify-content: center;
    align-items: center;
  `}
`

const TabName = styled(Text)`
  ${({ active, theme: { space, colors } }) => `
    margin-vertical: ${space[1]}px;
    color: ${active ? colors.text : colors.disabled};
  `}
`

const uploaderStyle: ViewStyle = {
  width: 400,
  minHeight: 240
}

const MultipleMediaUploader = ({
  fullMediaProps,
  highlightsMediaProps
}: MultipleMediaUploaderProps) => {
  const [currentUploader, setCurrentUploader] = useState('full')
  const { t } = useTranslation()

  const mainTabActive = currentUploader === 'full'
  return (
    <GlobalContainer>
      <TabsContainer>
        <Tab active={mainTabActive} onPress={() => setCurrentUploader('full')}>
          <TabName active={mainTabActive}>{t('media:mainMedia')}</TabName>
        </Tab>
        <Tab
          active={!mainTabActive}
          onPress={() => setCurrentUploader('highlights')}
        >
          <TabName active={!mainTabActive}>{t('media:highlights')}</TabName>
        </Tab>
      </TabsContainer>
      <View>
        <UploaderContainer show={currentUploader === 'full'}>
          <MediaUploader {...fullMediaProps} style={uploaderStyle} />
        </UploaderContainer>
        <UploaderContainer show={currentUploader === 'highlights'}>
          <MediaUploader {...highlightsMediaProps} style={uploaderStyle} />
        </UploaderContainer>
      </View>
    </GlobalContainer>
  )
}

export default MultipleMediaUploader
