import { useNavigation } from '@react-navigation/native'
import { useEffect } from 'react'
import { useRecoilState } from 'recoil'
import deletedItemAtom from '../recoil/atoms/deletedItemAtom'

// Hook to avoid a table refetch immediately after deleting
// to workaround issue with AppSearch refresh time.
const useTableRefetch = (refetch?: () => void) => {
  const navigation = useNavigation()
  const [deletedItem, setDeletedItem] = useRecoilState(deletedItemAtom)

  useEffect(() => {
    return navigation.addListener('focus', () => {
      if (deletedItem) {
        setDeletedItem(false)
      } else {
        refetch?.()
      }
    })
  }, [navigation, deletedItem, refetch])
}

export default useTableRefetch
