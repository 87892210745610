import React, { useState } from 'react'
import i18next from 'i18next'
import Dropdown, {
  DefaultOptionLabel,
  DropdownOption,
  DropdownTitle,
  DropdownSubtitle
} from '../Dropdown'
import { components as RScomponents } from 'react-select'
import { Flex } from '../../FlexBox'
import Placeholder from '../Placeholder'
import useDebounce from '../../../hooks/useDebounce'
import useSearchPeopleQuery from './hooks/useSearchPeopleQuery'
// @ts-ignore
import AddAudienceIcon from '../../../images/add-audience.svg'

interface Props {
  onSelect: (value: [string] | string | null) => void
  onBlur: (value: string | null) => void
  value: [string] | null
  hasError: boolean
  style?: any
  placeholder?: string
  styleContainer?: any
  initialOptions?: object
}
const PeopleSelectOption = ({ label, custom }: DropdownOption) => (
  <>
    <DropdownTitle>{label}</DropdownTitle>
    {(custom as any)?.employerName && (
      <DropdownSubtitle numberOfLines={1} ellipsizeMode={'middle'}>
        {` (${(custom as any)?.employerName || ''})`}
      </DropdownSubtitle>
    )}
  </>
)

const PeopleSelectMultiValue = props => (
  <RScomponents.MultiValueLabel {...props}>
    {props.children}
    {props.data?.custom?.employerName && (
      <DropdownSubtitle>{props.data.custom.employerName}</DropdownSubtitle>
    )}
  </RScomponents.MultiValueLabel>
)

const PeopleSelect = ({
  style,
  styleContainer,
  placeholder = i18next.t('forms:audienceType:users'),
  initialOptions = {},
  onSelect,
  ...rest
}: Props) => {
  const [currentSelection, setCurrentSelection] = useState({})
  const [userSearchTerm, setUserSearchTerm] = useState(null)
  const debouncedUsersTerm = useDebounce(userSearchTerm, 300)
  const { data: userList } = useSearchPeopleQuery({ query: debouncedUsersTerm })

  return (
    <Flex flexDirection="column" style={styleContainer}>
      <Placeholder>{placeholder}</Placeholder>
      <Dropdown
        isMulti
        options={[
          ...Object.values(initialOptions),
          ...Object.values(currentSelection),
          ...userList
        ]}
        placeholder={''}
        style={{ backgroundColor: 'transparent', marginRight: 10, ...style }}
        closeMenuOnSelect
        formatOptionLabel={(props, meta) =>
          meta?.context === 'value' ? (
            <DefaultOptionLabel {...props} />
          ) : (
            <PeopleSelectOption {...props} />
          )
        }
        onInputChange={setUserSearchTerm}
        onSelect={el => {
          if (Array.isArray(el)) {
            let newSelection = {}
            el.forEach(
              selection =>
                (newSelection = {
                  ...newSelection,
                  [selection]:
                    initialOptions[selection] ||
                    currentSelection[selection] ||
                    userList.find(person => person.value === selection)
                })
            )
            setCurrentSelection(newSelection)
          }
          onSelect(el)
        }}
        dropdownIcon={<AddAudienceIcon />}
        MultiValueLabel={PeopleSelectMultiValue}
        filterOption={() => true}
        {...rest}
      />
    </Flex>
  )
}

export default PeopleSelect
