import * as Yup from 'yup'
import { MediaStatusEnum, MediaTypeEnum, MediaHostEnum } from '../../../types'
import { taggedPeopleSchema } from '../../../validators/taggedPeopleSchema'
import { topicsSchema } from '../../../validators/topicsSchema'
const hostMediaSchema = Yup.object()
  .shape({
    id: Yup.string().nullable(),
    hostId: Yup.string().nullable(),
    host: Yup.mixed()
      .oneOf([...Object.values(MediaHostEnum)])
      .nullable(),
    type: Yup.mixed()
      .oneOf([...Object.values(MediaTypeEnum)])
      .nullable(),
    name: Yup.string().nullable(),
    url: Yup.string().nullable(),
    status: Yup.mixed()
      .oneOf([...Object.values(MediaStatusEnum)])
      .nullable(),
    transcode: Yup.mixed()
      .oneOf([...Object.values(MediaStatusEnum)])
      .nullable()
  })
  .nullable()

const saveSchema = Yup.object().shape({
  id: Yup.string().nullable(),
  description: Yup.object(),
  title: Yup.string().min(1, 'Too Short!').required('Required'),
  subtitle: Yup.string().nullable(),
  category: Yup.string().nullable(),
  gathering: Yup.string().nullable(),
  hostMediaId: Yup.string().nullable(),
  highlightsHostMediaId: Yup.string().nullable(),
  heroImage: Yup.object()
    .shape({
      fileName: Yup.string().nullable(),
      url: Yup.string().nullable(),
      publicId: Yup.string().nullable()
    })
    .nullable(),
  communities: Yup.array().nullable().of(Yup.string()),
  groups: Yup.array().nullable().of(Yup.string()),
  people: Yup.array().nullable().of(Yup.string()),
  events: Yup.array().nullable().of(Yup.string()),
  published: Yup.bool(),
  publishDate: Yup.date().nullable(),
  hostMedia: hostMediaSchema,
  highlightsHostMedia: hostMediaSchema,
  topics: topicsSchema.nullable(),
  taggedPeople: taggedPeopleSchema.nullable()
})

export default saveSchema
