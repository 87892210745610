import React, {
  FunctionComponent,
  useState,
  useImperativeHandle,
  forwardRef
} from 'react'
import useTranslation from '../../hooks/useTranslation'
import { View, FlatList } from 'react-native'
import { Text } from 'react-native-elements'
import styled from 'styled-components/native'
import { useQuery, ApolloError } from '@apollo/client'
import { DocumentNode } from 'graphql'
import { DropdownOption } from './Dropdown'
import TableHeaderCell from './TableHeaderCell'
import useFeatureFlag from '../../hooks/useFeatureFlag'
/**
 * Show an infinite-scroll table of results from a query,
 * optionally with sortable columns.
 */
function TableComponent<T>(
  {
    columns,
    query,
    keyExtractor = defaultKeyExtractor,
    pageSize = 10,
    plainOffset = false
  }: TableProps<T>,
  ref
) {
  // initialize sort state
  const initialSortState = getInitialSortState(columns) || [false, false]
  const [[sortBy, sortDir], updateSort] = useState(initialSortState)
  const [queryFilters, setQueryFilters] = useState({
    ...getInitialFilters(columns)
  })
  const isAppSearchEnabled = useFeatureFlag('appSearch')

  const { data, fetchMore, loading, error, refetch } = useQuery(query, {
    variables: { size: pageSize, sortBy, sortDir, ...queryFilters }
  })

  useImperativeHandle(ref, () => ({ refetch }))
  const handleOnSort = newSortBy => {
    let newSortDir
    if (newSortBy === sortBy) {
      newSortDir = sortDir === 'asc' ? 'desc' : 'asc'
    } else {
      newSortDir = 'asc'
    }
    updateSort([newSortBy, newSortDir])
  }

  const fetchMoreResults = () => {
    if (!data || loading) return

    const offset =
      isAppSearchEnabled && !plainOffset
        ? Math.ceil(items.length / pageSize) + 1
        : items.length

    fetchMore({
      variables: {
        offset,
        size: pageSize,
        sortBy,
        sortDir,
        ...queryFilters
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        const typeKey = Object.keys(fetchMoreResult)[0]
        const newHits = fetchMoreResult?.[typeKey]?.hits || []

        const previousHits = prev?.[typeKey]?.hits || []

        if (newHits.length === 0) return prev

        return {
          ...prev,
          [typeKey]: {
            ...prev?.[typeKey],
            hits: [...previousHits, ...(newHits || [])]
          }
        }
      }
    })
  }

  let items = []
  if (data?.searchEntity) {
    items = data ? data?.searchEntity?.hits.filter(n => n) : []
  } else if (data?.searchUsers) {
    items = data ? data?.searchUsers?.hits.filter(n => n) : []
  }
  return (
    <TableInner
      columns={columns}
      items={items}
      keyExtractor={keyExtractor}
      error={error}
      loading={loading}
      sortBy={sortBy}
      sortDir={sortDir}
      queryFilters={queryFilters}
      handleOnSort={handleOnSort}
      setQueryFilters={setQueryFilters}
      fetchMoreResults={fetchMoreResults}
    />
  )
}

export interface TableInnerProps<T> {
  columns: (
    | ColumnDefinition<T>
    | SortableColumnDefinition<T>
    | FilterableColumnDefinition<T>
  )[]
  items: never[]
  keyExtractor?: (item: any) => any
  error: ApolloError | undefined
  loading: boolean
  queryFilters: FilterableColumnProps<any> | {}
  sortBy: string | boolean
  sortDir: sortDir | boolean
  handleOnSort: (newSortBy: any) => void
  setQueryFilters: React.Dispatch<any>
  fetchMoreResults: () => void
}

export function TableInnerComponent<T>(
  {
    columns,
    items,
    keyExtractor = defaultKeyExtractor,
    error,
    loading,
    sortBy,
    sortDir,
    queryFilters,
    handleOnSort,
    setQueryFilters,
    fetchMoreResults
  }: TableInnerProps<T>,
  ref
) {
  const { t } = useTranslation()
  return (
    <TableContainer>
      {error && <Text>{t('error:errorLoadingItems')}</Text>}
      <FlatList
        ref={ref}
        data={items}
        keyExtractor={keyExtractor}
        ListEmptyComponent={
          loading ? (
            <Text>{t('common:loading')}</Text>
          ) : (
            <TableRow>
              <TableCell>
                <Text>{t('error:noItemsFound')}</Text>
              </TableCell>
            </TableRow>
          )
        }
        ListHeaderComponent={
          <TableRow header>
            {columns.map(
              (
                // @ts-ignore if sort isn't defined, then it's undefined
                { header, sort, width, filter, headerStyle = {}, ...rest },
                i
              ) => {
                return (
                  <TableCell width={width} key={`${header}_${i}`}>
                    <TableHeaderCell
                      header={header}
                      headerStyle={headerStyle}
                      orderBy={sortBy}
                      orderDir={sortDir}
                      sort={sort}
                      filter={filter}
                      queryFilter={queryFilters}
                      handleChangeSort={() => handleOnSort(sort.key)}
                      handleChangeFilters={change => {
                        const key = Array.isArray(change)
                          ? change[0]
                          : filter.key
                        const value = Array.isArray(change) ? change[1] : change
                        setQueryFilters({
                          ...queryFilters,
                          [key]: value
                        })
                      }}
                      {...rest}
                    />
                  </TableCell>
                )
              }
            )}
          </TableRow>
        }
        renderItem={({ item, index }) => (
          <HoverableRow rowStyle={index % 2 === 0 ? 'even' : 'odd'}>
            {columns.map(({ Cell, width }, i) => (
              <TableCell key={i} width={width}>
                <Cell item={item} />
              </TableCell>
            ))}
          </HoverableRow>
        )}
        onEndReached={fetchMoreResults}
        onEndReachedThreshold={0.1}
      />
    </TableContainer>
  )
}

export const TableInner = forwardRef(TableInnerComponent)

/*
 * Extracted Elements
 */
const TableContainer = styled(View)`
  flex: 1;
  z-index: unset;
`
export const TableRow = styled(View)`
  display: flex;
  flex-direction: row;
  align-items: ${({ header }) => (header ? 'flex-start' : 'center')};
  padding: 0 10px;
  margin: 2px 0;
  border-radius: 8px;
  ${({ rowStyle }) =>
    rowStyle === 'even'
      ? `
      background-color: rgba(196, 196, 196, 0.14);
    `
      : ``}
  ${({ hover }) =>
    hover
      ? `
      background-color: rgba(0, 104, 144, 0.1);
    `
      : ``}
`
const TableCell = styled(View)`
  ${({ width }) => (width ? `width: ${width}px` : 'flex: 1')};
  padding: 5px 8px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: unset;
`

const HoverableRow = ({ children, ...rest }: any) => {
  const [hover, setHover] = useState(false)

  const onMouseEnter = () => setHover(true)
  const onMouseLeave = () => setHover(false)

  return (
    <TableRow
      hover={hover}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      {...rest}
    >
      {children}
    </TableRow>
  )
}

/*
 * Types
 */

export type sortDir = 'asc' | 'desc'

export type CellRendererProps<T> = {
  item: T
}

export type CellRenderer<T> = FunctionComponent<CellRendererProps<T>>

export interface SortableColumnProps<T> {
  key: string
  isDefault?: boolean
  defaultDir?: sortDir
}

export interface ColumnDefinition<T> {
  header: string
  Cell: CellRenderer<T>
  width?: Number
}

export interface SortableColumnDefinition<T> extends ColumnDefinition<T> {
  sort: SortableColumnProps<T> | false
}

export type filterType = 'search' | 'filter'

export interface FilterableColumnProps<T> {
  key: string
  type: filterType
  options?: DropdownOption[]
  defaultValue?: T
  placeholder?: string
}
export interface FilterableColumnDefinition<T> extends ColumnDefinition<T> {
  filter: FilterableColumnProps<T> | false
}

export interface TableProps<T> {
  columns: (
    | ColumnDefinition<T>
    | SortableColumnDefinition<T>
    | FilterableColumnDefinition<T>
  )[]
  query: DocumentNode
  keyExtractor?: (item: T) => string
  pageSize?: number
  plainOffset?: boolean // This prop is used to disable the offset calculation based on the page size
}
export type SortState = [string, sortDir]

/*
 * Helper Functions
 */

/**
 * Given a list of ColumnDefinitions, select the one to use as the default sort.
 */
function getDefaultSortColumn<T>(
  columns: (ColumnDefinition<T> | SortableColumnDefinition<T>)[]
): SortableColumnDefinition<T> | undefined {
  // columns marked as sortDefault
  const sortDefaultCols = columns.filter(
    c => c['sort'] && c['sort']['isDefault']
  )
  if (sortDefaultCols.length === 1)
    return sortDefaultCols[0] as SortableColumnDefinition<T>
  if (sortDefaultCols.length > 1) {
    throw new Error(
      `Cannot specify more than one sortDefault column, found ${sortDefaultCols.length}`
    )
  }
  // none marked as sortDefault, take first sortable column
  const sortableCols = columns.filter(c => c['sort'])
  if (sortableCols.length > 1)
    return sortableCols[0] as SortableColumnDefinition<T>
}

/**
 * Builds the initial SortState from a list of column definitions
 * SortState: [ sortKey, sortDir ]
 *
 * sortKey: will be sent to the Query to identify the sort "column"
 * sortDir: ASC or DESC. defaults to ASC, unless overridden in column config
 */
function getInitialSortState<T>(
  columns: ColumnDefinition<T>[]
): SortState | false {
  const defaultSortColumn = getDefaultSortColumn(columns)
  return defaultSortColumn
    ? [
        // @ts-ignore
        defaultSortColumn.sort.key,
        // @ts-ignore
        defaultSortColumn.sort.defaultDir || 'asc'
      ]
    : false
}

const getInitialFilters = columns => {
  const result: any[] = []
  columns.forEach(column => {
    if (!column.filter) {
      return
    }
    let filters = Array.isArray(column.filter) ? column.filter : [column.filter]
    filters.forEach(filter => {
      if (filter.defaultValue !== undefined) {
        result.push(filter)
      }
    })
  })
  return result.reduce(
    (acc, { key, defaultValue }) => ({
      ...acc,
      [key]: defaultValue
    }),
    {}
  )
}

/**
 * Extract Key from item id
 */
const defaultKeyExtractor = (item: any) => item?.id

export default forwardRef(TableComponent)
