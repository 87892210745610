import * as Yup from 'yup'
import {
  MediaCategoryEnum,
  MediaStatusEnum,
  MediaTypeEnum,
  MediaHostEnum,
  GenericObject
} from '../../../types'
import { taggedPeopleSchema } from '../../../validators/taggedPeopleSchema'
import { topicsSchema } from '../../../validators/topicsSchema'

const hostMediaSchema = Yup.object()
  .nullable()
  .shape({
    id: Yup.string().nullable().required('Required'),
    hostId: Yup.string().nullable().required('Required'),
    host: Yup.mixed()
      .oneOf([...Object.values(MediaHostEnum)])
      .nullable(),
    type: Yup.mixed()
      .oneOf([...Object.values(MediaTypeEnum)])
      .nullable(),
    name: Yup.string().nullable(),
    url: Yup.string().nullable(),
    status: Yup.mixed().oneOf([MediaStatusEnum.Complete]).required('Required'),
    transcode: Yup.mixed()
      .oneOf([MediaStatusEnum.Complete])
      .required('Required')
  })

const publishSchema = Yup.object().shape(
  {
    id: Yup.string().nullable(),
    description: Yup.object().test(
      'required',
      'Required',
      (value: GenericObject) => value.getCurrentContent().hasText()
    ),
    title: Yup.string().required('Required'),
    subtitle: Yup.string().required('Required'),
    category: Yup.string()
      .required('Required')
      .oneOf(Object.values(MediaCategoryEnum), 'Required'),
    gathering: Yup.string().nullable(),
    hostMediaId: Yup.string().nullable().required('Required'),
    heroImage: Yup.object().shape({
      fileName: Yup.string().nullable(),
      url: Yup.string().required(),
      publicId: Yup.string().required()
    }),
    communities: Yup.array().when(['groups', 'gatherings', 'people'], {
      is: (groups, gatherings, people) =>
        (!groups || groups.length === 0) &&
        (!gatherings || gatherings.length === 0) &&
        (!people || people.length === 0),
      then: Yup.array().min(1, 'atLeastAudienceType')
    }),
    groups: Yup.array().when(['communities', 'gatherings', 'people'], {
      is: (communities, gatherings, people) =>
        (!communities || communities.length === 0) &&
        (!gatherings || gatherings.length === 0) &&
        (!people || people.length === 0),
      then: Yup.array().min(1, 'atLeastAudienceType')
    }),
    gatherings: Yup.array().when(['communities', 'groups', 'people'], {
      is: (communities, groups, people) =>
        (!communities || communities.length === 0) &&
        (!groups || groups.length === 0) &&
        (!people || people.length === 0),
      then: Yup.array().min(1, 'atLeastAudienceType')
    }),
    people: Yup.array().when(['communities', 'groups', 'gatherings'], {
      is: (communities, groups, gatherings) =>
        (!communities || communities.length === 0) &&
        (!groups || groups.length === 0) &&
        (!gatherings || gatherings.length === 0),
      then: Yup.array().min(1, 'atLeastAudienceType')
    }),
    published: Yup.bool(),
    publishDate: Yup.date().nullable().required('Required'),
    hostMedia: hostMediaSchema.required('Required'),
    highlightsHostMedia: hostMediaSchema.notRequired(),
    topics: topicsSchema.nullable(),
    taggedPeople: taggedPeopleSchema.nullable()
  },
  [
    ['communities', 'groups'],
    ['communities', 'gatherings'],
    ['communities', 'people'],
    ['groups', 'communities'],
    ['groups', 'gatherings'],
    ['groups', 'people'],
    ['gatherings', 'communities'],
    ['gatherings', 'groups'],
    ['gatherings', 'people'],
    ['people', 'communities'],
    ['people', 'groups'],
    ['people', 'gatherings']
  ]
)

export default publishSchema
