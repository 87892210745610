import { DraftBlockType, EditorState } from 'draft-js'
import React, { useEffect, useState } from 'react'
import RichTextEditor, {
  RichTextEditorProps
} from '../../../components/RichTextEditor'
import { useDebouncedCallback } from 'use-debounce'

const INPUT_DELAY = 200

const richtextEditorClasses: { [key: DraftBlockType]: string } = {
  heading: 'summit-heading',
  'sub-heading': 'summit-sub-heading',
  unstyled: 'summit-unstyled',
  blockquote: 'summit-blockquote',
  'unordered-list-item': 'summit-unordered-list-item',
  'ordered-list-item': 'summit-ordered-list-item'
}

export const SummitRichTextEditor: React.FC<RichTextEditorProps> = ({
  onChange,
  editorState,
  ...props
}) => {
  const [internalState, setInternalState] = useState<EditorState>(editorState)
  useEffect(() => {
    setInternalState(editorState)
  }, [editorState])

  const debouncedHandleOnChange = useDebouncedCallback((x: EditorState) => {
    onChange && onChange(x)
  }, INPUT_DELAY)

  return (
    <RichTextEditor
      styling={block => {
        const cssClass = richtextEditorClasses[block.getType()] ?? ''
        return cssClass
      }}
      editorState={internalState}
      onChange={x => {
        setInternalState(x)
        debouncedHandleOnChange(x)
      }}
      {...props}
    />
  )
}
