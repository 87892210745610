import React, { useState } from 'react'
import KebabIcon from '../../../images/kebab.svg'
import { View } from 'react-native'
import { Button } from 'react-native-elements'
import useTranslation from '../../../hooks/useTranslation'
import Colors from '../../../constants/Colors'
import { Menu, MenuItem } from 'react-native-material-menu'
import ManageRolesModal from './roles/modal'

const UserManageMenu = ({ person }) => {
  const { t } = useTranslation()
  const [menuOpen, setMenuOpen] = useState(false)
  const [modalOpen, setModalOpen] = useState(false)

  const toggleMenu = () => setMenuOpen(!menuOpen)
  const toggleModal = action => {
    switch (action) {
      case 'manage':
        setModalOpen(!modalOpen)
        break
      default:
        break
    }
    if (!modalOpen) {
      toggleMenu()
    }
    menuOpen && toggleMenu()
  }
  const closeModal = () => {
    setModalOpen(false)
  }

  const menuItems = [
    {
      label: t('users:roles:manage'),
      action: 'manage'
    },
    {
      label: t('users:roles:duplicate'),
      action: 'duplicate',
      isDisabled: true
    },
    {
      label: t('users:roles:append'),
      action: 'append',
      isDisabled: true
    }
  ]

  return (
    <>
      <View
        style={{
          height: '100%',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        <Menu
          style={{ width: 150 }}
          visible={menuOpen}
          anchor={
            <Button icon={<KebabIcon />} onPress={toggleMenu} type="clear" />
          }
          onRequestClose={toggleMenu}
        >
          {menuItems.map(({ action, label, isDisabled }, index) => (
            <MenuItem
              key={index}
              textStyle={isDisabled && { color: Colors.light.disabled }}
              onPress={() => toggleModal(action)}
            >
              {label}
            </MenuItem>
          ))}
        </Menu>
      </View>

      {modalOpen && (
        <ManageRolesModal
          isOpen={modalOpen}
          person={person}
          onClose={closeModal}
        />
      )}
    </>
  )
}

export default UserManageMenu
