import React from 'react'
import CheckBox from '../CheckBox'
import useTranslation from '../../../hooks/useTranslation'

interface Props {
  handlePressed: () => void
  published: boolean
  style?: any
}

const PublishCheckbox = ({ handlePressed, published }: Props) => {
  const { t } = useTranslation()

  return (
    <CheckBox
      title={t('common:labels:publish')}
      checked={published}
      onPress={handlePressed}
    />
  )
}

export default PublishCheckbox
