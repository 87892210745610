import styled from 'styled-components/native'
import CloudinaryImage from './CloudinaryImage'

const CloudinaryAvatar = styled(CloudinaryImage).attrs(props => ({
  transforms: {
    width: props.size * 4 || 128,
    height: props.size * 4 || 128,
    crop: 'fill',
    gravity: 'face'
  }
}))`
  width: ${props => props.size || 40}px;
  height: ${props => props.size || 40}px;
  border-radius: ${props => props.size / 2}px;
`

export default CloudinaryAvatar
