import React from 'react'
import NotFoundScreen from '../../screens/NotFoundScreen'
import {
  createStackNavigator,
  StackNavigationOptions
} from '@react-navigation/stack'
import { createDrawerNavigator } from '@react-navigation/drawer'
import styled from 'styled-components/native'
import LibraryScreen from '../../screens/library/LibraryScreen'
import DocumentEditScreen from '../../screens/library/DocumentEditScreen'
import MediaScreen from '../../screens/media/MediaScreen'
import MediaEditScreen from '../../screens/media/MediaEditScreen'
import SideBar from '../../components/layout/SideBar'
import { View } from 'react-native'
import { useRecoilValue } from 'recoil'
import appHeaderAtom from '../../recoil/atoms/appHeaderAtom'
import SummitScreen from '../../screens/summit/SummitScreen'
import ImageScreen from '../../screens/image/ImageScreen'
import UserListScreen from '../../screens/user/UserListScreen'
import useCurrentUser from '../../hooks/useCurrentUser'
import LoadingScreen from '../../screens/LoadingScreen'
import InnerUrlShortenerScreen from '../../screens/urlShortener/UrlShortenerScreen'
import {
  AppDrawerParamList,
  MediaNavigatorParamList,
  LibraryNavigatorParamList,
  PageBuilderStackParamList,
  ImageNavigatorParamList,
  UserNavigatorParamList
} from '../../types'
import { DataExtractionScreen as InnerDataExtractionScreen } from '../../screens/dataExtraction/DataExtractionScreen'
import { MessageLogScreen as InnerMessageLogScreen } from '../../screens/messageLog/MessageLogScreen'
import InnerCommunitiesMicrositesScreen from '../../screens/communitiesMicrosites/PermissionsScreen'

interface OptionsProp extends StackNavigationOptions {
  defaultPrevious: string
}

const MediaStack = createStackNavigator<MediaNavigatorParamList>()
const LibraryStack = createStackNavigator<LibraryNavigatorParamList>()
const PageBuilderStack = createStackNavigator<PageBuilderStackParamList>()
const ImageStack = createStackNavigator<ImageNavigatorParamList>()
const UserStack = createStackNavigator<UserNavigatorParamList>()
const Drawer = createDrawerNavigator<AppDrawerParamList>()

const AppDrawerNavigator = () => {
  const { currentUser } = useCurrentUser()

  const hasAccess = currentUser?.access?.hasAccess

  if (!currentUser) {
    return <LoadingScreen />
  }

  return (
    <Drawer.Navigator
      backBehavior="history"
      screenOptions={{
        drawerType: 'front',
        drawerStyle: {
          width: 240,
          marginRight: 4
        },
        headerShown: false
      }}
      drawerContent={() => <SideBar />}
    >
      {hasAccess && (
        <>
          {currentUser.access.media && (
            <Drawer.Screen
              name="Media"
              key="mediaScreen"
              component={MediaNavigator}
            />
          )}
          {currentUser.access.library && (
            <Drawer.Screen
              name="Library"
              key="libraryScreen"
              component={LibraryNavigator}
            />
          )}
          {currentUser.access.pageBuilder && (
            <Drawer.Screen
              name="PageBuilder"
              key="pageBuilderScreen"
              component={PageBuilderNavigator}
            />
          )}
          {currentUser.access.images && (
            <Drawer.Screen
              name="Image"
              key="imageScreen"
              component={ImageNavigator}
            />
          )}
          {currentUser.access.userManagement && (
            <Drawer.Screen
              name="Users"
              key="usersScreen"
              component={UserNavigator}
            />
          )}
          {currentUser.access.communitiesMicrositesPermissions && (
            <Drawer.Screen
              name="CommunitiesMicrositesPermissions"
              key="communitiesMicrositesScreen"
              component={CommunitiesMicrositesScreen}
            />
          )}
          {currentUser.access.urlShortener && (
            <Drawer.Screen
              name="UrlShortener"
              key="urlShortenerScreen"
              component={UrlShortenerScreen}
            />
          )}
          {currentUser.access.userManagement && (
            <Drawer.Screen
              name="DataExtraction"
              key="dataExtractionScreen"
              component={DataExtractionScreen}
            />
          )}
          {currentUser.access.userManagement && (
            <Drawer.Screen
              name="MessageLog"
              key="messageLogScreen"
              component={MessageLogScreen}
            />
          )}
        </>
      )}
      <Drawer.Screen
        name="NotFound"
        key="notFoundScreen"
        component={NotFoundScreen}
      />
    </Drawer.Navigator>
  )
}

const ImageNavigator = () => {
  const appHeader = useRecoilValue(appHeaderAtom)
  return (
    <AppViewWrapper>
      {appHeader}
      <AppViewScrollArea>
        <ImageStack.Navigator screenOptions={{ headerShown: false }}>
          <ImageStack.Screen name="ImageList" component={ImageScreen} />
        </ImageStack.Navigator>
      </AppViewScrollArea>
    </AppViewWrapper>
  )
}

const MediaNavigator = () => {
  const appHeader = useRecoilValue(appHeaderAtom)
  return (
    <AppViewWrapper>
      {appHeader}
      <AppViewScrollArea>
        <MediaStack.Navigator screenOptions={{ headerShown: false }}>
          <MediaStack.Screen name="MediaList" component={MediaScreen} />
          <MediaStack.Screen
            name="MediaCreate"
            component={MediaEditScreen}
            options={{ defaultPrevious: 'MediaList' } as OptionsProp}
          />
          <MediaStack.Screen
            name="MediaEdit"
            component={MediaEditScreen}
            options={{ defaultPrevious: 'MediaList' } as OptionsProp}
          />
        </MediaStack.Navigator>
      </AppViewScrollArea>
    </AppViewWrapper>
  )
}

const LibraryNavigator = () => {
  const appHeader = useRecoilValue(appHeaderAtom)
  return (
    <AppViewWrapper>
      {appHeader}
      <AppViewScrollArea>
        <LibraryStack.Navigator screenOptions={{ headerShown: false }}>
          <LibraryStack.Screen name="LibraryList" component={LibraryScreen} />
          <LibraryStack.Screen
            name="DocumentCreate"
            component={DocumentEditScreen}
            options={{ defaultPrevious: 'LibraryList' } as OptionsProp}
          />
          <LibraryStack.Screen
            name="DocumentEdit"
            component={DocumentEditScreen}
            options={{ defaultPrevious: 'LibraryList' } as OptionsProp}
          />
        </LibraryStack.Navigator>
      </AppViewScrollArea>
    </AppViewWrapper>
  )
}

const PageBuilderNavigator = () => {
  const appHeader = useRecoilValue(appHeaderAtom)
  return (
    <AppViewWrapper>
      {appHeader}
      <AppViewScrollArea>
        <PageBuilderStack.Navigator screenOptions={{ headerShown: false }}>
          <PageBuilderStack.Screen
            name="PageBuilderEdit"
            component={SummitScreen}
          />
        </PageBuilderStack.Navigator>
      </AppViewScrollArea>
    </AppViewWrapper>
  )
}

const UserNavigator = () => {
  const appHeader = useRecoilValue(appHeaderAtom)
  return (
    <AppViewWrapper>
      {appHeader}
      <AppViewScrollArea>
        <UserStack.Navigator screenOptions={{ headerShown: false }}>
          <UserStack.Screen name="UserList" component={UserListScreen} />
        </UserStack.Navigator>
      </AppViewScrollArea>
    </AppViewWrapper>
  )
}

const CommunitiesMicrositesScreen = () => {
  const appHeader = useRecoilValue(appHeaderAtom)
  return (
    <AppViewWrapper>
      {appHeader}
      <AppViewScrollArea>
        <InnerCommunitiesMicrositesScreen />
      </AppViewScrollArea>
    </AppViewWrapper>
  )
}

const UrlShortenerScreen = () => {
  const appHeader = useRecoilValue(appHeaderAtom)
  return (
    <AppViewWrapper>
      {appHeader}
      <AppViewScrollArea>
        <InnerUrlShortenerScreen />
      </AppViewScrollArea>
    </AppViewWrapper>
  )
}

const DataExtractionScreen = ({ route, navigation }) => {
  const appHeader = useRecoilValue(appHeaderAtom)
  return (
    <AppViewWrapper>
      {appHeader}
      <AppViewScrollArea>
        <InnerDataExtractionScreen route={route} navigation={navigation} />
      </AppViewScrollArea>
    </AppViewWrapper>
  )
}

const MessageLogScreen = () => {
  const appHeader = useRecoilValue(appHeaderAtom)
  return (
    <AppViewWrapper>
      {appHeader}
      <AppViewScrollArea>
        <InnerMessageLogScreen />
      </AppViewScrollArea>
    </AppViewWrapper>
  )
}

const AppViewWrapper = styled(View)`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  margin: 0 auto;
`
const AppViewScrollArea = styled(View)`
  flex: 1;
`

export default AppDrawerNavigator
