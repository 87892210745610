import { useState, useEffect } from 'react'
import gql from 'graphql-tag'
import { useSubscription } from '@apollo/client'

export const hostMediaUpdatedSubscription = gql`
  subscription($id: String!) {
    hostMediaUpdated(hostMediaId: $id) {
      id
      hostId
      name
      duration
      type
      url
      transcode
      status
    }
  }
`
export interface IHostMedia {
  id: string
  hostId: string
  name: string
  url: string
  type: string
  host: string
  status: string
  transcode: string
  createdAt: Date
}

export interface IHostMediaUpdatedSubscription {
  hostMediaUpdated?: IHostMedia
}

// TODO: Dig into Apollo to see if there is a way around the initialSkip hack.
const useHostMediaUpdatedSubscription = (
  id: string | undefined | null,
  onData
) => {
  const [initialSkip, setInitialSkip] = useState(true)
  useEffect(() => {
    requestAnimationFrame(() => {
      setInitialSkip(false)
    })
  }, [])

  useSubscription(hostMediaUpdatedSubscription, {
    variables: { id },
    skip: !id || initialSkip,
    fetchPolicy: 'no-cache',
    onData
  })
}

export default useHostMediaUpdatedSubscription
