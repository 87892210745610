import * as Yup from 'yup'

export const topicsSchema = Yup.array()
  .min(0)
  .max(3)
  .of(
    Yup.object().shape({
      id: Yup.string().required(),
      autoFilled: Yup.boolean().default(false)
    })
  )
