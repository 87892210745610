import gql from 'graphql-tag'
import { useQuery } from '@apollo/client'
import { useNavigation } from '@react-navigation/core'
import useTranslation from '../../../hooks/useTranslation'
import useToast, { ToastType } from '../../../hooks/useToast'

const getConfig = gql`
  {
    audioFileSizeLimit: config(key: "audioFileSizeLimit")
    documentFileSizeLimit: config(key: "fileSizeLimit")
    documentAllowedFileTypes: config(key: "fileTypeWhitelist")
  }
`

const useConfigQuery = () => {
  const navigation: any = useNavigation()
  const { t } = useTranslation()
  const { showToast } = useToast()
  const { data } = useQuery(getConfig, {
    fetchPolicy: 'network-only',
    onError: () => {
      showToast(t('media:config:errorLoading'), ToastType.ERROR)
      navigation.replace('MediaList')
    }
  })

  return (
    data || {
      documentFileSizeLimit: 0,
      audioFileSizeLimit: 0,
      documentAllowedFileTypes: []
    }
  )
}

export default useConfigQuery
