import { useQuery } from '@apollo/client'
import getCommunities from '../queries/getCommunities'

const useGetCommunities = (variables = {}) => {
  const { data, loading } = useQuery(getCommunities, {
    variables: {
      ...variables
    }
  })
  return { data: data?.communities, loading }
}

export default useGetCommunities
