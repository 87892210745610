import React from 'react'
import { View } from 'react-native'
import { Text } from 'react-native-elements'
import styled from 'styled-components/native'
import useCurrentUser from '../../../hooks/useCurrentUser'
import CloudinaryAvatar from '../../CloudinaryAvatar'

const Container = styled(View)`
  ${({ theme: { colors, space } }) => `
    flex-direction: row;
    padding: ${space[3]}px;
    align-items: center;
    background-color: ${colors.primary};
    margin-bottom: ${space[3]}px;
`}
`

const NameContainer = styled(View)``

const NameText = styled(Text)`
  ${({ theme: { colors, fontSizes, space } }) => `
        margin-left: ${space[3]}px;
        font-size: ${fontSizes[5]}px;
        color: ${colors.headerText};
    `}
`

const Header = () => {
  const { currentUser } = useCurrentUser()

  if (!currentUser) return null
  const { firstName, lastName, photo } = currentUser.person

  return (
    <Container>
      {photo && (
        <CloudinaryAvatar
          style={{ borderWidth: 2, borderColor: '#FFF' }}
          publicId={photo.cloudinaryId}
          size={48}
        />
      )}
      <NameContainer>
        <NameText>{`${firstName}`} </NameText>
        <NameText>{`${lastName}`}</NameText>
      </NameContainer>
    </Container>
  )
}

export default Header
