import React, { ReactNode, useEffect } from 'react'
import { View, ViewStyle } from 'react-native'
import styled from 'styled-components/native'
import { useNavigation, useRoute, useIsFocused } from '@react-navigation/native'
import {
  CustomHeaderContainer,
  CustomHeaderContent,
  HeaderProps,
  ItemHeaderContainer,
  ItemHeaderContent,
  MenuHeaderContainer,
  MenuHeaderContent
} from './AppHeader'
import { useSetRecoilState } from 'recoil'
import appHeaderAtom from '../../recoil/atoms/appHeaderAtom'

interface ScreenContainerWithMenuHeaderProps extends ScreenContainerProps {
  title?: string
  headerLogo?: boolean
  webTitle?: string
  children?: any
  screenCategory: string | null
}
export function ScreenContainerWithMenuHeader(
  props: ScreenContainerWithMenuHeaderProps
) {
  return <ScreenContainer header="menu-header" {...props} />
}
interface ScreenContainerWithItemHeaderProps {
  title?: string
  defaultBack?: string
  onBackPress?: Function
  HeaderRight?: React.ComponentType
  renderedHeaderRight?: ReactNode
  HeaderBottom?: React.ComponentType
  headerStyle?: ViewStyle
  webTitle?: string
  children?: any
  screenCategory: string | null
}
export function ScreenContainerWithItemHeader(
  props: ScreenContainerWithItemHeaderProps
) {
  return <ScreenContainer header="item-header" {...props} />
}
interface ScreenContainerWithCustomHeaderProps {
  Header?: React.ComponentType<HeaderProps>
  headerProps?: any
  headerStyle?: ViewStyle
  webTitle?: string
  children?: any
}
export function ScreenContainerWithCustomHeader(
  props: ScreenContainerWithCustomHeaderProps
) {
  return <ScreenContainer {...props} />
}

interface ScreenContainerProps {
  header?: 'none' | 'menu-header' | 'item-header' | 'custom' | 'unset'
  Header?: React.ComponentType<HeaderProps>
  headerProps?: any
  title?: string
  webTitle?: string
  defaultBack?: string
  onBackPress?: Function
  HeaderRight?: React.ComponentType
  renderedHeaderRight?: ReactNode
  HeaderBottom?: React.ComponentType
  headerStyle?: ViewStyle
  headerLogo?: boolean
  children?: any
  style?: ViewStyle
}
export default function ScreenContainer({
  header = 'unset',
  Header,
  headerProps,
  title,
  webTitle,
  HeaderRight,
  renderedHeaderRight,
  HeaderBottom,
  headerStyle,
  defaultBack,
  onBackPress,
  style,
  children
}: ScreenContainerProps) {
  const route = useRoute()
  const navigation = useNavigation()
  const isFocused = useIsFocused()
  const setAppHeader = useSetRecoilState(appHeaderAtom)

  const pageTitle = title ?? route?.name
  if (Header) {
    header = 'custom'
  }

  // Setting AppHeader when screen focus changes
  useEffect(() => {
    let HeaderContainer, headerContent
    switch (header) {
      case 'menu-header':
        HeaderContainer = MenuHeaderContainer
        headerContent = <MenuHeaderContent />
        break
      case 'item-header':
        HeaderContainer = ItemHeaderContainer
        headerContent = (
          <ItemHeaderContent
            pageTitle={pageTitle}
            HeaderRight={HeaderRight}
            renderedHeaderRight={renderedHeaderRight}
            defaultBack={defaultBack}
            onBackPress={onBackPress}
            navigation={navigation}
          />
        )
        break
      case 'custom':
        HeaderContainer = CustomHeaderContainer
        headerContent = (
          <CustomHeaderContent
            Header={Header}
            headerProps={headerProps}
            navigation={navigation}
            onBackPress={onBackPress}
          />
        )
        break
      default:
        HeaderContainer = undefined
        headerContent = undefined
        break
    }
    if (!isFocused) return
    // screen has specified no header info
    // so leave current header config in place
    if (header === 'unset') return
    // screen has specifically requested no header
    if (header === 'none') return setAppHeader(null)
    // otherwise, set requested header info in atom
    setAppHeader(
      <HeaderContainer style={headerStyle ?? {}}>
        {headerContent}
        {HeaderBottom && <HeaderBottom />}
      </HeaderContainer>
    )
  }, [isFocused, header, headerStyle, HeaderBottom])

  useEffect(() => {
    if (!isFocused) return
    navigation.setOptions({
      webTitle: webTitle || title || ''
    })
  }, [isFocused, webTitle, title])
  return <StyledScreenContainer style={style}>{children}</StyledScreenContainer>
}
const StyledScreenContainer = styled(View)`
  flex: 1;
  z-index: 100;
  background-color: ${props => props.theme.colors.background};
`
