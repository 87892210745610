import React, { useCallback } from 'react'
import { CellRendererProps } from '../../components/common/Table'
import NoWrapText from '../../components/cell/NoWrapText'
import { formatDate } from '../../utils/format'
import TitleCell from '../../components/cell/TitleCellContent'
import AudienceCellContent from '../../components/cell/AudienceCellContent'
import useTranslation from '../../hooks/useTranslation'
import { Text } from 'react-native-elements'
import Constants from 'expo-constants'
import PublishedCellContent from '../../components/cell/PublishedCellContent'
import MultiValueCellContent from '../../components/cell/MultiValueCellContent'
import useLinkToScreen from '../../hooks/useLinkToScreen'
import { DocumentTypeEnum } from '../../types'
const MEMBER_WEB_PROTOCOL = Constants.manifest.extra.MEMBER_WEB_PROTOCOL
const MEMBER_WEB_URL = Constants.manifest.extra.MEMBER_WEB_URL

export interface TypeOption {
  label: string
  value: string
}

export const columns = [
  {
    header: 'Created',
    width: 117,
    sort: {
      key: 'createdAt',
      defaultDir: 'desc',
      isDefault: true
    },
    Cell: ({ item }: CellRendererProps<any>) => (
      <NoWrapText>{formatDate(item.createdAt)}</NoWrapText>
    )
  },
  {
    header: 'Title',
    sort: {
      key: 'title'
    },
    filter: {
      key: 'query',
      defaultValue: '',
      type: 'search'
    },
    Cell: ({ item }: CellRendererProps<any>) => {
      const linkToScreen = useLinkToScreen()
      const onPress = useCallback(() => {
        linkToScreen('DocumentEdit', { id: item.id })
      }, [linkToScreen, item.id])
      return <TitleCell item={item} onPress={onPress} />
    }
  },
  {
    header: 'Audience',
    Cell: ({ item }: CellRendererProps<any>) => (
      <AudienceCellContent
        communities={item.communities}
        groups={item.groups}
        events={item.events}
        people={item.people}
      />
    ),
    filter: [
      {
        key: 'communityId',
        type: 'filter',
        placeholder: 'Community'
      },
      {
        key: 'groupId',
        type: 'filter',
        placeholder: 'Group'
      },
      {
        key: 'eventId',
        type: 'filter',
        placeholder: 'Event'
      }
    ]
  },
  {
    header: 'Type',
    sort: {
      key: 'type'
    },
    Cell: ({ item }: CellRendererProps<any>) => {
      const { t } = useTranslation()

      if (!item.type) {
        return null
      }

      if (item.type === DocumentTypeEnum.SharedFile && item.file) {
        const extension = item.file.fileName.split('.').pop()
        return <Text>{extension.toUpperCase()}</Text>
      }

      return <Text>{t(`library:document:types:${item.type}`)}</Text>
    },
    filter: {
      key: 'types',
      type: 'filter'
    }
  },
  {
    header: 'Topics',
    width: 250,
    Cell: ({ item }: CellRendererProps<any>) => (
      <MultiValueCellContent values={item.topics} />
    ),
    filter: {
      key: 'topicIds',
      type: 'filter'
    }
  },
  {
    header: 'Published',
    width: 140,
    sort: {
      key: 'publishDate',
      defaultDir: 'desc'
    },
    Cell: ({ item }: CellRendererProps<any>) => {
      const url = `${MEMBER_WEB_PROTOCOL}://${MEMBER_WEB_URL}/document/${item.id}`
      return <PublishedCellContent item={item} url={url} />
    },
    filter: {
      key: 'publishingState',
      type: 'filter'
    }
  }
]
