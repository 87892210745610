import { gql, useQuery } from '@apollo/client'

const getImagesByPerson = gql`
  query($personId: String!) {
    person(id: $personId) {
      id
      taggedImages {
        id
        image {
          id
          cloudinaryId
        }
      }
    }
  }
`

const useGetImagesByPerson = personId => {
  const { data, refetch } = useQuery(getImagesByPerson, {
    variables: {
      personId
    },
    skip: !personId
  })
  return { data: data?.person || [], refetch }
}

export default useGetImagesByPerson
