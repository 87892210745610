import React from 'react'
import { FlatList, Text, TouchableOpacity, View } from 'react-native'
import styled from 'styled-components/native'
import { H4 } from '../../../components/common/Text'
import CopyShortUrlButton from './CopyShortUrlButton'
import { Flex } from '../../../components/FlexBox'
import Button from '../../../components/common/Button'
import truncate from '../../../utils/truncate'

const openUrl = (url: string) => window.open(url, '_blank')

const UrlText = styled(Text)`
  ${({ theme: { colors } }) => `
    color: ${colors.brightPrimary};
    font-size: 16px;
  `}
`

const Container = styled(View)`
  ${({ theme: { space } }) => `
    flex: 1;
    margin: ${space[3]}px;
  `}
`

export const UrlLink = ({
  url,
  numberOfLines = 1,
  ellipsizeMode
}: {
  url: string
  numberOfLines?: number
  ellipsizeMode?: string
}) => (
  <TouchableOpacity onPress={() => openUrl(url)}>
    <UrlText numberOfLines={numberOfLines} ellipsizeMode={ellipsizeMode}>
      {url}
    </UrlText>
  </TouchableOpacity>
)

const RowContainer = styled(View)`
  ${({ theme: { space }, dark }) => `
    flex-direction: row;
    align-items: center;
    background-color: ${
      dark ? `rgba(196, 196, 196, 0.14)` : `rgba(0, 104, 144, 0.1)`
    };
    padding: ${space[3]}px;
    border-radius: 32px;
    justify-content: space-between;
    margin: ${space[1]}px 0px;
  `}
`

const HeaderRowContainer = styled(RowContainer)`
  ${({ theme: { space } }) => `
    margin: ${space[2]}px 0px;
  `}
`

const DeleteButton = styled(Button).attrs(({ theme: { colors } }) => ({
  buttonStyle: { backgroundColor: colors.danger }
}))``

const LongUrlCell = styled(Flex).attrs(() => ({
  flexDirection: 'row',
  alignItems: 'center'
}))``

const ShortUrlCell = styled(Flex).attrs(() => ({
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'flex-end'
}))``

const DeleteButtonContainer = styled(View)`
  ${({ theme: { space } }) => `
    margin-left: ${space[2]}px;
  `}
`

const MAX_CHARACTER_URL_COUNT = 90
const UrlRow = ({ shortUrl, dark, onSelect, onDelete }) => {
  return (
    <RowContainer dark={dark}>
      <LongUrlCell>
        <UrlLink
          url={truncate(shortUrl.targetUrl, MAX_CHARACTER_URL_COUNT, true)}
        />
      </LongUrlCell>
      <ShortUrlCell>
        <UrlLink url={shortUrl.sourceUrl} />
        <CopyShortUrlButton url={shortUrl.sourceUrl} />
        <Button onPress={() => onSelect(shortUrl.id)} label="Edit" />
        <DeleteButtonContainer>
          <DeleteButton onPress={() => onDelete(shortUrl.id)} label="Delete" />
        </DeleteButtonContainer>
      </ShortUrlCell>
    </RowContainer>
  )
}

const ListHeader = () => {
  return (
    <HeaderRowContainer>
      <H4>{'Long URL'}</H4>
      <H4>{'Short URL'}</H4>
    </HeaderRowContainer>
  )
}
const ShortUrlsTable = ({ shortUrls, onSelect, onDelete }) => {
  return (
    <Container>
      <FlatList
        data={shortUrls}
        ListHeaderComponent={ListHeader}
        renderItem={({ item, index }) => (
          <UrlRow
            shortUrl={item}
            dark={index % 2 === 0}
            onSelect={onSelect}
            onDelete={onDelete}
          />
        )}
      />
    </Container>
  )
}

export default ShortUrlsTable
