import { EditorState, convertToRaw, convertFromRaw } from 'draft-js'
import linkDecorator from './linkDecorator'

export const createEmptyRichTextEditorState = () => {
  return EditorState.createEmpty(linkDecorator)
}

export const convertFromJSONToDraft = json => {
  const rawData = JSON.parse(json)
  const contentState = convertFromRaw(rawData)
  const newEditorState = EditorState.createWithContent(
    contentState,
    linkDecorator
  )

  return newEditorState
}

export const convertFromDraftToJSON = editorState => {
  const content = editorState.getCurrentContent()
  const rawObject = convertToRaw(content)
  return JSON.stringify(rawObject)
}
