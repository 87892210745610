import React, { useState } from 'react'
import { TouchableOpacity, View } from 'react-native'
import styled from 'styled-components/native'
import { IQueueGraph } from './queries/queue'
import { Text } from '../../components/common/Text'
import { Flex } from '../../components/FlexBox'

const ListenerValue = ({
  listener
}: {
  listener: IQueueGraph['listeners'][0]['value'][0]
}) => {
  const [subGroupExpanded, setSubGroupExpanded] = useState(false)
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { _meta, ...rest } = listener.ref.args || {}
  return (
    <View style={{ backgroundColor: '#C5DCF5', borderRadius: 8, padding: 8 }}>
      <TouchableOpacity
        onPress={() => setSubGroupExpanded(!subGroupExpanded)}
        style={{ padding: 8 }}
      >
        <Text>
          {subGroupExpanded ? '▼ ' : '▶ '}
          <b>{listener.ref.type}</b>
        </Text>
      </TouchableOpacity>
      {subGroupExpanded && (
        <Flex mx={4} my={1} flexDirection="row">
          <Text>
            {Object.keys(rest).length === 0
              ? 'Full extraction'
              : JSON.stringify(rest)}
          </Text>
        </Flex>
      )}
    </View>
  )
}

const ListenerCard = styled.View`
  flex: 1;
  padding: 10px;
  background-color: ${props =>
    props.failed ? props.theme.colors.failure : props.theme.colors.primary};
  border-radius: 8px;
`

const ListenerGroup = ({
  listener
}: {
  listener: IQueueGraph['listeners'][0]
}) => {
  const [keyExpanded, setKeyExpanded] = useState(false)
  return (
    <>
      <TouchableOpacity
        onPress={() => setKeyExpanded(!keyExpanded)}
        style={{ padding: 8 }}
      >
        <Text>
          {keyExpanded ? '▼ ' : '▶ '}
          <b>{listener.key}</b>
        </Text>
      </TouchableOpacity>
      {keyExpanded &&
        listener.value.map(v => (
          <Flex mx={4} my={1} flexDirection="column">
            <ListenerValue listener={v} key={v.ref.type} />
          </Flex>
        ))}
    </>
  )
}

export const ListenersTab = ({
  listeners
}: {
  listeners?: IQueueGraph['listeners']
}) => {
  if (!listeners?.length) {
    return (
      <Flex mx={4} my={1} flexDirection="row">
        <Text>No listeners</Text>
      </Flex>
    )
  }
  return (
    <Flex mx={4} my={1} flexDirection="row">
      {listeners.map(listener => (
        <ListenerCard>
          <ListenerGroup listener={listener} />
        </ListenerCard>
      ))}
    </Flex>
  )
}
