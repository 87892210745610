import Constants from 'expo-constants'

const MEMBER_WEB_PROTOCOL = Constants.manifest.extra.MEMBER_WEB_PROTOCOL
const MEMBER_WEB_URL = Constants.manifest.extra.MEMBER_WEB_URL

const openPreview = async (itemPath: string) => {
  const url = `${MEMBER_WEB_PROTOCOL}://${MEMBER_WEB_URL}/${itemPath}`
  // used this opposed to Linking as it is web only and we want it to open a new tab
  window.open(url, '_blank')
}

export default openPreview
