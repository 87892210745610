import React from 'react'
import DateTimeInput from '../../../ui-library/DateTimeInput'
import useTranslation from '../../../hooks/useTranslation'
import { DateTimeInputProps } from '../../../ui-library/DateTimeInput/types'

interface Props {
  value: Date | null
  onBlur: (e: any) => void
  onChangeDate: (date: any) => void
  hasError?: boolean
  initialDate: Date
  containerStyle?: DateTimeInputProps['containerStyle']
}

const PublishDateTimeInput = (props: Props) => {
  const { t } = useTranslation()

  return <DateTimeInput {...props} placeholder={t('forms:publishDate')} />
}

export default PublishDateTimeInput
