import React from 'react'
import { Text, TouchableOpacity } from 'react-native'
import { useRecoilState } from 'recoil'
import toastMessageAtom from '../recoil/atoms/toastMessageAtom'
import styled from 'styled-components/native'
import DismissIcon from './icon/DismissIcon'
import { lighten } from 'polished'
import { ToastType, TOAST_DEATH_MS } from '../hooks/useToast'
import ReactModal from 'react-modal'

export default function ToastAnchor() {
  const [toasts, setToasts] = useRecoilState(toastMessageAtom)

  if (toasts.length === 0) {
    return null
  }

  return (
    <ReactModal
      isOpen
      style={{
        overlay: {
          position: 'fixed',
          width: 0,
          height: 0,
          top: 0,
          right: 0,
          zIndex: 99999999
        },
        content: {
          position: 'fixed',
          top: 0,
          right: 0,
          width: 0,
          height: 0,
          margin: 0,
          padding: 0,
          zIndex: 99999999
        }
      }}
    >
      <ToastSlider>
        {toasts.map(x => {
          const isDead = x.queuedForRemoval
          return (
            <ToastContainer
              key={x.id}
              type={x.type}
              visible={!isDead}
              style={{
                filter: 'drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.2))'
              }}
            >
              <ToastText visible={!isDead}>{x.message}</ToastText>
              {!isDead && (
                <TouchableOpacity
                  onPress={() =>
                    setToasts(messages => messages.filter(m => m.id !== x.id))
                  }
                >
                  <DismissIcon size={20} style={{ marginLeft: 16 }} />
                </TouchableOpacity>
              )}
            </ToastContainer>
          )
        })}
      </ToastSlider>
    </ReactModal>
  )
}

const ToastSlider = styled.View`
  position: fixed;
  top: 12px;
  right: 12px;
  overflow: hidden;
  min-height: 32px;
  z-index: 99999999;
  overflow: visible;
`
const ToastContainer = styled.View`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: ${({ visible }) => (visible ? 16 : 0)}px;
  margin-bottom: ${({ visible }) => (visible ? 8 : 0)}px;
  background-color: ${({ theme, type }) =>
    type === ToastType.ERROR
      ? lighten(0.3, theme.colors.danger)
      : lighten(0.6, theme.colors.primary)};
  border-radius: 4px;
  border: 1px solid black;
  opacity: ${({ visible }) => (visible ? 1 : 0)};
  transition: all ${TOAST_DEATH_MS / 1000}s
    cubic-bezier(0.175, 0.885, 0.32, 1.275);
`

const ToastText = styled(Text)`
  font-size: ${({ visible }) => (visible ? 16 : 0)};
  opacity: ${({ visible }) => (visible ? 1 : 0)};
  transition: font-size ${TOAST_DEATH_MS / 1000}s
    cubic-bezier(0.175, 0.885, 0.32, 1.275);
`
