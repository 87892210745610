import { gql, useMutation } from '@apollo/client'

const tagEventMutation = gql`
  mutation(
    $communityId: String!
    $imageId: String!
    $xCoord: Float!
    $yCoord: Float!
  ) {
    createCommunityTag(
      communityId: $communityId
      imageId: $imageId
      xCoord: $xCoord
      yCoord: $yCoord
    ) {
      id
    }
  }
`

const useTagEventMutation = (options = {}) => {
  const [tagEvent]: any[] = useMutation(tagEventMutation, options)
  return tagEvent
}

export default useTagEventMutation
