import React from 'react'
import { View } from 'react-native'
import { Svg, Path } from 'react-native-svg'

interface IconProps {
  color?: string
  size?: number
  style?: object | object[]
  rotate?: number
}

export const CaretIcon = React.memo(
  ({ color = '#FFFFFF', size = 18, style, rotate, ...rest }: IconProps) => (
    <View
      pointerEvents="none"
      style={{ ...style, transform: [{ rotate: `${rotate ?? 0}deg` }] }}
    >
      <Svg width={size} height={size} fill="none" viewBox="0 0 18 18" {...rest}>
        <Path
          d="M5.4 7.8L9 11.4l3.6-3.6"
          stroke={color}
          strokeLinecap="square"
        />
      </Svg>
    </View>
  )
)
