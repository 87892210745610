export type UserProp = { id: string; name: string; appRoles: AppRoles[] }
export type AppRoles = { app: GlobalRoleApps; role: string }

export enum GlobalRoleApps {
  INNOVATION = 'innovation',
  MEMBER_APP = 'member_app',
  MI6 = 'mi6'
}

export const groupRoleLabel = {
  advisor: 'Advisor',
  advisingMember: 'Advising Member',
  associateGroupLeader: 'Associate Group Leader',
  executiveAdvisor: 'Executive Advisor',
  groupLeader: 'Group Leader',
  member: 'Member',
  msAssociate: 'MS Associate'
}
