import React from 'react'
import { View } from 'react-native'
import Modal from '../Modal'
import { H4, Text } from '../Text'
import Button from '../../../ui-library/Button'
import useTranslation from '../../../hooks/useTranslation'
import styled from 'styled-components/native'

interface ValidationModalProps {
  isOpen: boolean
  handleClose: () => void
  children: JSX.Element | JSX.Element[]
}

const ValidationModal = ({
  isOpen,
  handleClose,
  children
}: ValidationModalProps) => {
  const { t } = useTranslation()

  return (
    <Modal open={isOpen} close={handleClose}>
      <H4>Errors</H4>
      <View style={{ padding: 10 }}>
        <View style={{ paddingBottom: 10 }}>{children}</View>
      </View>
      <View
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-end'
        }}
      >
        <Button title={t('common:labels:close')} onPress={handleClose} />
      </View>
    </Modal>
  )
}

export default ValidationModal

export const ErrorText = styled(Text)`
  font-size: 17px;
  margin-bottom: 5px;
`
