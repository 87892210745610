import React, { useCallback, useRef } from 'react'
import gql from 'graphql-tag'
import { ScreenContainerWithMenuHeader } from '../../components/layout/ScreenContainer'
import useTranslation from '../../hooks/useTranslation'
import { Flex } from '../../components/FlexBox'
import { H4 } from '../../components/common/Text'
import Button from '../../components/common/Button'
import Table from '../../components/common/Table'
import { columns } from './ColumnsConfig'
import useLinkToScreen from '../../hooks/useLinkToScreen'
import useTableRefetch from '../../hooks/useTableRefetch'

const getDocuments = gql`
  query searchDocuments(
    $size: Int
    $offset: Int
    $sortBy: SearchEntitySortBy
    $sortDir: SearchEntitySortDir
    $query: String
    $publishingState: PublishingState
    $communityId: String
    $groupId: String
    $eventId: String
    $topicIds: [String!]
    $types: [DocumentType!]
  ) {
    searchEntity(
      entity: document
      includeUnpublished: true
      size: $size
      offset: $offset
      sortBy: $sortBy
      sortDir: $sortDir
      query: $query
      publishingState: $publishingState
      communityId: $communityId
      groupId: $groupId
      eventId: $eventId
      topicIds: $topicIds
      types: $types
    ) {
      total
      hits {
        __typename
        ... on Document {
          id
          title
          subtitle
          body
          published
          publishDate
          createdAt
          type
          communities {
            id
            name
            type
            groups {
              id
              name
            }
          }
          groups {
            id
            name
          }
          topics {
            id
            name
          }
          events {
            id
            name
          }
          people {
            id
            firstName
            lastName
          }
          file {
            fileName
          }
        }
      }
    }
  }
`

const LibraryScreen = () => {
  const tableRef = useRef()
  const { t } = useTranslation()
  const linkToScreen = useLinkToScreen()

  // @ts-ignore tableRef.current isn't undefined
  useTableRefetch(tableRef?.current?.refetch)

  const onPressNew = useCallback(() => {
    linkToScreen('DocumentCreate')
  }, [linkToScreen])

  return (
    <ScreenContainerWithMenuHeader screenCategory="library">
      <Flex flexDirection="row" mx={4} my={3}>
        <Flex flex={1}>
          <H4>{t('library:title')}</H4>
        </Flex>
        <Button label={t('common:buttons:new')} onPress={onPressNew} />
      </Flex>
      <Flex flex={1} flexDirection="row" mx={4} my={3}>
        <Flex flex={1} style={{ maxHeight: 650, minWidth: 760 }}>
          <Table columns={columns} query={getDocuments} ref={tableRef} />
        </Flex>
      </Flex>
    </ScreenContainerWithMenuHeader>
  )
}

export default LibraryScreen
