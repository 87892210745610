import React, { useEffect, useState } from 'react'
import { useDebouncedCallback } from 'use-debounce'
import TextInput, { TextInputProps } from '../../../ui-library/TextInput'

const SummitTextInput: React.FC<TextInputProps> = ({
  value,
  onChangeText,
  ...props
}) => {
  const [internalState, setInternalState] = useState<string | undefined>(value)
  useEffect(() => {
    setInternalState(value)
  }, [value])

  const debouncedHandleOnChange = useDebouncedCallback((x: string) => {
    onChangeText && onChangeText(x)
  }, 200)
  return (
    <TextInput
      value={internalState}
      onChangeText={x => {
        setInternalState(x)
        debouncedHandleOnChange(x)
      }}
      {...props}
    />
  )
}

export default SummitTextInput
