import { gql, useMutation } from '@apollo/client'

const deleteEventTagMutation = gql`
  mutation($id: String!) {
    deleteCommunityTag(id: $id)
  }
`

const useDeleteEventTagMutation = (options = {}) => {
  const [deleteEventTag]: any[] = useMutation(deleteEventTagMutation, options)
  return deleteEventTag
}

export default useDeleteEventTagMutation
