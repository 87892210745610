import React, { useEffect, useState, useRef, useCallback } from 'react'
import { ScrollView } from 'react-native'
import { StackScreenProps } from '@react-navigation/stack'
import useTranslation from '../../hooks/useTranslation'
import { H4 } from '../../components/common/Text'
import Dropdown from '../../components/common/Dropdown'
import { MediaCategoryEnum, AppStackParamList } from '../../types'
import { useFormik } from 'formik'
import HeroImageDrop from '../../components/common/form/HeroImageDrop'
import { Flex } from '../../components/FlexBox'
import TextInput from '../../ui-library/TextInput'
import { useTheme } from 'styled-components/native'
import useFormikHelper from '../../hooks/useFormikHelper'
import useToggle from '../../hooks/useToggle'
import useToast, { ToastType } from '../../hooks/useToast'
import useCopyContent, { ContentTypeEnum } from '../../hooks/useCopyContent'
import useMediaQuery from './queries/useMediaQuery'
import useMediaEditQuery from './queries/useMediaEditQuery'
import useCreateMediaMutation from './mutations/useCreateMediaMutation'
import useMediaUpdatedSubscription from './subscriptions/useMediaUpdatedSubscription'
import useHostMediaUpdatedSubscription from './subscriptions/useHostMediaUpdatedSubscription'
import useDeleteMediaMutation from './mutations/useDeleteMediaMutation'
import { publishSchema, saveSchema, previewSchema } from './validators'
import usePreventNavOnDirtyState from '../../hooks/usePreventNavigationOnDirtyState'
import openPreview from '../../utils/openPreview'
import RichTextEditor, {
  convertFromDraftToJSON,
  convertFromJSONToDraft,
  createEmptyRichTextEditorState
} from '../../components/RichTextEditor'
import PersonTagManager from '../../components/common/form/PersonTagManager'
import MultipleMediaUploader from './components/MultipleMediaUploader'
import {
  mapAudience,
  mapGroups,
  mapGatherings,
  mapPeople,
  mapTopics,
  mapHeroImage,
  mapTaggedPeople,
  mapHostMedia,
  getCloudinaryImage
} from '../../utils/formHelpers'
import {
  AudienceMultiselect,
  ContentTopicMultiselect,
  PublishCheckbox,
  PublishDateTimeInput,
  ErrorText,
  ValidationModal,
  GatheringSelect,
  Footer,
  ShareCheckbox,
  PeopleSelect
} from '../../components/common/form'
import useCurrentUser from '../../hooks/useCurrentUser'
import useLinkToScreen from '../../hooks/useLinkToScreen'
import { ScreenContainerWithItemHeader } from '../../components/layout/ScreenContainer'
import { useSetRecoilState } from 'recoil'
import deletedItemAtom from '../../recoil/atoms/deletedItemAtom'
import useFeatureFlag from '../../hooks/useFeatureFlag'
import { AudienceType } from '../../components/common/form/hooks/useAudienceQuery'
import useConfigQuery from './queries/useConfigQuery'

const touchFields: any = {
  title: true,
  description: true,
  subtitle: true,
  category: true,
  gathering: true,
  hostMedia: true,
  heroImage: true,
  communities: true,
  groups: true,
  gatherings: true,
  people: true,
  publishDate: true,
  topics: true
}

const DEFAULT_FORM_VALUES: any = {
  id: null,
  description: createEmptyRichTextEditorState(),
  title: '',
  subtitle: '',
  category: null,
  gathering: null,
  hostMediaId: null,
  hostMedia: null,
  highlightsHostMediaId: null,
  highlightsHostMedia: null,
  heroImage: null,
  published: false,
  shareable: false,
  communities: [],
  groups: [],
  gatherings: [],
  people: [],
  topics: [],
  taggedPeople: [],
  publishDate: null
}

const mapFormValues = (
  defaultValues,
  savedData,
  communities,
  groups,
  gatherings,
  people,
  heroImage,
  hostMedia,
  highlightsHostMedia,
  topics,
  taggedPeople
) => {
  return {
    ...defaultValues,
    id: savedData?.id || defaultValues?.id,
    title: savedData?.title || defaultValues?.title,
    subtitle: savedData?.subtitle || defaultValues?.subtitle,
    hostMediaId: savedData?.hostMedia?.id || defaultValues?.hostMediaId,
    highlightsHostMediaId:
      savedData?.highlightsHostMedia?.id || defaultValues?.hostMediaId,
    description: savedData?.description
      ? convertFromJSONToDraft(savedData?.description)
      : defaultValues.description,
    category: savedData?.category || defaultValues?.category,
    gathering: savedData?.event?.id || defaultValues?.gathering,
    published: savedData?.published || defaultValues?.published,
    publishDate: savedData?.publishDate || defaultValues?.publishDate,
    shareable: savedData?.shareable || defaultValues?.shareable,
    heroImage,
    communities,
    groups,
    gatherings,
    people,
    hostMedia,
    highlightsHostMedia,
    topics,
    taggedPeople
  }
}

type Props = StackScreenProps<AppStackParamList, 'MediaEdit' | 'MediaCreate'>

export default function MediaEditScreen({ navigation, route }: Props) {
  const isEditing: boolean = !!route?.params?.id
  const { t } = useTranslation()
  const linkToScreen = useLinkToScreen()
  const { showToast } = useToast()
  const { data: mediaEditData } = useMediaEditQuery()
  const data: any = useMediaQuery(route?.params?.id)
  const { documentFileSizeLimit, audioFileSizeLimit } = useConfigQuery()
  const { currentUserId } = useCurrentUser()
  const [uploadingMedia, setUploadingMedia] = useState(false)
  const [uploadingHighlights, setUploadingHighlights] = useState(false)
  const setDeletedItem = useSetRecoilState(deletedItemAtom)
  const isContentSharingEnabled = useFeatureFlag('contentSharing')
  const isContentPublishingEnabled = useFeatureFlag('enhancedContentPublishing')
  const theme = useTheme()
  // Ref
  const scrollRef = useRef<ScrollView>()
  const scrollToEnd = (timeout = 300) => {
    if (scrollRef?.current) {
      setTimeout(() => {
        scrollRef?.current?.scrollToEnd?.({ animated: true })
      }, timeout)
    }
  }

  const {
    contentCopied,
    isContentCopied,
    setValuesToCopy,
    resetCopyContent
  } = useCopyContent()

  const { createMedia, loading } = useCreateMediaMutation({
    onCompleted: results => {
      if (isContentCopied) {
        resetCopyContent()
      }
      isSubmittingRef.current = false
      formik.setSubmitting(false)
      const resultId = results?.createMedia?.id
      if (resultId) {
        showToast(t('forms:saveSuccess'))
        if (!isEditing) {
          navigation.replace('MediaEdit', { id: resultId })
        }
      }
      handleFormInitOnSave({ media: results?.createMedia })
    },
    onError: () => showToast(t('forms:saveFail'), ToastType.ERROR)
  })

  const deleteMedia = useDeleteMediaMutation({
    onCompleted: () => {
      showToast(t('forms:deleteSuccess'))
      setDeletedItem(true)
      linkToScreen('MediaList')
    },
    onError: () => showToast(t('forms:deleteFail'), ToastType.ERROR),
    update(cache) {
      const objectId = cache.identify(data.media)
      cache.evict({ id: objectId })
    }
  })

  // This ref is hacky but there doesn't seem to
  // be a way around it in formik.
  const isSubmittingRef = useRef(false)
  const {
    isOn: showValidationModal,
    turnOn: turnOnValidationModal,
    turnOff: turnOffValidationModal
  } = useToggle(false)

  // This is for using publish validator without publish value for preview
  const {
    isOn: validatingPreview,
    turnOn: turnOnValidatingPreview,
    turnOff: turnOffValidatingPreview
  } = useToggle(false)

  const formik = useFormik({
    initialValues: DEFAULT_FORM_VALUES,
    validate: values => {
      let errors = {}

      try {
        if (validatingPreview) {
          previewSchema.validateSync(values, { abortEarly: false })
          openPreview(`media/${formik?.values?.id}`)
        } else if (values.published || validatingPreview) {
          publishSchema.validateSync(values, { abortEarly: false })
        } else {
          saveSchema.validateSync(values, { abortEarly: false })
        }
      } catch (e: any) {
        if (isSubmittingRef.current || validatingPreview) {
          turnOnValidationModal()
        }
        e.inner.forEach(error => {
          errors[error.path] = error.message
        })
      }
      return errors
    },
    onSubmit: values => {
      const variables = {
        ...values,
        publishDate: values.publishDate
          ? // @ts-ignore publishDate isn't null, due to ternary operator check
            new Date(values.publishDate)
          : null,
        description: convertFromDraftToJSON(values.description),
        category: values?.category || null,
        eventId: values?.gathering || null,
        id: values?.id || null,
        communities: values?.communities || null,
        groups: values?.groups || null,
        events: values.gatherings || null,
        people: values.people || null,
        hostMediaId: values?.hostMedia?.id,
        highlightsHostMediaId: values?.highlightsHostMedia?.id || null,
        cloudinaryImage: getCloudinaryImage(values),
        taggedPeople: values.taggedPeople.map(({ personId, source }) => ({
          personId,
          source
        })),
        topics: values.topics.map(({ id }) => id)
      }

      formik.resetForm({ values })

      createMedia({ variables })
    }
  })

  const uploadingHeroImage =
    formik?.values?.heroImage?.fileName && !formik?.values?.heroImage?.publicId

  const {
    handleChangeHeroImage,
    handleHeroImageError,
    handleSelectGathering,
    handleSelectGatherings,
    handleChangePublishDate,
    handleChangePublished,
    handleChangeShareable,
    handleSelectCommunities,
    handleSelectGroups,
    handleSelectContentTopic,
    handleTagPeople,
    handleSelectPeople,
    initialPublishDateTime
  } = useFormikHelper(formik, touchFields)

  isSubmittingRef.current = formik.isSubmitting
  usePreventNavOnDirtyState(formik)

  const handleFormInitOnSave = data => {
    if (data && data.media) {
      const savedData = data.media
      const audienceValue = mapAudience(
        DEFAULT_FORM_VALUES.communities,
        savedData
      )
      const groupsValue = mapGroups(DEFAULT_FORM_VALUES.groups, savedData)
      const gatheringsValue = mapGatherings(
        DEFAULT_FORM_VALUES.gatherings,
        savedData
      )
      const peopleValue = mapPeople(DEFAULT_FORM_VALUES.people, savedData)
      const topicValues = mapTopics(DEFAULT_FORM_VALUES, savedData)
      const heroImageValue = mapHeroImage(
        DEFAULT_FORM_VALUES.heroImage,
        savedData
      )
      const hostMedia = mapHostMedia(
        DEFAULT_FORM_VALUES.hostMedia,
        savedData,
        false
      )
      const highlightsHostMedia = mapHostMedia(
        DEFAULT_FORM_VALUES.hostMedia,
        savedData,
        true
      )
      const taggedPeopleValue = mapTaggedPeople(
        DEFAULT_FORM_VALUES.taggedPeople,
        savedData?.personTags
      )
      const newValues = mapFormValues(
        DEFAULT_FORM_VALUES,
        savedData,
        audienceValue,
        groupsValue,
        gatheringsValue,
        peopleValue,
        heroImageValue,
        hostMedia,
        highlightsHostMedia,
        topicValues,
        taggedPeopleValue
      )

      // reset in order to properly init new defaults and reset dirty status
      formik.resetForm({ values: newValues, touched: {} })
      if (isContentCopied) {
        formik.setFieldValue('title', `Copy of ${data.media.title}`)
        showToast(t('forms:copySuccess'))
      }
    }
  }

  useEffect(() => {
    if (!isContentCopied) {
      handleFormInitOnSave(data)
    }
  }, [data, isContentCopied])

  useEffect(() => {
    if (!data && isContentCopied && contentCopied?.media) {
      handleFormInitOnSave(contentCopied)
    }
  }, [contentCopied, isContentCopied])

  const [invalidatedByEdit, setInvalidatedByEdit] = useState(false)
  const [invalidatedByEditUser, setInvalidatedByEditUser] = useState<
    any | null
  >(null)

  const showInvalidatedByEditMessage = () => {
    if (invalidatedByEditUser) {
      const { firstName, lastName } = invalidatedByEditUser
      showToast(
        t('media:mediaUpdatedByPerson', {
          name: `${firstName} ${lastName}`
        }),
        undefined,
        -1
      )
    } else {
      showToast(t('media:mediaUpdated'))
    }
  }

  useEffect(() => {
    if (invalidatedByEdit) {
      showInvalidatedByEditMessage()
    }
  }, [invalidatedByEdit, invalidatedByEditUser])

  useMediaUpdatedSubscription(
    route?.params?.id,
    ({ data: subscriptionData }) => {
      const {
        data: {
          mediaUpdated: { updatedBy }
        }
      } = subscriptionData

      if (!updatedBy || updatedBy.user.id !== currentUserId) {
        setInvalidatedByEdit(true)
        setInvalidatedByEditUser(updatedBy)
      }
    }
  )

  const handleHostMediaSubscription = (field: string) => ({
    data: subscriptionData
  }) => {
    const {
      data: { hostMediaUpdated }
    } = subscriptionData

    if (hostMediaUpdated && hostMediaUpdated.transcode === 'complete') {
      formik.setFieldValue(field, hostMediaUpdated)
    }
  }

  useHostMediaUpdatedSubscription(
    formik?.values?.hostMedia?.id,
    handleHostMediaSubscription('hostMedia')
  )

  useHostMediaUpdatedSubscription(
    formik?.values?.highlightsHostMedia?.id,
    handleHostMediaSubscription('highlightsHostMedia')
  )

  const handleChangeHostMedia = useCallback(media => {
    formik.setTouched(touchFields, false)
    formik.setFieldValue('hostMedia', media)
    formik.setFieldValue('hostMediaId', media && media.id)
  }, [])

  const handleChangeHighlightsHostMedia = useCallback(media => {
    formik.setTouched(touchFields, false)
    formik.setFieldValue('highlightsHostMedia', media)
    formik.setFieldValue('highlightsHostMediaId', media && media.id)
  }, [])

  const handleHighlightsHostMediaError = useCallback(err => {
    formik.setFieldError('highlightsHostMedia', err)
  }, [])

  const handleHostMediaError = useCallback(err => {
    formik.setFieldError('hostMedia', err)
  }, [])

  const handleSelectCategory = useCallback(
    value => formik.setFieldValue('category', value || null),
    []
  )

  const handleChangeDescription = useCallback(
    state => {
      formik.setFieldValue('description', state)
    },
    [formik?.values?.description]
  )

  const handleDeleteMedia = useCallback(() => {
    const answer = confirm(t('media:confirmDelete'))
    if (answer) {
      deleteMedia({ variables: { id: data?.media?.id } })
    }
  }, [data?.media?.id])

  const handleSaveMedia = useCallback((e: any) => {
    if (invalidatedByEdit) {
      showInvalidatedByEditMessage()
      return
    }

    isSubmittingRef.current = true
    formik.handleSubmit(e)
  }, [])

  const handleCopyContent = useCallback(() => {
    if (formik.dirty) {
      turnOnValidationModal()
      return
    }
    isSubmittingRef.current = true
    setValuesToCopy(data, ContentTypeEnum.media)
    navigation.navigate('MediaCreate')
  }, [formik])

  useEffect(() => {
    if (validatingPreview) {
      formik.setTouched(touchFields, false)
      formik.validateForm()
      turnOffValidatingPreview()
    }
  }, [validatingPreview])

  if (!mediaEditData || !mediaEditData.hostMedias) {
    return null
  }

  const categoryList = [
    {
      label: t('media:category:engageCalls'),
      value: MediaCategoryEnum.EngageCalls
    },
    {
      label: t('media:category:huddleCalls'),
      value: MediaCategoryEnum.HuddleCalls
    },
    {
      label: t('media:category:executiveSummaries'),
      value: MediaCategoryEnum.ExecutiveSummaries
    },
    {
      label: t('media:category:compilations'),
      value: MediaCategoryEnum.Compilations
    },
    {
      label: t('media:category:summitHighlights'),
      value: MediaCategoryEnum.SummitHighlights
    },
    {
      label: t('media:category:podcasts'),
      value: MediaCategoryEnum.Podcasts
    }
  ]

  // extracting some logic to cleanup the jsx below
  const hostMediaHasFormikErrors = hostMediaKey =>
    Object.keys(formik.errors).some(key => key.indexOf(hostMediaKey) === 0)

  const errCopyWithUnsavedChanges = isEditing && formik.dirty
  const errStillProcessingMedia = !!formik.errors['hostMedia.transcode']
  const errRequiredFieldsMissing = Object.values(formik.errors).some(
    v => v === 'Required'
  )
  const errRequiredAtLeastAudienceType = Object.values(formik.errors).some(
    v => v === 'atLeastAudienceType'
  )

  const savedGatheringId = data?.media?.event?.id
  const autoFillFromEvent = savedGatheringId !== formik.values.gathering

  const fullMediaProps = {
    documentFileSizeLimit: documentFileSizeLimit,
    audioFileSizeLimit: audioFileSizeLimit,
    value: formik.values.hostMedia,
    hasError:
      hostMediaHasFormikErrors('hostMedia') && !!formik.touched.hostMedia,
    setValue: handleChangeHostMedia,
    setError: handleHostMediaError,
    setUploading: setUploadingMedia
  }

  const highlightsMediaProps = {
    documentFileSizeLimit: documentFileSizeLimit,
    audioFileSizeLimit: audioFileSizeLimit,
    value: formik.values.highlightsHostMedia,
    hasError:
      hostMediaHasFormikErrors('highlightsHostMedia') &&
      !!formik.touched.highlightsHostMedia,
    setValue: handleChangeHighlightsHostMedia,
    setError: handleHighlightsHostMediaError,
    setUploading: setUploadingHighlights
  }

  let initialGatheringOptions = {}
  if (data?.media?.events?.length) {
    data?.media?.events.forEach(event => {
      initialGatheringOptions = {
        ...initialGatheringOptions,
        [event.id]: {
          value: event.id,
          label: event.name,
          custom: {
            date: event.startDate,
            groups: event.groups
          }
        }
      }
    })
  }

  let initialPeopleOptions = {}
  if (data?.media?.people?.length) {
    data?.media?.people.forEach(person => {
      initialPeopleOptions = {
        ...initialPeopleOptions,
        [person.id]: {
          value: person.id,
          label: `${person.firstName} ${person.lastName}`,
          custom: { employerName: person.employerName || '' }
        }
      }
    })
  }

  return (
    <ScreenContainerWithItemHeader
      screenCategory="MediaEdit"
      defaultBack="MediaList"
    >
      <Flex flexDirection="column" flexBasis="100%" p="20px">
        {/* Title Row */}
        <Flex flexDirection="row">
          <H4 styles={{ flexBasis: '100%', marginBottom: 20 }}>
            {data ? t('media:editTitle') : t('media:createTitle')}
          </H4>
        </Flex>

        {/* Scroll For Form */}
        {/* @ts-ignore */}
        <ScrollView ref={scrollRef}>
          {/* Row for main inputs and hero image */}
          <Flex
            flexDirection="row"
            flexWrap="wrap"
            flex={1}
            mt="20px"
            style={{ backgroundColor: 'green' }}
          >
            {/* Column for main inputs */}
            <Flex
              flexDirection="column"
              flex={2}
              mr="15px"
              style={{ minWidth: '50%' }}
            >
              {/* Inputs Row */}
              <Flex
                flexDirection="row"
                flexWrap="wrap"
                alignItems="flex-start"
                justifyContent="flex-start"
                alignContent="flex-start"
              >
                <TextInput
                  placeholder={t('forms:title')}
                  value={formik.values.title}
                  onChangeText={formik.handleChange('title')}
                  onBlur={formik.handleBlur('title')}
                  hasError={!!formik.errors.title && !!formik.touched.title}
                  style={{ minWidth: '35%', flex: 1, marginRight: 10 }}
                />

                <PublishDateTimeInput
                  value={
                    formik.values.publishDate
                      ? // @ts-ignore null publishDate is protected
                        new Date(formik.values.publishDate)
                      : null
                  }
                  onBlur={formik.handleBlur('publishDate')}
                  onChangeDate={handleChangePublishDate}
                  hasError={
                    !!formik.errors.publishDate && !!formik.touched.publishDate
                  }
                  initialDate={initialPublishDateTime}
                />
                <PublishCheckbox
                  handlePressed={handleChangePublished}
                  published={formik.values.published}
                />
                <TextInput
                  placeholder={t('forms:subtitle')}
                  value={formik.values.subtitle}
                  onChangeText={formik.handleChange('subtitle')}
                  onBlur={formik.handleBlur('subtitle')}
                  hasError={
                    !!formik.errors.subtitle && !!formik.touched.subtitle
                  }
                  style={{ minWidth: '50%', flex: 2, marginRight: 10 }}
                />

                <Dropdown
                  options={categoryList}
                  placeholder={t('forms:category')}
                  onSelect={handleSelectCategory}
                  onBlur={formik.handleBlur('category')}
                  value={formik.values.category}
                  hasError={
                    !!formik.errors.category && !!formik.touched.category
                  }
                  style={{ minWidth: '30%', marginRight: 10 }}
                  isClearable
                />
                <GatheringSelect
                  onSelect={handleSelectGathering}
                  onBlur={formik.handleBlur('gathering')}
                  value={formik.values.gathering}
                  hasError={
                    !!formik.errors.gathering && !!formik.touched.gathering
                  }
                  editingId={data?.media?.event?.id}
                />
                {isContentSharingEnabled ? (
                  <ShareCheckbox
                    handlePressed={handleChangeShareable}
                    shareable={formik.values.shareable}
                  />
                ) : null}
                <PersonTagManager
                  values={formik.values.taggedPeople}
                  onValueChange={handleTagPeople}
                  {...(autoFillFromEvent && {
                    eventId: formik.values.gathering
                  })}
                />
                <Flex
                  width="100%"
                  flexWrap="wrap"
                  flexDirection="row"
                  justifyContent="space-between"
                >
                  <AudienceMultiselect
                    placeholder={t('forms:audienceType:communities')}
                    onSelect={handleSelectCommunities}
                    value={formik?.values?.communities}
                    onBlur={formik?.handleBlur('communities')}
                    hasError={
                      !!formik?.errors?.communities &&
                      !!formik?.touched?.communities
                    }
                  />
                  {isContentPublishingEnabled && (
                    <AudienceMultiselect
                      audience={AudienceType.GROUPS}
                      placeholder={t('forms:audienceType:groups')}
                      onSelect={handleSelectGroups}
                      value={formik?.values?.groups}
                      onBlur={formik?.handleBlur('groups')}
                      hasError={
                        !!formik?.errors?.groups && !!formik?.touched?.groups
                      }
                    />
                  )}
                  {isContentPublishingEnabled && (
                    <GatheringSelect
                      isMulti
                      onSelect={handleSelectGatherings}
                      onBlur={formik.handleBlur('gatherings')}
                      value={formik.values.gatherings}
                      hasError={
                        !!formik.errors.gatherings &&
                        !!formik.touched.gatherings
                      }
                      editingId={data?.media?.event?.id}
                      initialOptions={initialGatheringOptions}
                    />
                  )}
                  {isContentPublishingEnabled && (
                    <PeopleSelect
                      value={formik.values.people}
                      onSelect={handleSelectPeople}
                      onBlur={formik.handleBlur('people')}
                      hasError={
                        !!formik.errors.people && !!formik.touched.people
                      }
                      styleContainer={{ marginRight: 'auto' }}
                      initialOptions={initialPeopleOptions}
                    />
                  )}
                  <ContentTopicMultiselect
                    onSelect={handleSelectContentTopic}
                    value={formik.values.topics}
                    onBlur={formik.handleBlur('topics')}
                    hasError={!!formik.errors.topics && !!formik.touched.topics}
                    {...(autoFillFromEvent && {
                      eventId: formik.values.gathering
                    })}
                  />
                </Flex>
              </Flex>
              {/* Row For Rich Text Editor */}
              <Flex flexDirection="row" flexWrap="wrap">
                <RichTextEditor
                  editorState={formik.values.description}
                  onChange={handleChangeDescription}
                  placeholder={t('forms:description')}
                  onBlur={formik.handleBlur('description')}
                  style={{
                    flex: 1,
                    marginRight: 20,
                    height: theme.breakpoints.phone,
                    width: theme.breakpoints.tablet
                  }}
                  hasError={
                    !!formik.errors.description && !!formik.touched.description
                  }
                  scrollToEnd={scrollToEnd}
                />
              </Flex>
            </Flex>

            {/* Column For Hero Image */}
            {fullMediaProps.documentFileSizeLimit && (
              <Flex flexDirection="column" flexWrap="wrap">
                <HeroImageDrop
                  value={formik.values.heroImage}
                  hasError={
                    !!formik.errors.heroImage && !!formik.touched.heroImage
                  }
                  setValue={handleChangeHeroImage}
                  setError={handleHeroImageError}
                  style={{
                    minWidth: '400px',
                    height: 250,
                    marginBottom: '15px'
                  }}
                  cloudinaryDir="mediaHeroImages"
                />
                <MultipleMediaUploader
                  fullMediaProps={fullMediaProps}
                  highlightsMediaProps={highlightsMediaProps}
                />
              </Flex>
            )}
          </Flex>
        </ScrollView>
        <Footer
          isDeleteDisabled={!isEditing}
          handlePressDelete={handleDeleteMedia}
          isPreviewDisabled={
            uploadingMedia ||
            uploadingHighlights ||
            !!formik.dirty ||
            !isEditing
          }
          handlePressPreview={turnOnValidatingPreview}
          isSaveDisabled={
            uploadingMedia ||
            uploadingHighlights ||
            !formik.dirty ||
            uploadingHeroImage ||
            (loading && !isEditing)
          }
          handlePressSave={handleSaveMedia}
          isCopyContentDisabled={!isEditing}
          handlePressCopyContent={handleCopyContent}
        />

        <ValidationModal
          isOpen={showValidationModal}
          handleClose={turnOffValidationModal}
          children={
            <>
              {errRequiredAtLeastAudienceType && (
                <ErrorText>{t('forms:atLeastAudienceType')}</ErrorText>
              )}
              {errRequiredFieldsMissing && (
                <ErrorText>{t('forms:missingRequiredFields')}</ErrorText>
              )}
              {errStillProcessingMedia && (
                <ErrorText>{t('media:mediaStillProcessing')}</ErrorText>
              )}
              {errCopyWithUnsavedChanges && (
                <ErrorText>{t('forms:errCopyWithUnsavedChanges')}</ErrorText>
              )}
            </>
          }
        />
      </Flex>
    </ScreenContainerWithItemHeader>
  )
}
