import gql from 'graphql-tag'

const featureFlagQuery = gql`
  query {
    featureFlags {
      name
      value
    }
  }
`

export default featureFlagQuery
