import React, { useEffect, useMemo } from 'react'
import useContentTopicQuery from './hooks/useContentTopicQuery'
import Dropdown from '../Dropdown'
import useTranslation from '../../../hooks/useTranslation'
import AddTopicsIcon from '../../../images/add-topics.svg'
import gql from 'graphql-tag'
import { useQuery } from '@apollo/client'
import { Flex } from '../../FlexBox'
import Placeholder from '../Placeholder'

interface Props {
  onSelect: (value: any[]) => void
  value: any[]
  eventId?: string
  onBlur: (e: any) => void
  hasError: boolean
  style?: any
}

const eventTopicsQuery = gql`
  query eventWithTopics($id: String!) {
    event(id: $id) {
      id
      topics {
        id
      }
    }
  }
`

const MAX_TOPICS = 3

const ContentTopicMultiselect = ({
  style,
  eventId,
  onSelect,
  value,
  ...rest
}: Props) => {
  const { data } = useContentTopicQuery()
  const { t } = useTranslation()

  const { data: eventData } = useQuery(eventTopicsQuery, {
    variables: { id: eventId },
    skip: !eventId
  })

  // Get incoming values from event to auto populate
  useEffect(() => {
    if (eventData?.event) {
      const autoFillValues = eventData.event.topics
        .filter(({ id }) => !value.find(({ id: valueId }) => id === valueId))
        .map(({ id }) => ({ id, autoFilled: true }))

      onSelect([...autoFillValues, ...value])
    } // If unselected event, clear out the auto filled topics.
    else {
      onSelect(value.filter(({ autoFilled }) => !autoFilled))
    }
  }, [eventData])

  // Need to add the autoFilled flag to the option because
  // the dropdown only accepts string values
  const options = useMemo(() => {
    if (data?.contentTopics) {
      return data.contentTopics.map(elem => {
        const { value: optionId } = elem
        const v = value.find(({ id: valueId }) => optionId === valueId)
        if (v) {
          return { ...elem, custom: { autoFilled: v.autoFilled } }
        }
        return elem
      })
    } else {
      return []
    }
  }, [value, data])

  const handleSelect = (ids: string[]) => {
    const selectedOptions = options
      .filter(({ value }) => ids.includes(value))
      .map(({ value, custom }) => ({
        id: value,
        autoFilled: custom?.autoFilled
      }))
    onSelect(selectedOptions)
  }

  return (
    <Flex flexDirection="column" style={style}>
      <Placeholder>{t('forms:topics')}</Placeholder>
      <Dropdown
        options={options}
        maxAmount={MAX_TOPICS}
        placeholder={''}
        isMulti
        closeMenuOnSelect={false}
        dropdownIcon={<AddTopicsIcon />}
        style={{
          backgroundColor: 'transparent',
          ...style
        }}
        value={value.map(({ id }) => id)}
        onSelect={handleSelect}
        {...rest}
      />
    </Flex>
  )
}

export default ContentTopicMultiselect
