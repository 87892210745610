import * as tus from 'tus-js-client'
import Constants from 'expo-constants'

const {
  VIMEO_ACCESS_TOKEN,
  VIMEO_API_URL
} = Constants.manifest.extra.VIMEO_CONFIG

const vimeoHeaders = {
  Authorization: `Bearer ${VIMEO_ACCESS_TOKEN}`,
  Accept: 'application/vnd.vimeo.*+json;version=3.4'
}

const vimeoUploadUrl = `${VIMEO_API_URL}me/videos`

interface VimeoVideo {
  id: string
}

/**
 * In order to upload to vimeo via TUS, we create the initial video
 * from the file and use the upload url to upload the video.
 * @param file
 */
const createVimeoVideo = async file => {
  const body = {
    name: file.name,
    upload: {
      approach: 'tus',
      size: file.size
    }
  }

  const response = await fetch(vimeoUploadUrl, {
    method: 'POST',
    mode: 'cors',
    cache: 'no-cache',
    credentials: 'same-origin',
    headers: {
      Authorization: `bearer ${VIMEO_ACCESS_TOKEN}`,
      'Content-Type': 'application/json',
      Accept: 'application/vnd.vimeo.*+json;version=3.4'
    },
    redirect: 'follow',
    referrerPolicy: 'no-referrer',
    body: JSON.stringify(body)
  })

  return response.json()
}

export default async function uploadToVimeo(file, onProgress) {
  const video = await createVimeoVideo(file)

  return new Promise((resolve, reject) => {
    const upload = new tus.Upload(file, {
      uploadUrl: video?.upload?.upload_link,
      headers: vimeoHeaders,
      retryDelays: [0, 1000, 3000, 5000, 10000, 20000],
      metadata: {
        filename: file.name,
        filetype: file.type
      },
      uploadSize: file.size,
      chunkSize: 5000000,
      onProgress: (loaded, total) => {
        onProgress(Math.round((loaded / total) * 100))
      },
      onError: err => reject(err),
      onSuccess: () => resolve(video)
    })
    upload.start()
  })
}

export async function getVideoDetails(id) {
  const response = await fetch(`${vimeoUploadUrl}/${id}`, {
    method: 'GET',
    mode: 'cors',
    cache: 'no-cache',
    credentials: 'same-origin',
    headers: {
      Authorization: `bearer ${VIMEO_ACCESS_TOKEN}`,
      'Content-Type': 'application/json',
      Accept: 'application/vnd.vimeo.*+json;version=3.4'
    },
    redirect: 'follow',
    referrerPolicy: 'no-referrer'
  })

  return response.json()
}
