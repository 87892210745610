import gql from 'graphql-tag'
import { useMutation } from '@apollo/client'

// TODO: setup custom useMutation hook once we have sentry and toast notifications(if we want them)
const deleteMediaMutation = gql`
  mutation deleteMedia($id: String) {
    deleteMedia(id: $id)
  }
`

// TODO: implement error handling once we have a system in place
const useDeleteMediaMutation = (options = {}) => {
  const [deleteMedia]: any[] = useMutation(deleteMediaMutation, options)
  return deleteMedia
}

export default useDeleteMediaMutation
