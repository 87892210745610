import { atom, RecoilState } from 'recoil'
import { ToastType } from '../../hooks/useToast'

export interface IToast {
  id: string
  message: string
  type: ToastType
  queuedForRemoval?: boolean
}

const toastMessage: RecoilState<IToast[]> = atom({
  key: 'toastMessage',
  default: [] as IToast[]
})

export default toastMessage
