import React, { FC } from 'react'
import { Text } from 'react-native'

import {
  DZSubText,
  DZHeaderText,
  DZIconContainer,
  DropZoneContentProps
} from '../FileDropzone'
import useTranslation from '../../hooks/useTranslation'

export interface DropZoneContainerProps extends DropZoneContentProps {
  title: string
  imageIcon?: JSX.Element
}

const DropZoneContainer: FC<DropZoneContainerProps> = ({
  title,
  imageIcon,
  selectFileButton
}) => {
  const { t } = useTranslation()

  return (
    <>
      <DZIconContainer>{imageIcon}</DZIconContainer>
      <DZHeaderText>{title}</DZHeaderText>
      <Text>{t('fileDropZone:or')} </Text>
      <DZSubText>{selectFileButton}</DZSubText>
    </>
  )
}

export default DropZoneContainer
