import { gql } from '@apollo/client'
import { IPageContent } from './types'

export interface IUpsertPageContentMutation {
  upsertPageContent: {
    id: string
    // eventId: string
    sections: IPageContent
    createdAt: string
    updatedAt: string
  }
}

export const upsertPageContentMutation = gql`
  mutation upsertPageContent(
    $eventId: String!
    $pageContent: UpsertPageContentInput!
  ) {
    upsertPageContent(eventId: $eventId, pageContent: $pageContent) {
      id
      sections {
        uid
        title
        live
        blocks {
          uid
          type
          live
          exportToPdf
          columns {
            type
            subType
            content
          }
        }
      }
      createdAt
      updatedAt
      publishDate
    }
  }
`
export const publishPageContentMutation = gql`
  mutation publishPageContent($eventId: String!, $publishDate: DateTime) {
    publishPageContent(eventId: $eventId, publishDate: $publishDate) {
      id
      createdAt
      updatedAt
      publishDate
    }
  }
`
