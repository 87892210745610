import React from 'react'
import { ScreenContainerWithMenuHeader } from '../../components/layout/ScreenContainer'
import { Flex } from '../../components/FlexBox'
import { H4 } from '../../components/common/Text'
import Button from '../../components/common/Button'
import NewShortUrlModal from './components/NewShortUrlModal'
import useUrlShortener from './hooks/useUrlShortener'
import ShortUrlsTable from './components/ShortUrlsTable'
import DeleteShortUrlModal from './components/DeleteShortUrlModal'

const UrlShortenerScreen = () => {
  const {
    confirmDeletion,
    cancelDeletion,
    shortUrls,
    selectForDeletion,
    selectedForDeletion,
    loadingData,
    loadingDeletion,
    loadingUpsertion,
    targetUrl,
    setTargetUrl,
    upsertShortUrl,
    shortenedUrl,
    selectUrl,
    isOn,
    turnOff,
    turnOn
  } = useUrlShortener()

  if (loadingData) {
    return null
  }

  return (
    <ScreenContainerWithMenuHeader screenCategory={'urlShortener'}>
      <Flex flexDirection="row" mx={4} my={3}>
        <Flex flex={1}>
          <H4>{'Url Shortener'}</H4>
        </Flex>
        <Button onPress={turnOn} label="New" />
      </Flex>
      <ShortUrlsTable
        shortUrls={shortUrls}
        onSelect={selectUrl}
        onDelete={selectForDeletion}
      />
      <NewShortUrlModal
        isOn={isOn}
        turnOff={turnOff}
        targetUrl={targetUrl}
        setTargetUrl={setTargetUrl}
        upsertShortUrl={upsertShortUrl}
        shortUrl={shortenedUrl?.sourceUrl}
        loading={loadingUpsertion}
      />
      <DeleteShortUrlModal
        isOn={!!selectedForDeletion}
        shortUrl={selectedForDeletion?.sourceUrl}
        targetUrl={selectedForDeletion?.targetUrl}
        onConfirm={confirmDeletion}
        onCancel={cancelDeletion}
        loading={loadingDeletion}
      />
    </ScreenContainerWithMenuHeader>
  )
}

export default UrlShortenerScreen
