import React, { useState, useEffect } from 'react'

import { View, Text } from 'react-native'
import AutoCompleteDropdown from '../../../../components/common/form/AutoCompleteDropdown'
import {
  appRoles,
  innovationEditableRoles,
  mi6Roles
} from '../../../../constants/Utils'
import { capitalizeEachLetter } from '../../../../utils/capitalize'
import { AppRoles, GlobalRoleApps } from './utils'
import styled from 'styled-components/native'
import useTranslation from '../../../../hooks/useTranslation'
import { DropdownOption } from '../../../../components/common/Dropdown'

const textInputStyle = {
  borderRadius: 16,
  marginTop: 16,
  marginBottom: 4,
  paddingLeft: 8,
  width: '100%'
}

const DropdownTitle = styled(Text)`
  ${({ theme: { space } }) => `
    font-size: 16px;
    margin-top: ${space[2]}px;
  `}
`

const ContentTitle = styled(Text)`
  ${({ theme: { space } }) => `
    margin-bottom: ${space[3]}px;
    font-size: 16px;
  `}
`

const RoleSelectorContainer = styled(View)`
  ${({ theme: { space } }) => `
    margin-bottom: ${space[4]}px;
  `}
`

const AppToRolesMap = {
  [GlobalRoleApps.MI6]: mi6Roles,
  [GlobalRoleApps.INNOVATION]: innovationEditableRoles,
  [GlobalRoleApps.MEMBER_APP]: appRoles
}

const Mi6RolesMap = {
  communities_microsites_permissions: 'Microsites Editor'
}

const getRolesOptions = (
  app: GlobalRoleApps | undefined,
  existingRoles: AppRoles[]
) => {
  if (!app) {
    return []
  }

  const roles: string[] = AppToRolesMap[app] ?? []
  return roles
    .filter(
      role =>
        !existingRoles.find(
          existingRole => existingRole.app === app && existingRole.role === role
        )
    )
    .map(role => ({
      label: Mi6RolesMap[role]
        ? Mi6RolesMap[role]
        : capitalizeEachLetter(role.replace(/_/g, ' ')),
      value: role
    }))
}

interface AddRolesProps {
  onSelection: (appSelections: AppRoles) => void
  existingRoles: AppRoles[]
}

interface RoleSelectorProps {
  onSelection: (role: string) => void
  options: DropdownOption[]
  value?: string
}

const RoleSelector = ({ onSelection, options, value }: RoleSelectorProps) => {
  const { t } = useTranslation()

  if (!options.length) {
    return (
      <DropdownTitle>
        {t('users:roles:noRolesAvailableForSelectedApp')}
      </DropdownTitle>
    )
  }
  return (
    <RoleSelectorContainer>
      <DropdownTitle>{t('users:roles:select')}</DropdownTitle>
      <AutoCompleteDropdown
        options={options}
        placeholder=""
        value={value ?? null}
        onSelectValue={onSelection}
        styles={textInputStyle}
      />
    </RoleSelectorContainer>
  )
}

const AddRoles = ({ onSelection, existingRoles }: AddRolesProps) => {
  const [roleSelection, setRoleSelection] = useState<AppRoles | null>(null)

  const { t } = useTranslation()
  const options = getRolesOptions(roleSelection?.app, existingRoles)

  const setSelectedApp = app => {
    setRoleSelection({ app, role: '' })
  }

  const setSelectedRole = role => {
    setRoleSelection({ app: roleSelection?.app!!, role })
  }

  useEffect(() => {
    if (roleSelection?.app && roleSelection?.role) {
      onSelection(roleSelection)
    }
  }, [roleSelection])

  return (
    <>
      <ContentTitle>{t('users:roles:define')}</ContentTitle>
      <DropdownTitle>{t('users:app:select')}</DropdownTitle>
      <AutoCompleteDropdown
        options={[
          { label: 'MI-6', value: GlobalRoleApps.MI6 },
          { label: 'World 50 App', value: GlobalRoleApps.MEMBER_APP },
          { label: 'Innovation', value: GlobalRoleApps.INNOVATION }
        ]}
        placeholder=""
        value={roleSelection?.app ?? null}
        onSelectValue={setSelectedApp}
        styles={textInputStyle}
      />
      {!!roleSelection?.app && (
        <RoleSelector
          onSelection={setSelectedRole}
          options={options}
          value={roleSelection?.role}
        />
      )}
    </>
  )
}

export default AddRoles
