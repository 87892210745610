import React, { useRef, useCallback, useEffect } from 'react'

const useThrottledCallback = (callback: any, wait) => {
  const nextCall: any = useRef(null)
  const lastCallTime: any = useRef(0)
  const rafRef: any = React.useRef()

  const proxyFn = useCallback((...args) => {
    nextCall.current = args
  }, [])

  const update = () => {
    const currentTime = Date.now()
    const deltaTime = currentTime - lastCallTime.current

    if (nextCall.current !== null && deltaTime >= wait) {
      /* eslint-disable */
      callback(...nextCall.current)

      nextCall.current = null
      lastCallTime.current = currentTime
    }

    rafRef.current = requestAnimationFrame(update)
  }

  useEffect(() => {
    rafRef.current = requestAnimationFrame(update)
    return () => cancelAnimationFrame(rafRef.current)
  }, [callback, wait])

  return proxyFn
}

export default useThrottledCallback
