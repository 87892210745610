import Colors from './Colors'

const theme = {
  breakpoints: {
    desktop: 1200,
    tablet: 900,
    phone: 600
  },
  space: [0, 4, 8, 16, 32, 64, 128, 256, 512],
  sizes: [0, 4, 8, 16, 32, 64, 128, 256, 512],
  radii: [0, 2, 4, 8, 16, 32],
  borderWidths: [0, 1, 2, 4],
  fontSizes: [6, 8, 10, 12, 16, 17, 20, 24, 34, 48, 60, 96],
  fontWeights: {
    thin: 100,
    light: 300,
    regular: 400,
    medium: 500,
    bold: 700,
    black: 900
  },
  colors: Colors.light
}
export default theme

const dark = {
  ...theme,
  colors: Colors.dark
}
export { dark }
