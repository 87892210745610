import React from 'react'
import { View } from 'react-native'
import { Svg, Path } from 'react-native-svg'

interface IconProps {
  color: string
  size?: number
  style?: object | object[]
}

const MenuIcon = React.memo(
  ({ color, size = 24, style, ...rest }: IconProps) => (
    <View pointerEvents="none" style={style}>
      <Svg width={size} height={size} viewBox="0 0 128 128" {...rest}>
        <Path
          d="M124.45,71H3.55c-2,0-3.5-1.24-3.5-2.76V57.76c0-1.51,1.5-2.76,3.5-2.76h121.01c1.9,0,3.5,1.24,3.5,2.76v10.49
        C127.95,69.76,126.45,71,124.45,71z M128,26.24V15.76c0-1.51-1.5-2.76-3.5-2.76H3.5c-2,0-3.5,1.24-3.5,2.76v10.49
        C0,27.76,1.5,29,3.5,29h121.1C126.5,29,128,27.76,128,26.24z M128,110.24V99.76c0-1.51-1.5-2.76-3.5-2.76H3.5
        c-2,0-3.5,1.24-3.5,2.76v10.49c0,1.51,1.5,2.76,3.5,2.76h121.1C126.5,113,128,111.76,128,110.24z"
          fill={color}
        />
      </Svg>
    </View>
  )
)

export default MenuIcon
