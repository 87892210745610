import * as Yup from 'yup'
import { topicsSchema } from '../../../validators/topicsSchema'

const saveSchema = Yup.object().shape({
  id: Yup.string().nullable(),
  description: Yup.object(),
  title: Yup.string().min(1, 'Too Short!').required('Required'),
  subtitle: Yup.string().nullable(),
  type: Yup.string().nullable(),
  gathering: Yup.string().nullable(),
  heroImage: Yup.object()
    .shape({
      fileName: Yup.string().nullable(),
      url: Yup.string().nullable(),
      publicId: Yup.string().nullable()
    })
    .nullable(),
  communities: Yup.array().nullable().of(Yup.string()),
  groups: Yup.array().nullable().of(Yup.string()),
  people: Yup.array().nullable().of(Yup.string()),
  events: Yup.array().nullable().of(Yup.string()),
  published: Yup.bool(),
  publishDate: Yup.date().nullable(),
  topics: topicsSchema.nullable()
})

export default saveSchema
