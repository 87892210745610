import {
  LegacyRoles,
  AppRoles,
  OsoGroupRoles,
  MI6Roles,
  InnovationEditableRoles,
  InnovationRoles
} from '../types'

export const ByteFactor = 1024

export const appRoles = Object.values(AppRoles)
export const groupRoles = Object.values(OsoGroupRoles)
export const legacyRoles = Object.values(LegacyRoles)
export const mi6Roles = Object.values(MI6Roles)
export const innovationEditableRoles = Object.values(InnovationEditableRoles)
export const innovationRoles = Object.values(InnovationRoles)
