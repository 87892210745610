import React, { FC, useCallback, useState } from 'react'
import { FontAwesome5 } from '@expo/vector-icons'
import EditorButton from './EditorButton'
import { H3 } from '../common/Text'

import { Flex } from '../FlexBox'
import Button from '../common/Button'
import Modal from '../common/Modal'
import useTranslation from '../../hooks/useTranslation'

import ContentDrop from '../common/form/ContentDrop'
// @ts-ignore
import DropImageIcon from '../../images/drop-image.svg'
import styled from 'styled-components/native'
import { Image } from 'react-native'
import { FileDropzoneValue } from '../FileDropzone'
import { MAX_UPLOADED_WIDTH } from './media/Image'

interface InlineImageUploadProps {
  onAddImage: (data) => void
}

const adjustDimensionsToMinWidth = (width, height, minWidth) => {
  if (width <= minWidth) {
    return { width, height }
  }

  const aspectRatio = width / height
  const widthDifference = width - minWidth
  const divisions = Math.ceil(widthDifference / aspectRatio)

  const adjustedWidth = width - divisions
  const adjustedHeight = Math.round(adjustedWidth / aspectRatio)

  return { width: adjustedWidth, height: adjustedHeight }
}
const InlineImageUpload: FC<InlineImageUploadProps> = ({ onAddImage }) => {
  const { t } = useTranslation()
  const [image, setImage] = useState<FileDropzoneValue | null>(null)
  const [modalOpen, setModalOpen] = useState(false)

  const onClose = useCallback(() => {
    setImage(null)
    setModalOpen(false)
  }, [])

  return (
    <>
      <EditorButton
        icon={<FontAwesome5 name="image" size={28} color="black" />}
        onPress={() => {
          setModalOpen(true)
        }}
      />
      <Modal
        open={modalOpen}
        close={() => {
          setModalOpen(false)
        }}
      >
        <H3 styles={{ paddingBottom: 10 }}>
          {t('richTextEditor:addInlineImage')}
        </H3>

        <Flex>
          <ContentDrop
            // @ts-ignore
            value={image}
            setValue={v => {
              setImage(v)
            }}
            setError={e => {
              console.error(e)
            }}
            style={{
              minWidth: '400px',
              height: 250,
              marginBottom: '15px'
            }}
            cloudinaryDir="mediaInlineImages"
            acceptFormat="image/png, image/jpeg"
            dropZoneContent={{
              imageIcon: <DropImageIcon />,
              title: t('media:dropImage')
            }}
            removeContentModal={{
              title: t('media:removeImage'),
              description: t('media:removeImageConfirmation')
            }}
            onRenderUrl={uri => (
              <PreviewImage resizeMode="contain" source={{ uri }} />
            )}
          />
        </Flex>

        <Flex
          flexDirection="row"
          justifyContent="flex-end"
          style={{ paddingTop: 10 }}
        >
          <Button
            label="Cancel"
            onPress={onClose}
            style={{ marginRight: 10 }}
          />
          <Button
            label="Add Image"
            disabled={!image?.publicId}
            onPress={() => {
              const newDimensions = adjustDimensionsToMinWidth(
                image?.width,
                image?.height,
                MAX_UPLOADED_WIDTH
              )
              onAddImage({
                src: image?.url,
                style: {
                  width: newDimensions?.width,
                  height: newDimensions?.height
                }
              })
              onClose()
            }}
          />
        </Flex>
      </Modal>
    </>
  )
}

const PreviewImage = styled(Image)`
  ${({ theme: { sizes } }) => `
    height: ${sizes[6] + sizes[5]};
    width: 100%;
  `}
`

export default InlineImageUpload
