import React from 'react'
import { ActivityIndicator, ViewStyle, TextStyle, View } from 'react-native'
import {
  Button as RNEButton,
  ButtonProps as RNEButtonProps
} from 'react-native-elements'
import styled, { useTheme } from 'styled-components/native'

const StyledButton: React.FC<ButtonProps> = styled(RNEButton)`
  position: relative;
  border-radius: 5px;
`

interface ButtonProps extends RNEButtonProps {
  isProcessing?: boolean
  buttonStyle?: ViewStyle
  titleStyle?: TextStyle
  containerStyle?: ViewStyle
  disabledStyle?: ViewStyle
}

export default function Button({
  isProcessing,
  buttonStyle,
  titleStyle,
  containerStyle,
  disabledStyle,
  disabled,
  icon,
  type = 'solid',
  ...rest
}: ButtonProps) {
  const theme = useTheme()

  const spinner = (
    <ActivityIndicator size="small" color={theme.colors.buttonText} />
  )

  const buttonStyles = {
    solid: {
      buttonStyle: {
        backgroundColor: disabled
          ? theme.colors.disabled
          : theme.colors.buttonBackground,
        borderWidth: 1,
        borderStyle: 'solid',
        borderColor: theme.colors.buttonBorder
      },
      titleStyle: {
        color: theme.colors.buttonText
      },
      containerStyle: {},
      disabledStyle: {}
    },
    outline: {
      buttonStyle: {
        backgroundColor: 'transparent',
        borderColor: theme.colors.borderDark,
        borderWidth: 1,
        borderStyle: 'solid'
      },
      titleStyle: {
        color: theme.colors.borderDark
      },
      containerStyle: {},
      disabledStyle: {
        backgroundColor: 'transparent'
      }
    },
    clear: {
      buttonStyle: {
        width: 'auto',
        backgroundColor: 'transparent',
        padding: 0
      },
      titleStyle: {
        color: theme.colors.brightPrimary,
        fontSize: 17
      },
      containerStyle: {},
      disabledStyle: {}
    }
  }

  const showIcon = icon || isProcessing

  return (
    <StyledButton
      disabled={disabled}
      icon={
        showIcon &&
        /* @ts-ignore icon or isProcessing is assured to be set here */
        (isProcessing ? <IconContainer>{spinner}</IconContainer> : icon)
      }
      containerStyle={{
        ...buttonStyles[type].containerStyle,
        ...containerStyle
      }}
      buttonStyle={{
        width: 178,
        margin: 0,
        padding: 4,
        ...(buttonStyles[type].buttonStyle as any),
        ...buttonStyle
      }}
      titleStyle={{
        fontSize: 16,
        fontStyle: 'normal',
        ...buttonStyles[type].titleStyle,
        ...titleStyle
      }}
      disabledStyle={{
        ...buttonStyles[type].disabledStyle,
        ...disabledStyle
      }}
      {...rest}
    />
  )
}

interface IconContainerProps {
  children: JSX.Element
}
const IconContainer = ({ children }: IconContainerProps) => (
  <View style={{ position: 'absolute', left: 15, top: 9 }}>{children}</View>
)
