import React, { useState } from 'react'
import { TouchableOpacity, View } from 'react-native'
import styled from 'styled-components/native'
import { IQueueGraph } from './queries/queue'
import { Text } from '../../components/common/Text'
import { Flex } from '../../components/FlexBox'

const DependencyValue = ({
  dependency
}: {
  dependency: IQueueGraph['dependencies'][0]['value'][0]
}) => {
  const [subGroupExpanded, setSubGroupExpanded] = useState(false)
  return (
    <View style={{ backgroundColor: '#C5DCF5', borderRadius: 8, padding: 8 }}>
      <TouchableOpacity
        onPress={() => setSubGroupExpanded(!subGroupExpanded)}
        style={{ padding: 8 }}
      >
        <Text>
          {subGroupExpanded ? '▼ ' : '▶ '}
          <b>{dependency.type}</b>
        </Text>
      </TouchableOpacity>
      {subGroupExpanded && (
        <Flex mx={4} my={1} flexDirection="row">
          <Text>
            {Object.keys(dependency.args || {}).length === 0
              ? 'Full extraction'
              : JSON.stringify(dependency.args, null, 2)}
          </Text>
        </Flex>
      )}
    </View>
  )
}

const DependenciesCard = styled.View`
  flex: 1;
  padding: 10px;
  background-color: ${props =>
    props.failed ? props.theme.colors.failure : props.theme.colors.primary};
  border-radius: 8px;
`

const DependencyGroup = ({
  dependency
}: {
  dependency: IQueueGraph['dependencies'][0]
}) => {
  const [keyExpanded, setKeyExpanded] = useState(false)
  return (
    <>
      <TouchableOpacity
        onPress={() => setKeyExpanded(!keyExpanded)}
        style={{ padding: 8 }}
      >
        <Text>
          {keyExpanded ? '▼ ' : '▶ '}
          <b>{dependency.key}</b>
        </Text>
      </TouchableOpacity>
      {keyExpanded &&
        dependency.value.map(v => (
          <Flex mx={4} my={1} flexDirection="column" key={v.type}>
            <DependencyValue dependency={v} />
          </Flex>
        ))}
    </>
  )
}

export const DependenciesTab = ({
  dependencies
}: {
  dependencies?: IQueueGraph['dependencies']
}) => {
  if (!dependencies?.length) {
    return (
      <Flex mx={4} my={1} flexDirection="row">
        <Text>No dependencies</Text>
      </Flex>
    )
  }
  return (
    <Flex mx={4} my={1} flexDirection="row">
      {dependencies.map(dependency => (
        <DependenciesCard>
          <DependencyGroup dependency={dependency} />
        </DependenciesCard>
      ))}
    </Flex>
  )
}
