import React from 'react'
import { CheckBox as ElementsCheckBox } from 'react-native-elements'

const CheckBox = (props: CheckBoxProps) => {
  const containerStyle = {
    backgroundColor: 'transparent',
    borderWidth: 0,
    padding: 0,
    margin: 0,
    opacity: props.disabled ? 0.4 : 1
  }
  return (
    <ElementsCheckBox
      {...props}
      checkedColor="#21201B"
      uncheckedColor="#21201B"
      containerStyle={props.containerStyle || containerStyle}
    />
  )
}

interface CheckBoxProps {
  title: string
  checked: boolean
  onPress?: () => void
  containerStyle?: object
  disabled?: boolean
}

export default CheckBox
