import React from 'react'
import Navigation from '../navigation'
import Providers from './providers'
import ToastAnchor from '../components/ToastAnchor'

const AppRoot: React.FC = () => {
  return (
    <Providers>
      <Navigation />
      <ToastAnchor />
    </Providers>
  )
}

export default AppRoot
