import React from 'react'
import i18next from 'i18next'
import { Flex } from '../../FlexBox'
import useAudienceQuery, { AudienceType } from './hooks/useAudienceQuery'
import Dropdown from '../Dropdown'
// @ts-ignore
import AddAudienceIcon from '../../../images/add-audience.svg'
import Placeholder from '../Placeholder'
interface Props {
  onSelect: (value: [string]) => void
  value: [string] | never[]
  onBlur: (e: any) => void
  hasError: boolean
  style?: any
  placeholder?: string
  audience?: AudienceType
}

const AudienceMultiselect = ({
  placeholder = i18next.t('forms:audience'),
  audience = AudienceType.COMMUNITIES,
  style,
  ...rest
}: Props) => {
  const { data } = useAudienceQuery(audience)

  return (
    <Flex flexDirection="column" style={style}>
      <Placeholder>{placeholder}</Placeholder>
      <Dropdown
        options={data || []}
        isMulti
        placeholder={''}
        closeMenuOnSelect={false}
        dropdownIcon={<AddAudienceIcon />}
        style={{
          backgroundColor: 'transparent',
          marginRight: 10
        }}
        {...rest}
      />
    </Flex>
  )
}

export default AudienceMultiselect
