import React, { ReactNode } from 'react'
import { StyleProp, View, ViewStyle } from 'react-native'
import styled from 'styled-components/native'
import ReactModal from 'react-modal'
import { Flex } from '../FlexBox'
import DismissIcon from '../icon/DismissIcon'
import { TouchableOpacity } from 'react-native-gesture-handler'

ReactModal.setAppElement('#root')

const ModalBodyContainer = styled(View)`
  min-width: 300px;
  border-radius: 10px;
  padding: 10px;
  background-color: #ffffff;
`

const defaultOverlayStyle = {
  backgroundColor: 'rgba(0, 0, 0, 0.5)',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center'
}

const defaultContentStyle = {
  position: 'static'
}

const Modal = ({
  children,
  open,
  close,
  overlayStyle,
  contentStyle,
  showCloseIcon
}: ModalProps) => {
  return (
    <ReactModal
      isOpen={open}
      onRequestClose={close}
      style={{
        overlay: overlayStyle ?? defaultOverlayStyle,
        content: contentStyle ?? defaultContentStyle
      }}
    >
      {showCloseIcon && (
        <Flex flexDirection="row" justifyContent="end">
          <TouchableOpacity onPress={close}>
            <DismissIcon size={18} />
          </TouchableOpacity>
        </Flex>
      )}
      <ModalBodyContainer>{children}</ModalBodyContainer>
    </ReactModal>
  )
}

interface ModalProps {
  children: ReactNode
  close: () => void
  open: boolean
  overlayStyle?: StyleProp<ViewStyle>
  contentStyle?: StyleProp<ViewStyle>
  showCloseIcon?: boolean
}

export default Modal
