import * as Yup from 'yup'

export const taggedPeopleSchema = Yup.array().of(
  Yup.object().shape({
    personId: Yup.string().required(),
    source: Yup.object()
      .shape({
        sourceId: Yup.string().required(),
        sourceType: Yup.string().required()
      })
      .nullable()
      .default(null)
  })
)
