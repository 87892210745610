import React from 'react'
import { TouchableOpacity, View } from 'react-native'
import { Text } from 'react-native-elements'
import styled from 'styled-components/native'
import useTranslation from '../../hooks/useTranslation'
import DebouncedTextInput from './form/DebouncedTextInput'
import { FilterableColumnProps, SortableColumnProps, sortDir } from './Table'
import AutoCompleteDropdown from './form/AutoCompleteDropdown'
import useFilterOptions from '../../hooks/useFilterOptions'

const Container = styled(View)``

const HeaderText = styled(Text)`
  font-weight: bold;
  font-size: 20px;
  white-space: nowrap;
`

const SortHandle = styled(Text)`
  font-size: 13px;
  padding-left: 5px;
`

const textInputStyle = {
  borderRadius: 8,
  marginTop: 4,
  marginBottom: 4,
  paddingLeft: 8,
  width: '100%'
}

const FilterDropdownControl = ({
  filter,
  header,
  value,
  handleChangeFilters
}) => {
  const filterOptions = useFilterOptions(filter.key)
  return (
    <AutoCompleteDropdown
      options={filterOptions}
      placeholder={filter.placeholder ?? header}
      value={value}
      onSelectValue={value => {
        handleChangeFilters([filter.key, value])
      }}
      isMulti={filter.isMulti}
      shortMultiSelect
      styles={textInputStyle}
    />
  )
}

const FilterControls = ({
  filter,
  header,
  queryFilter,
  handleChangeFilters,
  ...other
}) => {
  const filters = Array.isArray(filter) ? filter : [filter]
  const { t } = useTranslation()
  return (
    <>
      {filters.map(
        (filter, i) =>
          filter &&
          (filter.type === 'search' ? (
            <DebouncedTextInput
              key={`filter${filter.key}${i}`}
              initialValue={filter.defaultValue}
              placeholder={t('common:search')}
              onChangeText={handleChangeFilters}
              style={textInputStyle}
            />
          ) : (
            <FilterDropdownControl
              filter={filter}
              handleChangeFilters={handleChangeFilters}
              key={`filter${filter.key}${i}`}
              header={header}
              value={queryFilter[filter.key]}
              {...other}
            />
          ))
      )}
    </>
  )
}

const TableHeaderCell = ({
  header,
  sort,
  filter,
  orderBy,
  orderDir,
  headerStyle,
  handleChangeSort,
  handleChangeFilters,
  queryFilter
}: TableHeaderCellProps) => {
  return (
    <Container>
      {sort ? (
        <TouchableOpacity onPress={() => handleChangeSort(sort.key)}>
          <HeaderText style={headerStyle}>
            {header}
            {orderBy === sort.key && (
              <SortHandle>{orderDir === 'asc' ? '▲' : '▼'}</SortHandle>
            )}
          </HeaderText>
        </TouchableOpacity>
      ) : (
        <HeaderText>{header}</HeaderText>
      )}
      <FilterControls
        filter={filter}
        header={header}
        queryFilter={queryFilter}
        handleChangeFilters={handleChangeFilters}
        placeholder={filter?.placeholder}
      />
    </Container>
  )
}

interface TableHeaderCellProps {
  header: string
  width?: Number
  headerStyle: any
  sort?: SortableColumnProps<any>
  filter: FilterableColumnProps<any>
  orderBy: string | boolean
  orderDir: sortDir | boolean
  queryFilter: {}
  handleChangeSort: (value: string) => void
  handleChangeFilters: (value: string) => void
}

export default TableHeaderCell
