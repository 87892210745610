import { useEffect, useState } from 'react'
import i18n from 'i18next'

const useTranslation = () => {
  const [language, setLanguage] = useState(i18n.language)

  useEffect(() => {
    i18n.on('languageChanged', setLanguage)
    return () => i18n.off('languageChanged', setLanguage)
  }, [setLanguage])

  return { language, i18n, t: i18n.t.bind(i18n) }
}

export default useTranslation
