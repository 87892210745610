import gql from 'graphql-tag'
const upsertShortUrlMutation = gql`
  mutation upsertShortUrl($id: String, $targetUrl: String!) {
    upsertShortUrl(id: $id, targetUrl: $targetUrl) {
      id
      sourceUrl
      targetUrl
    }
  }
`

export default upsertShortUrlMutation
