import React, { useState, useRef } from 'react'
import { View, Text, Platform, KeyboardAvoidingView } from 'react-native'
import styled from 'styled-components/native'
import Firebase from '../../Firebase'
import { getAuth as getFBAuth, signInWithEmailAndPassword } from 'firebase/auth'
import TextInput from '../../ui-library/TextInput'
import Button from '../../ui-library/Button'
import useValidateEmailMutation from './mutations/useValidateEmailMutation'
import useTranslation from '../../hooks/useTranslation'
import { UserAccountStatusEnum } from '../../AppRoot/controllers/CurrentUserController'
import Logo from '../../components/icon/Logo'

const Container = styled(KeyboardAvoidingView)`
  flex: 1;
  align-items: center;
  background: ${({ theme }) => theme.colors.appBackground};
  ${Platform.OS === 'web' ? 'padding-top: 20%' : 'justify-content: center'};
`

const ErrorContainer = styled(View)`
  padding: 0 10px 20px 10px;
  max-width: 253px;
`
const ErrorText = styled(Text)`
  color: ${({ theme }) => theme.colors.danger};
`

export default function LoginScreen() {
  const { t } = useTranslation()
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [error, setError] = useState('')
  const [isProcessing, setIsProcessing] = useState(false)
  const emailRef = useRef(null)
  const passwordRef = useRef(null)

  const [validateEmail, loading] = useValidateEmailMutation({
    variables: { email },
    onCompleted: results => {
      const { userExists, accountStatus, hasMI6Access } = results?.validateEmail
      if (!userExists) {
        return setError(t('error:auth:unknownUsername'))
      }
      if (accountStatus === UserAccountStatusEnum.Inactive) {
        return setError(t('error:auth:inactiveAccount'))
      }
      if (!hasMI6Access) {
        return setError(t('error:auth:accountNotAuthorized'))
      }
      if (results?.validateEmail) {
        return login()
      }
    }
  })

  const login = () => {
    setIsProcessing(true)
    signInWithEmailAndPassword(getFBAuth(Firebase), email, password).catch(
      err => {
        setError(err.message)
        setIsProcessing(false)
        // @ts-ignore protecting against null
        emailRef.current && emailRef.current.focus()
      }
    )
  }

  const onSubmitEmail = () => {
    // @ts-ignore protecting against null
    passwordRef.current && passwordRef.current.focus()
  }

  return (
    <Container behavior={Platform.OS === 'ios' ? 'padding' : 'height'}>
      <Logo height={120} width={120} style={{ marginBottom: 50 }} />
      <TextInput
        value={email}
        onChangeText={setEmail}
        autoCapitalize="none"
        placeholder="Email"
        autoFocus
        ref={emailRef}
        onSubmitEditing={onSubmitEmail}
      />
      <TextInput
        value={password}
        onChangeText={setPassword}
        autoCapitalize="none"
        secureTextEntry
        placeholder="Password"
        ref={passwordRef}
        onSubmitEditing={validateEmail}
      />
      {!!error && (
        <ErrorContainer>
          <ErrorText>{error}</ErrorText>
        </ErrorContainer>
      )}
      <Button
        title="Login"
        onPress={validateEmail}
        isProcessing={isProcessing || loading}
        style={{ marginTop: 20 }}
      />
    </Container>
  )
}
