import gql from 'graphql-tag'
import { useMutation } from '@apollo/client'
import { searchPersonas } from '../../UserListScreen'

const addUserRoleMutation = gql`
  mutation addUserAppRole(
    $userId: String!
    $app: GlobalRoleApp!
    $role: String!
  ) {
    addUserAppRole(userId: $userId, app: $app, role: $role) {
      id
      appRoles {
        app
        role
      }
    }
  }
`

const useAddUserRoleMutation = (options = {}) => {
  const [addUserRole, { loading }]: any[] = useMutation(addUserRoleMutation, {
    ...options,
    refetchQueries: [{ query: searchPersonas, variables: { query: '' } }],
    awaitRefetchQueries: true
  })

  return [addUserRole, loading]
}

export default useAddUserRoleMutation
