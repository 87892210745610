import React, { FC, useState } from 'react'
import { ViewStyle, View, TouchableOpacity } from 'react-native'
import useTranslation from '../../hooks/useTranslation'
import { MediaUploaderValue } from '../media/components/MediaUploader'
import {
  validateCloudinaryUrl,
  validateVimeoUrl
} from '../media/components/useMediaUploader'
import { MEDIA_REGEX_MAP } from '../media/constants/media'
import { H4, Text } from '../../components/common/Text'
import CopyToClipboardButton from '../media/components/CopyToClipboardButton'
import Modal from '../../components/common/Modal'
import Button from '../../ui-library/Button'
import TextInput from '../../ui-library/TextInput'
import DropMediaIcon from '../../images/drop-media.svg'
import styled, { useTheme } from 'styled-components/native'

interface MediaUploaderProps {
  value?: MediaUploaderValue | null
  hasError?: boolean
  setValue: (value: Partial<MediaUploaderValue> | null) => void
  setError: (value: string | undefined) => void
  setUploading: (value: boolean) => void
  style?: ViewStyle
  documentFileSizeLimit?: number
  audioFileSizeLimit?: number
}

interface MediaUploadPreviewProps {
  value: MediaUploaderValue
}

const PreviewContainer = styled(View)`
  width: 100%;
  height: 220px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const MediaContainer = styled(View)`
  border-radius: 3px;
  ${({ theme, hasError }) =>
    hasError ? `border: 1px solid ${theme.colors.danger};` : ``}
`

const BottomRow = styled(View)`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 5px;
`
const Filename = styled(Text)`
  flex: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 18px;
  color: gray;
  font-style: italic;
  white-space: nowrap;
  padding: 0 8px;
  max-width: 120px;
`

const ClearButton = styled(TouchableOpacity)`
  background: rgba(0, 0, 0, 0.24);
  width: 1.2em;
  height: 1.2em;
  border-radius: 2em;
`
const ClearButtonText = styled(Text)`
  text-align: center;
  color: #fff;
  font-size: 18px;
  line-height: 1em;
  font-weight: bold;
`

const IconContainer = styled(View)`
  ${({ theme: { space } }) => `
    margin: ${space[3]}px;
  `}
`

const SubText = styled(View)`
  display: flex;
  flex-direction: row;
  align-items: baseline;
  justify-content: center;
`

const Container = styled(View)`
  padding: ${props => props.theme.space[3]}px;
`

interface IProps {
  setValue: (value: Partial<MediaUploaderValue> | null) => void
}

const DropZoneContent: React.FC<IProps> = ({ setValue }) => {
  const { t } = useTranslation()
  const { colors } = useTheme()
  const [showUrlModal, setShowUrlModal] = useState(false)
  const [error, setError] = useState<string | null>(null)
  const [formValue, setFormValue] = useState<string>('')
  const [loading, setLoading] = useState(false)

  return (
    <Container>
      <IconContainer>
        <DropMediaIcon />
      </IconContainer>
      <SubText>
        <Button
          title={t('media:pasteURL')}
          type="clear"
          onPress={() => setShowUrlModal(true)}
          titleStyle={{
            color: colors.brightPrimary
          }}
        />
      </SubText>
      <Modal
        open={showUrlModal}
        close={() => {
          setError(null)
          setFormValue('')
          setShowUrlModal(false)
        }}
      >
        <H4>{t('media:existingMediaUrl')}</H4>
        <TextInput
          placeholder={t('forms:mediaUrl')}
          value={formValue}
          onChangeText={value => {
            const validUrl = MEDIA_REGEX_MAP.mediaUrl.test(value)
            if (!validUrl && !error) {
              setError(t('media:invalidMediaUrl'))
            } else if (validUrl && error) {
              setError(error)
            }
            setFormValue(value)
          }}
          hasError={!!error}
          autoFocus
          style={{ minWidth: 640, marginTop: 12 }}
        />
        <ErrorText>{error}</ErrorText>
        <Button
          title={t('common:labels:submit')}
          containerStyle={{ alignItems: 'flex-end' }}
          type="solid"
          isProcessing={loading}
          onPress={async () => {
            setLoading(true)
            try {
              let mediaData = await validateVimeoUrl(formValue)

              if (!mediaData) {
                mediaData = await validateCloudinaryUrl(formValue)
              }

              if (mediaData?.media?.error) {
                throw new Error(mediaData.media.error)
              }
              const extract = {
                name:
                  mediaData?.media?.name || mediaData?.media?.original_filename,
                url:
                  mediaData?.media?.player_embed_url ||
                  mediaData?.media?.secure_url ||
                  mediaData?.media?.url,
                hostId: mediaData?.hostId,
                host: mediaData?.host,
                type: mediaData?.media?.type === 'video' ? 'video' : 'audio',
                embed: mediaData?.media?.embed?.html
              }
              setValue(extract)
            } catch (error) {
              setError(error instanceof Error ? error.message : String(error))
            } finally {
              setLoading(false)
            }
          }}
        />
      </Modal>
    </Container>
  )
}

const ErrorText = styled(Text)`
  ${({ theme }) => `
      color: ${theme.colors.inputTextError};
    `}
`

const MediaUploadPreview: React.FC<MediaUploadPreviewProps> = ({ value }) => {
  const url = value?.url
  const type = value?.type

  return (
    <PreviewContainer>
      {type === 'video' ? (
        <iframe src={url} frameBorder="0" allowFullScreen />
      ) : (
        <audio controls>
          <source src={url} />
        </audio>
      )}
    </PreviewContainer>
  )
}

export const MediaURLPaste: FC<MediaUploaderProps> = ({
  value,
  hasError,
  setValue,
  setError,
  style,
  documentFileSizeLimit,
  audioFileSizeLimit
}) => {
  const { t } = useTranslation()
  const [showConfirmationModal, setShowConfirmationModal] = useState(false)

  if (!audioFileSizeLimit || !documentFileSizeLimit) {
    return null
  }

  if (!value?.url) {
    return <DropZoneContent setValue={setValue} />
  }

  return (
    <MediaContainer style={style} hasError={hasError}>
      <MediaUploadPreview value={value!} />
      <BottomRow>
        {value && value.url && <CopyToClipboardButton url={value.url} />}
        {value && value.name && (
          <Filename title={value.name}>{value.name}</Filename>
        )}
        {value && (
          <ClearButton onPress={() => setShowConfirmationModal(true)}>
            <ClearButtonText>×</ClearButtonText>
          </ClearButton>
        )}
      </BottomRow>

      <Modal
        open={showConfirmationModal}
        close={() => setShowConfirmationModal(false)}
      >
        <H4>{t('media:removeMedia')}</H4>
        <View style={{ padding: 10 }}>
          <View style={{ paddingBottom: 10 }}>
            <Text>{t('media:removeMediaConfirmation')}</Text>
          </View>
        </View>
        <View
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-end'
          }}
        >
          <Button
            title={t('common:labels:cancel')}
            style={{ marginRight: 10 }}
            type="outline"
            onPress={() => {
              setShowConfirmationModal(false)
            }}
          />
          <Button
            title={t('common:labels:remove')}
            onPress={() => {
              setValue(null)
              setError(undefined)
              setShowConfirmationModal(false)
            }}
          />
        </View>
      </Modal>
    </MediaContainer>
  )
}
