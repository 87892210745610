import React from 'react'
import { Switch as RNSwitch } from 'react-native'
import { useTheme } from 'styled-components/native'

interface IProps {
  value?: boolean
  onValueChange?: (value: boolean) => void
}

export const Switch: React.FC<IProps> = ({ value, onValueChange }) => {
  const { colors } = useTheme()
  return (
    <RNSwitch
      value={value}
      onValueChange={onValueChange}
      style={{ transform: [{ scaleX: 0.8 }, { scaleY: 0.8 }] }}
      thumbColor={colors.appBackground}
      // @ts-expect-error - https://github.com/facebook/react-native/issues/30429
      activeThumbColor={colors.appBackground}
      trackColor={{
        true: colors.brightPrimary,
        false: colors.inputBackground
      }}
    />
  )
}
