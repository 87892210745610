import { gql, useMutation } from '@apollo/client'

const deletePersonTagMutation = gql`
  mutation($id: String!) {
    deletePersonTag(id: $id)
  }
`

const useDeletePersonTagMutation = (options = {}) => {
  const [deletePersonTag]: any[] = useMutation(deletePersonTagMutation, options)
  return deletePersonTag
}

export default useDeletePersonTagMutation
