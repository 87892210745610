import React, { useContext, ReactNode } from 'react'
import { CompositeDecorator, ContentState } from 'draft-js'
import { ThemeContext } from 'styled-components/native'

// This function searches for entities in the draftjs state and applies the
// given callback to these entities. In this case the function searches for
// link entities which will then be converted into the Link component defined
// below.
// For reference, this code was pulled from the draftjs documentation:
// https://github.com/facebook/draft-js/blob/master/examples/draft-0-10-0/link/link.html#L193
function findLinkEntities(contentBlock, callback, contentState) {
  contentBlock.findEntityRanges(character => {
    const entityKey = character.getEntity()
    return (
      entityKey !== null &&
      contentState.getEntity(entityKey).getType() === 'LINK'
    )
  }, callback)
}

interface LinkProps {
  children: ReactNode
  contentState: ContentState
  entityKey: string
}

const Link = ({ children }: LinkProps) => {
  const theme: any = useContext(ThemeContext)

  // Doesn't need to be an actual link because draftjs intercepts
  // mouse events. It's not actually possible to click this link in the editor.
  return (
    <span
      style={{ color: theme.colors.brightPrimary, textDecoration: 'underline' }}
    >
      {children}
    </span>
  )
}

const linkDecorator = new CompositeDecorator([
  {
    strategy: findLinkEntities,
    component: Link
  }
])

export default linkDecorator
