import gql from 'graphql-tag'

export interface IHostMediaByHostIdQuery {
  hostMediaByHostId: {
    id: string
    hostId: string
    name: string
    url: string | null
    type: string
    host: string
    status: string
    transcode: string
    createdAt: string
    updatedAt: string
  }
}

export default gql`
  query HostMediaByHostId($hostId: String!) {
    hostMediaByHostId(hostId: $hostId) {
      id
      hostId
      name
      url
      type
      host
      status
      transcode
      createdAt
      updatedAt
    }
  }
`
