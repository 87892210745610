import gql from 'graphql-tag'
import { useQuery } from '@apollo/client'

const searchPeopleQuery = gql`
  fragment personFragment on Person {
    id
    firstName
    lastName
    employerName
  }

  query searchPeople($query: String!, $index: [String]) {
    search(
      query: $query
      index: $index
      first: 20
      adminSearch: true
      includeNonMembers: false
      includeUnpublishedPeople: false
    ) {
      hits {
        __typename
        ... on Person {
          ...personFragment
        }
      }
    }
  }
`

export interface SearchPeopleVariablesI {
  index?: string[]
  query?: string
}

const defaultVariables: SearchPeopleVariablesI = {
  index: ['person'],
  query: ''
}

const useSearchPeopleQuery = (variables?: SearchPeopleVariablesI) => {
  const { data, loading } = useQuery(searchPeopleQuery, {
    variables: { ...defaultVariables, ...variables },
    skip: !variables?.query
  })

  const people = data && data.search ? data.search.hits : []
  const mappedData = people
    .filter(person => person != null)
    .map(person => ({
      value: person.id,
      label: `${person.firstName} ${person.lastName}`,
      custom: { employerName: person.employerName || '' }
    }))

  return { data: mappedData, loading }
}

export default useSearchPeopleQuery
