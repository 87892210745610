import React from 'react'
import { View } from 'react-native'
import EllipticalText from './EllipticalText'
import NoWrapText from './NoWrapText'

export type CellValue = {
  id: string | number
  name: string
}
interface MultiValueCellProps {
  values: CellValue[]
}

const MultiValueCellContent = ({ values }: MultiValueCellProps) => {
  return (
    <>
      {values.map(value => {
        return (
          <View key={`${value.id}-i`}>
            <NoWrapText>
              <EllipticalText>{value.name}</EllipticalText>
            </NoWrapText>
          </View>
        )
      })}
    </>
  )
}

export default MultiValueCellContent
