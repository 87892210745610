import React, { useCallback, useEffect, useRef, useState } from 'react'
import { EditorState } from 'draft-js'
import { Map } from 'immutable'
import useThrottledCallback from '../../../hooks/useThrottledCallback'

export const MAX_UPLOADED_WIDTH = 550
const DEFAULT_DIMENSIONS = {
  width: 250,
  height: 250
}
const toJS = data => {
  if (data) {
    return typeof data.toJS === 'function'
      ? data?.mapKeys(k => k)?.toJS()
      : data
  }

  return {}
}

const scaleDownCloudinaryImage = (url, width) => {
  const urlParts = url.split('/')
  const pathIndex = urlParts.findIndex(part => part === 'upload')
  if (pathIndex === -1) {
    return url
  }

  urlParts.splice(pathIndex + 1, 0, `w_${width}`, 'q_auto:best')

  return urlParts.join('/')
}

const getSrc = (contentState: any, block: any) => {
  const { src } =
    (block.getEntityAt(0) &&
      contentState.getEntity(block.getEntityAt(0)).getData()) ||
    {}

  return scaleDownCloudinaryImage(src, MAX_UPLOADED_WIDTH)
}

const getDimensionsFromResizeObserver = (entry: any) => {
  const width = entry?.contentRect?.width ?? DEFAULT_DIMENSIONS.width
  const height = entry?.contentRect?.height ?? DEFAULT_DIMENSIONS.height

  return { width, height }
}
const Image = props => {
  const image = useRef(null)
  const container = useRef(null)
  const [resize, setResize] = useState('horizontal')
  const {
    block,
    contentState,
    blockProps: { getEditorState, onChange }
  } = props

  const updateBlockData = useCallback(
    (newData = {}, data = block.getData()) => {
      data = data.merge(newData)
      const newBlock = block.set('data', data)

      let blockMap = getEditorState().getCurrentContent().getBlockMap()

      blockMap = blockMap.set(block.getKey(), newBlock)

      const newContent = getEditorState()
        .getCurrentContent()
        .set('blockMap', blockMap)
      const selection = getEditorState().getSelection()

      let editorState = EditorState.push(
        getEditorState(),
        newContent,
        'change-block-data'
      )
      editorState = EditorState.forceSelection(editorState, selection)

      onChange(editorState)
      setResize('horizontal')
    },
    [block, getEditorState, onChange]
  )

  const data = block.getData()
  let imgStyle = data.get('imgStyle')
  let figStyle = data.delete('imgStyle')

  imgStyle = (imgStyle && toJS(data.get('imgStyle'))) || {}
  figStyle = (figStyle && toJS(figStyle.filter(v => v !== 'class'))) || {}

  const { height, width } = imgStyle
  delete imgStyle.height
  delete imgStyle.width

  const [size, setSize] = useState({ height, width })

  const handleImgLoaded = () => {
    // @ts-ignore
    const { naturalHeight, naturalWidth } = image.current

    if (!size.height || !size.width) {
      if (naturalHeight && naturalWidth) {
        const height = `${naturalHeight}px`
        const width = `${naturalWidth}px`
        updateBlockData({
          imgStyle: Map({ objectFit: 'contain', height, width })
        })
        setSize({ height, width })
      } else {
        setSize({ height: '250px', width: '250px' })
      }
    } else if (imgStyle.objectFit) {
      updateBlockData({
        imgStyle: Map({ objectFit: 'contain', height, width })
      })
      setSize({ height, width })
    }
  }

  const onResize = ([entry]) => {
    const { width, height } = getDimensionsFromResizeObserver(entry)

    if (width && height) {
      const values = {
        width: `${width}px`,
        height: `${height}px`
      }
      updateBlockData({
        imgStyle: Map(block.getData().get('imgStyle')).merge(values)
      })
      setSize(values)
    }
  }
  const throttleOnResize = useThrottledCallback(onResize, 500)

  useEffect(() => {
    const element = container?.current

    if (!element) return

    const observer = new ResizeObserver(throttleOnResize)

    updateBlockData({ isActive: true })
    setResize('horizontal')
    observer.observe(element)

    return () => {
      observer.disconnect()
    }
  }, [])

  const src = getSrc(contentState, block)

  return (
    <div
      ref={container}
      style={{
        ...figStyle,
        ...{
          width: size.width,
          maxWidth: '390px',
          height: 'auto'
        },
        ...{
          boxSizing: 'content-box',
          position: 'relative',
          zIndex: 1,
          display: 'block',
          margin: '0 auto',
          overflow: 'hidden',
          padding: 5,
          resize,
          border: `1px dashed ${
            resize !== 'both' ? 'rgba(0, 0, 0, 0.24)' : 'transparent'
          }`
        }
      }}
    >
      <img
        ref={image}
        src={src}
        width="100%"
        height="100%"
        style={imgStyle}
        onLoad={handleImgLoaded}
      />
    </div>
  )
}

export default Image
