import parseToCloudinaryUrl from './idToCloudinaryUrl'

// These helper functions are not included in the useFormikHelper hook as they are necessary for creation of the Formik object which felt too circular to be safe.

const mapAudience = (defaultAudience, savedData) => {
  const communityObjects = savedData?.communities || defaultAudience

  const communityIds = communityObjects.map(community => {
    return community.id
  })
  return communityIds
}

const mapGroups = (defaultGroups, savedData) => {
  const groupObjects = savedData?.groups || defaultGroups
  const groupIds = groupObjects.map(group => {
    return group.id
  })
  return groupIds
}

const mapGatherings = (defaultGatherings, savedData) => {
  const gatheringsObjects = savedData?.events || defaultGatherings
  const gatheringsIds = gatheringsObjects.map(gathering => gathering.id)
  return gatheringsIds
}

const mapPeople = (defaultPeople, savedData) => {
  const peopleObjs = savedData?.people || defaultPeople
  const peopleIds = peopleObjs.map(person => person.id)
  return peopleIds
}

const mapTopics = (defaultTopics, savedData) => {
  return savedData?.topics || defaultTopics
}

const mapHeroImage = (defaultImage, savedData) => {
  let heroImageValue = savedData?.image || defaultImage

  if (heroImageValue) {
    heroImageValue = {
      publicId: heroImageValue.cloudinaryId,
      url: parseToCloudinaryUrl(heroImageValue.cloudinaryId),
      fileName: heroImageValue.fileName
    }
  }
  return heroImageValue
}

const mapSharedFile = (defaultFile, savedData, isFileValid) => {
  let sharedFileValue = savedData?.file || defaultFile

  if (sharedFileValue) {
    const options: any = {}

    if (sharedFileValue.cloudinaryId.includes('.pdf')) {
      options.resource_type = 'raw'
    }

    sharedFileValue = isFileValid
      ? {
          publicId: sharedFileValue.cloudinaryId,
          url: parseToCloudinaryUrl(sharedFileValue.cloudinaryId, options),
          fileName: sharedFileValue.fileName
        }
      : { fileName: sharedFileValue.fileName }
  }

  return sharedFileValue
}

interface EntityPersonTag {
  person: {
    id: string
  }
  source?: {
    __typename: string
    id: string
  }
}
const mapTaggedPeople = (
  defaultTags: EntityPersonTag[],
  savedTags?: EntityPersonTag[]
) => {
  const taggedPeople = savedTags || defaultTags
  return taggedPeople.map(({ person, source }) => ({
    personId: person.id,
    ...(source && {
      source: {
        sourceType: source.__typename?.toLowerCase(),
        sourceId: source.id
      }
    })
  }))
}

const mapHostMedia = (defaultHostMedia, savedData, isHighlights = false) => {
  const key = isHighlights ? 'highlightsHostMedia' : 'hostMedia'
  const hostMedia = savedData?.[key]
  if (!hostMedia) {
    return defaultHostMedia
  }

  return {
    id: hostMedia.id,
    hostId: hostMedia.hostId,
    host: hostMedia.host,
    type: hostMedia.type,
    name: hostMedia.name,
    url: hostMedia.url,
    status: hostMedia.status,
    transcode: hostMedia.transcode
  }
}

const getCloudinaryImage = values => {
  return getCloudinaryInfo(values?.heroImage)
}

interface MediaContentData {
  url: string
  publicId: string
  fileName: string
  id: string
}

const getCloudinaryInfo = (data: MediaContentData | null) => {
  const { publicId: cloudinaryId, fileName } = data || {}
  return cloudinaryId && fileName ? { cloudinaryId, fileName } : null
}

export {
  mapAudience,
  mapGroups,
  mapGatherings,
  mapPeople,
  mapTopics,
  mapHeroImage,
  mapSharedFile,
  mapTaggedPeople,
  mapHostMedia,
  getCloudinaryImage,
  getCloudinaryInfo
}
