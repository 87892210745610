import { gql } from '@apollo/client'

export interface IMessageLog {
  id: string
  messageId: string
  messageData: Record<string, unknown>
  notificationId: string
  status?: string
  verifiedAt?: string
  createdAt: string
  errors?: Record<string, unknown>[] | null
  type?: string
}

export const messageLogsQuery = gql`
  query messageLogs($limit: Int!, $offset: Int!, $filter: MessageLogFilter) {
    messageLogs(limit: $limit, offset: $offset, filter: $filter) {
      messages {
        id
        messageId
        messageData
        notificationId
        status
        verifiedAt
        createdAt
        errors
        type
      }
      totalCount
    }
  }
`
