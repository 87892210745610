import gql from 'graphql-tag'
const upsertMicrositePermissionMutation = gql`
  mutation inviteToCommunityMicrosite(
    $id: String
    $firstName: String!
    $lastName: String!
    $emailAddress: String!
    $communityId: String!
    $expirationDate: DateTime!
  ) {
    inviteToCommunityMicrosite(
      id: $id
      firstName: $firstName
      lastName: $lastName
      emailAddress: $emailAddress
      communityId: $communityId
      expirationDate: $expirationDate
    ) {
      id
      community {
        id
        name
      }
      token
      expirationDate
      emailAddress
      firstName
      lastName
      shortUrl {
        id
        sourceUrl
        targetUrl
      }
    }
  }
`

export default upsertMicrositePermissionMutation
