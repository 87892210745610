import gql from 'graphql-tag'

const imageFragment = gql`
  fragment imageFragment on Image {
    id
    fileName
    cloudinaryId
  }
`

export interface ImageType {
  id: string
  filename: string
  cloudinaryId: string
}

export default imageFragment
