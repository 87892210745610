import gql from 'graphql-tag'
import { useQuery } from '@apollo/client'

import { formatDate } from '../../../../utils/format'

export enum AudienceType {
  COMMUNITIES = 'communities',
  GROUPS = 'groups',
  EVENTS = 'events'
}

type QueryAudienceI = { [key in AudienceType]: any }

const queryByAudience: QueryAudienceI = {
  communities: gql`
    query audienceQueryCommunities {
      communities(types: [crossGroup, group]) {
        id
        name
      }
    }
  `,
  groups: gql`
    query audienceQueryGroups {
      groups {
        id
        name
        shortName
      }
    }
  `,
  events: gql`
    query audienceQueryEvents {
      events {
        id
        name
        startDate
      }
    }
  `
}

interface AudienceDataI {
  value: string
  label: string
}

const formatDataResponse = (
  rawData: any,
  audience: AudienceType = AudienceType.COMMUNITIES,
  useShortName: boolean = true
): AudienceDataI[] =>
  rawData[audience].map((item: any) => {
    const label = useShortName ? item.shortName : item.name
    const labelWithDate =
      audience === AudienceType.EVENTS
        ? `${label} ${formatDate(item.startDate)}`
        : label

    return {
      value: item.id,
      label: labelWithDate
    }
  })

const useAudienceQuery = (
  audience: AudienceType = AudienceType.COMMUNITIES,
  useShortName: boolean = audience === AudienceType.GROUPS
) => {
  const { data: rawData, refetch } = useQuery(queryByAudience[audience])
  return {
    data: rawData ? formatDataResponse(rawData, audience, useShortName) : [],
    refetch
  }
}

export default useAudienceQuery
