import moment from 'moment'
import { TFunction } from 'i18next'
import { DATE_FORMAT, DATE_FORMAT_NO_YEAR } from './constants'

const getCurrentYear = () => new Date().getFullYear()

const getDateFormat = (date: string | Date) => {
  const currentYear = getCurrentYear()
  const incomingYear = new Date(date).getFullYear()

  // removes current year from dates

  if (currentYear === incomingYear) {
    return DATE_FORMAT_NO_YEAR
  }
  return DATE_FORMAT
}

const interpretDateTime = (date: string | Date, t: TFunction) => {
  return moment(date).calendar(undefined, {
    lastDay: `[${t('dateTime:lastDay')}]`,
    sameDay: `hh:mm A`,
    nextDay: `[${t('dateTime:nextDay')}]`,
    lastWeek: `dddd`,
    nextWeek: `[${t('dateTime:nextWeek')}] dddd`,
    sameElse: getDateFormat(date)
  })
}

export const postFormatDate = (date: Date | string, t: TFunction) => {
  const diff = moment.duration(moment().diff(date))
  const sameDay =
    diff.asMinutes() < 1
      ? `[${t('post:justNow')}]`
      : diff.asHours() < 1
      ? `${Math.floor(diff.asMinutes())}[m]`
      : `${Math.floor(diff.asHours())}[h]`
  return moment(date).calendar(undefined, {
    sameDay,
    lastDay: `${Math.floor(diff.asHours())}[h]`,
    lastWeek: `${Math.floor(diff.asDays())}[d]`,
    sameElse: getDateFormat(date)
  })
}

export default interpretDateTime
