import React from 'react'
import MultiValueCellContent from './MultiValueCellContent'

interface AudienceCellProps {
  communities: any[]
  groups?: any[]
  events?: any[]
  people?: any[]
  topics?: any[]
}

const AudienceCellContent = ({
  communities,
  groups,
  events,
  people,
  topics
}: AudienceCellProps) => {
  let values = communities
    .filter(
      c => c.type === 'crossGroup' || (c && c.groups && c.groups.length > 0)
    )
    .map(c => ({
      id: c.id,
      name: `Community: ${c.name}`
    }))

  groups?.forEach(group => {
    values.push({
      id: group.id,
      name: `Group: ${group.name}`
    })
  })

  events?.forEach(event => {
    values.push({
      id: event.id,
      name: `Event: ${event.name}`
    })
  })

  people?.forEach(p => {
    values.push({
      id: p.id,
      name: `Person: ${p.firstName} ${p.lastName}`
    })
  })

  topics?.forEach(topic => {
    values.push({
      id: topic.id,
      name: `Topic: ${topic.name}`
    })
  })

  return (
    <>
      <MultiValueCellContent values={values} />
    </>
  )
}

export default AudienceCellContent
