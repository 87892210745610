import React, { useContext } from 'react'
import { createStackNavigator } from '@react-navigation/stack'
import { ThemeContext } from 'styled-components/native'
import LoginScreen from '../../screens/authentication/LoginScreen'
import AppNavigator from './AppNavigator'
import useCurrentUser from '../../hooks/useCurrentUser'

// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { RootStackParamList } from '../../types'

declare global {
  namespace ReactNavigation {
    interface RootParamList extends RootStackParamList {}
  }
}

const Stack = createStackNavigator()

const RootNavigator = () => {
  const theme = useContext(ThemeContext) as any
  const { firebaseToken } = useCurrentUser()
  const screenOptions = {
    headerShown: false,
    cardStyle: {
      backgroundColor: theme.colors.appBackground,
      flex: 1
    }
  }

  return (
    <Stack.Navigator options={screenOptions}>
      {firebaseToken ? (
        <Stack.Screen
          name="App"
          component={AppNavigator}
          options={{ headerShown: false }}
        />
      ) : (
        <Stack.Screen
          name="Login"
          component={LoginScreen}
          options={{ headerShown: false }}
        />
      )}
    </Stack.Navigator>
  )
}

export default RootNavigator
