import React, { FC } from 'react'
import Modal from '../../components/common/Modal'
import { Text } from '../../components/common/Text'
import { Flex } from '../../components/FlexBox'
import useTranslation from '../../hooks/useTranslation'
import Button from '../../ui-library/Button'

interface IProps {
  isOpen: boolean
  onClose: () => void
  onSubmit: () => void
}

export const DiscardChangesModal: FC<IProps> = ({
  isOpen,
  onClose,
  onSubmit
}) => {
  const { t } = useTranslation()
  return (
    <>
      <Modal open={isOpen} close={onClose}>
        <Text styles={{ paddingBottom: 10 }}>
          {t('summits:editor:discardChangesPrompt')}
        </Text>

        <Flex
          flexDirection="row"
          justifyContent="flex-end"
          style={{ paddingTop: 10 }}
        >
          <Button
            title="Cancel"
            onPress={onClose}
            style={{ marginRight: 10 }}
          />
          <Button title="Yes" onPress={onSubmit} />
        </Flex>
      </Modal>
    </>
  )
}
