import React from 'react'
import Modal from '../../../components/common/Modal'
import { Flex } from '../../../components/FlexBox'
import { H4 } from '../../../components/common/Text'
import styled from 'styled-components/native'
import { View, Text } from 'react-native'
import Button from '../../../components/common/Button'
import { UrlLink } from './ShortUrlsTable'

interface DeleteShortUrlModalProps {
  isOn: boolean
  targetUrl?: string
  shortUrl?: string
  onConfirm: () => void
  onCancel: () => void
  loading?: boolean
}

const TitleContainer = styled(View)`
  ${({ theme: { space } }) => `
  margin-bottom: ${space[3]}px;
`}
`

const ButtonsRow = styled(Flex).attrs(() => ({
  flexDirection: 'row',
  justifyContent: 'space-around'
}))`
  ${({ theme: { space } }) => `
    margin-top: ${space[3]}px;
  `}
`

const ModalText = styled(Text)`
  font-size: 16px;
  line-height: 20px;
`

const DeleteButton = styled(Button).attrs(
  ({ theme: { colors, sizes }, disabled }) => ({
    buttonStyle: {
      backgroundColor: disabled ? colors.gray : colors.danger,
      width: sizes[6]
    },
    disabled
  })
)``

const CancelButton = styled(Button).attrs(
  ({ theme: { sizes, colors }, disabled }) => ({
    buttonStyle: {
      width: sizes[6],
      backgroundColor: disabled ? colors.gray : colors.primary
    },
    disabled
  })
)``

const DeleteShortUrlModal = ({
  isOn,
  targetUrl,
  shortUrl,
  onConfirm,
  onCancel,
  loading
}: DeleteShortUrlModalProps) => {
  return (
    <Modal open={isOn} close={onCancel} showCloseIcon>
      <Flex>
        <TitleContainer>
          <H4>{'Delete Short URL'}</H4>
        </TitleContainer>
        <ModalText>
          {`Do you wish to delete link `}
          <UrlLink url={shortUrl!!} />
        </ModalText>
        <ModalText>
          {`redirection to `}
          <UrlLink url={targetUrl!!} />
          {'?'}
        </ModalText>
        <ButtonsRow>
          <CancelButton disabled={loading} onPress={onCancel} label="Cancel" />
          <DeleteButton disabled={loading} onPress={onConfirm} label="Delete" />
        </ButtonsRow>
      </Flex>
    </Modal>
  )
}

export default DeleteShortUrlModal
