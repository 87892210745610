import React, { useState } from 'react'
import Close from '../../../../images/close.svg'

import { View, Text, TouchableHighlight } from 'react-native'
import Button from '../../../../ui-library/Button'
import useDeleteUserRoleMutation from './useDeleteRoleMutation'
import Colors from '../../../../constants/Colors'

const RolesListItem = ({
  label,
  userAppRole,
  onDeleteSuccess,
  canDelete
}: {
  label: string
  userAppRole: { userId: string; app: string; role: string }
  onDeleteSuccess?: (role: string) => void
  canDelete?: boolean
}) => {
  const { userId, app, role } = userAppRole
  const [shouldDeleteRole, setShouldDeleteRole] = useState(false)
  const [deleteUserRole, deleteUserRoleLoading] = useDeleteUserRoleMutation({
    variables: {
      userId: userId,
      app,
      role
    },
    onCompleted: () => {
      setShouldDeleteRole(false)
      onDeleteSuccess && onDeleteSuccess(role)
    }
  })

  return (
    <View
      style={{
        height: 40,
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        opacity: deleteUserRoleLoading ? 0.4 : 'unset'
      }}
    >
      <Text
        style={{
          flex: 5
        }}
      >
        {label}
      </Text>
      {canDelete && (
        <>
          {shouldDeleteRole ? (
            // @ts-ignore gap is a thing
            <View style={{ flexDirection: 'row', gap: 4 }}>
              <Text
                style={{
                  color: Colors.light.danger,
                  marginRight: 8,
                  fontSize: 17,
                  paddingTop: 2
                }}
              >
                {deleteUserRoleLoading ? 'Deleting...' : 'Delete?'}
              </Text>
              <Button
                onPress={deleteUserRole}
                type="clear"
                title={'Yes'}
                disabled={deleteUserRoleLoading}
                disabledTitleStyle={{ color: Colors.light.buttonText }}
              />
              <Button
                onPress={() => setShouldDeleteRole(false)}
                type="clear"
                title={'No'}
                disabled={deleteUserRoleLoading}
                disabledTitleStyle={{ color: Colors.light.buttonText }}
              />
            </View>
          ) : (
            <TouchableHighlight
              onPress={() => setShouldDeleteRole(true)}
              style={{
                height: 22,
                width: 22,
                justifyContent: 'center',
                borderRadius: 11,
                borderColor: 'black',
                borderWidth: 1,
                borderStyle: 'solid'
              }}
            >
              <View style={{ alignSelf: 'center' }}>
                {' '}
                <Close height={12} width={12} />
              </View>
            </TouchableHighlight>
          )}
        </>
      )}
    </View>
  )
}

export default RolesListItem
