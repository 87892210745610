import React, { useState } from 'react'
import { ScreenContainerWithMenuHeader } from '../../components/layout/ScreenContainer'
import { Flex } from '../../components/FlexBox'
import { H4, Text } from '../../components/common/Text'
import Button from '../../components/common/Button'
import { useObliterateQueue, useQueues } from './queries/queue'
import CheckBox from '../../components/common/CheckBox'
import { extractionQueues, runExtractor } from './queries/runExtraction'
import useToast, { ToastType } from '../../hooks/useToast'
import { RunExtractionModal } from './components/RunExtractionModal'
import { ExtractionDetailsModal } from './components/ExtractionDetailsModal'
import { useInterval } from '../../hooks/useInterval'
import TextInput from '../../components/common/TextInput'
import { QueueTable } from './QueueTable'
import styled, { useTheme } from 'styled-components/native'
import Modal from '../../components/common/Modal'
import { StackScreenProps } from '@react-navigation/stack'
import { AppStackParamList } from '../../types'

const AUTO_REFRESH_INTERVAL = 5

function filterQueueName(queueName: string, filter?: string) {
  return filter ? queueName.toLowerCase().includes(filter.toLowerCase()) : true
}

const WarningText = styled(Text)`
  color: ${props => props.theme.colors.danger};
  font-weight: bold;
`

type Props = StackScreenProps<AppStackParamList, 'DataExtraction'>

export const DataExtractionScreen = ({ route }: Props) => {
  const { showToast } = useToast()
  const { queues, refetch, loading } = useQueues()
  const [autoRefresh, setAutoRefresh] = useState(false)
  const [autoRefreshTimer, setAutoRefreshTimer] = useState(0)
  const [selectedQueue, setSelectedQueue] = useState<string>()
  const [runModalOpen, setRunModalOpen] = useState<boolean>(false)
  const [selectedExtractor, setSelectedExtractor] = useState<string>('')
  const [isEnqueuing, setIsEnqueuing] = useState({} as Record<string, boolean>)
  const [cancelModalOpen, setCancelModalOpen] = useState<boolean>(false)
  const obliterateQueue = useObliterateQueue({ all: true })

  const graph = route.params?.graph === 'true'

  useInterval(() => {
    if (!autoRefresh) {
      return
    }
    if (autoRefreshTimer === AUTO_REFRESH_INTERVAL) {
      setAutoRefreshTimer(0)
      refetch()
    } else {
      setAutoRefreshTimer(autoRefreshTimer + 1)
    }
  }, 1000)

  const current = extractionQueues[selectedExtractor]
  const { colors } = useTheme()
  const [queueNameFilter, setQueueNameFilter] = useState<string | undefined>()
  return (
    <ScreenContainerWithMenuHeader screenCategory={'dataExtraction'}>
      <Flex
        flexDirection="row"
        mx={4}
        my={3}
        alignItems="center"
        style={{ minWidth: 'fit-content' }}
      >
        <Flex flex={1}>
          <H4>{'Data Extraction'}</H4>
        </Flex>
        <Flex flexDirection="row" alignItems="center">
          <TextInput
            inputStyle={{ width: '100%' }}
            style={{
              alignSelf: 'center',
              width: 200,
              margin: 0
            }}
            errorStyle={{ margin: 0 }}
            placeholder="Queue name..."
            value={queueNameFilter ?? ''}
            onChangeText={(queueName: string) =>
              setQueueNameFilter(queueName.toLocaleLowerCase())
            }
          />
          <CheckBox
            title={
              'Auto Refresh' +
              (autoRefresh
                ? ` (${AUTO_REFRESH_INTERVAL - autoRefreshTimer})`
                : '')
            }
            checked={autoRefresh}
            onPress={() => {
              setAutoRefresh(!autoRefresh)
            }}
            containerStyle={{ width: 184 }}
          />
          <Button
            onPress={() => {
              refetch()
            }}
            label="Refresh"
            disabled={loading}
          />
          <Button
            onPress={() => setCancelModalOpen(true)}
            buttonStyle={{
              backgroundColor: colors.danger
            }}
            style={{
              marginLeft: 8
            }}
            label="Obliterate ALL"
            disabled={loading}
          />
        </Flex>
      </Flex>
      <Flex my={3} mx={4} flexDirection="row" minWidth={'fit-content'}>
        <QueueTable
          showExtraColumns={graph}
          queues={queues}
          filter={q =>
            filterQueueName(q.name, queueNameFilter) &&
            q.name.includes(':extraction:')
          }
          selectedQueue={selectedQueue}
          setSelectedQueue={setSelectedQueue}
        />
      </Flex>

      <Flex my={3} mx={4}>
        <H4>Other Queues</H4>
      </Flex>
      <Flex my={3} mx={4} flexDirection="row" minWidth={'fit-content'}>
        <QueueTable
          showExtraColumns={graph}
          queues={queues}
          filter={q =>
            filterQueueName(q.name, queueNameFilter) &&
            !q.name.includes(':extraction:')
          }
          selectedQueue={selectedQueue}
          setSelectedQueue={setSelectedQueue}
        />
      </Flex>

      <RunExtractionModal
        isLoading={selectedExtractor ? isEnqueuing[selectedExtractor] : false}
        close={() => setRunModalOpen(false)}
        open={runModalOpen}
        onSubmit={context => {
          showToast(`Starting ${current.name} extraction`)
          setIsEnqueuing({ ...isEnqueuing, [selectedExtractor]: true })

          runExtractor(selectedExtractor, context)
            .then(async res => {
              if (res.ok) {
                refetch()
                setRunModalOpen(false)
              } else {
                const result = await res.json()
                console.log('Error enqueueing extractor', result)
                showToast(
                  `Failed to start ${current.name} extraction: ${JSON.stringify(
                    result
                  )}`,
                  ToastType.ERROR
                )
              }
            })
            .catch(() => {
              console.log('Error enqueueing extractor')
              showToast(
                `Failed to start ${current.name} extraction`,
                ToastType.ERROR
              )
            })
            .finally(() => {
              setIsEnqueuing({ ...isEnqueuing, [selectedExtractor]: false })
            })
        }}
        selectedExtractor={selectedExtractor}
        setSelectedExtractor={setSelectedExtractor}
      />
      {selectedQueue && (
        <ExtractionDetailsModal
          open={!!selectedQueue}
          close={() => setSelectedQueue(undefined)}
          selectedQueue={selectedQueue}
          setSelectedQueue={setSelectedQueue}
          setRunModalOpen={setRunModalOpen}
          setSelectedExtractor={setSelectedExtractor}
          isLoading={selectedExtractor ? isEnqueuing[selectedExtractor] : false}
          showExtraTabs={graph}
        />
      )}
      <Modal open={cancelModalOpen} close={() => setCancelModalOpen(false)}>
        <Text styles={{ marginBottom: 8 }}>
          Are you sure you want to obliterate ALL queues?
        </Text>
        <WarningText>This will delete all jobs unrecoverably.</WarningText>
        <WarningText>
          This will also delete any history of completed and failed jobs.
        </WarningText>
        <Button
          label="Confirm"
          onPress={() => {
            obliterateQueue().then(() => {
              refetch()
              setCancelModalOpen(false)
            })
          }}
          style={{
            marginVertical: 8
          }}
          // disabled={isLoading}
        />
        <Button label="Cancel" onPress={() => setCancelModalOpen(false)} />
      </Modal>
    </ScreenContainerWithMenuHeader>
  )
}
