import { useState, useEffect } from 'react'
import gql from 'graphql-tag'
import { useSubscription } from '@apollo/client'

const fileAttachmentUpdatedSubscription = gql`
  subscription($id: String!) {
    fileAttachmentUpdated(fileAttachmentId: $id) {
      id
      cloudinaryId
      fileName
      scanStatus
      rawConvertStatus
    }
  }
`

const useFileAttachmentUpdatedSubscription = (
  id: string | undefined | null,
  onData
) => {
  const [initialSkip, setInitialSkip] = useState(true)
  useEffect(() => {
    requestAnimationFrame(() => {
      setInitialSkip(false)
    })
  }, [])

  useSubscription(fileAttachmentUpdatedSubscription, {
    variables: { id },
    skip: !id || initialSkip,
    fetchPolicy: 'no-cache',
    onData
  })
}

export default useFileAttachmentUpdatedSubscription
