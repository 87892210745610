import React, { FC } from 'react'
import { View } from 'react-native'
import { useTheme } from 'styled-components/native'
import { Text } from '../../components/common/Text'

export const ErrorText: FC<{ error?: string }> = ({ error }) => {
  const { colors } = useTheme()
  if (!error) {
    return null
  }
  return (
    <View style={{ marginTop: 8 }}>
      <Text styles={{ color: colors.danger }}>{error}</Text>
    </View>
  )
}
