import gql from 'graphql-tag'

const getMicrositesPermissionsQuery = gql`
  query getMicrositesPermissions($pagination: PaginationArgs, $query: String) {
    getMicrositesPermissions(pagination: $pagination, query: $query) {
      results {
        id
        emailAddress
        firstName
        lastName
        community {
          id
          name
        }
        expirationDate
        token
        shortUrl {
          targetUrl
          sourceUrl
        }
        createdBy {
          id
          firstName
          lastName
        }
      }
      cursor
    }
  }
`

export default getMicrositesPermissionsQuery
