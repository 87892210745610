import gql from 'graphql-tag'
import { useMutation } from '@apollo/client'

// TODO: setup custom useMutation hook once we have sentry and toast notifications(if we want them)
const validateEmailMutation = gql`
  mutation login($email: String!) {
    validateEmail(email: $email) {
      userExists
      accountStatus
      hasMI6Access
    }
  }
`

// TODO: implement error handling once we have a system in place
const useValidateEmailMutation = (options = {}) => {
  const [validateEmail, { loading }]: any[] = useMutation(
    validateEmailMutation,
    options
  )

  return [validateEmail, loading]
}

export default useValidateEmailMutation
