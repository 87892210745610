import gql from 'graphql-tag'
import { useMutation } from '@apollo/client'

// TODO: setup custom useMutation hook once we have sentry and toast notifications(if we want them)
const upsertFileAttachmentMutation = gql`
  mutation upsertFileAttachment(
    $id: String
    $fileName: String
    $cloudinaryId: String!
    $isVirusScanRequired: Boolean
    $isRawConvertRequired: Boolean
  ) {
    upsertFileAttachment(
      id: $id
      fileName: $fileName
      cloudinaryId: $cloudinaryId
      isVirusScanRequired: $isVirusScanRequired
      isRawConvertRequired: $isRawConvertRequired
    ) {
      id
      cloudinaryId
      fileName
      scanStatus
      rawConvertStatus
    }
  }
`

// TODO: implement error handling once we have a system in place
const useUpsertFileAttachmentMutation = (options = {}) => {
  const [createFileAttachment]: any[] = useMutation(
    upsertFileAttachmentMutation,
    options
  )
  return createFileAttachment
}

export default useUpsertFileAttachmentMutation
