import gql from 'graphql-tag'
import { useMutation } from '@apollo/client'
import { searchPersonas } from '../../UserListScreen'

const deleteUserRoleMutation = gql`
  mutation deleteUserAppRole(
    $userId: String!
    $app: GlobalRoleApp!
    $role: String!
  ) {
    deleteUserAppRole(userId: $userId, app: $app, role: $role) {
      id
      appRoles {
        app
        role
      }
    }
  }
`

const useDeleteUserRoleMutation = (options = {}) => {
  const [deleteUserRole, { loading }]: any[] = useMutation(
    deleteUserRoleMutation,
    {
      ...options,
      refetchQueries: [{ query: searchPersonas, variables: { query: '' } }],
      awaitRefetchQueries: true
    }
  )

  return [deleteUserRole, loading]
}

export default useDeleteUserRoleMutation
