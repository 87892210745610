import { useState, useEffect } from 'react'
import gql from 'graphql-tag'
import { useSubscription } from '@apollo/client'

const summitUpdatedSubscription = gql`
  subscription($id: String!) {
    pageContentUpdated(entityId: $id) {
      id
      pdfCloudinaryId
      updatedBy {
        id
        firstName
        lastName

        user {
          id
        }
      }
    }
  }
`

// TODO: Dig into Apollo to see if there is a way around the initialSkip hack.
const useSummitUpdatedSubscription = (
  id: string | undefined | null,
  onData
) => {
  const [initialSkip, setInitialSkip] = useState(true)
  useEffect(() => {
    requestAnimationFrame(() => {
      setInitialSkip(false)
    })
  }, [])

  useSubscription(summitUpdatedSubscription, {
    variables: { id },
    skip: !id || initialSkip,
    fetchPolicy: 'no-cache',
    onData
  })
}

export default useSummitUpdatedSubscription
