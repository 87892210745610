import { gql, useQuery } from '@apollo/client'
import React, { useEffect } from 'react'
import PersonTagDropdown from './PersonTagDropdown'

const eventQuery = gql`
  query getEventSpeakers($id: String!) {
    event(id: $id) {
      id
      speakers(includeUnpublishedPeople: true) {
        id
        firstName
        lastName
      }
    }
  }
`

const PersonTagManager = ({
  eventId,
  onValueChange,
  values
}: PersonTagManagerProps) => {
  const { data } = useQuery(eventQuery, {
    variables: { id: eventId },
    skip: !eventId
  })

  useEffect(() => {
    if (data && data.event) {
      const autoFillValues = data.event.speakers
        .filter(({ id }) => !values.find(({ personId }) => personId === id))
        .map(({ id }) => ({ personId: id, autoFilled: true }))

      onValueChange([
        ...values.filter(({ autoFilled }) => !autoFilled),
        ...autoFillValues
      ])
    } else {
      onValueChange(values.filter(({ autoFilled }) => !autoFilled))
    }
  }, [data])

  const handleSetTag = prev => current => {
    if (prev) {
      if (current) {
        onValueChange(
          values.map(value =>
            value.personId === prev.personId
              ? {
                  personId: current.personId,
                  source: current.source
                }
              : value
          )
        )
      } else {
        onValueChange(values.filter(value => value.personId !== prev.personId))
      }
    } else if (current) {
      onValueChange([...values, current])
    }
  }

  const excludeIds = values.map(({ personId }) => personId)

  return (
    <>
      {values.map((tag, i) => (
        <PersonTagDropdown
          key={`person-tag-${i}-${tag.personId}-${tag.source?.sourceId}`}
          personId={tag.personId}
          sourceId={tag.source?.sourceId}
          onValueChange={handleSetTag(tag)}
          autoFilled={tag.autoFilled}
          excludeIds={excludeIds}
        />
      ))}
      <PersonTagDropdown
        key={`new-person-tag-${values.length}`}
        onValueChange={handleSetTag(null)}
        excludeIds={excludeIds}
      />
    </>
  )
}

interface PersonTagManagerProps {
  eventId?: string | null
  onValueChange?: any
  values: any[]
}

export default PersonTagManager
