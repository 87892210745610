import React from 'react'
import { TableCol, TableRow } from '../../dataExtraction/QueueTable'
import { Text } from '../../../components/common/Text'

export const TableSkeleton: React.FC<{ rows: number }> = ({ rows }) => {
  return (
    <>
      {new Array(rows).fill(0).map((_, index) => (
        <TableRow key={index}>
          <TableCol>
            <Text>Loading...</Text>
          </TableCol>
          <TableCol />
          <TableCol />
          <TableCol />
          <TableCol />
          <TableCol />
        </TableRow>
      ))}
    </>
  )
}
