import React, { FC, useEffect, useState } from 'react'
import useFeatureFlag from '../../../hooks/useFeatureFlag'
import Button from '../../../ui-library/Button'
import { Linking, Platform } from 'react-native'
import Constants from 'expo-constants'
import { useTranslation } from 'react-i18next'
import Dropdown from '../Dropdown'
import { Flex } from '../../FlexBox'

import { Text } from '../Text'
import { useTheme } from 'styled-components/native'
import idToCloudinaryUrl from '../../../utils/idToCloudinaryUrl'
import { IDbPageContent } from '../../../screens/summit/query'
import useSummitPDFSubscription from '../../../screens/summit/subscriptions/useSummitPDFSubscription'

const MEMBER_WEB_PROTOCOL = Constants.manifest?.extra?.MEMBER_WEB_PROTOCOL!
const MEMBER_WEB_URL = Constants.manifest?.extra?.MEMBER_WEB_URL!

export const Label = ({ label }) => {
  const { colors, fontSizes } = useTheme()

  return (
    <Flex flexDirection="column" alignContent={'center'}>
      <Text
        styles={{
          color: colors.buttonText,
          textAlign: 'center',
          fontSize: fontSizes[4]
        }}
      >
        {label}
      </Text>
    </Flex>
  )
}

const generatePdfUrl = cloudinaryId => {
  return cloudinaryId
    ? idToCloudinaryUrl(cloudinaryId, {
        resource_type: 'image'
      })
    : null
}

const DropDownSelect = ({ appUrl, disabled, eventId, pageContent }) => {
  const { t } = useTranslation()
  const { colors } = useTheme()
  const [pdfUrl, setPdfUrl] = useState<null | string>(null)

  useEffect(() => {
    if (pageContent?.pdfCloudinaryId) {
      setPdfUrl(generatePdfUrl(pageContent?.pdfCloudinaryId))
    }
  }, [])

  useSummitPDFSubscription({
    eventId,
    pdfCloudinaryId: pageContent?.pdfCloudinaryId,
    onData: ({ data: { data } }) => {
      const { pdfCloudinaryId } = data.pageContentPdfCloudinaryIdUpdated

      setPdfUrl(generatePdfUrl(pdfCloudinaryId))
    }
  })
  const options = [
    {
      value: 'app',
      label: t('summits:editor:appPreview'),
      disabled: false
    },
    {
      value: 'pdf',
      label: t('summits:editor:pdfPreview'),
      disabled: !pdfUrl
    }
  ]

  const openPreview = option => {
    let url

    if (option.value === 'app') {
      url = appUrl
    } else if (option.value === 'pdf') {
      url = pdfUrl
    }

    Platform.OS === 'web' ? window.open(url, '_blank') : Linking.openURL(url)
  }
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [currentSelection, setCurrentSelection] = useState<any>('app')

  return (
    <Flex
      flexDirection="column"
      style={{ minWidth: '178px', marginRight: '10px' }}
    >
      <Dropdown
        isDisabled={disabled}
        value={currentSelection}
        options={options}
        placeholder={t('summits:editor:preview')}
        style={{
          width: '178px',
          backgroundColor: colors.buttonBackground
        }}
        menuStyle={{
          backgroundColor: colors.buttonBackground
        }}
        theme={{
          colors: {
            neutral20: colors.buttonText,
            neutral60: colors.buttonText,
            primary25: colors.brightPrimary,
            primary: colors.buttonBackground
          }
        }}
        closeMenuOnSelect
        formatOptionLabel={props => <Label {...props} />}
        onSelect={el => {
          const option = options.find(e => e.value === el)

          if (option?.value) {
            openPreview(option)
          }
        }}
        isOptionDisabled={option => option.disabled}
      />
    </Flex>
  )
}

const PreviewSelect: FC<{
  disabled: boolean
  selectedSummit: string | undefined
  pageContent?: IDbPageContent | null
}> = ({ disabled, selectedSummit, pageContent }) => {
  const { t } = useTranslation()
  const isAgendaPdfEnabled = useFeatureFlag('createEventAgendaPDF')

  const appUrl = `${MEMBER_WEB_PROTOCOL}://${MEMBER_WEB_URL}/gatherings/${selectedSummit}?preview=true`

  const openPreview = () => {
    Platform.OS === 'web'
      ? window.open(appUrl, '_blank')
      : Linking.openURL(appUrl)
  }

  return isAgendaPdfEnabled ? (
    <DropDownSelect
      appUrl={appUrl}
      disabled={disabled}
      eventId={selectedSummit}
      pageContent={pageContent}
    />
  ) : (
    <Button
      disabled={disabled}
      title={t('summits:editor:preview')}
      onPress={openPreview}
      style={{ marginRight: 8 }}
    />
  )
}

export default PreviewSelect
