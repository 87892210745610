import * as Yup from 'yup'
import {
  MediaCategoryEnum,
  MediaStatusEnum,
  MediaTypeEnum,
  MediaHostEnum,
  GenericObject
} from '../../../types'
import { taggedPeopleSchema } from '../../../validators/taggedPeopleSchema'
import { topicsSchema } from '../../../validators/topicsSchema'

const hostMediaSchema = Yup.object().shape({
  id: Yup.string().required('Required'),
  hostId: Yup.string().required('Required'),
  host: Yup.mixed()
    .oneOf([...Object.values(MediaHostEnum)])
    .required('Required'),
  type: Yup.mixed()
    .oneOf([...Object.values(MediaTypeEnum)])
    .required('Required'),
  name: Yup.string().nullable(),
  url: Yup.string().nullable(),
  status: Yup.mixed().oneOf([MediaStatusEnum.Complete]).required('Required'),
  transcode: Yup.mixed().oneOf([MediaStatusEnum.Complete]).required('Required')
})

const previewSchema = Yup.object().shape(
  {
    id: Yup.string().nullable(),
    description: Yup.object().test(
      'required',
      'Required',
      (value: GenericObject) => value.getCurrentContent().hasText()
    ),
    title: Yup.string().min(1, 'Too Short!').required('Required'),
    subtitle: Yup.string().min(1, 'Too Short!').required('Required'),
    category: Yup.string()
      .oneOf(Object.values(MediaCategoryEnum))
      .required('Required'),
    gathering: Yup.string().nullable(),
    hostMediaId: Yup.string().required('Required'),
    highlightsHostMediaId: Yup.string().nullable(),
    heroImage: Yup.object().shape({
      fileName: Yup.string().nullable(),
      url: Yup.string().required(),
      publicId: Yup.string().required()
    }),
    communities: Yup.array().when(['groups', 'gatherings', 'people'], {
      is: (groups, gatherings, people) =>
        (!groups || groups.length === 0) &&
        (!gatherings || gatherings.length === 0) &&
        (!people || people.length === 0),
      then: Yup.array().min(1, 'atLeastAudienceType')
    }),
    groups: Yup.array().when(['communities', 'gatherings', 'people'], {
      is: (communities, gatherings, people) =>
        (!communities || communities.length === 0) &&
        (!gatherings || gatherings.length === 0) &&
        (!people || people.length === 0),
      then: Yup.array().min(1, 'atLeastAudienceType')
    }),
    gatherings: Yup.array().when(['communities', 'groups', 'people'], {
      is: (communities, groups, people) =>
        (!communities || communities.length === 0) &&
        (!groups || groups.length === 0) &&
        (!people || people.length === 0),
      then: Yup.array().min(1, 'atLeastAudienceType')
    }),
    people: Yup.array().when(['communities', 'groups', 'gatherings'], {
      is: (communities, groups, gatherings) =>
        (!communities || communities.length === 0) &&
        (!groups || groups.length === 0) &&
        (!gatherings || gatherings.length === 0),
      then: Yup.array().min(1, 'atLeastAudienceType')
    }),
    published: Yup.bool(),
    publishDate: Yup.date().nullable(),
    hostMedia: hostMediaSchema,
    highlightsHostMedia: hostMediaSchema.nullable(),
    topics: topicsSchema.nullable(),
    taggedPeople: taggedPeopleSchema.nullable()
  },
  [
    ['communities', 'groups'],
    ['communities', 'gatherings'],
    ['communities', 'people'],
    ['groups', 'communities'],
    ['groups', 'gatherings'],
    ['groups', 'people'],
    ['gatherings', 'communities'],
    ['gatherings', 'groups'],
    ['gatherings', 'people'],
    ['people', 'communities'],
    ['people', 'groups'],
    ['people', 'gatherings']
  ]
)

export default previewSchema
