import { useEffect } from 'react'
import { Platform } from 'react-native'
import { useNavigation } from '@react-navigation/native'
import useTranslation from './useTranslation'
import useCopyContent from './useCopyContent'

export default function usePreventNavOnDirtyState(formik) {
  const navigation = useNavigation()
  const { t } = useTranslation()
  const { resetCopyContent } = useCopyContent()

  useEffect(() => {
    if (!formik.dirty) return

    // handles browser unload events like refreshes and
    // navigating to a new site
    if (Platform.OS === 'web' && formik.dirty) {
      window.addEventListener('beforeunload', interruptUnload)
    }

    // handles in-app navigation through react-navigation events
    const removeNavigationListener = navigation.addListener(
      'beforeRemove',
      e => {
        const discardChanges = window.confirm(t('common:unsavedChanges'))
        if (!discardChanges) {
          e.preventDefault()
        }
      }
    )

    return () => {
      removeNavigationListener()
      resetCopyContent()
      if (Platform.OS === 'web') {
        window.removeEventListener('beforeunload', interruptUnload)
      }
    }
  }, [navigation, formik.dirty])
}

function interruptUnload(e) {
  e.preventDefault()
  e.returnValue = ''
}
