import gql from 'graphql-tag'
import { useMutation } from '@apollo/client'

// TODO: setup custom useMutation hook once we have sentry and toast notifications(if we want them)
const upsertDocumentMutation = gql`
  mutation upsertDocument(
    $id: String
    $title: String!
    $subtitle: String
    $body: String
    $type: DocumentType
    $published: Boolean
    $publishDate: DateTime
    $shareable: Boolean
    $imageId: String
    $cloudinaryImage: CloudinaryUploadObject
    $cloudinaryFile: CloudinaryUploadObject
    $fileId: String
    $communities: [String!]
    $groups: [String!]
    $events: [String!]
    $people: [String!]
    $eventId: String
    $topics: [String!]
    $taggedPeople: [EntityPersonTagInput!]
  ) {
    upsertDocument(
      id: $id
      title: $title
      subtitle: $subtitle
      body: $body
      type: $type
      published: $published
      publishDate: $publishDate
      shareable: $shareable
      imageId: $imageId
      cloudinaryImage: $cloudinaryImage
      cloudinaryFile: $cloudinaryFile
      fileId: $fileId
      communities: $communities
      groups: $groups
      events: $events
      people: $people
      eventId: $eventId
      topics: $topics
      taggedPeople: $taggedPeople
    ) {
      id
      title
      subtitle
      body
      type
      published
      publishDate
      shareable
      image {
        id
        cloudinaryId
        fileName
      }
      file {
        id
        cloudinaryId
        fileName
      }
      communities {
        id
      }
      groups {
        id
      }
      people {
        id
        firstName
        lastName
        employerName
      }
      events {
        id
        name
        startDate
        groups {
          id
          name
        }
      }
      event {
        id
      }
      topics {
        id
      }
      personTags(includeUnpublishedPeople: true) {
        person {
          id
          firstName
          lastName
        }
        source {
          __typename
          id
          title
        }
      }
    }
  }
`

// TODO: implement error handling once we have a system in place
const useUpsertDocumentMutation = (options = {}) => {
  const [createDocument]: any[] = useMutation(upsertDocumentMutation, options)
  return createDocument
}

export default useUpsertDocumentMutation
