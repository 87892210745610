import gql from 'graphql-tag'
import { useSubscription } from '@apollo/client'

const summitPDFUpdatedSubscription = gql`
  subscription pageContentPdfCloudinaryIdUpdated(
    $entityId: String!
    $pdfCloudinaryId: String
  ) {
    pageContentPdfCloudinaryIdUpdated(
      entityId: $entityId
      pdfCloudinaryId: $pdfCloudinaryId
    ) {
      id
      entityId
      pdfCloudinaryId
    }
  }
`

const useSummitPDFSubscription = ({ eventId, pdfCloudinaryId, onData }) => {
  useSubscription(summitPDFUpdatedSubscription, {
    variables: { entityId: eventId, pdfCloudinaryId },
    skip: !eventId,
    onData
  })
}

export default useSummitPDFSubscription
