import React, { useEffect, useState } from 'react'
import TextInput from '../../../ui-library/TextInput'

const DebouncedTextInput = ({
  initialValue,
  onChangeText,
  ...rest
}: DebouncedTextInputProps) => {
  const [query, setQuery] = useState(initialValue || '')

  useEffect(() => {
    const handler = setTimeout(() => {
      onChangeText(query)
    }, 300)

    return () => clearTimeout(handler)
  }, [query])

  return <TextInput value={query} onChangeText={setQuery} {...rest} />
}

interface DebouncedTextInputProps {
  initialValue?: string
  placeholder: string
  onChangeText: (value: string) => void
  style?: any | any[]
}

export default DebouncedTextInput
