import React from 'react'
import { Flex } from '../../../components/FlexBox'
import { Text } from '../../../components/common/Text'
import Button from '../../../components/common/Button'
import { BorderBox } from './BorderBox'

export const Pagination: React.FC<{
  page: number
  setPage: (page: number) => void
  totalCount: number
  limitPerPage: number
}> = ({ page, setPage, totalCount, limitPerPage }) => {
  return (
    <Flex flexDirection="row" justifyContent="center" my={3}>
      <Button
        label="<<"
        style={{ marginRight: 10 }}
        onPress={() => {
          setPage(0)
        }}
        disabled={page === 0}
      >
        {'<<'}
      </Button>
      <Button
        label="<"
        style={{ marginRight: 10 }}
        onPress={() => {
          setPage(page - 1)
        }}
        disabled={page === 0}
      />
      <BorderBox>
        <Text>
          {`Page ${page + 1}`} / {`${Math.ceil(totalCount / limitPerPage)}`}
        </Text>
      </BorderBox>
      <Button
        label=">"
        disabled={
          page === Math.ceil(totalCount / limitPerPage) - 1 ||
          totalCount < limitPerPage
        }
        onPress={() => {
          setPage(page + 1)
        }}
      />
      <Button
        label=">>"
        style={{ marginLeft: 10 }}
        onPress={() => {
          setPage(Math.ceil(totalCount / limitPerPage) - 1)
        }}
        disabled={
          page === Math.ceil(totalCount / limitPerPage) - 1 ||
          totalCount < limitPerPage
        }
      />
    </Flex>
  )
}
