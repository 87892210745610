import { useRecoilState } from 'recoil'
import currentUserAtom from '../recoil/atoms/currentUserAtom'
import { CurrentUser as CurrentUserType, RoleEnum } from '../types'

export interface CurrentUserHook {
  firebaseInitialized: boolean
  firebaseToken: string | null
  currentUser: CurrentUserType | null
  currentUserId: String | undefined
  currentPersonId: String | undefined
  isAdmin: boolean
  isAssociate: boolean
  setCurrentUser: (val: CurrentUserType | null) => void
  setFirebase: (token: string | null | undefined) => void
  isCurrentPerson: (id: String | undefined) => boolean
  isCurrentUser: (id: String | undefined) => boolean
}

const useCurrentUser = (): CurrentUserHook => {
  const [currentUserState, setCurrentUserState] = useRecoilState(
    currentUserAtom
  )

  const currentUser = currentUserState?.user

  const isCurrentPerson = id => {
    const currentPersonId = currentUser?.person?.id
    return !!id && !!currentPersonId && id === currentPersonId
  }

  const isCurrentUser = id => {
    const currentUserId = currentUser?.id
    return !!id && !!currentUserId && id === currentUserId
  }

  const setCurrentUser = val => {
    let newVal = val || null
    setCurrentUserState({ ...currentUserState, user: newVal })
  }

  const setFirebase = token => {
    if (!token) {
      setCurrentUserState({
        firebaseInitialized: true,
        firebaseToken: null,
        user: null
      })
    } else {
      setCurrentUserState({
        ...currentUserState,
        firebaseInitialized: true,
        firebaseToken: token
      })
    }
  }

  const hasRole = roleToCheck =>
    currentUser?.roles?.includes(roleToCheck) || false

  return {
    firebaseInitialized: currentUserState?.firebaseInitialized,
    firebaseToken: currentUserState?.firebaseToken,
    currentUser,
    currentUserId: currentUser?.id,
    currentPersonId: currentUser?.person?.id,
    isAdmin: hasRole(RoleEnum.Admin),
    isAssociate: hasRole(RoleEnum.Associate),
    setCurrentUser,
    setFirebase,
    isCurrentPerson,
    isCurrentUser
  }
}

export default useCurrentUser
