import { Text } from 'react-native-elements'
import styled from 'styled-components/native'

const EllipticalText = styled(Text)`
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: inline-block;
`

export default EllipticalText
