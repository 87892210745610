import { FormikProps } from 'formik'
import React, { useState } from 'react'
import Dropdown from '../../components/common/Dropdown'
import { Flex } from '../../components/FlexBox'
import useTranslation from '../../hooks/useTranslation'
import Button from '../../ui-library/Button'
import { IEditorState, IEventResult } from './types'
import { DefaultSummitSite, SummitBuilder } from './utils'

interface IProps {
  form: FormikProps<IEditorState>
  event?: IEventResult
}

export const AddSection: React.FC<IProps> = ({ form, event }) => {
  const [addSectionSelection, setAddSectionSelection] = useState<string>(
    'empty-section'
  )

  const { t } = useTranslation()

  const findSection = (uid: string) =>
    form.values.sections?.find(s => s.uid === uid)

  return (
    <Flex flexDirection="row" alignItems="center" justifyContent="flex-end">
      <Dropdown
        options={[
          { label: t('summits:editor:sections:empty'), value: 'empty-section' },
          {
            label: t('summits:editor:sections:logistics'),
            value: 'logistics',
            isDisabled: !!findSection('logistics')
          },
          {
            label: t('summits:editor:sections:overview'),
            value: 'overview',
            isDisabled: !!findSection('overview')
          },
          {
            label: t('summits:editor:sections:programGuests'),
            value: 'program-guests',
            isDisabled: !!findSection('program-guests')
          },
          {
            label: t('summits:editor:sections:agenda'),
            value: 'agenda',
            isDisabled: !!findSection('agenda')
          },
          {
            label: t('summits:editor:sections:pageBreak'),
            value: 'pageBreak'
          }
        ]}
        value={addSectionSelection}
        placeholder={t('summits:editor:sections:selectTemplate')}
        onBlur={() => {}}
        onSelect={value => setAddSectionSelection(value)}
        style={{ marginBottom: 0, marginRight: 8 }}
      />
      <Button
        title={t('summits:editor:sections:addSection')}
        onPress={() => {
          const operator = {
            overview: () => {
              return DefaultSummitSite.getOverview(event!)
            },
            agenda: () => {
              return DefaultSummitSite.getAgenda()
            },
            logistics: () => {
              return DefaultSummitSite.getLogistics(event!)
            },
            'program-guests': () => {
              return DefaultSummitSite.getProgramGuests()
            },
            'empty-section': () => {
              return SummitBuilder.sections.getEmptySection()
            },
            pageBreak: () => {
              return DefaultSummitSite.getPageBreak()
            }
          }
          form.values.sections.push(operator[addSectionSelection]())
          form.setValues(form.values)
          setAddSectionSelection('empty-section')
        }}
        style={{ borderRadius: 999 }}
      />
    </Flex>
  )
}
