import React, { useRef, useEffect } from 'react'
import { View } from 'react-native'
import styled from 'styled-components/native'
// Do not change the below import to 'styled-components/native'
// eslint-disable-next-line no-restricted-imports
import styledWeb from 'styled-components'

const BaseIframe = styledWeb.iframe`
  border-width: 0px;
  height: 100%;
  width: 100%;
`
const StyledView = styled(View)``

const WebView = ({ source, style, ...rest }: any) => {
  return (
    <StyledView style={style}>
      {source.uri && <BaseIframe src={source.uri} {...rest} />}
      {source.html && <HtmlIframe html={source.html} {...rest} />}
    </StyledView>
  )
}

export default WebView

interface HtmlIframeProps {
  html: string
}

const HtmlIframe = ({ html, ...rest }: HtmlIframeProps) => {
  const iframeRef = useRef(null)

  useEffect(() => {
    if (!iframeRef.current) return
    // @ts-ignore it isn't null. ^^^
    const doc = iframeRef.current.contentDocument
    doc.open()
    doc.write(html)
    doc.close()
  }, [iframeRef.current])

  return <BaseIframe ref={iframeRef} {...rest} />
}
