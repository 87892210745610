import React from 'react'
import { TouchableOpacity } from 'react-native'
import { Text } from 'react-native-elements'
import styled from 'styled-components/native'
import useCurrentUser from '../../../hooks/useCurrentUser'

interface MenuItemProps {
  Icon?: JSX.Element
  label: string
  highlight?: boolean
  onPress: () => void
}

const Label = styled(Text)`
  ${({ theme: { space, fontSizes } }) => `
  font-size: ${fontSizes[4]}px;
  line-height: ${fontSizes[6]}px;
  margin-left: ${space[2]}px;
`}
`

const ItemContainer = styled(TouchableOpacity)`
  ${({ theme: { space } }) => `
    flex-direction: row;
    align-items: center;
    margin-bottom: ${space[2]}px;
    padding-left: ${space[2]}px;
    padding: 8px;
    width: 100%;
  `}
`

const MenuItem = ({ Icon, label, onPress }: MenuItemProps) => {
  return (
    <ItemContainer onPress={onPress}>
      {Icon}
      <Label>{label}</Label>
    </ItemContainer>
  )
}

export interface MenuItemWithPermissionProps extends MenuItemProps {
  permission: string
}
export const MenuItemWithPermission = ({
  permission,
  ...rest
}: MenuItemWithPermissionProps) => {
  const { currentUser } = useCurrentUser()

  if (!currentUser?.access[permission]) {
    return null
  }
  return <MenuItem {...rest} />
}

export default MenuItem
