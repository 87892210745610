import React, { FC, useState } from 'react'

import {
  View,
  Text,
  TouchableOpacity,
  ViewStyle,
  ActivityIndicator
} from 'react-native'
import styled from 'styled-components/native'

import useFileUploader from '../../../hooks/useFileUploader'
import { H4 } from '../../../components/common/Text'
import Modal from '../../../components/common/Modal'
import Button from '../../../ui-library/Button'
import FileDropzone, {
  FileDropzoneValue,
  DropZoneContentProps
} from '../../../components/FileDropzone'
import DropZoneContainer, { DropZoneContainerProps } from '../DropZoneContainer'

import useTranslation from '../../../hooks/useTranslation'
import CopyToClipboardButton from '../../../screens/media/components/CopyToClipboardButton'
import { CloudinaryFileAttachment } from '../../../types'
import idToCloudinaryUrl from '../../../utils/idToCloudinaryUrl'
import { Flex } from '../../FlexBox'

interface RemoveContentModal {
  title: string
  description: string
}

export interface ContentValue {
  fileName: string
  url: string
  publicId?: string
}

type DropZoneContent = Omit<DropZoneContainerProps, 'selectFileButton'> & {
  selectFileButton?: JSX.Element
}

export interface ContentDropProps {
  value: ContentValue | Array<ContentValue> | null | undefined
  hasError?: boolean
  showCopyClipboard?: boolean
  documentFileSizeLimit?: number
  setValue: (value: FileDropzoneValue | null) => void
  setError: (value: string | undefined) => void
  style?: ViewStyle
  cloudinaryDir: string
  acceptFormat: string
  dropZoneContent: DropZoneContent
  removeContentModal: RemoveContentModal
  isRawConvertRequired?: boolean
  isVirusScanRequired?: boolean
  shouldSetValue?: boolean
  onRenderUrl: (url: string) => JSX.Element
  handleFileUploaded?: (fileAttachment: CloudinaryFileAttachment) => void
  multiple?: boolean
}

export type CustomDropProps = Omit<
  ContentDropProps,
  'acceptFormat' | 'dropZoneContent' | 'removeContentModal' | 'onRenderUrl'
> & {
  allowedFileTypes?: string[]
}

const MultipleImageWrapper = styled(View)`
  flex-basis: 50px;
  margin: 2px;
`

const ContentDrop: FC<ContentDropProps> = ({
  value,
  style,
  setError,
  hasError,
  setValue,
  documentFileSizeLimit,
  onRenderUrl,
  acceptFormat,
  cloudinaryDir,
  dropZoneContent,
  showCopyClipboard,
  handleFileUploaded,
  removeContentModal,
  isVirusScanRequired,
  isRawConvertRequired,
  multiple = false,
  shouldSetValue = true
}) => {
  const [showConfirmationModal, setShowConfirmationModal] = useState(false)
  const { t } = useTranslation()

  const [uploadFile] = useFileUploader({
    isRawConvertRequired,
    isVirusScanRequired,
    handleFileUploaded,
    shouldSetValue,
    cloudinaryDir,
    documentFileSizeLimit,
    setValue,
    multiple,
    setError
  })

  const loading = value?.fileName && !value?.publicId
  const showDropzone = multiple ? !value?.length : !value

  const DropZoneContent = (props: DropZoneContentProps) => (
    <DropZoneContainer {...props} {...dropZoneContent} />
  )

  return (
    <>
      {showDropzone ? (
        <FileDropzone
          style={style}
          hasError={hasError}
          accept={acceptFormat}
          upload={uploadFile}
          Content={DropZoneContent}
          multiple={multiple}
        />
      ) : (
        <View style={style}>
          {multiple ? (
            <>
              <Flex flexDirection="row" mx={3} my={3} flexWrap="wrap">
                {value?.map(image => (
                  <MultipleImageWrapper key={image.fileName}>
                    {image.fileName && !image.publicId ? (
                      <LoadingIndicator style={{ height: 50 }} />
                    ) : (
                      onRenderUrl(image.url)
                    )}
                  </MultipleImageWrapper>
                ))}
              </Flex>
              <BottomRow>
                <ClearAllButton onPress={() => setShowConfirmationModal(true)}>
                  <ClearButtonText>Clear all ×</ClearButtonText>
                </ClearAllButton>
              </BottomRow>
            </>
          ) : (
            <>
              {loading ? <LoadingIndicator /> : onRenderUrl(value.url)}
              <BottomRow>
                {showCopyClipboard && !loading && value?.url && (
                  <CopyToClipboardButton
                    url={idToCloudinaryUrl(value.publicId, {
                      resource_type: 'raw'
                    })}
                  />
                )}
                <Filename>{value.fileName}</Filename>
                <ClearButton onPress={() => setShowConfirmationModal(true)}>
                  <ClearButtonText>×</ClearButtonText>
                </ClearButton>
              </BottomRow>
            </>
          )}

          <Modal
            open={showConfirmationModal}
            close={() => setShowConfirmationModal(false)}
          >
            <H4>{removeContentModal.title}</H4>
            <View style={{ padding: 10 }}>
              <View style={{ paddingBottom: 10 }}>
                <Text>{removeContentModal.description}</Text>
              </View>
            </View>
            <View
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-end'
              }}
            >
              <Button
                title={t('common:labels:cancel')}
                style={{ marginRight: 10 }}
                type="outline"
                onPress={() => {
                  setShowConfirmationModal(false)
                }}
              />
              <Button
                title={t('common:labels:remove')}
                onPress={() => {
                  setValue(multiple ? [] : null)
                  setShowConfirmationModal(false)
                }}
              />
            </View>
          </Modal>
        </View>
      )}
    </>
  )
}

const LoadingIndicator = styled(ActivityIndicator)`
  ${({ theme: { sizes } }) => `
    height: ${sizes[6] + sizes[5]};
    width: 100%;
  `}
`

const BottomRow = styled(View)`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 5px;
`

const Filename = styled(Text)`
  flex: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 18px;
  color: gray;
  max-width: 400px;
  font-style: italic;
  white-space: nowrap;
  padding: 0 8px;
`

const ClearButton = styled(TouchableOpacity)`
  background: rgba(0, 0, 0, 0.24);
  width: 1.2em;
  height: 1.2em;
  border-radius: 2em;
`

const ClearAllButton = styled(TouchableOpacity)`
  ${({ theme: { sizes } }) => `
    padding: ${sizes[1]}px;
  `}
  background: rgba(0, 0, 0, 0.24);
  border-radius: 2em;
  margin-left: auto;
`

const ClearButtonText = styled(Text)`
  text-align: center;
  color: #fff;
  font-size: 18px;
  line-height: 1em;
  font-weight: bold;
`

export default ContentDrop
