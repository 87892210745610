import React from 'react'
import { NavigationContainer } from '@react-navigation/native'

import LoadingScreen from '../screens/LoadingScreen'
import LinkingConfiguration from './LinkingConfiguration'
import RootNavigator from './navigators/RootNavigator'
import useCurrentUserLoader from '../hooks/useCurrentUserLoader'

export default function Navigation() {
  const { firebaseInitialized } = useCurrentUserLoader()

  if (!firebaseInitialized) {
    return <LoadingScreen />
  }

  return (
    <NavigationContainer linking={LinkingConfiguration}>
      <RootNavigator />
    </NavigationContainer>
  )
}
