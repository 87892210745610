import React from 'react'
import { View } from 'react-native'
import { Svg, Path, SvgProps } from 'react-native-svg'

const DEFAULT_SIZE = 24

interface Props {
  size?: number
}

const TrashIcon: React.FC<Props & SvgProps> = React.memo(
  ({ size = DEFAULT_SIZE, ...rest }) => {
    return (
      <View>
        <Svg
          width={size}
          height={size}
          viewBox="0 0 1024 1024"
          fill="none"
          {...rest}
        >
          <Path
            transform="scale(1.05, 1.05)"
            d="M360 184h-8c4.4 0 8-3.6 8-8v8h304v-8c0 4.4 3.6 8 8 8h-8v72h72v-80c0-35.3-28.7-64-64-64H352c-35.3 0-64 28.7-64 64v80h72v-72zm504 72H160c-17.7 0-32 14.3-32 32v32c0 4.4 3.6 8 8 8h60.4l24.7 523c1.6 34.1 29.8 61 63.9 61h454c34.2 0 62.3-26.8 63.9-61l24.7-523H888c4.4 0 8-3.6 8-8v-32c0-17.7-14.3-32-32-32zM731.3 840H292.7l-24.2-512h487l-24.2 512z"
            fill="#21201B"
          />
        </Svg>
      </View>
    )
  }
)

export default TrashIcon
