import React, { useContext, useMemo } from 'react'
import Select, { components as RScomponents, MenuPosition } from 'react-select'
import styled, { ThemeContext } from 'styled-components/native'
import { Flex } from '../FlexBox'
import { Text } from './Text'
import { lighten } from 'polished'
import useTranslation from '../../hooks/useTranslation'
import { ViewStyle } from 'react-native'

export type DropdownValue = string | string[]

export const DropdownTitle = styled(Text).attrs(props => ({
  styles: {
    fontSize: props.theme.fontSizes[4]
  }
}))``

export const DropdownSubtitle = styled(Text).attrs(props => ({
  styles: {
    fontSize: props.theme.fontSizes[3],
    color: lighten(0.25, props.theme.colors.text)
  }
}))``

const MaxLimitText = styled(Text).attrs(
  ({ error, theme: { colors, fontSizes, space } }) => ({
    styles: {
      fontSize: fontSizes[3],
      color: error ? colors.danger : colors.primary,
      marginLeft: space[2]
    }
  })
)``

interface DefaultOptionProps {
  label: String
}

export const DefaultOptionLabel = ({ label }: DefaultOptionProps) => (
  <Flex flexDirection="column">
    <DropdownTitle numberOfLines={1} ellipsizeMode="tail">
      {label}
    </DropdownTitle>
  </Flex>
)

const LeftIconInput = (props: any) => {
  return (
    <>
      <Flex flexDirection="row" style={{ marginLeft: 8 }}>
        {props.selectProps.leftIcon}
      </Flex>
      <RScomponents.ValueContainer {...props}>
        {props.children}
      </RScomponents.ValueContainer>
    </>
  )
}

const MultiValueWithLimitControl = ({ selectProps, ...rest }: any) => {
  const { t } = useTranslation()
  const { maxAmountError, maxAmount } = selectProps
  return (
    <>
      <RScomponents.Control {...rest} />
      <MaxLimitText error={maxAmountError}>
        {t('forms:maxAmount', { maxAmount: maxAmount })}
      </MaxLimitText>
    </>
  )
}
export default function Dropdown({
  options,
  value,
  onSelect,
  placeholder,
  isMulti = false,
  shortMultiSelect = false,
  style = {},
  closeMenuOnSelect = true,
  onBlur,
  hasError,
  dropdownIcon,
  leftIcon,
  MultiValueLabel,
  formatOptionLabel = DefaultOptionLabel,
  onMenuScrollToBottom,
  onInputChange,
  isClearable,
  maxAmount,
  isDisabled,
  noOptionsMessage,
  isSearchable,
  filterOption,
  getOptionLabel,
  onMenuClose,
  menuStyle,
  theme: dropdownStyles,
  isOptionDisabled
}: DropdownProps) {
  const theme: any = useContext(ThemeContext)
  const styles = getStyles(theme, style, hasError, shortMultiSelect, menuStyle)

  const components = {
    IndicatorSeparator: false
  } as any

  if (dropdownIcon) {
    components.DropdownIndicator = props => (
      <RScomponents.DropdownIndicator {...props}>
        {dropdownIcon}
      </RScomponents.DropdownIndicator>
    )
  }

  if (leftIcon) {
    components.ValueContainer = LeftIconInput
  }

  if (isMulti && MultiValueLabel) {
    components.MultiValueLabel = MultiValueLabel
  }

  const maxAmountError =
    isMulti && maxAmount && value && value.length > maxAmount
  if (isMulti && maxAmount) {
    components.Control = MultiValueWithLimitControl
  }

  // react-select wants an object as a value
  const currentSelection = useMemo(
    () =>
      isMulti
        ? ((value as string[]) || []).map(v =>
            options.find(option => option.value === v)
          )
        : options.find(option => option.value === value),
    [value, isMulti, options]
  )

  const handleChange = selected => {
    if (isMulti) {
      selected = selected && selected.length ? selected : []
      if (!maxAmount || maxAmount >= selected.length) {
        onSelect(selected.map(s => s.value))
      }
    } else {
      onSelect(selected?.value)
    }
  }

  const key = Array.isArray(currentSelection)
    ? currentSelection.reduce((cs, cv) => cs + cv?.value, '')
    : currentSelection?.value

  const selectProps = {
    options,
    closeMenuOnSelect,
    placeholder,
    isMulti,
    theme: dropdownStyles,
    styles,
    value: currentSelection,
    onChange: handleChange,
    onBlur,
    components,
    formatOptionLabel,
    onMenuScrollToBottom,
    onInputChange,
    isClearable,
    isDisabled,
    isSearchable,
    noOptionsMessage: () => noOptionsMessage,
    leftIcon,
    menuPortalTarget: document?.body,
    menuPosition: 'fixed' as MenuPosition,
    closeMenuOnScroll: true,
    filterOption,
    maxAmount,
    maxAmountError,
    getOptionLabel,
    onMenuClose,
    isOptionDisabled
  }

  return <Select key={`select_key_${key}`} {...selectProps} />
}

export interface DropdownOption {
  label: string
  value: any
  subtitle?: string
  custom?: Record<string, any>
  isDisabled?: boolean
}

export interface DropdownProps {
  options: DropdownOption[]
  value: DropdownValue | null
  placeholder: any
  onSelect: (DropdownValue) => void
  onBlur?: (any) => void
  isMulti?: boolean
  shortMultiSelect?: boolean
  style?: ViewStyle
  menuStyle?: ViewStyle
  theme?: any
  closeMenuOnSelect?: boolean
  hasError?: boolean
  dropdownIcon?: any
  leftIcon?: any
  MultiValueLabel?: any
  formatOptionLabel?: (props: any, context: any) => React.ReactNode
  onMenuScrollToBottom?: (any) => void
  onInputChange?: (any) => void
  isClearable?: boolean
  maxAmount?: number
  isDisabled?: boolean
  isSearchable?: boolean
  noOptionsMessage?: string
  filterOption?: (any) => boolean
  getOptionLabel?: (option?: DropdownOption) => string
  onMenuClose?: () => void
  isOptionDisabled?: (any) => any
}

const getStyles = (theme, style, hasError, shortMultiSelect, menuStyle) => ({
  container: (provided, { isFocused, isMulti }) => ({
    ...provided,
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: isFocused
      ? theme.colors.borderDark
      : hasError
      ? theme.colors.danger
      : theme.colors.inputBorder,
    borderRadius: 3,
    backgroundColor: theme.colors.inputBackground,
    width: 253,
    fontSize: 14,
    fontFamily:
      'system-ui, -apple-system, system-ui, "Segoe UI", Roboto, Ubuntu, "Helvetica Neue", sans-serif',
    alignItems: 'baseline',
    marginBottom: 20,
    minHeight: isMulti && !shortMultiSelect && 120,
    height: isMulti && !shortMultiSelect && 'auto',
    ...style
  }),
  option: (provided, { data, isDisabled }) => ({
    ...provided,
    fontSize: 14,
    fontFamily:
      'system-ui, -apple-system, system-ui, "Segoe UI", Roboto, Ubuntu, "Helvetica Neue", sans-serif',
    display: data?.custom?.isHidden ? 'none' : undefined,
    ...(isDisabled ? { opacity: 0.5 } : {})
    // backgroundColor: isDisabled ? 'red' : 'transparent'
  }),
  indicatorsContainer: provided => ({
    ...provided,
    alignItems: 'flex-start'
  }),
  dropdownIndicator: provided => ({
    ...provided,
    padding: 5,
    cursor: 'pointer'
  }),
  clearIndicator: provided => ({
    ...provided,
    padding: 5,
    cursor: 'pointer'
  }),
  multiValue: (provided, { data }) => ({
    ...provided,
    background: 'none',
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: data?.custom?.autoFilled ? '#2F2' : theme.colors.borderDark,
    borderRadius: 14,
    overflow: 'hidden',
    fontWeight: 'bold'
  }),
  multiValueLabel: provided => ({
    ...provided,
    color: theme.colors.borderDark
  }),
  multiValueRemove: provided => ({
    ...provided,
    borderRadius: 10,
    background: theme.colors.borderDark,
    color: '#fff',
    padding: 0,
    height: 14,
    width: 14,
    alignSelf: 'center',
    marginRight: 3,
    marginLeft: 4,
    cursor: 'pointer'
  }),
  indicatorSeparator: provided => ({
    ...provided,
    height: 22
  }),
  control: (provided, { isMulti }) => ({
    ...provided,
    borderWidth: 0,
    minHeight: isMulti && !shortMultiSelect ? 100 : 32,
    alignItems: isMulti && !shortMultiSelect ? 'flex-start' : 'center',
    backgroundColor: 'transparent',
    boxShadow: 0
  }),
  menu: provided => ({
    ...provided,
    zIndex: 9999,
    width: 'max-content',
    minWidth: '100%',
    marginTop: 0,
    ...menuStyle
  }),
  menuPortal: provided => ({
    ...provided,
    marginTop: 8,
    zIndex: 9999
  }),
  singleValue: (provided, state) => {
    // TODO: ????
    const opacity = state.isDisabled ? 0.5 : 1
    return { ...provided, opacity }
  }
})
