import { gql, useQuery } from '@apollo/client'

interface ILastExtractionQuery {
  getLastExtractionPointers: {
    id: string
    date: string
    type: string
  }[]
}

const lastExtractionQuery = gql`
  query getLastExtraction($extractionTypes: [String!]!) {
    getLastExtractionPointers(extractionTypes: $extractionTypes) {
      id
      date
      type
    }
  }
`

export function useLastExtraction(type?: string) {
  const { data, loading, error } = useQuery<ILastExtractionQuery>(
    lastExtractionQuery,
    {
      variables: {
        extractionTypes: [type]
      },
      skip: !type
    }
  )

  return {
    lastExtraction: data?.getLastExtractionPointers?.[0],
    loading,
    error
  }
}

const getAllLastExtractionTimesQuery = gql`
  query getAllLastExtractionTimes {
    getAllLastExtractionTimes {
      id
      salesforceTimestamp
      salesforceId
      extractionType
    }
  }
`

export interface ILastExtractionTime {
  id: string
  extractionType: string
  salesforceTimestamp?: string
  salesforceId?: string
}

interface IGetAllLastExtractionTimes {
  getAllLastExtractionTimes: ILastExtractionTime[]
}

export const useExtractionTimes = () => {
  const {
    data,
    loading,
    refetch,
    error
  } = useQuery<IGetAllLastExtractionTimes>(getAllLastExtractionTimesQuery)

  return {
    extractionTimes: data?.getAllLastExtractionTimes,
    loading,
    refetch,
    error
  }
}
