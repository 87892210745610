import { useEffect } from 'react'
import Firebase from '../Firebase'
import { getAuth as getFBAuth } from 'firebase/auth'
import useCurrentUser from './useCurrentUser'

const useCurrentUserLoader = () => {
  const { setFirebase, firebaseToken, firebaseInitialized } = useCurrentUser()

  useEffect(() => {
    const clearCallback = getFBAuth(Firebase).onAuthStateChanged(
      (user: any) => {
        setFirebase(user ? user.getIdToken() : null)
      }
    )

    return () => clearCallback()
  }, [])

  return { firebaseInitialized, firebaseToken }
}

export default useCurrentUserLoader
