import React from 'react'
// import { useColorScheme } from 'react-native-appearance'
import { ThemeProvider as NativeThemeProvider } from 'styled-components/native'
// eslint-disable-next-line no-restricted-imports
import { ThemeProvider as WebThemeProvider } from 'styled-components'
import lightTheme /*, { dark as darkTheme } */ from '../../constants/Theme'

const ThemeProvider = ({ children }: any) => {
  // wsrast: commenting these references out because `activeTheme` is not referenced
  // anywhere, so it's throwing linting errors.
  // const scheme = useColorScheme()
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  // const activeTheme = scheme === 'dark' ? darkTheme : lightTheme
  return (
    <NativeThemeProvider theme={lightTheme}>
      <WebThemeProvider theme={lightTheme}>{children}</WebThemeProvider>
    </NativeThemeProvider>
  )
}

export default ThemeProvider
