import gql from 'graphql-tag'

const profilePictureFragment = gql`
  fragment profilePictureFragment on ProfilePicture {
    id
    image {
      id
      cloudinaryId
    }
    cropping
  }
`

export interface ProfilePictureType {
  id: string
  cropping: string
  image: {
    id: string
    cloudinaryId: string
  }
}

export default profilePictureFragment
