import { addMonths } from 'date-fns'
import { zonedTimeToUtc, utcToZonedTime, format } from 'date-fns-tz'

const timeZone = 'America/New_York'

/**
 * Get the default date set to 11:59 PM EST exactly 1 month from now, stored in UTC.
 * @returns {Date} The UTC date.
 */
export function getDefaultDate() {
  const now = new Date()

  const oneMonthLater = addMonths(now, 1)

  const estDate = new Date(oneMonthLater)
  estDate.setHours(23, 59, 0, 0)

  return zonedTimeToUtc(estDate, timeZone)
}

/**
 * Format a given date (either a Date object or a date-time string) to 'MM-dd-yy HH:mm' in EST.
 * @param {Date | string} date - The date to be formatted.
 * @returns {string} The formatted date string in EST.
 */
export function formatToEst(
  date: Date | string,
  dateFormat = 'MM-dd-yy HH:mm'
) {
  const dateObj = typeof date === 'string' ? new Date(date) : date

  // Convert to EST
  const estDate = utcToZonedTime(dateObj, timeZone)

  // Format the date
  return format(estDate, dateFormat, { timeZone })
}

export function convertToZondedTime(date?: Date | string) {
  if (!date) return

  const dateObj = typeof date === 'string' ? new Date(date) : date

  return utcToZonedTime(dateObj, timeZone)
}
