import React, { useState } from 'react'
import Dropdown from '../../components/common/Dropdown'
import { Flex } from '../../components/FlexBox'
import Button from '../../ui-library/Button'

export interface IAddBlock {
  onPress: (value: string) => void
}

export const AddBlock: React.FC<IAddBlock> = ({ onPress }) => {
  const [selection, setSelection] = useState<string>('content-block')
  return (
    <Flex flexDirection="row" alignItems="center">
      <Dropdown
        options={[
          { label: 'Content Block', value: 'content-block' },
          { label: 'Divider', value: 'divider' },
          { label: 'Container', value: 'container' },
          {
            label: 'Confidentiality Statement',
            value: 'confidentiality-statement'
          },
          {
            label: 'Text Block',
            value: 'text-block'
          }
        ]}
        value={selection}
        placeholder={'Select block template'}
        onBlur={() => {}}
        onSelect={value => setSelection(value)}
        style={{ marginBottom: 0, marginRight: 8 }}
      />
      <Button
        title="Add Block"
        onPress={() => onPress(selection)}
        style={{ borderRadius: 999, marginRight: 8 }}
      />
    </Flex>
  )
}
