import React, { useRef, useState, useEffect, useCallback } from 'react'
import { ScrollView } from 'react-native'
import { StackScreenProps } from '@react-navigation/stack'
import { useFormik } from 'formik'
import useDocumentQuery from './queries/useDocumentQuery'
import { publishSchema, saveSchema, previewSchema } from './validators'
import useToast, { ToastType } from '../../hooks/useToast'
import useToggle from '../../hooks/useToggle'
import useTranslation from '../../hooks/useTranslation'
import useFormikHelper from '../../hooks/useFormikHelper'
import usePreventNavOnDirtyState from '../../hooks/usePreventNavigationOnDirtyState'
import { H4 } from '../../components/common/Text'
import {
  AppStackParamList,
  DocumentTypeEnum,
  RawConvertStatusEnum,
  ScanStatusEnum
} from '../../types'
import { Flex } from '../../components/FlexBox'
import Dropdown from '../../components/common/Dropdown'
import RichTextEditor, {
  convertFromDraftToJSON,
  convertFromJSONToDraft,
  createEmptyRichTextEditorState
} from '../../components/RichTextEditor'
import TextInput from '../../ui-library/TextInput'
import openPreview from '../../utils/openPreview'
import FileDrop from '../../components/common/form/FileDrop'
import HeroImageDrop from '../../components/common/form/HeroImageDrop'
import {
  mapAudience,
  mapTopics,
  mapHeroImage,
  getCloudinaryImage,
  mapTaggedPeople,
  mapSharedFile,
  mapGroups,
  mapGatherings,
  mapPeople
} from '../../utils/formHelpers'
import useDocumentUpdatedSubscription from './subscriptions/useDocumentUpdatedSubscription'
import useUpsertFileAttachmentMutation from './mutations/useUpsertFileAttachmentMutation'
import useUpsertDocumentMutation from './mutations/useUpsertDocumentMutation'
import useDeleteDocumentMutation from './mutations/useDeleteDocumentMutation'
import {
  AudienceMultiselect,
  ContentTopicMultiselect,
  PublishCheckbox,
  PublishDateTimeInput,
  GatheringSelect,
  ErrorText,
  ValidationModal,
  Footer,
  ShareCheckbox,
  PeopleSelect
} from '../../components/common/form'
import PersonTagManager from '../../components/common/form/PersonTagManager'
import useCopyContent, { ContentTypeEnum } from '../../hooks/useCopyContent'
import useFeatureFlag from '../../hooks/useFeatureFlag'
import useCurrentUser from '../../hooks/useCurrentUser'
import useLinkToScreen from '../../hooks/useLinkToScreen'
import {
  useInitialGatheringOptions,
  useInitialPeopleOptions
} from '../../hooks/useInitialOptions'
import { ScreenContainerWithItemHeader } from '../../components/layout/ScreenContainer'
import { useSetRecoilState } from 'recoil'
import deletedItemAtom from '../../recoil/atoms/deletedItemAtom'
import useFileAttachmentUpdatedSubscription from './subscriptions/useFileAttachmentUpdatedSubscription'
import { AudienceType } from '../../components/common/form/hooks/useAudienceQuery'
import useConfigQuery from './queries/useConfigQuery'

interface TypeOption {
  label: string
  value: string
  custom?: object
}

const DEFAULT_FORM_VALUES: any = {
  id: null,
  body: createEmptyRichTextEditorState(),
  title: '',
  subtitle: '',
  type: null,
  gathering: null,
  heroImage: null,
  published: false,
  shareable: false,
  communities: [],
  groups: [],
  gatherings: [],
  people: [],
  topics: [],
  taggedPeople: [],
  publishDate: null,
  sharedFile: null
}

const touchFields: any = {
  title: true,
  body: true,
  subtitle: true,
  type: true,
  gathering: true,
  heroImage: true,
  communities: true,
  groups: true,
  gatherings: true,
  people: true,
  publishDate: true,
  topics: true
}

const mapFormValues = (
  defaultValues,
  savedData,
  communities,
  groups,
  gatherings,
  people,
  heroImage,
  topics,
  taggedPeople,
  sharedFile
) => {
  return {
    ...defaultValues,
    id: savedData?.id || defaultValues?.id,
    title: savedData?.title || defaultValues?.title,
    subtitle: savedData?.subtitle || defaultValues?.subtitle,
    body: savedData?.body
      ? convertFromJSONToDraft(savedData?.body)
      : defaultValues.body,
    type: savedData?.type || defaultValues?.type,
    gathering: savedData?.event?.id || defaultValues?.gathering,
    published: savedData?.published || defaultValues?.published,
    publishDate: savedData?.publishDate || defaultValues?.publishDate,
    shareable: savedData?.shareable || defaultValues?.shareable,
    heroImage,
    communities,
    groups,
    gatherings,
    people,
    topics,
    taggedPeople,
    sharedFile
  }
}

type Props = StackScreenProps<
  AppStackParamList,
  'DocumentEdit' | 'DocumentCreate'
>

interface NewFileAttachment {
  id?: string
  fileName: string
  cloudinaryId: string
  isRawConvertRequired?: boolean
  isVirusScanRequired?: boolean
}

const DocumentEditScreen = ({ navigation, route }: Props) => {
  const { currentUserId } = useCurrentUser()
  const linkToScreen = useLinkToScreen()
  const { t } = useTranslation()
  const { showToast } = useToast()
  const isSubmittingRef = useRef(false)
  const setDeletedItem = useSetRecoilState(deletedItemAtom)
  const isSharedFileEnabled = useFeatureFlag('sharedFilesOnTextObjects')
  const isContentSharingEnabled = useFeatureFlag('contentSharing')
  const isContentPublishingEnabled = useFeatureFlag('enhancedContentPublishing')

  // Copy Content
  const {
    contentCopied,
    isContentCopied,
    setValuesToCopy,
    resetCopyContent
  } = useCopyContent()

  const [fileId, setFileId] = useState()
  const { data, loading } = useDocumentQuery(route?.params?.id)
  const { documentFileSizeLimit, documentAllowedFileTypes } = useConfigQuery()

  const {
    isOn: validatingPreview,
    turnOn: turnOnValidatingPreview,
    turnOff: turnOffValidatingPreview
  } = useToggle(false)

  const {
    isOn: showValidationModal,
    turnOn: turnOnValidationModal,
    turnOff: turnOffValidationModal
  } = useToggle(false)

  useEffect(() => {
    if (validatingPreview) {
      formik.setTouched(touchFields, false)
      formik.validateForm()
      turnOffValidatingPreview()
    }
  }, [validatingPreview])

  const [invalidatedByEdit, setInvalidatedByEdit] = useState(false)
  const [invalidatedByEditUser, setInvalidatedByEditUser] = useState<
    any | null
  >(null)

  // Ref
  const scrollRef = useRef<ScrollView>()
  const scrollToEnd = (timeout = 300) => {
    if (scrollRef?.current) {
      setTimeout(() => {
        scrollRef?.current?.scrollToEnd?.({ animated: true })
      }, timeout)
    }
  }

  useEffect(() => {
    if (invalidatedByEdit) {
      showInvalidatedByEditMessage()
    }
  }, [invalidatedByEdit, invalidatedByEditUser])

  useDocumentUpdatedSubscription(
    route?.params?.id,
    ({ data: subscriptionData }) => {
      const {
        data: {
          documentUpdated: { updatedBy }
        }
      } = subscriptionData

      if (!updatedBy || updatedBy.user.id !== currentUserId) {
        setInvalidatedByEdit(true)
        setInvalidatedByEditUser(updatedBy)
      }
    }
  )

  const checkFileStatusValid = file => {
    const isPdf = file?.fileName?.includes('.pdf')

    return file &&
      file.scanStatus === ScanStatusEnum.Approved &&
      file.rawConvertStatus !== RawConvertStatusEnum.Failed &&
      !isPdf
      ? file.rawConvertStatus === RawConvertStatusEnum.Complete
      : true
  }

  useFileAttachmentUpdatedSubscription(fileId, ({ data: subscriptionData }) => {
    const {
      data: { fileAttachmentUpdated: file }
    } = subscriptionData

    if (checkFileStatusValid(file)) {
      formik.setFieldValue(
        'sharedFile',
        mapSharedFile(
          DEFAULT_FORM_VALUES.sharedFile,
          { ...(data?.document || {}), file },
          checkFileStatusValid(file)
        )
      )
    }
  })

  const isEditing: boolean = !!route?.params?.id

  const showInvalidatedByEditMessage = () => {
    if (invalidatedByEditUser) {
      const { firstName, lastName } = invalidatedByEditUser
      showToast(
        t('library:document:updatedByPerson', {
          name: `${firstName} ${lastName}`
        }),
        undefined,
        -1
      )
    } else {
      showToast(t('library:document:updated'))
    }
  }

  const upsertDocument = useUpsertDocumentMutation({
    onCompleted: results => {
      if (isContentCopied) {
        resetCopyContent()
      }
      isSubmittingRef.current = false
      formik.setSubmitting(false)
      const resultId = results?.upsertDocument?.id
      if (resultId) {
        showToast(t('forms:saveSuccess'))
        if (!isEditing) {
          navigation.replace('DocumentEdit', { id: resultId })
        }
      }
      handleFormInitOnSave({ document: results?.upsertDocument })
    },
    onError: () => showToast(t('forms:saveFail'))
  })

  const deleteDocument = useDeleteDocumentMutation({
    onCompleted: () => {
      showToast(t('forms:deleteSuccess'))
      setDeletedItem(true)
      linkToScreen('LibraryList')
    },
    onError: () => showToast(t('forms:deleteFail'), ToastType.ERROR),
    update(cache) {
      const objectId = cache.identify(data.document)
      cache.evict({ id: objectId })
    }
  })

  const upsertFileAttachment = useUpsertFileAttachmentMutation({
    onCompleted: results => {
      if (results.upsertFileAttachment && results.upsertFileAttachment.id) {
        setFileId(results.upsertFileAttachment.id)
      }
    },
    onError: () => showToast(t('forms:saveFail'), ToastType.ERROR)
  })

  const formik = useFormik({
    initialValues: DEFAULT_FORM_VALUES,
    validate: values => {
      let errors = {}
      try {
        if (validatingPreview) {
          previewSchema.validateSync(values, { abortEarly: false })
          openPreview(`document/${formik?.values?.id}`)
        } else if (values.published || validatingPreview) {
          publishSchema.validateSync(values, { abortEarly: false })
        } else {
          saveSchema.validateSync(values, { abortEarly: false })
        }
      } catch (e: any) {
        if (isSubmittingRef.current || validatingPreview) {
          turnOnValidationModal()
        }

        e.inner.forEach(error => {
          errors[error.path] = error.message
        })
      }
      return errors
    },
    onSubmit: values => {
      const variables = {
        ...values,
        publishDate: values.publishDate
          ? // @ts-ignore publishDate isn't null, due to ternary operator check
            new Date(values.publishDate)
          : null,
        body: convertFromDraftToJSON(values?.body),
        type: values?.type || null,
        eventId: values?.gathering || null,
        id: values?.id,
        communities: values?.communities || null,
        groups: values?.groups || null,
        events: values.gatherings || null,
        people: values.people || null,
        cloudinaryImage: getCloudinaryImage(values),
        fileId: values?.sharedFile?.publicId,
        taggedPeople: values.taggedPeople.map(({ personId, source }) => ({
          personId,
          source
        })),
        topics: values.topics.map(({ id }) => id)
      }

      formik.resetForm({ values })

      upsertDocument({ variables })
    }
  })

  usePreventNavOnDirtyState(formik)

  const {
    handleChangeHeroImage,
    handleHeroImageError,
    handleChangeSharedFile,
    handleSharedFileError,
    handleSelectGathering,
    handleSelectGatherings,
    handleChangePublishDate,
    handleChangePublished,
    handleChangeShareable,
    handleSelectCommunities,
    handleSelectGroups,
    handleSelectContentTopic,
    initialPublishDateTime,
    handleTagPeople,
    handleSelectPeople
  } = useFormikHelper(formik, touchFields)

  isSubmittingRef.current = formik.isSubmitting

  // body and type are one-offs for now
  const handleChangeBody = useCallback(
    state => {
      formik.setFieldValue('body', state)
    },
    [formik?.values?.body]
  )

  const handleSelectType = useCallback(
    value => formik.setFieldValue('type', value || null),
    [formik?.values?.body]
  )

  const handleDeleteDocument = useCallback(() => {
    const answer = confirm(t('library:document:confirmDelete'))
    if (answer) {
      deleteDocument({ variables: { id: data?.document?.id } })
    }
  }, [data?.document?.id])

  const handleSaveDocument = (e: any) => {
    if (invalidatedByEdit) {
      showInvalidatedByEditMessage()
      return
    }

    isSubmittingRef.current = true
    formik.handleSubmit(e)
  }

  const handleCopyContent = useCallback(() => {
    if (formik.dirty) {
      turnOnValidationModal()
      return
    }
    if (!loading && data?.document) {
      isSubmittingRef.current = true
      setValuesToCopy(data, ContentTypeEnum.document)
      navigation.navigate('DocumentCreate')
    }
  }, [formik])

  const handleFormInitOnSave = data => {
    if (data && data.document) {
      const savedData = data.document
      const audienceValue = mapAudience(
        DEFAULT_FORM_VALUES.communities,
        savedData
      )
      const groupsValue = mapGroups(DEFAULT_FORM_VALUES.groups, savedData)
      const gatheringsValue = mapGatherings(
        DEFAULT_FORM_VALUES.gatherings,
        savedData
      )
      const peopleValue = mapPeople(DEFAULT_FORM_VALUES.people, savedData)
      const topicValues = mapTopics(DEFAULT_FORM_VALUES.topics, savedData)
      const heroImageValue = mapHeroImage(
        DEFAULT_FORM_VALUES.heroImage,
        savedData
      )
      const sharedFileValue = mapSharedFile(
        DEFAULT_FORM_VALUES.sharedFile,
        savedData,
        checkFileStatusValid(savedData.file)
      )
      const taggedPeopleValue = mapTaggedPeople(
        DEFAULT_FORM_VALUES.taggedPeople,
        savedData?.personTags
      )
      const newValues = mapFormValues(
        DEFAULT_FORM_VALUES,
        savedData,
        audienceValue,
        groupsValue,
        gatheringsValue,
        peopleValue,
        heroImageValue,
        topicValues,
        taggedPeopleValue,
        sharedFileValue
      )

      setFileId(data.document.file?.id)
      // reset in order to properly init new defaults and reset dirty status
      formik.resetForm({ values: newValues, touched: {} })
      if (isContentCopied) {
        formik.setFieldValue('title', `Copy of ${data.document.title}`)
        showToast(t('forms:copySuccess'))
      }
    }
  }

  const handleSharedFileUploaded = data => {
    if (data && data.filename && data.public_id) {
      const { filename, moderation, public_id, asset_id, info } = data
      const variables: NewFileAttachment = {
        id: asset_id,
        fileName: filename,
        cloudinaryId: public_id
      }

      if (Array.isArray(moderation)) {
        const virusScanFound = moderation.find(
          el => el.kind === 'perception_point'
        )

        if (virusScanFound && virusScanFound.status === 'pending') {
          variables.isVirusScanRequired = true
        }
      }

      if (info && info.raw_convert) {
        const rawConvertFound = info.raw_convert.aspose

        if (rawConvertFound && rawConvertFound.status === 'pending') {
          variables.isRawConvertRequired = true
        }
      }

      upsertFileAttachment({ variables })
    } else {
      throw new Error('Failed to store File Attachment')
    }
  }

  const uploadingHeroImage =
    formik?.values?.heroImage?.fileName && !formik?.values?.heroImage?.publicId

  useEffect(() => {
    if (!isContentCopied) {
      handleFormInitOnSave(data)
    }
  }, [data, isContentCopied])

  useEffect(() => {
    if (!data && isContentCopied && contentCopied?.document) {
      handleFormInitOnSave(contentCopied)
    }
  }, [contentCopied, isContentCopied])

  const errRequiredFieldsMissing = Object.values(formik.errors).some(
    v => v === 'Required'
  )

  const errRequiredAtLeastAudienceType = Object.values(formik.errors).some(
    v => v === 'atLeastAudienceType'
  )

  const errCopyWithUnsavedChanges = isEditing && formik.dirty
  const savedGatheringId = data?.document?.event?.id
  const autoFillFromEvent = savedGatheringId !== formik.values.gathering
  const isSharedFileSelected =
    formik.values.type === DocumentTypeEnum.SharedFile

  const typesList: TypeOption[] = []
  for (let type in DocumentTypeEnum) {
    const value = DocumentTypeEnum[type]

    typesList.push({
      value,
      label: t(`library:document:types:${value}`),
      custom: { isHidden: value === DocumentTypeEnum.SharedFile }
    })
  }

  // Initial options hooks

  const { initialPeopleOptions } = useInitialPeopleOptions(
    isContentCopied ? contentCopied?.document : data?.document
  )

  const { initialGatheringOptions } = useInitialGatheringOptions(
    isContentCopied ? contentCopied?.document : data?.document
  )

  return (
    // Main Container
    <ScreenContainerWithItemHeader
      screenCategory="DocumentEdit"
      defaultBack="LibraryList"
    >
      <Flex flexDirection="column" flexBasis="100%" p="20px">
        {/* Title Row */}
        <Flex flexDirection="row">
          <H4>
            {isEditing
              ? t('library:document:editTitle')
              : t('library:document:createTitle')}
          </H4>
        </Flex>

        {/* Scroll For Form */}
        {/* @ts-ignore */}
        <ScrollView ref={scrollRef}>
          {/* Row for main inputs and hero image */}
          <Flex
            flexDirection="row"
            flexWrap="wrap"
            flex={1}
            mt="20px"
            style={{ backgroundColor: 'green' }}
          >
            {/* Column for main inputs */}
            <Flex
              flexDirection="column"
              flex={2}
              mr="15px"
              style={{ minWidth: '50%' }}
            >
              {/* Inputs Row */}
              <Flex
                flexDirection="row"
                flexWrap="wrap"
                alignItems="flex-start"
                justifyContent="flex-start"
                alignContent="flex-start"
              >
                <TextInput
                  placeholder={t('forms:title')}
                  value={formik.values.title}
                  onChangeText={formik.handleChange('title')}
                  onBlur={formik.handleBlur('title')}
                  hasError={!!formik.errors.title && !!formik.touched.title}
                  style={{ flex: 2, minWidth: '35%', marginRight: 10 }}
                />
                <PublishDateTimeInput
                  value={
                    formik.values.publishDate
                      ? // @ts-ignore null publishDate is protected
                        new Date(formik.values.publishDate)
                      : null
                  }
                  onBlur={formik.handleBlur('publishDate')}
                  onChangeDate={handleChangePublishDate}
                  hasError={
                    !!formik.errors.publishDate && !!formik.touched.publishDate
                  }
                  initialDate={initialPublishDateTime}
                />
                <PublishCheckbox
                  handlePressed={handleChangePublished}
                  published={formik.values.published}
                />
                <TextInput
                  placeholder={t('forms:subtitle')}
                  value={formik.values.subtitle}
                  onChangeText={formik.handleChange('subtitle')}
                  onBlur={formik.handleBlur('subtitle')}
                  hasError={
                    !!formik.errors.subtitle && !!formik.touched.subtitle
                  }
                  style={{ minWidth: '50%', flex: 2, marginRight: 10 }}
                />
                <Dropdown
                  options={typesList}
                  placeholder={t('forms:type')}
                  onSelect={handleSelectType}
                  onBlur={formik.handleBlur('type')}
                  value={formik.values.type}
                  hasError={!!formik.errors.type && !!formik.touched.type}
                  style={{ minWidth: '30%' }}
                  isClearable={!isSharedFileSelected}
                  isDisabled={isSharedFileSelected}
                />
                <GatheringSelect
                  onSelect={handleSelectGathering}
                  onBlur={formik.handleBlur('gathering')}
                  value={formik.values.gathering}
                  hasError={
                    !!formik.errors.gathering && !!formik.touched.gathering
                  }
                  editingId={data?.document?.event?.id}
                />
                {isContentSharingEnabled ? (
                  <ShareCheckbox
                    handlePressed={handleChangeShareable}
                    shareable={formik.values.shareable}
                  />
                ) : null}

                <PersonTagManager
                  values={formik.values.taggedPeople}
                  onValueChange={handleTagPeople}
                  {...(autoFillFromEvent && {
                    eventId: formik.values.gathering
                  })}
                />
                <Flex
                  width="100%"
                  flexWrap="wrap"
                  flexDirection="row"
                  justifyContent="space-between"
                >
                  <AudienceMultiselect
                    placeholder={t('forms:audienceType:communities')}
                    onSelect={handleSelectCommunities}
                    value={formik?.values?.communities}
                    onBlur={formik?.handleBlur('communities')}
                    hasError={
                      !!formik?.errors?.communities &&
                      !!formik?.touched?.communities
                    }
                  />
                  {isContentPublishingEnabled && (
                    <AudienceMultiselect
                      audience={AudienceType.GROUPS}
                      placeholder={t('forms:audienceType:groups')}
                      onSelect={handleSelectGroups}
                      value={formik?.values?.groups}
                      onBlur={formik?.handleBlur('groups')}
                      hasError={
                        !!formik?.errors?.groups && !!formik?.touched?.groups
                      }
                    />
                  )}
                  {isContentPublishingEnabled && (
                    <GatheringSelect
                      isMulti
                      onSelect={handleSelectGatherings}
                      onBlur={formik.handleBlur('gatherings')}
                      value={formik.values.gatherings}
                      hasError={
                        !!formik.errors.gatherings &&
                        !!formik.touched.gatherings
                      }
                      editingId={data?.media?.event?.id}
                      initialOptions={initialGatheringOptions}
                    />
                  )}
                  {isContentPublishingEnabled && (
                    <PeopleSelect
                      value={formik.values.people}
                      onSelect={handleSelectPeople}
                      onBlur={formik.handleBlur('people')}
                      hasError={
                        !!formik.errors.people && !!formik.touched.people
                      }
                      styleContainer={{ marginRight: 'auto' }}
                      initialOptions={initialPeopleOptions}
                    />
                  )}
                  <ContentTopicMultiselect
                    onSelect={handleSelectContentTopic}
                    value={formik.values.topics}
                    onBlur={formik.handleBlur('topics')}
                    hasError={!!formik.errors.topics && !!formik.touched.topics}
                    {...(autoFillFromEvent && {
                      eventId: formik.values.gathering
                    })}
                  />
                </Flex>
              </Flex>

              {/* Row For Rich Text Editor */}
              <Flex flexDirection="row" flexWrap="wrap">
                <RichTextEditor
                  editorState={formik.values.body}
                  onChange={handleChangeBody}
                  placeholder={t('forms:description')}
                  onBlur={formik.handleBlur('body')}
                  style={{ flex: 1, marginRight: 20, height: '250px' }}
                  hasError={!!formik.errors.body && !!formik.touched.body}
                  scrollToEnd={scrollToEnd}
                />
              </Flex>
            </Flex>

            {/* Column For Hero Image */}
            {documentFileSizeLimit > 0 && (
              <Flex flexDirection="column" alignItems="center" flexWrap="wrap">
                <HeroImageDrop
                  value={formik?.values?.heroImage}
                  hasError={
                    !!formik?.errors?.heroImage && !!formik?.touched?.heroImage
                  }
                  setValue={handleChangeHeroImage}
                  setError={handleHeroImageError}
                  style={{
                    minWidth: '400px',
                    height: 250,
                    marginBottom: '15px'
                  }}
                  cloudinaryDir="documentHeroImages"
                  documentFileSizeLimit={documentFileSizeLimit}
                />
                {isSharedFileEnabled && (
                  <FileDrop
                    documentFileSizeLimit={documentFileSizeLimit}
                    allowedFileTypes={documentAllowedFileTypes}
                    value={formik?.values?.sharedFile}
                    hasError={
                      !!formik?.errors?.sharedFile &&
                      !!formik?.touched?.sharedFile
                    }
                    setValue={handleChangeSharedFile}
                    setError={handleSharedFileError}
                    handleFileUploaded={handleSharedFileUploaded}
                    style={{
                      minWidth: '400px',
                      height: 250,
                      marginBottom: '15px'
                    }}
                    cloudinaryDir="documentSharedFiles"
                    isRawConvertRequired
                    isVirusScanRequired
                  />
                )}
              </Flex>
            )}
          </Flex>
        </ScrollView>

        <Footer
          isDeleteDisabled={!isEditing}
          handlePressDelete={handleDeleteDocument}
          isPreviewDisabled={!!formik.dirty || !isEditing}
          handlePressPreview={turnOnValidatingPreview}
          isSaveDisabled={uploadingHeroImage || !formik.dirty}
          handlePressSave={handleSaveDocument}
          isCopyContentDisabled={!isEditing}
          handlePressCopyContent={handleCopyContent}
        />

        <ValidationModal
          isOpen={showValidationModal}
          handleClose={turnOffValidationModal}
          children={
            <>
              {errRequiredAtLeastAudienceType && (
                <ErrorText>{t('forms:atLeastAudienceType')}</ErrorText>
              )}
              {errRequiredFieldsMissing && (
                <ErrorText>{t('forms:missingRequiredFields')}</ErrorText>
              )}
              {errCopyWithUnsavedChanges && (
                <ErrorText>{t('forms:errCopyWithUnsavedChanges')}</ErrorText>
              )}
            </>
          }
        />
      </Flex>
    </ScreenContainerWithItemHeader>
  )
}

export default DocumentEditScreen
