import { Media } from './Media'

export const getBlockRendererFn = (
  editor,
  getEditorState,
  onChange
) => contentBlock => {
  const type = contentBlock.getType()

  switch (type) {
    case 'atomic':
      return {
        component: Media,
        editable: true,
        props: {
          editor,
          getEditorState,
          onChange
        }
      }

    default:
      return null
  }
}
