import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import shared from '../locales/shared.json'
import en from '../locales/en.json'

i18n.use(initReactI18next).init({
  fallbackLng: 'en',
  ns: ['common'],
  defaultNS: 'common',
  lng: 'en',
  interpolation: {
    escapeValue: false
  },

  react: {
    useSuspense: false,
    wait: true,
    bindI18n: ''
  },

  resources: {
    en: { ...en, common: { ...shared.common, ...en.common } }
  }
})

export default i18n
