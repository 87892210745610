import {
  convertToRaw,
  EditorState,
  convertFromRaw,
  RawDraftContentState,
  convertFromHTML,
  ContentState,
  DefaultDraftBlockRenderMap
} from 'draft-js'
import { createEmptyRichTextEditorState } from '../../components/RichTextEditor'
import linkDecorator from '../../components/RichTextEditor/linkDecorator'
import { MediaUploaderValue } from '../media/components/MediaUploader'
import {
  ColumnStateType,
  ColumnType,
  IBlockColumnState,
  IBlockState,
  IEditorState,
  IEventAddressResult,
  IEventResult,
  IMediaState,
  IPageContent,
  ISectionState,
  ITextColumnValue,
  ProgramGuestState
} from './types'
import * as Immutable from 'immutable'

const generateRandomUid = (length: number = 12) => {
  const chars = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ'
  let result = ''
  for (let i = length; i > 0; --i) {
    result += chars[Math.floor(Math.random() * chars.length)]
  }
  return result
}

// TODO: this is temporary to get it working for now
export function transformEditorStateToPayload(state: IEditorState) {
  return {
    sections: state.sections.map(section => ({
      uid: section.uid ?? undefined,
      title: section.title ?? undefined,
      live: !!section.live,
      exportToPdf: !!section.exportToPdf,
      includeAttendeesInPdf: !!section.includeAttendeesInPdf,
      blocks: section.blocks.map(block => ({
        uid: block.uid ?? undefined,
        live: !!block.live,
        exportToPdf: !!block.exportToPdf,
        type: block.type ?? undefined,
        columns: block.columns.map(column => {
          switch (column.type) {
            case ColumnStateType.Text:
              return {
                type: ColumnType.Text,
                content: {
                  text: (column.state as ITextColumnValue).text
                }
              }
            case ColumnStateType.Media:
              if (!column.state) {
                return {
                  type: ColumnType.Image,
                  content: { value: {} }
                }
              }
              return {
                type: ((column.state as IMediaState)
                  .value as MediaUploaderValue)?.type,
                content: {
                  ...column.state
                }
              }
            case ColumnStateType.Image:
              return {
                type: column.type,
                content: {
                  ...column.state
                }
              }

            case ColumnStateType.Markdown:
              const content = (column.state as EditorState).getCurrentContent()
              const rawObject = convertToRaw(content)
              return {
                type: column.type,
                content: rawObject
              }
            case ColumnStateType.Custom:
              if (column.subType === 'program-guest') {
                const bio = ((column.state as ProgramGuestState)
                  .bio as EditorState).getCurrentContent()
                const rawObject = convertToRaw(bio)
                return {
                  type: column.type,
                  subType: column.subType,
                  content: {
                    ...column.state,
                    bio: rawObject
                  }
                }
              } else if (column.subType === 'pageBreak') {
                return {
                  type: column.type,
                  subType: column.subType,
                  content: {
                    ...column.state
                  }
                }
              }
              throw new Error('Unsupported column subType: ' + column.subType)
            default:
              throw new Error('Unsupported column type: ' + column.type)
          }
        })
      }))
    }))
  }
}

export function transformPayloadToEditorState(
  payload: IPageContent
): IEditorState {
  return {
    sections: payload.sections.map(section => ({
      uid: section.uid ?? generateRandomUid(),
      title: section.title ?? undefined,
      live: !!section.live,
      exportToPdf: !!section.exportToPdf,
      includeAttendeesInPdf: !!section.includeAttendeesInPdf,
      blocks: section.blocks.map(block => ({
        uid: block.uid ?? generateRandomUid(),
        live: !!block.live,
        exportToPdf: !!block.exportToPdf,
        type: (block.type ?? undefined) as IBlockState['type'],
        columns: block.columns.map(column => {
          switch (column.type) {
            case ColumnType.Text:
              return {
                type: ColumnStateType.Text,
                state: { text: column.content.text }
              }
            case ColumnType.Video:
            case ColumnType.Audio:
              return {
                type: ColumnStateType.Media,
                state: { ...(column.content as IMediaState) }
              }
            case ColumnType.Image:
              return {
                type: ColumnStateType.Image,
                state: { ...(column.content as IMediaState) }
              }
            case ColumnType.Markdown:
              const content = convertFromRaw(
                column.content as RawDraftContentState
              )
              const state = EditorState.createWithContent(
                content,
                linkDecorator
              )
              return {
                type: ColumnStateType.Markdown,
                state
              }
            case ColumnType.Custom:
              if (column.subType === 'program-guest') {
                const bioContent = convertFromRaw(
                  column.content.bio as RawDraftContentState
                )
                return {
                  ...column,
                  type: ColumnStateType.Custom,
                  state: {
                    ...(column.content as ProgramGuestState),
                    bio: EditorState.createWithContent(
                      bioContent,
                      linkDecorator
                    )
                  }
                }
              } else if (column.subType === 'pageBreak') {
                return {
                  ...column,
                  type: ColumnStateType.Custom,
                  state: {
                    ...column.content
                  }
                }
              }
              throw new Error('Unsupported column subType: ' + column.subType)
            default:
              throw new Error('Unsupported column type: ' + column.type)
          }
        })
      }))
    }))
  }
}

const getDefaultHeaderSection = (event: IEventResult): ISectionState => {
  return {
    uid: 'header',
    live: true,
    exportToPdf: true,
    includeAttendeesInPdf: false,
    blocks: [
      {
        uid: 'title',
        live: true,
        exportToPdf: true,
        columns: [
          {
            type: ColumnStateType.Text,
            state: { text: event.name }
          }
        ]
      },
      {
        uid: 'hero-image',
        live: true,
        exportToPdf: false,
        columns: [
          {
            type: ColumnStateType.Image,
            state: null
          }
        ]
      }
    ]
  }
}

// address can have leading white spaces due to format - needed a function to get rid of them
const removeLeadingWhitespace = (
  strings: TemplateStringsArray,
  ...values: any[]
) => {
  const result = strings.reduce(
    (acc, str, i) => acc + str + (values[i] || ''),
    ''
  )
  // Remove leading whitespace from each line of the string
  const trimmed = result.replace(/^\s+/gm, '')

  return trimmed
}

const getAddress = (ea: IEventAddressResult, title: string) => {
  if (ea?.addressNotes) {
    const addressNotesFormat = removeLeadingWhitespace`
      ${title ? `<h1>${title}</h1><br />` : ''}
      <b>${ea?.address?.name}</b><br/>
      ${ea?.addressNotes?.replace(/(?:\r\n|\r|\n)/g, '<br>')}
    `
    return addressNotesFormat
  } else {
    const onlyAddressFormat = removeLeadingWhitespace`
      ${title ? `<h1>${title}</h1><br />` : ''}
      <b>${ea.address.name}</b>
      <br />
      ${ea.address.address1}
      <br />
      ${ea.address.address2 ? `${ea.address.address2}<br />` : ``}
      ${ea.address.city}, ${ea.address.state} ${ea.address.zip}<br />${ea.phone}
    `
    return onlyAddressFormat
  }
}

const getDefaultLogisticsSection = (event: IEventResult): ISectionState => {
  const logisticsBlocks: IBlockState[] = []
  const venues = event.eventAddresses.filter(a => a.type === 'venue')
  const accommodations = event.eventAddresses.filter(a => a.type === 'hotel')

  const getTitle = (type: string, index: number) =>
    type === 'venue' && index === 0
      ? 'Gathering Location'
      : type === 'hotel' && index === 0
      ? 'Accommodations'
      : ''

  const pairLocations = (locations: IEventAddressResult[]) => {
    const paired: IBlockState[] = []
    for (let i = 0; i < locations.length; i += 2) {
      const firstColumn = getMarkdownColumnFromHtml(
        getAddress(locations[i], getTitle(locations[i].type, i))
      )

      const secondColumn = locations[i + 1]
        ? getMarkdownColumnFromHtml(
            getAddress(locations[i + 1], getTitle(locations[i + 1].type, i + 1))
          )
        : undefined

      paired.push({
        live: true,
        exportToPdf: true,
        columns: [firstColumn].concat(secondColumn ? [secondColumn] : [])
      })
    }
    return paired
  }

  if (venues.length === 1 && accommodations.length === 1) {
    logisticsBlocks.push({
      live: true,
      exportToPdf: true,
      columns: [
        getMarkdownColumnFromHtml(
          getAddress(venues[0], getTitle(venues[0].type, 0))
        ),
        getMarkdownColumnFromHtml(
          getAddress(accommodations[0], getTitle(accommodations[0].type, 0))
        )
      ]
    })
  } else {
    if (venues?.length) {
      logisticsBlocks.push(...pairLocations(venues))
    }
    if (accommodations?.length) {
      logisticsBlocks.push(...pairLocations(accommodations))
    }
  }

  if (event.reservationInfo) {
    logisticsBlocks.push({
      type: 'container',
      live: true,
      exportToPdf: true,
      columns: [getMarkdownColumnFromHtml(event.reservationInfo)]
    })
  }
  if (event.recommendedTransportationInfo) {
    logisticsBlocks.push({
      live: true,
      exportToPdf: true,
      columns: [
        getMarkdownColumnFromHtml(
          `<h2>Recommended Transportation</h2><br />${event.recommendedTransportationName}<br />${event.recommendedTransportationInfo}`
        )
      ]
    })
  }
  return {
    uid: 'logistics',
    title: 'Logistics',
    live: true,
    exportToPdf: true,
    blocks: logisticsBlocks,
    includeAttendeesInPdf: false
  }
}

const getDefaultSummitSite = (event: IEventResult) => {
  const state: IEditorState = {
    sections: [
      getDefaultHeaderSection(event),
      getOverviewSection(event)
      // getDefaultLogisticsSection(event)
    ]
  }

  return state
}

const getOverviewSection = (event: IEventResult): ISectionState => {
  return {
    uid: 'overview',
    title: 'Overview',
    live: true,
    exportToPdf: true,
    includeAttendeesInPdf: false,
    blocks: [
      {
        uid: 'overview',
        live: true,
        exportToPdf: true,
        columns: [
          { type: ColumnStateType.Text, state: { text: event.description } }
        ]
      }
    ]
  }
}

const getAgendaSection = (): ISectionState => {
  return {
    uid: 'agenda',
    title: 'Agenda',
    live: true,
    exportToPdf: true,
    blocks: [
      {
        uid: 'agenda',
        live: true,
        exportToPdf: true,
        columns: [
          {
            state: createEmptyRichTextEditorState(),
            type: ColumnStateType.Markdown
          },
          {
            state: createEmptyRichTextEditorState(),
            type: ColumnStateType.Markdown
          }
        ]
      }
    ]
  }
}

export const getPageBreakSection = (): ISectionState => {
  return {
    uid: 'pageBreak',
    title: 'pageBreak',
    live: false,
    exportToPdf: true,
    blocks: []
  }
}
const getProgramGuestsSection = (): ISectionState => {
  return {
    uid: 'program-guests',
    title: 'Program Guests',
    live: true,
    exportToPdf: true,
    includeAttendeesInPdf: false,
    blocks: []
  }
}

export const getEmptyBlock = (): IBlockState => {
  return {
    uid: generateRandomUid(),
    live: true,
    exportToPdf: false,
    columns: [
      {
        state: null,
        type: ColumnStateType.Image
      },
      {
        state: createEmptyRichTextEditorState(),
        type: ColumnStateType.Markdown
      }
    ]
  }
}

export const getBlockContainer = (): IBlockState => {
  return {
    uid: generateRandomUid(),
    type: 'container',
    live: true,
    exportToPdf: false,
    columns: [
      {
        state: createEmptyRichTextEditorState(),
        type: ColumnStateType.Markdown
      }
    ]
  }
}

export const getTextBlock = (): IBlockState => {
  return {
    uid: generateRandomUid(),
    type: 'text-block',
    live: true,
    exportToPdf: false,
    columns: [
      { type: ColumnStateType.Text, state: { text: '' } as ITextColumnValue }
    ]
  }
}

export const getDividerBlock = (): IBlockState => {
  return {
    uid: generateRandomUid(),
    type: 'divider',
    exportToPdf: false,
    columns: []
  }
}

export const getConfStatement = (): IBlockState => {
  const message = `<i>As is the case with all World 50 gatherings, discussions at the upcoming Summit are private and confidential. What is said in the room will not reach beyond the membership.</i>`

  return {
    uid: 'confidentiality',
    exportToPdf: false,
    columns: [getMarkdownColumnFromHtml(message)]
  }
}

const blockRenderMap = Immutable.Map({
  heading: {
    element: 'h1',
    aliasedElements: ['h2', 'h3', 'h4', 'h5', 'h6']
  }
})

// Include 'paragraph' as a valid block and updated the unstyled element but
// keep support for other draft default block types
const extendedBlockRenderMap = DefaultDraftBlockRenderMap.merge(blockRenderMap)

export const getEditorStateFromHtml = (html: string) => {
  const blocksFromHTML = convertFromHTML(
    html,
    undefined,
    extendedBlockRenderMap
  )
  return EditorState.createWithContent(
    ContentState.createFromBlockArray(
      blocksFromHTML.contentBlocks,
      blocksFromHTML.entityMap
    )
  )
}

export const getMarkdownColumnFromHtml = (html: string): IBlockColumnState => {
  return {
    type: ColumnStateType.Markdown,
    state: getEditorStateFromHtml(html)
  }
}

export const getEmptySection = () => {
  return {
    uid: generateRandomUid(),
    blocks: []
  }
}

export const SummitBuilder = {
  columns: {
    getMarkdownColumnFromHtml
  },
  sections: {
    getEmptySection
  },
  blocks: {
    getDividerBlock,
    getEmptyBlock,
    getConfStatement,
    getBlockContainer,
    getTextBlock
  }
}

export const DefaultSummitSite = {
  get: getDefaultSummitSite,
  getHeader: getDefaultHeaderSection,
  getLogistics: getDefaultLogisticsSection,
  getOverview: getOverviewSection,
  getProgramGuests: getProgramGuestsSection,
  getAgenda: getAgendaSection,
  getPageBreak: getPageBreakSection
}

export const getInitialValues = (event?: IEventResult): IEditorState => {
  if (!event) {
    return {
      sections: []
    }
  }

  return DefaultSummitSite.get(event)
}
