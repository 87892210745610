import { LinkingOptions } from '@react-navigation/native'
import { LinkingOptionsParamList } from '../types'
import * as Linking from 'expo-linking'

const linkingOptions: LinkingOptions<LinkingOptionsParamList> = {
  prefixes: [Linking.makeUrl('/')],
  config: {
    screens: {
      Login: 'login',
      NotFound: '*',
      App: {
        screens: {
          Media: {
            screens: {
              MediaList: 'media',
              MediaCreate: 'media/new',
              MediaEdit: 'media/:id/edit'
            }
          },
          Image: {
            screens: {
              ImageList: 'image'
            }
          },
          Library: {
            screens: {
              LibraryList: 'library',
              DocumentCreate: 'library/new',
              DocumentEdit: 'library/:id/edit'
            }
          },
          PageBuilder: {
            screens: {
              PageBuilderEdit: 'page-builder/:id?'
            }
          },
          Users: {
            screens: {
              UserList: 'users'
            }
          },
          CommunitiesMicrositesPermissions: 'communities-microsites',
          UrlShortener: 'url-shortener',
          DataExtraction: 'data-extraction',
          MessageLog: 'message-log'
        }
      }
    }
  }
}

export default linkingOptions
