import React, { useState } from 'react'
import { Flex } from '../../../components/FlexBox'
import { Text } from '../../../components/common/Text'
import TextInput from '../../../components/common/TextInput'
import Button from '../../../components/common/Button'
import { IMessageLogFilter } from '../MessageLogScreen'
import { NativeSyntheticEvent, TextInputKeyPressEventData } from 'react-native'
import Dropdown from '../../../components/common/Dropdown'

// This shouldn't have to be done but there is some mismatch between the React Native and React types
type KeyEvent = NativeSyntheticEvent<TextInputKeyPressEventData> & {
  nativeEvent: { key: string }
}
interface IMessageLogFilterProps {
  setFilter: (value: IMessageLogFilter) => void
  setPage: (value: number) => void
}

type MessageStatus =
  | 'processed'
  | 'all'
  | 'deferred'
  | 'delivered'
  | 'bounced'
  | 'dropped'

const messageLogTypes = [
  'atMention',
  'communityDigest',
  'directConnection',
  'directMessage',
  'eventConnection',
  'eventRsvp',
  'eventRsvpNonMember',
  'innovationAdvisorInvite',
  'innovationSubmissionSuccess',
  'innovationTeamInvite',
  'missedDM',
  'monthlyCalendar',
  'newPost',
  'passwordReset',
  'pollVote',
  'postReply',
  'profileUpdates',
  'unreadDMs',
  'virtualEventInvite'
] as const

const FilterTextInput = (props: {
  value: string
  onChange: (value: string) => void
  placeholder: string
  onKeyPress: (event: KeyEvent) => void
}) => {
  return (
    <TextInput
      inputStyle={{ width: '100%' }}
      inputContainerStyle={{ borderRadius: 8 }}
      style={{
        width: 200,
        marginRight: 12
      }}
      errorStyle={{ margin: 0 }}
      placeholder={props.placeholder}
      value={props.value}
      onChangeText={props.onChange}
      // @ts-expect-error
      onKeyPress={props.onKeyPress}
    />
  )
}

const InverseButton = (props: { label: string; onPress: () => void }) => {
  return (
    <Button
      label={props.label}
      onPress={props.onPress}
      style={{
        backgroundColor: 'white'
      }}
      buttonStyle={{
        backgroundColor: 'white',
        borderWidth: 1,
        borderColor: 'black'
      }}
      titleStyle={{ color: 'black' }}
    />
  )
}

export const MessageLogFilter: React.FC<IMessageLogFilterProps> = ({
  setFilter,
  setPage
}) => {
  const [sendToFilter, setSendToFilter] = useState<string>('')
  const [sendFromFilter, setSendFromFilter] = useState<string>('')
  const [typeFilter, setTypeFilter] = useState<
    typeof messageLogTypes[number] | 'all'
  >('all')
  const [statusFilter, setStatusFilter] = useState<MessageStatus>('all')
  const [verifiedFilter, setVerifiedFilter] = useState<boolean | undefined>(
    undefined
  )

  const applyFilter = () => {
    setPage(0)
    setFilter({
      sendTo: sendToFilter,
      sendFrom: sendFromFilter,
      type: typeFilter === 'all' ? undefined : typeFilter,
      status: statusFilter === 'all' ? undefined : statusFilter,
      verified: verifiedFilter
    })
  }

  const clearFilter = () => {
    setPage(0)
    setFilter({})
    setSendToFilter('')
    setSendFromFilter('')
    setTypeFilter('all')
    setStatusFilter('all')
    setVerifiedFilter(undefined)
  }

  return (
    <>
      <Flex flexDirection="row" style={{ marginBottom: 8 }}>
        <Text styles={{ width: 200, marginRight: 12 }}>Send from</Text>
        <Text styles={{ width: 200, marginRight: 12 }}>Send to</Text>
        <Text styles={{ width: 200, marginRight: 12 }}>Type</Text>
        <Text styles={{ width: 200, marginRight: 12 }}>Status</Text>
        <Text styles={{ width: 200, marginRight: 12 }}>Verified</Text>
      </Flex>

      <Flex flexDirection="row">
        <FilterTextInput
          value={sendFromFilter}
          onChange={setSendFromFilter}
          placeholder="Send from..."
          onKeyPress={({ nativeEvent }) => {
            if (nativeEvent.key === 'Enter') {
              applyFilter()
            }
          }}
        />
        <FilterTextInput
          value={sendToFilter}
          onChange={setSendToFilter}
          placeholder="Send to..."
          onKeyPress={({ nativeEvent }) => {
            if (nativeEvent.key === 'Enter') {
              applyFilter()
            }
          }}
        />
        <Dropdown
          placeholder={'Type'}
          options={[
            { label: 'All', value: 'all' },
            ...messageLogTypes.map(type => ({ label: type, value: type }))
          ]}
          value={typeFilter}
          onSelect={value => {
            setTypeFilter(value)
          }}
          isMulti={false}
          style={{ width: 200, marginRight: 12, padding: 4 }}
        />
        <Dropdown
          placeholder={'Status'}
          options={[
            { label: 'All', value: 'all' },
            {
              label: 'Processed',
              value: 'processed'
            },
            {
              label: 'Deferred',
              value: 'deferred'
            },
            {
              label: 'Delivered',
              value: 'delivered'
            },
            {
              label: 'Bounced',
              value: 'bounced'
            },
            {
              label: 'Dropped',
              value: 'dropped'
            }
          ]}
          value={statusFilter}
          onSelect={value => {
            setStatusFilter(value)
          }}
          style={{ width: 200, marginRight: 12, padding: 4 }}
        />
        <Dropdown
          placeholder={'Verification'}
          options={[
            { label: 'All', value: 'all' },
            { label: 'Verified', value: 'verified' },
            { label: 'Unverified', value: 'unverified' }
          ]}
          value={
            verifiedFilter === true
              ? 'verified'
              : verifiedFilter === false
              ? 'unverified'
              : 'all'
          }
          onSelect={value => {
            setVerifiedFilter(
              value === 'verified'
                ? true
                : value === 'unverified'
                ? false
                : undefined
            )
          }}
          isMulti={false}
          style={{ width: 200, marginRight: 12, padding: 4 }}
        />
        <Button
          label="Filter"
          style={{ marginRight: 12 }}
          onPress={() => {
            applyFilter()
          }}
        />
        <InverseButton label="Clear" onPress={clearFilter} />
      </Flex>
    </>
  )
}
