import React from 'react'
import { StyleProp, TextStyle, ViewStyle } from 'react-native'
import { Button as ElementsButton } from 'react-native-elements'

const Button: React.FC<ButtonProps> = ({
  label,
  icon,
  onPress,
  disabled = false,
  style = {},
  buttonStyle = {},
  titleStyle = {}
}) => {
  return (
    <ElementsButton
      title={label}
      icon={icon}
      disabled={disabled}
      onPress={onPress}
      style={style}
      buttonStyle={
        typeof buttonStyle === 'object'
          ? {
              backgroundColor: '#21201B',
              borderRadius: 8,
              paddingLeft: 24,
              paddingRight: 24,
              ...buttonStyle
            }
          : buttonStyle
      }
      titleStyle={titleStyle}
    />
  )
}

interface ButtonProps {
  label?: string
  icon?: any
  onPress: any
  disabled?: boolean
  style?: StyleProp<ViewStyle>
  titleStyle?: StyleProp<TextStyle>
  buttonStyle?: StyleProp<ViewStyle>
}

export default Button
