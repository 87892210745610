import gql from 'graphql-tag'
import { useQuery } from '@apollo/client'

const contentTopicQuery = gql`
  query contentTopicQuery {
    contentTopics {
      value: id
      label: name
    }
  }
`

const useContentTopicQuery = () => {
  const { data, refetch } = useQuery(contentTopicQuery)

  return { data, refetch }
}

export default useContentTopicQuery
