import React from 'react'
import Modal from '../common/Modal'
import { H3 } from '../common/Text'
import Button from '../common/Button'
import useTranslation from '../../hooks/useTranslation'
import TextInput from '../common/TextInput'
import { Flex } from '../FlexBox'

interface LinkModalProps {
  isOpen: boolean
  onClose: () => void
  url: string
  onUrlChange: (string) => void
  onSubmit: () => void
}

const LinkModal = ({
  isOpen,
  onClose,
  url,
  onUrlChange,
  onSubmit
}: LinkModalProps) => {
  const { t } = useTranslation()

  return (
    <Modal open={isOpen} close={onClose}>
      <H3 styles={{ paddingBottom: 10 }}>{t('richTextEditor:linkPrompt')}</H3>
      <TextInput
        placeholder="https://google.com"
        value={url}
        onChangeText={onUrlChange}
      />

      <Flex
        flexDirection="row"
        justifyContent="flex-end"
        style={{ paddingTop: 10 }}
      >
        <Button label="Cancel" onPress={onClose} style={{ marginRight: 10 }} />
        <Button label="Submit" onPress={onSubmit} />
      </Flex>
    </Modal>
  )
}

export default LinkModal
