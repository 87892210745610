import React from 'react'
import { ScreenContainerWithMenuHeader } from '../../components/layout/ScreenContainer'
import { Flex } from '../../components/FlexBox'
import { H4 } from '../../components/common/Text'
import Button from '../../components/common/Button'
import NewPermissionModal from './components/NewPermission'
import DeletePermissionModal from './components/DeletePermission'
import PermissionsTable from './components/PermissionsTable'
import useCommunitiesMicrositesPermissions from './hooks/useCommunitiesMicrositesPermissions'
import SearchBar from './components/SearchBar'
import useTranslation from '../../hooks/useTranslation'

const PermissionsScreen = () => {
  const { t } = useTranslation()
  const {
    confirmDeletion,
    cancelDeletion,
    micrositesPermissions,
    selectForDeletion,
    selectedForDeletion,
    loadingData,
    loadingDeletion,
    loadingUpsertion,
    micrositePermission,
    upsertMicrositePermission,
    selectMicrositePermission,
    isOn,
    turnOff,
    turnOn,
    fetchMore,
    refetch,
    handleSearch
  } = useCommunitiesMicrositesPermissions()

  return (
    <ScreenContainerWithMenuHeader screenCategory={'micrositePermissions'}>
      <Flex flexDirection="row" mx={4} my={3}>
        <Flex flex={1}>
          <H4>{t('microsites:title')}</H4>
        </Flex>
        <Button onPress={turnOn} label={t('microsites:new')} />
      </Flex>
      <Flex flexDirection="row" mx={1} my={3}>
        <Flex flex={1} ml={25}>
          <SearchBar
            onSearch={handleSearch}
            placeholder={t('microsites:search')}
          />
        </Flex>
      </Flex>
      <PermissionsTable
        tokens={micrositesPermissions}
        onSelect={selectMicrositePermission}
        onDelete={selectForDeletion}
        fetchMore={fetchMore}
        refetch={refetch}
        loading={loadingData}
      />
      <NewPermissionModal
        isOn={isOn}
        turnOff={turnOff}
        permission={micrositePermission}
        upsertPermission={upsertMicrositePermission}
        loading={loadingUpsertion}
      />
      <DeletePermissionModal
        isOn={!!selectedForDeletion}
        permission={micrositePermission}
        onConfirm={confirmDeletion}
        onCancel={cancelDeletion}
        loading={loadingDeletion}
      />
    </ScreenContainerWithMenuHeader>
  )
}

export default PermissionsScreen
