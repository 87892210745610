import React, { CSSProperties, useState } from 'react'
import { StyleProp, ViewStyle } from 'react-native'
import Modal from '../../../components/common/Modal'
import { Text } from '../../../components/common/Text'
import { Flex } from '../../../components/FlexBox'
import { extractionQueues } from '../queries/runExtraction'
import Button from '../../../components/common/Button'
import { IQueueJob, useObliterateQueue, useQueueJobs } from '../queries/queue'
import { ButtonGroup } from 'react-native-elements'
import { Job } from '../Job'
import styled, { useTheme } from 'styled-components/native'
import { ListenersTab } from '../Listeners'
import { DependenciesTab } from '../Dependencies'

const JOB_TYPE_FILTERS = [
  'All',
  'Completed',
  'Failed',
  'Active',
  'Waiting'
  // 'Delayed'
]

const JOB_TYPES_EXTRA = [...JOB_TYPE_FILTERS, 'Listeners', 'Dependencies']

interface IProps {
  open: boolean
  close: () => void
  overlayStyle?: StyleProp<ViewStyle>
  selectedQueue?: string
  setSelectedQueue: (value: string) => void
  setRunModalOpen: (value: boolean) => void
  setSelectedExtractor: (value: string) => void
  isLoading: boolean
  showExtraTabs?: boolean
}

const WarningText = styled(Text)`
  color: ${props => props.theme.colors.danger};
  font-weight: bold;
`

export const ExtractionDetailsModal: React.FC<IProps> = ({
  open,
  close,
  selectedQueue,
  setRunModalOpen,
  setSelectedExtractor,
  isLoading,
  showExtraTabs
}) => {
  const [jobTypeFilter, setJobTypeFilter] = useState<number>(0)
  const obliterateQueue = useObliterateQueue({ queueName: selectedQueue! })
  const [cancelModalOpen, setCancelModalOpen] = useState<boolean>(false)
  const { colors } = useTheme()
  const {
    jobs,
    stats,
    graph,
    refetch: refetchJobs,
    loadMore,
    allResultsLoaded
  } = useQueueJobs(selectedQueue, {
    state: JOB_TYPE_FILTERS[jobTypeFilter]?.toUpperCase()
  })

  const tabs = showExtraTabs ? JOB_TYPES_EXTRA : JOB_TYPE_FILTERS

  const selectedTab = tabs[jobTypeFilter]

  return (
    <>
      <Modal
        open={open}
        close={close}
        contentStyle={
          ({
            overscrollBehavior: 'contain'
          } as CSSProperties) as ViewStyle
        }
      >
        <Flex
          flexDirection="row"
          mx={4}
          my={3}
          alignItems="center"
          justifyContent={'space-between'}
        >
          <Text>
            <b>
              {selectedQueue
                ? extractionQueues[selectedQueue]?.name ?? selectedQueue
                : ''}{' '}
              Extraction
            </b>
          </Text>
          <Flex flexDirection="row" alignItems="center">
            <Button
              label="Obliterate"
              onPress={() => setCancelModalOpen(true)}
              buttonStyle={{
                backgroundColor: colors.danger
              }}
              style={{
                marginRight: 8
              }}
              disabled={isLoading}
            />
            <Button
              label="Refresh"
              onPress={() => {
                const state = JOB_TYPE_FILTERS[jobTypeFilter]?.toUpperCase()
                refetchJobs(state)
              }}
              style={{ marginRight: 8 }}
            />
            {extractionQueues[selectedQueue || '']?.endpoint && (
              <Button
                label="Schedule"
                style={{ marginRight: 8 }}
                disabled={isLoading}
                onPress={() => {
                  if (!selectedQueue) {
                    return
                  }
                  setSelectedExtractor(selectedQueue)
                  setRunModalOpen(true)
                  close()
                }}
              />
            )}
            <Button onPress={() => close()} label="×" />
          </Flex>
        </Flex>

        <Flex
          flexDirection={'row'}
          flexGrow={1}
          my={2}
          mx={4}
          alignItems={'center'}
        >
          <ButtonGroup
            buttons={tabs.map(x => {
              const count = stats?.[x.toLowerCase()]
              if (!count) {
                return x
              }
              return `${x} (${count})`
            })}
            selectedIndex={jobTypeFilter}
            onPress={value => {
              setJobTypeFilter(value)
              if (value === JOB_TYPE_FILTERS.length - 1) {
                refetchJobs(JOB_TYPE_FILTERS[value].toUpperCase())
              }
            }}
            containerStyle={{
              margin: 0,
              flexGrow: 1
            }}
            selectedTextStyle={{ color: 'black' }}
          />
        </Flex>
        {jobTypeFilter <= JOB_TYPE_FILTERS.length - 1 && (
          <JobTab
            jobs={jobs}
            allResultsLoaded={allResultsLoaded}
            loadMore={loadMore}
          />
        )}
        {selectedTab === 'Listeners' && showExtraTabs && (
          <ListenersTab listeners={graph?.listeners} />
        )}
        {selectedTab === 'Dependencies' && showExtraTabs && (
          <DependenciesTab dependencies={graph?.dependencies} />
        )}
      </Modal>
      <Modal open={cancelModalOpen} close={() => setCancelModalOpen(false)}>
        <Text styles={{ marginBottom: 8 }}>
          Are you sure you want to obliterate this queue?
        </Text>
        <WarningText>
          This will delete all jobs in the queue unrecoverably.
        </WarningText>
        <WarningText>
          This will also delete any history of completed and failed jobs.
        </WarningText>
        <Button
          label="Confirm"
          onPress={() => {
            obliterateQueue().then(() => {
              refetchJobs()
              setCancelModalOpen(false)
            })
          }}
          style={{
            marginVertical: 8
          }}
          disabled={isLoading}
        />
        <Button label="Cancel" onPress={() => setCancelModalOpen(false)} />
      </Modal>
    </>
  )
}

const JobTab = ({
  jobs,
  allResultsLoaded,
  loadMore
}: {
  jobs: IQueueJob[]
  allResultsLoaded: boolean
  loadMore: () => void
}) => {
  return (
    <>
      {jobs?.map(job => (
        <Flex mx={4} my={1} flexDirection="row" key={job.id}>
          <Job job={job} />
        </Flex>
      ))}
      {jobs?.length && jobs.length > 0 && !allResultsLoaded ? (
        <Flex
          mx={4}
          my={1}
          flexDirection="column"
          alignContent="center"
          alignItems="center"
        >
          <Button onPress={() => loadMore()} label="Load More" />
        </Flex>
      ) : null}
      {jobs?.length === 0 && (
        <Flex mx={4} my={1} flexDirection="column" alignContent="center">
          <Text>No jobs to show.</Text>
        </Flex>
      )}
    </>
  )
}
