import { useRecoilState, SetterOrUpdater } from 'recoil'
import featureFlagAtom from '../recoil/atoms/featureFlagAtom'

const useFeatureFlag = (flag: string): boolean => {
  const [featureFlags] = useRecoilState(featureFlagAtom) as [
    object,
    SetterOrUpdater<object>
  ]
  return featureFlags[flag] || false
}

export default useFeatureFlag
