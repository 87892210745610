import gql from 'graphql-tag'

const getAllShortUrlQueries = gql`
  query GetAllShortUrls {
    allShortUrls {
      id
      sourceUrl
      targetUrl
    }
  }
`

export default getAllShortUrlQueries
