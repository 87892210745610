import useAudienceQuery, {
  AudienceType
} from '../components/common/form/hooks/useAudienceQuery'
import useContentTopicQuery from '../components/common/form/hooks/useContentTopicQuery'
import useTranslation from './useTranslation'
import useFeatureFlag from './useFeatureFlag'
import {
  appRoles,
  groupRoles,
  innovationRoles,
  mi6Roles
} from '../constants/Utils'
import capitalizeFirstLetter, {
  capitalizeEachLetter
} from '../utils/capitalize'
import insertSpaces from '../utils/insertSpaces'

const documentFilters = {
  all: null,
  engageHighlights: ['engageHighlights'],
  gatheringHighlights: ['roundtableHighlights', 'insights'],
  oneDegree: ['oneDegree'],
  podcasts: ['podcastHighlights'],
  theW: ['theW'],
  notebook: ['notebook'],
  playbook: ['playbook'],
  briefingBook: ['briefingBook'],
  sharedFile: ['sharedFile'],
  other: [
    'recap',
    'next',
    'deepDive',
    'evergreenContent',
    'programGuide',
    'agenda',
    'highlights'
  ]
}

const publishingFilters = ['published', 'unpublished', 'scheduled']

const mediaFilters = [
  'engageCalls',
  'huddleCalls',
  'executiveSummaries',
  'compilations',
  'summitHighlights',
  'podcasts'
]

const noTopicsOption = {
  value: [],
  label: 'No Topic'
}

const getFilterMapping = (values, prefix, t, disabledFilters = []) => () => {
  const isArray = Array.isArray(values)
  const arr: any[] = isArray ? values : Object.keys(values)
  return arr
    .filter(key => !disabledFilters.includes(key))
    .map(value => ({
      label: t(`${prefix}:${value}`),
      value: isArray ? value : values[value]
    }))
}

const updatedMemberRolesFilter = [
  ...appRoles,
  ...groupRoles,
  'executiveAssistant'
].map(role => ({
  label: insertSpaces(capitalizeFirstLetter(role)),
  value: role
}))

const memberAppRolesFilter = [...appRoles].map(role => ({
  label: insertSpaces(capitalizeFirstLetter(role)),
  value: role
}))

const mi6RolesFilter = [...mi6Roles].map(role => ({
  label: capitalizeEachLetter(role.replace(RegExp('_', 'g'), ' ')),
  value: role
}))

const innovationRolesFilter = [...innovationRoles].map(role => ({
  label: capitalizeEachLetter(role.replace(RegExp('_', 'g'), ' ')),
  value: role
}))

const useFilterOptions = filterType => {
  const { t } = useTranslation()
  const { data: audienceData } = useAudienceQuery(AudienceType.COMMUNITIES)
  const { data: groupsData } = useAudienceQuery(AudienceType.GROUPS, false)
  const { data: eventsData } = useAudienceQuery(AudienceType.EVENTS)
  const { data: contentTopicData } = useContentTopicQuery()
  const isSharedFileEnabled = useFeatureFlag('sharedFilesOnTextObjects')
  const mi6ManageUsersUpdate = useFeatureFlag('mi6ManageUsersUpdate')

  const disabledDocumentFilters: string[] = []

  if (!isSharedFileEnabled) {
    disabledDocumentFilters.push('sharedFile')
  }

  const filters = {
    types: getFilterMapping(
      documentFilters,
      'library:document:filterTypes',
      t,
      disabledDocumentFilters
    ),
    categories: getFilterMapping(mediaFilters, 'media:category', t),
    publishingState: getFilterMapping(
      publishingFilters,
      'library:document:published',
      t
    ),
    communityId: () => audienceData?.communities || audienceData || [],
    groupId: () => groupsData?.groups || groupsData || [],
    eventId: () => eventsData?.events || eventsData || [],
    topicIds: () =>
      contentTopicData?.contentTopics
        ? [noTopicsOption, ...contentTopicData.contentTopics]
        : [],
    memberAppRoles: () =>
      mi6ManageUsersUpdate ? updatedMemberRolesFilter : memberAppRolesFilter,
    mi6Roles: () => mi6RolesFilter,
    innovationRoles: () => innovationRolesFilter,
    group: () => groupsData?.groups || groupsData || []
  }

  return filters[filterType]?.() || []
}

export default useFilterOptions
