import React, { useEffect } from 'react'
import { useQuery } from '@apollo/client'
import featureFlagQuery from './featureFlagQuery'
import useCurrentUser from '../hooks/useCurrentUser'
import useFeatureFlagSystem, {
  FeatureFlagHelpers
} from '../hooks/useFeatureFlagSystem'

const FeatureFlagController = () => {
  const { currentUserId } = useCurrentUser()
  const {
    featureFlags,
    setFeatureFlags
  }: FeatureFlagHelpers = useFeatureFlagSystem()

  const { refetch } = useQuery(featureFlagQuery, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true
  })

  const refetchFlags = async () => {
    const { data } = await refetch()
    const newFlags = data?.featureFlags
    const mappedFlags = flagsListToObject(newFlags)

    if (featureFlags !== newFlags) {
      setFeatureFlags(mappedFlags)
    }
  }

  const flagsListToObject = flags => {
    if (flags === undefined) return {}
    return flags.reduce(
      (acc, { name, value }) => ({ ...acc, [name]: value }),
      {}
    )
  }

  useEffect(() => {
    // This needs to be run on the next event loop due to client.stop on AuthProvider
    setTimeout(refetchFlags, 1)
  }, [currentUserId])

  return <></>
}

export default FeatureFlagController
