import React, { FC, useContext, useEffect, useState } from 'react'
import {
  ActivityIndicator,
  Linking,
  Platform,
  ScrollView,
  View
} from 'react-native'
import { ThemeContext } from 'styled-components/native'
import { useFormik } from 'formik'
import { useTranslation } from 'react-i18next'
import { ScreenContainerWithMenuHeader } from '../../components/layout/ScreenContainer'
import { Flex } from '../../components/FlexBox'
import Button from '../../ui-library/Button'
import { ContentBlock } from './ContentBlock'

import {
  DefaultSummitSite,
  getInitialValues,
  SummitBuilder,
  transformEditorStateToPayload
} from './utils'
import Constants from 'expo-constants'
import { DiscardChangesModal } from './DiscardChangesModal'
import { AddBlock } from './AddBlock'
import { Text } from '../../components/common/Text'
import { Divider } from 'react-native-elements'
import useToast, { ToastType } from '../../hooks/useToast'
import { AddSection } from './AddSection'
import { SectionTitleInput } from './SectionTitleInput'
import { SectionControls } from './SectionControls'
import PublishModal from './PublishModal'
import { StackScreenProps } from '@react-navigation/stack'
import { AppStackParamList } from '../../types'
import ProgramGuests from './sections/ProgramGuests'
import { validateSummitForm } from './validation'
import { CaretIcon } from '../../components/icon/CaretIcon'
import {
  useCreateEventAgendaPDF,
  useGetPageContent,
  usePublishPageContent,
  useUpsertPageContent
} from './hooks'
import { GatheringSelect } from '../../components/common/form'
import useSummitUpdatedSubscription from './subscriptions/useSummitUpdatedSubscription'
import useCurrentUser from '../../hooks/useCurrentUser'
import useFeatureFlag from '../../hooks/useFeatureFlag'
import { Switch } from '../../components/common/Switch'
import PreviewSelect from '../../components/common/form/PreviewSelect'
import useSummitPDFSubscription from './subscriptions/useSummitPDFSubscription'

const MEMBER_WEB_PROTOCOL = Constants.manifest?.extra?.MEMBER_WEB_PROTOCOL!
const MEMBER_WEB_URL = Constants.manifest?.extra?.MEMBER_WEB_URL!

const ScreenContainer: FC = props => (
  <ScreenContainerWithMenuHeader
    screenCategory="summit-sites"
    style={{ padding: 32 }}
    {...props}
  />
)

type Props = StackScreenProps<AppStackParamList, 'PageBuilderEdit'>

const SummitScreen: React.FC<Props> = ({ navigation, route }) => {
  const [creatingPDF, setCreatingPDF] = useState<boolean>(false)
  const [creatingPDFToastId, setCreatingPDFToastId] = useState<string>(null)
  const [selectedSummit, setSelectedSummit] = useState<string | undefined>(
    route.params?.id
  )
  const { showToast, createManualToast, removeSpecificToast } = useToast()

  const displayMessage = (message: string) =>
    showToast(message, ToastType.INFO, 3000)

  const [isPublishModalOpen, setPublishModalOpen] = useState(false)
  const [isPublishing, setIsPublishing] = useState(false)
  const [publishDate, setPublishDate] = useState(null)

  const { currentUserId } = useCurrentUser()
  const isAgendaPdfEnabled = useFeatureFlag('createEventAgendaPDF')

  const [
    upsertPageContent,
    { loading: loadingUpsert, reset: resetUpsert }
  ] = useUpsertPageContent(selectedSummit)

  const { createEventPDF } = useCreateEventAgendaPDF()

  const form = useFormik({
    initialValues: getInitialValues(),
    validate: validateSummitForm,
    onSubmit: () => {
      const pageContent = transformEditorStateToPayload(form.values)
      upsertPageContent({
        variables: {
          eventId: selectedSummit,
          pageContent
        },
        onError(error) {
          showToast(error.message, ToastType.ERROR)
        },
        onCompleted() {
          displayMessage(t('summits:editor:messages:saveSuccess'))
          if (isAgendaPdfEnabled) {
            createEventPDF(selectedSummit).then(data => {
              if (data.createEventAgendaPDF) {
                setCreatingPDF(true)
                setCreatingPDFToastId(
                  createManualToast(t('summits:editor:generatingPDF')).id
                )
              } else if (isPublishing) {
                publishSummit(publishDate)
              }
            })
          }
        }
      })
    }
  })

  const [
    { data, loading, error, dataPublished },
    { lastSaved, lastPublish, lastUnpublish }
  ] = useGetPageContent(form, selectedSummit)

  const [
    publishPageContent,
    { loading: loadingPublish }
  ] = usePublishPageContent(selectedSummit)

  const [nextSelectedId, setNextSelectedId] = useState<string | null>()

  const [invalidatedByEdit, setInvalidatedByEdit] = useState(false)
  const [invalidatedByEditUser, setInvalidatedByEditUser] = useState<
    any | null
  >(null)

  const theme = useContext(ThemeContext) as any
  const { t } = useTranslation()

  const selectSummit = (summitId?: string) => {
    if (summitId === selectedSummit) {
      return
    }
    if (!summitId) {
      setSelectedSummit(undefined)
      navigation.push('PageBuilderEdit', {})
    } else if (summitId !== selectedSummit) {
      setSelectedSummit(summitId)
      navigation.push('PageBuilderEdit', { id: summitId })
    }

    form.resetForm({ values: getInitialValues() })
    resetUpsert()
  }

  const showInvalidatedByEditMessage = () => {
    if (invalidatedByEditUser) {
      const { firstName, lastName } = invalidatedByEditUser
      showToast(
        t('media:mediaUpdatedByPerson', {
          name: `${firstName} ${lastName}`
        }),
        undefined,
        -1
      )
    } else {
      showToast(t('media:mediaUpdated'))
    }
  }

  useEffect(() => {
    if (invalidatedByEdit) {
      showInvalidatedByEditMessage()
    }
  }, [invalidatedByEdit, invalidatedByEditUser])

  useSummitUpdatedSubscription(
    route?.params?.id,
    ({ data: subscriptionData }) => {
      const {
        data: {
          pageContentUpdated: { updatedBy }
        }
      } = subscriptionData

      if (!updatedBy || updatedBy.user.id !== currentUserId) {
        setInvalidatedByEdit(true)
        setInvalidatedByEditUser(updatedBy)
      }
    }
  )

  useSummitPDFSubscription({
    eventId: route?.params?.id,
    pdfCloudinaryId: data?.draftContent?.pdfCloudinaryId,
    onData: ({ data: { data } }) => {
      const { pdfCloudinaryId } = data.pageContentPdfCloudinaryIdUpdated
      if (!pdfCloudinaryId) {
        console.error('There has been an error while creating PDF', data)
      }
      /**
       * Once the PDF in created, we can remove the manual toast we created on save
       */
      setCreatingPDF(false)
      removeSpecificToast(creatingPDFToastId)

      /**
       * If the save action was initiated by Publish btn, then after the Agenda PDF is done
       * we need to trigger the publishing mutation with the selected date
       */

      if (isPublishing) {
        publishSummit(publishDate)
      }
    }
  })

  const handleSave = async () => {
    const errors = await form.validateForm()
    if (Object.keys(errors).length !== 0) {
      showToast(t('summits:editor:formValidationFailed'), ToastType.ERROR)
    }

    await form.submitForm()

    if (invalidatedByEdit) {
      showInvalidatedByEditMessage()
    }
  }

  const publishSummit = async date => {
    publishPageContent({
      variables: { eventId: selectedSummit, publishDate: date },
      onError(error) {
        showToast(error.message, ToastType.ERROR)
      },
      onCompleted() {
        displayMessage(
          t(
            `summits:editor:messages:${
              date ? 'publishSuccess' : 'unpublishSuccess'
            }`
          )
        )
        setPublishModalOpen(false)
        setIsPublishing(false)
        setPublishDate(null)
      }
    })
  }

  const extractTimestampFromCloudinaryId = (
    cloudinaryId: string | undefined
  ) => {
    if (!cloudinaryId) return null

    const splitCloudinaryId = cloudinaryId.split('-')

    return splitCloudinaryId[splitCloudinaryId.length - 1]
  }

  const isDraftPDFOlderThanPublished = () => {
    const draftPDFDate = extractTimestampFromCloudinaryId(
      data?.draftContent?.pdfCloudinaryId
    )
    const publishedPDFDate = extractTimestampFromCloudinaryId(
      dataPublished?.publishedContent?.pdfCloudinaryId
    )
    return draftPDFDate && publishedPDFDate && draftPDFDate < publishedPDFDate
  }

  /**
   * The change of order on saved sections is not triggering the form to be dirty. for saving on publish we need to make sure that the
   * In order to save changes when saved sections are re-ordered we have to compare the initial state with the current state of the form/
   */
  const hasUnsavedChanges = () => {
    if (isAgendaPdfEnabled) {
      if (form.dirty || isDraftPDFOlderThanPublished()) {
        return true
      } else {
        const initialSectionIds =
          data?.draftContent?.sections?.map(s => s.uid) ?? []
        const sectionIds = form.values?.sections?.map(s => s.uid) ?? []

        return sectionIds.some((e, i) => e !== initialSectionIds[i])
      }
    }
    return form.dirty
  }

  const handlePublish = async date => {
    if (isAgendaPdfEnabled) {
      /**
       * If there are changes not saved, then we save them and then publish
       * if all changes are saved, we just need to publish
       */
      const unsavedChanges = hasUnsavedChanges()

      if (unsavedChanges) {
        setIsPublishing(true)
        setPublishDate(date)

        await handleSave()
      } else {
        publishSummit(date)
      }
    } else {
      publishSummit(date)
    }
  }

  if (error) {
    return (
      <ScreenContainer>
        <View>
          <Text>Failed to load summits.</Text>
        </View>
        <View>
          <Text>{error instanceof Error ? error.message : String(error)}</Text>
        </View>
      </ScreenContainer>
    )
  }

  if (loading || (selectedSummit && !data)) {
    return (
      <ScreenContainer>
        <ActivityIndicator />
      </ScreenContainer>
    )
  }

  const { values, setValues } = form

  const canPublish =
    !!data?.draftContent &&
    !!selectedSummit &&
    !loadingPublish &&
    !loadingUpsert &&
    (isAgendaPdfEnabled ? !creatingPDF : true)

  return (
    <ScreenContainer>
      <DiscardChangesModal
        isOpen={!!nextSelectedId || nextSelectedId === null}
        onClose={() => {
          setNextSelectedId(undefined)
        }}
        onSubmit={() => {
          setSelectedSummit(nextSelectedId as string)
          setNextSelectedId(undefined)
        }}
      />
      {(lastPublish || lastUnpublish) && (
        <Flex
          flexDirection="row"
          justifyContent="end"
          style={{ paddingBottom: 12, marginTop: -16 }}
        >
          <Flex flexDirection="row">
            <Button
              type="clear"
              title={lastPublish ? 'View' : 'View App Page'}
              onPress={() => {
                const url = `${MEMBER_WEB_PROTOCOL}://${MEMBER_WEB_URL}/gatherings/${selectedSummit}`
                Platform.OS === 'web'
                  ? window.open(url, '_blank')
                  : Linking.openURL(url)
              }}
              style={{ marginTop: -5, marginRight: 8 }}
            />
            <Text>
              {lastPublish
                ? `${t(
                    'summits:editor:messages:lastPublished'
                  )} ${lastPublish.toLocaleString()}`
                : `${t(
                    'summits:editor:messages:unpublishedOn'
                  )} ${lastUnpublish!.toLocaleString()}`}
            </Text>
          </Flex>
        </Flex>
      )}
      <Flex
        flexDirection="row"
        style={{ marginBottom: 16 }}
        justifyContent="space-between"
      >
        <Flex flexDirection="row" alignItems="center">
          <GatheringSelect
            onSelect={id => {
              if (form.dirty && selectedSummit) {
                setNextSelectedId((id as string) ?? null)
              } else {
                selectSummit(id as string)
              }
            }}
            value={selectedSummit as string}
            style={{ width: 384, marginBottom: 0, marginRight: 8 }}
            initialOptions={
              selectedSummit
                ? {
                    [selectedSummit]: {
                      value: selectedSummit,
                      label: data?.event?.name,
                      custom: {
                        date: new Date(),
                        salesforceId: data?.event?.salesforceId,
                        nameInternal: data?.event?.nameInternal
                      }
                    }
                  }
                : undefined
            }
            isPageBuilder
          />
          {!!selectedSummit && !loading && (
            <Text styles={{ width: 140 }}>
              {form.dirty || !lastSaved
                ? t('summits:editor:statuses:unsavedDraft')
                : !lastPublish ||
                  lastSaved > lastPublish ||
                  !dataPublished?.publishedContent?.publishDate
                ? t('summits:editor:statuses:unpublishedDraft')
                : t('summits:editor:statuses:publishedDraft')}
            </Text>
          )}
        </Flex>
        <Flex flexDirection="row">
          <PreviewSelect
            disabled={
              !selectedSummit ||
              loadingPublish ||
              loadingUpsert ||
              !data?.draftContent
            }
            selectedSummit={selectedSummit}
            pageContent={data?.draftContent}
          />
          <Button
            disabled={!selectedSummit || loadingPublish || loadingUpsert}
            title={t('summits:editor:save')}
            onPress={handleSave}
            style={{ marginRight: 8 }}
          />
          <Button
            title={t('summits:editor:publish')}
            disabled={!canPublish || loadingUpsert}
            onPress={() => {
              setPublishModalOpen(true)
            }}
            icon={
              <CaretIcon
                style={{ marginLeft: 6 }}
                size={16}
                color={!canPublish ? 'grey' : 'white'}
              />
            }
            iconRight
          />
          <PublishModal
            isOpen={isPublishModalOpen}
            onClose={() => setPublishModalOpen(false)}
            startDate={
              dataPublished?.publishedContent?.publishDate
                ? new Date(dataPublished?.publishedContent?.publishDate)
                : undefined
            }
            lastPublish={lastPublish}
            onPublish={handlePublish}
            publishing={loadingPublish || isPublishing}
          />
        </Flex>
      </Flex>
      {isAgendaPdfEnabled && (
        <Flex
          flexDirection="column"
          justifyContent="end"
          style={{ marginBottom: 16 }}
        >
          <Flex flexDirection="row" justifyContent="end">
            <Text>{t('summits:editor:showAttendeesPdf')}</Text>
            <Switch
              value={values?.sections[0]?.includeAttendeesInPdf}
              onValueChange={() => {
                form.setFieldValue(
                  'sections.0.includeAttendeesInPdf',
                  !values?.sections[0]?.includeAttendeesInPdf
                )
              }}
            />
          </Flex>
          <Flex flexDirection="row" justifyContent="end">
            <Text>
              ({data?.event?.attendees?.length ?? 0}{' '}
              {t('summits:editor:numberOfAttendees')})
            </Text>
          </Flex>
        </Flex>
      )}
      {loading && <ActivityIndicator />}
      {!!selectedSummit && !loading && (
        <>
          <ScrollView>
            <>
              <form onSubmit={form.handleSubmit}>
                {values.sections.map((section, sectionIndex) => (
                  <View
                    key={`${sectionIndex}-${section.uid}`}
                    style={{
                      backgroundColor: `${theme.colors.appBackground}`,
                      marginBottom: 16,
                      borderRadius: 4,
                      padding: 8
                    }}
                  >
                    {sectionIndex !== 0 && (
                      <>
                        <SectionControls
                          form={form}
                          section={section}
                          sectionIndex={sectionIndex}
                        />
                        <Divider />
                        <SectionTitleInput
                          form={form}
                          section={section}
                          sectionIndex={sectionIndex}
                        />

                        <Flex
                          flexDirection="row"
                          style={{ marginBottom: 16 }}
                          alignItems="center"
                        >
                          {section.uid === 'logistics' && (
                            <Button
                              title="Reset from Salesforce"
                              onPress={() => {
                                setValues({
                                  ...values,
                                  sections: [
                                    ...values.sections.map(s =>
                                      s.uid !== 'logistics'
                                        ? s
                                        : DefaultSummitSite.getLogistics(
                                            data!.event!
                                          )
                                    )
                                  ]
                                })
                              }}
                              style={{ marginRight: 8, marginTop: 8 }}
                            />
                          )}
                        </Flex>
                      </>
                    )}
                    {section.uid === 'program-guests' ? (
                      <ProgramGuests
                        section={section}
                        sectionIndex={sectionIndex}
                        form={form}
                      />
                    ) : (
                      section.blocks.map((block, blockIndex) => (
                        <ContentBlock
                          key={block.uid || blockIndex}
                          form={form}
                          block={block}
                          blockIndex={blockIndex}
                          section={section}
                          sectionIndex={sectionIndex}
                        />
                      ))
                    )}
                    <Flex
                      flexDirection="row"
                      alignItems="center"
                      justifyContent="flex-end"
                    >
                      {sectionIndex !== 0 &&
                        section.uid !== 'program-guests' &&
                        section.uid !== 'pageBreak' && (
                          <AddBlock
                            onPress={value => {
                              const operator = {
                                'content-block': () => {
                                  return SummitBuilder.blocks.getEmptyBlock()
                                },
                                divider: () => {
                                  return SummitBuilder.blocks.getDividerBlock()
                                },
                                'confidentiality-statement': () => {
                                  return SummitBuilder.blocks.getConfStatement()
                                },
                                container: () => {
                                  return SummitBuilder.blocks.getBlockContainer()
                                },
                                'text-block': () => {
                                  return SummitBuilder.blocks.getTextBlock()
                                }
                              }
                              section.blocks.push(operator[value]())
                              setValues(values)
                            }}
                          />
                        )}
                    </Flex>
                  </View>
                ))}
                <AddSection form={form} event={data?.event} />
              </form>
            </>
          </ScrollView>
        </>
      )}
    </ScreenContainer>
  )
}

export default SummitScreen
