export type RootStackParamList = {
  App: undefined
  Login: undefined
}

export type AppStackParamList = {
  MediaList: undefined
  MediaCreate: undefined
  MediaEdit: { id: string }
  LibraryList: undefined
  DocumentCreate: undefined
  DocumentEdit: { id: string }
  PageBuilderEdit: { id?: string }
  DataExtraction: { graph?: string }
  UserList: undefined
  NotFound: undefined
}

export type AppDrawerParamList = {
  Media: undefined
  Library: undefined
  PageBuilder: undefined
  Image: undefined
  Users: undefined
  CommunitiesMicrositesPermissions: undefined
  UrlShortener: undefined
  NotFound: undefined
  DataExtraction: undefined
  MessageLog: undefined
}

export type LinkingOptionsParamList = {
  Login: undefined
  NotFound: undefined
  App: undefined
  Media: undefined
  Image: undefined
  Library: undefined
  PageBuilder: undefined
  Users: undefined
  UrlShortener: undefined
}

export type MediaNavigatorParamList = {
  MediaList: undefined
  MediaCreate: undefined
  MediaEdit: { id: string }
}

export type LibraryNavigatorParamList = {
  LibraryList: undefined
  DocumentCreate: undefined
  DocumentEdit: { id: string }
}

export type PageBuilderStackParamList = {
  PageBuilderEdit: { id?: string }
}

export type ImageNavigatorParamList = {
  ImageList: undefined
}

export type UserNavigatorParamList = {
  UserList: undefined
}

export type BottomTabParamList = {
  TabOne: undefined
  TabTwo: undefined
}

export type TabOneParamList = {
  TabOneScreen: undefined
}

export type TabTwoParamList = {
  TabTwoScreen: undefined
}

export enum MediaCategoryEnum {
  EngageCalls = 'engageCalls',
  HuddleCalls = 'huddleCalls',
  ExecutiveSummaries = 'executiveSummaries',
  Compilations = 'compilations',
  SummitHighlights = 'summitHighlights',
  Podcasts = 'podcasts'
}

export enum MediaStatusEnum {
  Processing = 'processing',
  Complete = 'complete',
  Error = 'error',
  Unknown = 'unknown'
}

export enum MediaTypeEnum {
  Audio = 'audio',
  Video = 'video'
}

export enum MediaHostEnum {
  Vimeo = 'vimeo',
  Cloudinary = 'cloudinary'
}

export enum DocumentTypeEnum {
  Agenda = 'agenda',
  BriefingBook = 'briefingBook',
  Highlights = 'highlights',
  Insights = 'insights',
  DeepDive = 'deepDive',
  EngageHighlights = 'engageHighlights',
  EvergreenContent = 'evergreenContent',
  OneDegree = 'oneDegree',
  PodcastHighlights = 'podcastHighlights',
  ProgramGuide = 'programGuide',
  Recap = 'recap',
  RoundtableHighlights = 'roundtableHighlights',
  TheW = 'theW',
  Next = 'next',
  Notebook = 'notebook',
  Playbook = 'playbook',
  SharedFile = 'sharedFile'
}

export type GenericObject = {
  [key: string]: any
}

export enum RoleEnum {
  Admin = 'admin',
  Associate = 'associate',
  Member = 'member',
  Developer = 'developer'
}

export interface Person {
  id: string
  firstName: string
  lastName: string
  photo?: {
    id: string
    cloudinaryId: string
  }
  employerName?: string
  emailAddresses?: { email: string }[]
  salesforceId?: string
}

export interface MI6Access {
  hasAccess: boolean
  media: boolean
  library: boolean
  images: boolean
  userManagement: boolean
  pageBuilder: boolean
  urlShortener: boolean
  communitiesMicrositesPermissions: boolean
}

export interface CurrentUser {
  id: string
  roles: RoleEnum[]
  person: Person
  access: MI6Access
}

export enum ScanStatusEnum {
  NotScanned = 'notscanned',
  Pending = 'pending',
  Rejected = 'rejected',
  Approved = 'approved'
}

export enum RawConvertStatusEnum {
  Pending = 'pending',
  Complete = 'complete',
  Failed = 'failed'
}

export interface CloudinaryModerationResponse {
  status: string
  kind: string
}
export interface CloudinaryFileAttachment {
  api_key: string
  asset_id: string
  bytes: number
  created_at: Date
  etag: string
  filename: string
  length: number
  original_filename: string
  placeholder: false
  public_id: string
  resource_type: string
  secure_url: string
  signature: string
  tags: any[]
  type: string
  url: string
  version: number
  version_id: string
  context: any
  custom: { caption: string }
  moderation: CloudinaryModerationResponse[]
}

export enum OsoGroupRoles {
  ADVISOR = 'advisor',
  ADVISING_MEMBER = 'advisingMember',
  ASSOCIATE_GROUP_LEADER = 'associateGroupLeader',
  EXECUTIVE_ADVISOR = 'executiveAdvisor',
  GROUP_LEADER = 'groupLeader',
  MEMBER = 'member',
  MS_ASSOCIATE = 'msAssociate'
}

export enum AppRoles {
  ADMIN = 'admin',
  ASSOCIATE = 'associate',
  DEVELOPER = 'developer'
}

export enum MI6Roles {
  ADMIN = 'admin',
  IMAGE_EDITOR = 'image_editor',
  LIBRARY_EDITOR = 'library_editor',
  MEDIA_EDITOR = 'media_editor',
  PAGE_CONTENT_EDITOR = 'page_content_editor',
  SHORT_URL_EDITOR = 'short_url_editor',
  MICROSITES_EDITOR = 'communities_microsites_permissions'
}

export enum InnovationEditableRoles {
  ADMIN = 'admin',
  STAFF = 'staff'
}

export enum InnovationRoles {
  ADMIN = 'admin',
  ADVISOR = 'advisor',
  TEAM_ADMIN = 'team_admin',
  TEAM_MEMBER = 'team_member',
  STAFF = 'staff'
}

export enum LegacyRoles {
  ADMIN = 'admin',
  ASSOCIATE = 'associate',
  MEMBER = 'member'
}
