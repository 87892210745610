import { gql } from '@apollo/client'
import imageFragment from './imageFragment'
import profilePictureFragment from './profilePictureFragment'

export const currentPersonQuery = gql`
  query getPerson($id: String!) {
    person(id: $id, includeUnpublishedPeople: true) {
      id
      firstName
      lastName
      jobTitle
      bio
      employerName
      salesforceId
      profilePicture {
        __typename
        ... on Image {
          ...imageFragment
        }
        ... on ProfilePicture {
          ...profilePictureFragment
        }
      }
    }
  }
  ${imageFragment}
  ${profilePictureFragment}
`
