import { gql, useQuery } from '@apollo/client'

const getImagesByEvent = gql`
  query($eventId: String!) {
    event(id: $eventId) {
      id
      community {
        id
        taggedImages {
          id
          image {
            id
            cloudinaryId
          }
        }
      }
    }
  }
`

const useGetImagesByEvent = eventId => {
  const { data, refetch } = useQuery(getImagesByEvent, {
    variables: {
      eventId
    },
    skip: !eventId
  })
  return { data: data?.event?.community, refetch }
}

export default useGetImagesByEvent
