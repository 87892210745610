import React, { useCallback } from 'react'

import { View, Text } from 'react-native'
import RolesListItem from './listItem'
import useToast, { ToastType } from '../../../../hooks/useToast'
import Colors from '../../../../constants/Colors'
import { GlobalRoleApps, UserProp } from './utils'
import capitalizeFirstLetter, {
  capitalizeEachLetter
} from '../../../../utils/capitalize'
import styled from 'styled-components/native'
import { innovationEditableRoles } from '../../../../constants/Utils'
import { InnovationEditableRoles } from '../../../../types'

const ListContainer = styled(View)`
  border-radius: 8px;
  border-width: 1px;
  border-color: ${Colors.light.lightGrey};
  border-style: solid;
  padding: 16px;
  max-height: 30%;
  overflow-y: auto;
  margin-bottom: 24px;
`

const RolesList = ({
  user,
  memberships
}: {
  user: UserProp
  memberships: { type: string; name: string }[]
}) => {
  const { showToast } = useToast()

  const onDeleteSuccess = useCallback(
    (role: string) => {
      showToast(
        `Success! ${role} role has been deleted from ${user.name}`,
        ToastType.INFO
      )
    },
    [showToast]
  )

  const mi6 = user.appRoles.filter(item => item.app === GlobalRoleApps.MI6)
  const w50 = user.appRoles.filter(
    item => item.app === GlobalRoleApps.MEMBER_APP
  )
  const innovation = user.appRoles.filter(
    item => item.app === GlobalRoleApps.INNOVATION
  )
  return (
    <View>
      <View
        style={{
          marginBottom: 20
        }}
      >
        <Text style={{ fontSize: 16 }}>Current Roles</Text>
      </View>
      <ListContainer>
        <Text style={{ textAlign: 'center', paddingBottom: 20 }}>
          World 50 App
        </Text>
        <View>
          {w50.map(({ app, role }, index: number) => (
            <RolesListItem
              key={index}
              label={capitalizeFirstLetter(role)}
              userAppRole={{
                userId: user.id,
                app,
                role
              }}
              onDeleteSuccess={() => onDeleteSuccess(role)}
              canDelete
            />
          ))}
          {memberships.map((item, index) => (
            <RolesListItem
              label={`${item.type} of ${item.name}`}
              key={index}
              userAppRole={{
                userId: user.id,
                app: 'member-app',
                role: item.type
              }}
            />
          ))}
        </View>
      </ListContainer>

      <ListContainer>
        <Text style={{ textAlign: 'center', paddingBottom: 20 }}>MI 6</Text>
        <View>
          {mi6.map(({ role, app }, index: number) => {
            const label = capitalizeEachLetter(role.replace(/_/g, ' '))
            return (
              <RolesListItem
                key={index}
                label={label}
                userAppRole={{ userId: user.id, app, role }}
                onDeleteSuccess={() => onDeleteSuccess(label)}
                canDelete
              />
            )
          })}
        </View>
      </ListContainer>
      <ListContainer>
        <Text style={{ textAlign: 'center', paddingBottom: 20 }}>
          Innovation
        </Text>
        <View>
          {innovation.map(({ role, app }, index: number) => {
            const label = capitalizeEachLetter(role.replace(/_/g, ' '))
            return (
              <RolesListItem
                key={index}
                label={label}
                userAppRole={{ userId: user.id, app, role }}
                onDeleteSuccess={() => onDeleteSuccess(label)}
                canDelete={innovationEditableRoles.includes(
                  role as InnovationEditableRoles
                )}
              />
            )
          })}
        </View>
      </ListContainer>
    </View>
  )
}

export default RolesList
