import React, { useEffect, useState } from 'react'
import Button from '../../../ui-library/Button'
import useTranslation from '../../../hooks/useTranslation'

interface CopyToClipboardButtonProps {
  url: string
}

const CopyToClipboardButton = ({ url }: CopyToClipboardButtonProps) => {
  const { t } = useTranslation()
  const [showCopiedText, setShowCopiedText] = useState(false)

  const copyFileUrl = async () => {
    await navigator.clipboard.writeText(url)
    setShowCopiedText(true)
  }

  useEffect(() => {
    if (showCopiedText) {
      const handle = setTimeout(() => {
        setShowCopiedText(false)
      }, 3000)

      return () => clearTimeout(handle)
    }
  }, [showCopiedText])

  return (
    <Button
      title={
        showCopiedText
          ? t('common:labels:copied')
          : t('common:labels:copyFileUrl')
      }
      type="clear"
      onPress={copyFileUrl}
    />
  )
}

export default CopyToClipboardButton
