import { useState, useCallback } from 'react'
import { FileDropzoneFile, getDataFromFile } from '../components/FileDropzone'
import { formatBytesToMB, formatStringWithArgs } from '../utils/format'
import idToCloudinaryUrl from '../utils/idToCloudinaryUrl'

import uploadToCloudinary from '../utils/uploadToCloudinary'
import useTranslation from './useTranslation'

type FileUploadError = 'Unauthorized' | 'noFileSelected' | null
type FileUploadSuccess = 'fileUploaded' | null

type UseFileUploaderResponse = [
  (file: FileDropzoneFile[]) => Promise<void>,
  Number | null,
  FileUploadError,
  FileUploadSuccess
]

const useFileUploader = ({
  isRawConvertRequired,
  isVirusScanRequired,
  handleFileUploaded,
  shouldSetValue,
  cloudinaryDir,
  documentFileSizeLimit,
  setValue,
  multiple,
  setError
}): UseFileUploaderResponse => {
  const { t } = useTranslation()
  const [progress, setProgress] = useState<Number | null>(null)
  const [error, setUploadError] = useState<FileUploadError>(null)
  const [success, setSuccess] = useState<FileUploadSuccess>(null)
  const uploadFile = useCallback(
    async (fileOrFiles: FileDropzoneFile[]) => {
      const files = Array.isArray(fileOrFiles) ? fileOrFiles : [fileOrFiles]

      if (!files.length) {
        setUploadError('noFileSelected')
        setProgress(null)
        setSuccess(null)

        return
      }

      let dataUrls
      if (multiple) {
        const filePromises = files.map(file => getDataFromFile(file))
        dataUrls = await Promise.all(filePromises)
      } else {
        dataUrls = [await getDataFromFile(files[0])]
      }

      files.forEach(file => {
        if (
          documentFileSizeLimit &&
          file.size &&
          file.size > documentFileSizeLimit
        ) {
          const errorMessage: string = formatStringWithArgs(
            t('forms:exceedFileSize'),
            formatBytesToMB(documentFileSizeLimit)
          )
          setProgress(null)
          setSuccess(null)
          setError({
            message: errorMessage
          })
        }
      })

      if (setValue) {
        const values = files.map((file, index) => ({
          fileName: file.name,
          url: dataUrls[index],
          publicId: null,
          assetId: null
        }))
        setValue(multiple ? values : values[0])
      }

      setUploadError(null)
      setProgress(null)
      setSuccess(null)

      try {
        const uploadOptions: any = {}

        if (isRawConvertRequired || isVirusScanRequired) {
          uploadOptions.resourceType = 'raw'
        }

        if (isRawConvertRequired) {
          uploadOptions.raw_convert = 'aspose'
        }

        if (isVirusScanRequired) {
          uploadOptions.moderation = 'perception_point'
        }

        const fileUploadPromises = files.map(file =>
          uploadToCloudinary(file, cloudinaryDir, setProgress, uploadOptions)
        )
        const uploadedFiles: any[] = await Promise.all(fileUploadPromises)

        if (setValue) {
          const fileOptions: any[] = []
          uploadedFiles.forEach(uploadedFile => {
            const options: any = {
              version: uploadedFile.version
            }

            if (uploadedFile.public_id.includes('.pdf')) {
              options.resource_type = 'raw'
            }
            fileOptions.push(options)
          })

          if (shouldSetValue) {
            const values = files.map((file, index) => ({
              fileName: file.name,
              url: idToCloudinaryUrl(
                uploadedFiles[index].public_id,
                fileOptions[index]
              ),
              publicId: uploadedFiles[index].public_id,
              assetId: uploadedFiles[index].asset_id,
              width: uploadedFiles[index].width,
              height: uploadedFiles[index].height
            }))

            setValue(multiple ? values : values[0])
          }
        }

        if (handleFileUploaded) {
          uploadedFiles.forEach(uploadedFile =>
            handleFileUploaded(uploadedFile)
          )
        }

        setProgress(null)
        setSuccess('fileUploaded')
      } catch (err) {
        setUploadError(err)
        setValue(null)
        setProgress(null)
        setSuccess(null)
        if (setError) {
          setError(err.message ? err.message : err)
        }
      }
    },
    [handleFileUploaded, documentFileSizeLimit]
  )

  return [uploadFile, progress, error, success]
}

export default useFileUploader
