import React, { FC, useCallback, useRef, useState } from 'react'
import styled, { useTheme } from 'styled-components/native'
import { TextInput, View, TouchableOpacity } from 'react-native'
import Button from '../../../components/common/Button'
import { Flex } from '../../../components/FlexBox'
import useTranslation from '../../../hooks/useTranslation'

import ClearIcon from '../../../components/icon/ClearIcon'

const SearchRow = styled(View)`
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 500px;
`

const SearchContainer = styled(View)`
  flex: 1;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-radius: 8px;
  background-color: ${({ theme }) => theme.colors.appBackground};
  shadow-color: #000;
  shadow-offset: { width: 0, height: 2 };
  shadow-opacity: 0.25;
  shadow-radius: 3.84px;
  elevation: 5;
  padding: 5px;
`

const SearchBarInput = styled(TextInput)`
  flex: 1;
  padding: 10px;
  border-radius: 8px;
  font-size: 16px;
  color: ${({ theme }) => theme.colors.text};
  background-color: #eeeeee;
  margin-right: 10px;
`

export const ClearButtonContainer = styled(TouchableOpacity)`
  height: 32px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 15;
  margin-left: 5px;
  margin-right: 5px;
`

export const StyledClearIcon = styled(ClearIcon).attrs(
  ({ color, display }) => ({
    size: 18,
    color: color,
    display: display
  })
)``

interface SearchBarProps {
  onSearch: (query?: string) => void
  placeholder: string
}

const SearchBar: FC<SearchBarProps> = ({ onSearch, placeholder }) => {
  const { t } = useTranslation()
  const { colors } = useTheme()
  const inputRef = useRef<TextInput>()
  const [query, setSearchQuery] = useState<string | undefined>()

  const resetSearch = useCallback(() => {
    setSearchQuery('')
    onSearch(undefined)
  }, [setSearchQuery, onSearch])

  const handleSearch = useCallback(() => {
    onSearch(query)
  }, [onSearch, query])

  return (
    <SearchRow>
      <SearchContainer>
        <Flex flex={1} flexDirection="row" alignItems="center">
          <SearchBarInput
            ref={inputRef}
            placeholder={placeholder}
            placeholderTextColor={colors.placeholderText}
            value={query}
            onChangeText={setSearchQuery}
            returnKeyType="done"
            color={colors.text}
            onSubmitEditing={handleSearch}
          />
          <ClearButtonContainer onPress={resetSearch}>
            <StyledClearIcon
              display={query && query.length > 0 ? 'flex' : 'none'}
              color={'#000000'}
            />
          </ClearButtonContainer>
        </Flex>
        <Button label={t('microsites:search')} onPress={handleSearch} />
      </SearchContainer>
    </SearchRow>
  )
}

export default SearchBar
