import React from 'react'

const videoIdRegex = /\/(\d+)\//
const Video = props => {
  const { src } = props
  let thumbnail =
    props.thumbnail || props.hostId
      ? `https://vumbnail.com/${props.hostId}.png`
      : null

  if (!thumbnail) {
    const match = src.match(videoIdRegex)

    if (match && match[1]) {
      thumbnail = `https://vumbnail.com/${match[1]}.png`
    }
  }
  const videoProps = thumbnail
    ? {
        poster: thumbnail
      }
    : {}
  return (
    <video
      controls
      src={src}
      style={{
        width: '100%'
      }}
      {...videoProps}
    />
  )
}

export default Video
