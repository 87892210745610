import { gql, useMutation } from '@apollo/client'

const tagPersonMutation = gql`
  mutation(
    $personId: String!
    $imageId: String!
    $xCoord: Float!
    $yCoord: Float!
  ) {
    createPersonTag(
      personId: $personId
      imageId: $imageId
      xCoord: $xCoord
      yCoord: $yCoord
    ) {
      id
    }
  }
`

const useTagPersonMutation = (options = {}) => {
  const [tagPerson]: any[] = useMutation(tagPersonMutation, options)
  return tagPerson
}

export default useTagPersonMutation
