import gql from 'graphql-tag'
import { useQuery } from '@apollo/client'

const mediaEditQueries = gql`
  query mediaEditQueries {
    hostMedias {
      id
      name
      url
      hostId
      host
      type
      status
      transcode
      createdAt
    }
  }
`

const useMediaEditQuery = () => {
  const { data, refetch } = useQuery(mediaEditQueries)

  return { data, refetch }
}

export default useMediaEditQuery
