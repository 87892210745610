import { gql } from '@apollo/client'

const currentUserQuery = gql`
  query {
    currentUser {
      id
      roles
      access: MI6Access {
        hasAccess
        media
        library
        userManagement
        pageBuilder
        images
        urlShortener
        communitiesMicrositesPermissions
      }
      person {
        id
        firstName
        lastName
        photo {
          id
          cloudinaryId
        }
      }
    }
  }
`

export default currentUserQuery
