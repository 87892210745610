import React, { forwardRef, useContext } from 'react'
import {
  TextInput as RNTextInput,
  TextInputProps as RNTextInputProps
} from 'react-native'
import styled, { ThemeContext } from 'styled-components/native'

const StyledTextInput = styled(RNTextInput)`
  ${({ theme, hasError }) => `
        color: ${
          hasError ? theme.colors.inputTextError : theme.colors.inputText
        };
        background: ${theme.colors.inputBackground};
        border: 1px solid ;
        border-color: ${
          hasError ? theme.colors.inputBorderError : theme.colors.inputBorder
        }
        border-radius: 3px;
        margin-bottom: 20px;
        height: 32px;
        width: 253px;
        padding: 7px 20px 7px 7px;
        position: relative;
    `}
`
export interface TextInputProps extends RNTextInputProps {
  hasError?: boolean
}

export default forwardRef(TextInput)
function TextInput(props: TextInputProps, ref) {
  const theme = useContext(ThemeContext) as any
  return (
    <StyledTextInput
      ref={ref}
      placeholderTextColor={theme.colors.placeholderText}
      {...props}
    />
  )
}
