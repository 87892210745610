import React from 'react'
import CheckBox from '../CheckBox'
import useTranslation from '../../../hooks/useTranslation'

interface Props {
  handlePressed: () => void
  shareable: boolean
  style?: any
}

const ShareCheckbox = ({ handlePressed, shareable }: Props) => {
  const { t } = useTranslation()

  return (
    <CheckBox
      title={t('common:labels:share')}
      checked={shareable}
      onPress={handlePressed}
    />
  )
}

export default ShareCheckbox
