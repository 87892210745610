import { gql } from '@apollo/client'
import { IEventResult, IPageContent } from './types'

export interface IEventQuery {
  events: {
    id: string
    name: string
    nameInternal: string
    timezone: string
    startDate: string
    endDate: string
    salesforceId: string
  }[]
}

export const getSummitsQuery = gql`
  query {
    events(type: summit) {
      id
      name
      nameInternal
      timezone
      startDate
      endDate
    }
  }
`

export interface ITrackedEntity {
  createdAt: string
  updatedAt: string
}

export interface IDbPageContent extends IPageContent, ITrackedEntity {}
export interface IGetPageContentByEventQuery {
  draftContent?: IDbPageContent
  event?: IEventResult
}

export interface IGetPublishedPageContentByEventQuery {
  publishedContent?: Omit<IDbPageContent, 'sections'> & { publishDate?: string }
}

export const getPageContentByEvent = gql`
  query pageContentByEvent($id: String!) {
    draftContent: pageContentByEvent(id: $id) {
      id
      pdfCloudinaryId
      sections {
        uid
        title
        live
        exportToPdf
        includeAttendeesInPdf
        blocks {
          uid
          type
          live
          exportToPdf
          columns {
            type
            subType
            content
          }
        }
      }
      createdAt
      updatedAt
      publishDate
    }

    event(id: $id) {
      id
      name
      salesforceId
      nameInternal
      description
      reservationInfo
      eventAddresses {
        id
        type
        phone
        address {
          id
          name
          address1
          address2
          city
          state
          country
          zip
        }
        description
        addressNotes
      }
      attendees(excludeStaff: true) {
        person {
          firstName
          lastName
        }
      }
      recommendedTransportationName
      recommendedTransportationInfo
    }
  }
`

export const getPublishedPageContentByEvent = gql`
  query pageContentByEvent($id: String!) {
    publishedContent: pageContentByEvent(id: $id, version: 0) {
      id
      pdfCloudinaryId
      publishDate
      createdAt
      updatedAt
    }
  }
`

export const useCreateEventAgendaPDFMutation = gql`
  mutation createEventAgendaPDF($id: String!) {
    createEventAgendaPDF(id: $id)
  }
`
