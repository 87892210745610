import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Button from '../../ui-library/Button'
import Modal from '../../components/common/Modal'
import { PublishDateTimeInput } from '../../components/common/form'
import { Flex } from '../../components/FlexBox'
import { Text } from '../../components/common/Text'
import CalendarIcon from '../../images/calendar.svg'

interface IPublishModalProps {
  publishing?: boolean
  startDate?: Date
  lastPublish?: Date
  onPublish: (date?: Date) => void
  isOpen: boolean
  onClose: () => void
}

export const PublishModal: React.FC<IPublishModalProps> = ({
  startDate,
  lastPublish,
  onPublish,
  publishing,
  isOpen,
  onClose
}) => {
  const [publishDate, setPublishDate] = useState(startDate ?? null)
  const { t } = useTranslation()

  useEffect(() => {
    setPublishDate(startDate ?? null)
  }, [startDate])

  const canPublishButton = publishDate && !publishing
  const canUnpublish = publishDate && lastPublish && !publishing
  return (
    <Modal
      open={isOpen}
      close={onClose}
      showCloseIcon
      contentStyle={{ overflow: 'inital', position: 'static' }}
    >
      <Flex
        flexDirection="row"
        justifyContent="end"
        style={{ paddingBottom: 12 }}
      >
        <Text>
          {lastPublish
            ? `${t(
                'summits:editor:messages:lastPublished'
              )} ${lastPublish.toLocaleString()}`
            : `${t('summits:editor:statuses:unpublishedDraft')}`}
        </Text>
      </Flex>

      <PublishDateTimeInput
        value={publishDate}
        onBlur={() => 1}
        onChangeDate={date => setPublishDate(date)}
        initialDate={publishDate ?? new Date()}
        containerStyle={{ width: 450 }}
      />
      <Flex flexDirection="row" justifyContent="space-between">
        <Button
          disabled={!canUnpublish}
          type="outline"
          title={t('summits:editor:publishModal:unpublish')}
          onPress={() => onPublish(undefined)}
        />

        <Button
          disabled={!canPublishButton}
          iconRight
          icon={
            <CalendarIcon
              style={{ marginLeft: 6 }}
              height={16}
              width={16}
              fill={!canPublishButton ? 'grey' : 'white'}
            />
          }
          title={t('summits:editor:publishModal:publish')}
          onPress={() => onPublish(publishDate!)}
        />
      </Flex>
    </Modal>
  )
}

export default PublishModal
