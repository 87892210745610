import React from 'react'
import { View } from 'react-native'
import { Svg, Path, Circle } from 'react-native-svg'

interface IconProps {
  color: string
  size?: number
  style?: object | object[]
}

const ClearIcon = React.memo(
  ({ color, size = 24, style, ...rest }: IconProps) => (
    <View pointerEvents="none" style={style}>
      <Svg
        width={size}
        height={size}
        viewBox={`0 0 24 24`}
        fill={'none'}
        {...rest}
      >
        <Circle cx="12" cy="12" r="12" fill={color} />
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M17.8356 7.1702L16.6927 6.02734L12.1213 10.5988L7.54984 6.02734L6.40698 7.1702L10.9784 11.7416L6.40698 16.3131L7.54984 17.4559L12.1213 12.8845L16.6927 17.4559L17.8356 16.3131L13.2641 11.7416L17.8356 7.1702Z"
          fill="white"
        />
      </Svg>
    </View>
  )
)

export default ClearIcon
