import React, { useEffect, useState } from 'react'
import i18next from 'i18next'
import { format } from 'date-fns'
import { components as RScomponents } from 'react-select'
import { Flex } from '../../FlexBox'
import Placeholder from '../Placeholder'
import Dropdown, {
  DefaultOptionLabel,
  DropdownOption,
  DropdownProps,
  DropdownSubtitle
} from '../Dropdown'
import useDebounce from '../../../hooks/useDebounce'
import useGatheringSearchQuery from './hooks/useGatheringSearchQuery'
import GatheringSelectOption from './GatheringSelectOption'
// @ts-ignore
import AddAudienceIcon from '../../../images/add-audience.svg'

interface IProps {
  onSelect: (value: string[] | string | null) => void
  onBlur?: (value: string | null) => void
  value: string[] | string | null
  hasError?: boolean
  isMulti?: boolean
  style?: any
  placeholder?: string
  styleContainer?: any
  editingId?: string | null
  initialOptions?: Record<string, any>
  isPageBuilder?: boolean
}

const MultiValueLabel = props => {
  const getSubtitle = () => {
    let subtitle
    if (props.data?.custom?.date) {
      subtitle = new Date(props.data.custom.date)
      subtitle = `${format(subtitle, 'MMM dd, yyyy')}`
    }
    return subtitle || ''
  }
  return (
    <RScomponents.MultiValueLabel {...props}>
      {props.children}
      <DropdownSubtitle>{getSubtitle()}</DropdownSubtitle>
    </RScomponents.MultiValueLabel>
  )
}

const dedupeOptions = (options: DropdownOption[]) => {
  const seen = new Set()
  return options.filter(option => {
    const duplicate = seen.has(option.value)
    seen.add(option.value)
    return !duplicate
  })
}

const GatheringSelect: React.FC<IProps & Partial<DropdownProps>> = ({
  isMulti = false,
  editingId,
  style,
  value,
  styleContainer,
  placeholder = i18next.t('forms:audienceType:gatherings'),
  initialOptions = {},
  onSelect,
  hasError,
  isPageBuilder,
  ...rest
}) => {
  const [currentSelection, setCurrentSelection] = useState({})
  const [gatheringSearchTerm, setGatheringSearchTerm] = useState(null)

  let debouncedGatheringSearchTerm = useDebounce(gatheringSearchTerm, 300)
  const {
    data,
    fetchMore: fetchMoreGatherings,
    refetch
  } = useGatheringSearchQuery(
    debouncedGatheringSearchTerm,
    editingId,
    isPageBuilder
  )

  const [gatheringsList, setGatheringsList] = useState<typeof data>([])

  useEffect(() => {
    if (data.length) {
      setGatheringsList(data)
    }
  }, [data])

  const options = dedupeOptions(
    isMulti
      ? [
          ...Object.values(initialOptions),
          ...Object.values(currentSelection),
          ...gatheringsList
        ]
      : initialOptions[value as string]
      ? [initialOptions[value as string], ...gatheringsList]
      : gatheringsList
  )

  return (
    <Flex
      flexDirection="column"
      style={{ minWidth: !isMulti && '60%', ...styleContainer }}
    >
      {isMulti && <Placeholder>{placeholder}</Placeholder>}
      <Dropdown
        isMulti={isMulti}
        value={value || gatheringSearchTerm}
        options={options}
        placeholder={isMulti ? '' : placeholder}
        getOptionLabel={option => {
          if (!option) {
            return ''
          }
          return `${option.label} ${option.custom?.salesforceId} ${option.custom?.nameInternal}`
        }}
        style={{
          ...(isMulti
            ? { backgroundColor: 'transparent', marginRight: 10 }
            : { width: '100%' }),
          ...style
        }}
        closeMenuOnSelect={!isMulti}
        formatOptionLabel={(props, meta) =>
          meta?.context === 'value' ? (
            <DefaultOptionLabel {...props} />
          ) : (
            <GatheringSelectOption {...props} />
          )
        }
        onMenuScrollToBottom={fetchMoreGatherings}
        onInputChange={setGatheringSearchTerm}
        onSelect={el => {
          if (isMulti && Array.isArray(el)) {
            let newSelection = {}
            el.forEach(
              selection =>
                (newSelection = {
                  ...newSelection,
                  [selection]:
                    initialOptions[selection] ||
                    currentSelection[selection] ||
                    gatheringsList.find(
                      gathering => gathering.value === selection
                    )
                })
            )
            setCurrentSelection(newSelection)
          }
          onSelect(el)
        }}
        dropdownIcon={isMulti ? <AddAudienceIcon /> : null}
        MultiValueLabel={isMulti ? MultiValueLabel : null}
        hasError={hasError}
        isClearable
        onMenuClose={async () => {
          const { data } = await refetch({
            term: ''
          })
          setGatheringsList(
            data?.eventSearch.map(item => ({
              value: item.id,
              label: item.name,
              custom: {
                salesforceId: item.salesforceId,
                nameInternal: item.nameInternal,
                date: item.startDate,
                groups: item.groups
              }
            }))
          )
        }}
        {...rest}
      />
    </Flex>
  )
}

export default GatheringSelect
