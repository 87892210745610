import { atom } from 'recoil'

export interface CopyContentAtomI {
  content: any | null
}

const copyContentAtom = atom<CopyContentAtomI>({
  key: 'copyContent',
  default: { content: null }
})

export default copyContentAtom
