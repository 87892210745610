import gql from 'graphql-tag'
import { useMutation } from '@apollo/client'
import hostMediaFragment from '../queries/hostMediaFragment'

// TODO: setup custom useMutation hook once we have sentry and toast notifications(if we want them)
const createMediaMutation = gql`
  mutation createMedia(
    $id: String
    $title: String!
    $subtitle: String
    $description: String
    $category: MediaCategoryEnum
    $published: Boolean
    $publishDate: DateTime
    $shareable: Boolean
    $hostMediaId: String
    $highlightsHostMediaId: String
    $imageId: String
    $cloudinaryImage: CloudinaryUploadObject
    $communities: [String!]
    $groups: [String!]
    $events: [String!]
    $people: [String!]
    $eventId: String
    $topics: [String!]
    $taggedPeople: [EntityPersonTagInput!]
  ) {
    createMedia(
      id: $id
      title: $title
      subtitle: $subtitle
      description: $description
      category: $category
      published: $published
      publishDate: $publishDate
      shareable: $shareable
      hostMediaId: $hostMediaId
      highlightsHostMediaId: $highlightsHostMediaId
      imageId: $imageId
      cloudinaryImage: $cloudinaryImage
      communities: $communities
      groups: $groups
      events: $events
      people: $people
      eventId: $eventId
      topics: $topics
      taggedPeople: $taggedPeople
    ) {
      id
      title
      subtitle
      description
      category
      published
      publishDate
      shareable
      hostMedia {
        ...hostMediaFragment
      }
      highlightsHostMedia {
        ...hostMediaFragment
      }
      image {
        id
        cloudinaryId
        fileName
      }
      communities {
        id
      }
      groups {
        id
      }
      people {
        id
        firstName
        lastName
        employerName
      }
      events {
        id
        name
        startDate
        groups {
          id
          name
        }
      }
      event {
        id
      }
      topics {
        id
      }
      personTags(includeUnpublishedPeople: true) {
        person {
          id
          firstName
          lastName
        }
        source {
          __typename
          id
          title
        }
      }
    }
  }
  ${hostMediaFragment}
`

// TODO: implement error handling once we have a system in place
const useCreateMediaMutation = (options = {}) => {
  const [createMedia, { loading }]: any[] = useMutation(
    createMediaMutation,
    options
  )
  return { createMedia, loading }
}

export default useCreateMediaMutation
