import gql from 'graphql-tag'
import { useQuery } from '@apollo/client'
import { useNavigation } from '@react-navigation/core'
import hostMediaFragment from './hostMediaFragment'
import useToast, { ToastType } from '../../../hooks/useToast'
import useTranslation from '../../../hooks/useTranslation'

const getMedia = gql`
  query Media($id: String!) {
    media(id: $id) {
      id
      title
      subtitle
      description
      category
      published
      publishDate
      shareable
      hostMedia {
        ...hostMediaFragment
      }
      highlightsHostMedia {
        ...hostMediaFragment
      }
      image {
        id
        cloudinaryId
        fileName
      }
      communities {
        id
      }
      groups {
        id
      }
      people {
        id
        firstName
        lastName
        employerName
      }
      events {
        id
        name
        startDate
        groups {
          id
          name
        }
      }
      event {
        id
      }
      currentUserMediaState {
        id
        lastTimeIndex
        plays
      }
      topics {
        id
      }
      personTags(includeUnpublishedPeople: true) {
        person {
          id
          firstName
          lastName
        }
        source {
          __typename
          id
          title
        }
      }
    }
  }
  ${hostMediaFragment}
`

const useMediaQuery = (id: string | null | undefined) => {
  const navigation: any = useNavigation()
  const { t } = useTranslation()
  const { showToast } = useToast()
  const { data } = useQuery(getMedia, {
    variables: { id },
    skip: !id,
    onError: () => {
      showToast(t('media:errorLoadingMedia'), ToastType.ERROR)
      navigation.replace('MediaList')
    }
  })

  return data
}

export default useMediaQuery
