import { useSetRecoilState } from 'recoil'
import toastMessageAtom from '../recoil/atoms/toastMessageAtom'
import { v4 as uuidv4 } from 'uuid'

export enum ToastType {
  ERROR = 'error',
  INFO = 'info'
}

const DEFAULT_TOAST_TIMEOUT = 6000
export const TOAST_DEATH_MS = 1000

const useToast = () => {
  const setToastMessages = useSetRecoilState(toastMessageAtom)

  const showToast = (
    message?: string,
    type = ToastType.INFO,
    timeout = DEFAULT_TOAST_TIMEOUT
  ) => {
    if (!message) {
      return
    }
    const toast = {
      id: uuidv4() as string,
      type,
      message
    }
    setToastMessages(currentState => [...currentState, toast])
    if (timeout < 0) return
    setTimeout(() => {
      setToastMessages(currentState =>
        currentState.map(item => {
          if (item.id === toast.id) {
            return {
              ...item,
              queuedForRemoval: true
            }
          }
          return item
        })
      )
      setTimeout(() => {
        setToastMessages(currentState =>
          currentState.filter(item => item.id !== toast.id)
        )
      }, TOAST_DEATH_MS)
    }, timeout)
  }

  /**
   * This toast will be always visible until is removed by an action
   */
  const createManualToast = (
    message: string,
    type = ToastType.INFO
  ): { id: string; type: ToastType; message: string } => {
    const toast = {
      id: uuidv4() as string,
      type,
      message
    }

    setToastMessages(currentState => [...currentState, toast])

    return toast
  }

  const removeSpecificToast = (toastId: string) => {
    setToastMessages(currentState =>
      currentState.map(item => {
        if (item.id === toastId) {
          return {
            ...item,
            queuedForRemoval: true
          }
        }
        return item
      })
    )
    setTimeout(() => {
      setToastMessages(currentState =>
        currentState.filter(item => item.id !== toastId)
      )
    }, TOAST_DEATH_MS)
  }

  return { showToast, createManualToast, removeSpecificToast }
}

export default useToast
