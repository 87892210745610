import React from 'react'
import { Foundation } from '@expo/vector-icons'
import styled from 'styled-components/native'
import { TouchableOpacity } from 'react-native'
import useToast, { ToastType } from '../../../hooks/useToast'
interface CopyButtonProps {
  url?: string
}

const Container = styled(TouchableOpacity)`
  ${({ theme: { space, colors }, disabled }) => `
    margin: 0px ${space[2]}px;
    padding: ${space[1]}px ${space[2]}px;
    background-color: ${disabled ? colors.disabled : colors.primary}
    border-radius: 4px;
  `}
`

const CopyShortUrlButton = ({ url }: CopyButtonProps) => {
  const disabled = !url
  const { showToast } = useToast()
  const copyUrl = disabled
    ? () => {}
    : () =>
        navigator.clipboard
          .writeText(url)
          .then(() => showToast('Copied To Clipboard', ToastType.INFO, 2000))

  return (
    <Container onPress={copyUrl} disabled={disabled}>
      <Foundation name="clipboard" size={24} color={'black'} />
    </Container>
  )
}

export default CopyShortUrlButton
