import * as Yup from 'yup'
import { DocumentTypeEnum, GenericObject } from '../../../types'
import { topicsSchema } from '../../../validators/topicsSchema'

const publishSchema = Yup.object().shape(
  {
    id: Yup.string().nullable(),
    body: Yup.object().test('required', 'Required', (value: GenericObject) =>
      value.getCurrentContent().hasText()
    ),
    title: Yup.string().required('Required'),
    subtitle: Yup.string().required('Required'),
    type: Yup.string()
      .required('Required')
      .oneOf(Object.values(DocumentTypeEnum), 'Required'),
    gathering: Yup.string().nullable(),
    heroImage: Yup.object()
      .shape({
        fileName: Yup.string().nullable(),
        url: Yup.string().nullable(),
        publicId: Yup.string().nullable()
      })
      .nullable(),
    communities: Yup.array().when(['groups', 'gatherings', 'people'], {
      is: (groups, gatherings, people) =>
        (!groups || groups.length === 0) &&
        (!gatherings || gatherings.length === 0) &&
        (!people || people.length === 0),
      then: Yup.array().min(1, 'atLeastAudienceType')
    }),
    groups: Yup.array().when(['communities', 'gatherings', 'people'], {
      is: (communities, gatherings, people) =>
        (!communities || communities.length === 0) &&
        (!gatherings || gatherings.length === 0) &&
        (!people || people.length === 0),
      then: Yup.array().min(1, 'atLeastAudienceType')
    }),
    gatherings: Yup.array().when(['communities', 'groups', 'people'], {
      is: (communities, groups, people) =>
        (!communities || communities.length === 0) &&
        (!groups || groups.length === 0) &&
        (!people || people.length === 0),
      then: Yup.array().min(1, 'atLeastAudienceType')
    }),
    people: Yup.array().when(['communities', 'groups', 'gatherings'], {
      is: (communities, groups, gatherings) =>
        (!communities || communities.length === 0) &&
        (!groups || groups.length === 0) &&
        (!gatherings || gatherings.length === 0),
      then: Yup.array().min(1, 'atLeastAudienceType')
    }),
    published: Yup.bool(),
    publishDate: Yup.date().nullable().required('Required'),
    topics: topicsSchema.nullable()
    // taggedPeople: Yup.array()
    //   .of(
    //     Yup.object().shape({
    //       personId: Yup.string().required(),
    //       sourceId: Yup.string().nullable()
    //     })
    //   )
    //   .nullable()
  },
  [
    ['communities', 'groups'],
    ['communities', 'gatherings'],
    ['communities', 'people'],
    ['groups', 'communities'],
    ['groups', 'gatherings'],
    ['groups', 'people'],
    ['gatherings', 'communities'],
    ['gatherings', 'groups'],
    ['gatherings', 'people'],
    ['people', 'communities'],
    ['people', 'groups'],
    ['people', 'gatherings']
  ]
)

export default publishSchema
