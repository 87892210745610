import { useMutation, useQuery } from '@apollo/client'
import { useEffect, useState } from 'react'
import upsertShortUrlMutation from '../mutations/upsertShortUrl'
import getAllShortUrlQuery from '../queries/getAllShortUrls'
import useToggle from '../../../hooks/useToggle'
import deleteShortUrlMutation from '../mutations/deleteShortUrl'
import useToast, { ToastType } from '../../../hooks/useToast'

export interface ShortenedUrl {
  id?: string
  sourceUrl?: string
  targetUrl: string
}
const useUrlShortener = () => {
  const [shortenedUrl, setShortenedUrl] = useState<ShortenedUrl | null>(null)
  const [
    selectedForDeletion,
    setSelectedForDeletion
  ] = useState<ShortenedUrl | null>(null)
  const { isOn, turnOn, turnOff } = useToggle()
  const { showToast } = useToast()

  const [upsertShortUrl, { loading: loadingMutation }] = useMutation(
    upsertShortUrlMutation
  )
  const [deleteShortUrl, { loading: loadingDeletion }] = useMutation(
    deleteShortUrlMutation
  )

  const { data, loading } = useQuery(getAllShortUrlQuery)
  const shortUrls = data?.allShortUrls ?? []
  const findById = (selectedId: string) =>
    shortUrls.find(({ id }) => id === selectedId)

  useEffect(() => {
    if (!isOn) {
      setShortenedUrl(null)
    }
  }, [isOn])

  useEffect(() => {
    if (!isOn && shortenedUrl?.id) {
      turnOn()
    }
  }, [shortenedUrl?.id])

  const selectUrl = (selectedId: string) => {
    setShortenedUrl(findById(selectedId))
  }

  const selectForDeletion = (selectedId: string) => {
    setSelectedForDeletion(findById(selectedId))
  }

  const cancelDeletion = () => setSelectedForDeletion(null)
  const confirmDeletion = () => {
    deleteShortUrl({
      variables: { id: selectedForDeletion?.id },
      onCompleted: () => {
        setSelectedForDeletion(null)
      },
      refetchQueries: [getAllShortUrlQuery]
    })
  }

  const setTargetUrl = (value: string) => {
    shortenedUrl === null
      ? setShortenedUrl({ targetUrl: value })
      : setShortenedUrl({ ...shortenedUrl, targetUrl: value })
  }

  const upsertShortUrlWithVariables = () => {
    upsertShortUrl({
      variables: {
        targetUrl: shortenedUrl?.targetUrl,
        id: shortenedUrl?.id
      },
      onCompleted: data => {
        const { id, sourceUrl, targetUrl } = data.upsertShortUrl
        setShortenedUrl({ id, sourceUrl, targetUrl })
        showToast('Your short URL was generated', ToastType.INFO, 3000)
      },
      onError: error => {
        showToast(error.message, ToastType.ERROR, 8000)
        turnOff()
      },
      refetchQueries: [getAllShortUrlQuery]
    })
  }
  return {
    confirmDeletion,
    cancelDeletion,
    selectForDeletion,
    selectedForDeletion,
    isOn,
    turnOff,
    turnOn,
    targetUrl: shortenedUrl?.targetUrl ?? '',
    setTargetUrl,
    shortenedUrl,
    selectUrl,
    upsertShortUrl: upsertShortUrlWithVariables,
    shortUrls,
    loadingData: loading,
    loadingUpsertion: loadingMutation,
    loadingDeletion
  }
}

export default useUrlShortener
