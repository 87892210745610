import React, { useState, forwardRef } from 'react'
import { Text, TouchableOpacity, View } from 'react-native'
import { DateTimeInputProps } from './types'
import TextInput from '../../ui-library/TextInput'
import DatePicker from 'react-datepicker'
import { localTimezone } from './utils'
import { format as formatDate } from 'date-fns'
import TimeInputPolyfill from 'react-time-input-polyfill'
// eslint-disable-next-line no-restricted-imports
import styled from 'styled-components'

import 'react-datepicker/dist/react-datepicker.css'

const noop = () => null

export default function DateTimeInput(props: DateTimeInputProps) {
  const {
    onChangeDate,
    value,
    maximumDate,
    minimumDate,
    placeholder,
    hasError,
    initialDate = new Date(),
    onBlur,
    containerStyle
  } = props
  const [isCalendarOpen, setIsCalendarOpen] = useState(false)
  const onCalendarOpen = () => setIsCalendarOpen(true)
  const onCalendarClose = () => setIsCalendarOpen(false)

  const DateInput = forwardRef(({ value, ...rest }: { value: any }, ref) => {
    return (
      <UnstyledTextInput
        ref={ref}
        value={value}
        onChangeText={noop}
        onBlur={onBlur}
        {...rest}
        placeholder={placeholder}
      />
    )
  })

  const setTime = timeStr => {
    const [hrs, mins] = (timeStr || '00:00').split(':').map(Number)
    const newDate = new Date((value || new Date()).getTime())
    newDate.setHours(hrs)
    newDate.setMinutes(mins)
    newDate.setSeconds(0)
    newDate.setMilliseconds(0)
    onChangeDate(newDate)
  }

  const timeStrValue = value ? formatDate(value, 'HH:mm') : '00:00'

  const _onChangeDate = function (dt) {
    if (!value && initialDate) {
      dt.setHours(initialDate.getHours())
      dt.setMinutes(initialDate.getMinutes())
      dt.setMilliseconds(initialDate.getMilliseconds())
    }
    onChangeDate(dt)
  }

  return (
    <StyledContainer
      hasError={hasError}
      style={{
        zIndex: isCalendarOpen ? 2 : 0,
        paddingRight: value ? 17 : 0,
        ...(containerStyle ?? {})
      }}
    >
      <DatePicker
        selected={value}
        onChange={_onChangeDate}
        onBlur={onBlur}
        onClickOutside={onBlur}
        maxDate={maximumDate}
        minDate={minimumDate}
        onCalendarOpen={onCalendarOpen}
        onCalendarClose={onCalendarClose}
        customInput={<DateInput value="" />}
        openToDate={props.initialDate}
      />

      {!!value && (
        <>
          <UnstyledTimeInput
            value={timeStrValue}
            onChange={({ value }) => setTime(value)}
            onBlur={onBlur}
            aria-label="time"
            required
          />
          <View style={{ display: 'flex', justifyContent: 'center' }}>
            <TimezoneText>{localTimezone}</TimezoneText>
          </View>
          <ClearButton onPress={() => onChangeDate(null)} />
        </>
      )}
    </StyledContainer>
  )
}

const StyledContainer = styled.div`
  ${({ theme, hasError }) => `
        background: ${theme.colors.inputBackground};
        border: 1px solid ${
          hasError ? theme.colors.inputBorderError : theme.colors.inputBorder
        };
        border-radius: 3px;
        margin-bottom: 20px;
        height: 32px;
        position: relative;
        width: 300px;
        box-sizing: border-box;
        padding:  0 7px;

        display: flex;
        flex-direction: row;
        
        .react-datepicker-wrapper {
          flex: 1;
        }

        input { 
          width: 100%; 
          height: 32px;
          border: none;
          background-color: transparent;
          padding-left: 0px;
          padding-right: 0px;
          font-family:
            system-ui, -apple-system, system-ui, "Segoe UI", Roboto, Ubuntu, "Helvetica Neue", sans-serif;
        }
        input[type="time"] { flex: 1; max-width: 75px;}
        input[type="time"]::-webkit-calendar-picker-indicator {
          display: none;
      }
    `}
`
const UnstyledTextInput = styled(TextInput)`
  border: 0;
  background-color: transparent;
  cursor: pointer;
  padding: 0;
  margin: 0;
`
const UnstyledTimeInput = styled(TimeInputPolyfill)`
  border: 0;
  background: transparent;
  flex: 1;
  box-sizing: border-box;
  font-size: 14px;
  padding: 0;
  color: ${({ theme }) => theme.colors.inputText};
`
const TimezoneText = styled(Text)`
  position: absolute;
  right: 5px;
  bottom: -16px;
  font-size: 12px;
  color: gray;
`
const ClearButton = styled(TouchableOpacity)`
  position: absolute;
  top: 7px;
  right: 7px;
  background: gray;
  height: 17px;
  width: 17px;
  border-radius: 100%;
  text-align: center;
  line-height: 17px;
  color: #fff;

  &::before {
    content: '×';
  }
`
