import { useState, useCallback } from 'react'

const useToggle = (initialToggled = false) => {
  const [isOn, setState] = useState(initialToggled)

  const turnOn = useCallback(() => {
    setState(true)
  }, [])

  const turnOff = useCallback(() => {
    setState(false)
  }, [])

  const toggle = useCallback(() => {
    setState(!isOn)
  }, [isOn])

  return {
    turnOn,
    turnOff,
    toggle,
    isOn
  }
}

export default useToggle
