import React, { FC } from 'react'
import styled from 'styled-components/native'

import ContentDrop, { CustomDropProps } from './ContentDrop'
import WebView from '../../WebView'

import useTranslation from '../../../hooks/useTranslation'
import useToast, { ToastType } from '../../../hooks/useToast'

import FileIcon from '../../../images/drop-file.svg'

const FileDrop: FC<CustomDropProps> = ({
  setError,
  allowedFileTypes,
  ...props
}) => {
  const { t } = useTranslation()
  const { showToast } = useToast()

  const onDropError = error => {
    showToast(error?.message ? error.message : error, ToastType.ERROR)

    setError && setError(error)
  }

  return (
    <ContentDrop
      {...props}
      showCopyClipboard
      setError={onDropError}
      acceptFormat={allowedFileTypes?.map(ext => `.${ext}`)?.join(', ') || ''}
      dropZoneContent={{
        imageIcon: <FileIcon />,
        title: t('library:document:uploadFile')
      }}
      removeContentModal={{
        title: t('library:document:removeFile'),
        description: t('library:document:removeFileConfirmation')
      }}
      onRenderUrl={uri => <PreviewFile source={{ uri }} />}
    />
  )
}

const PreviewFile = styled(WebView)`
  ${({ theme: { sizes } }) => `
    height: ${sizes[7] + sizes[5]};
    width: 100%;
  `}
`

export default FileDrop
