import React from 'react'
import { Image, ImageProps, Platform } from 'react-native'
import idToCloudinaryUrl from '../utils/idToCloudinaryUrl'

interface Props extends Omit<ImageProps, 'source'> {
  publicId: string
  transforms?: any
}

const CloudinaryImage = ({ publicId, transforms, ...rest }: Props) => {
  const url = idToCloudinaryUrl(publicId, {
    ...(transforms || {}),
    quality: 'auto:eco',
    fetchFormat: Platform.OS === 'web' ? 'auto' : 'jpg'
  })
  return <Image source={{ uri: url }} {...(rest ?? {})} />
}

export default CloudinaryImage
