import React from 'react'
import Modal from '../../../components/common/Modal'
import { Flex } from '../../../components/FlexBox'
import { H4 } from '../../../components/common/Text'
import TextInput from '../../../ui-library/TextInput'
import styled from 'styled-components/native'
import { View, Text } from 'react-native'
import Button from '../../../components/common/Button'
import CopyShortUrlButton from './CopyShortUrlButton'

interface NewShortUrlModalProps {
  isOn: boolean
  turnOff: () => void
  targetUrl: string
  setTargetUrl: (value: string) => void
  shortUrl?: string
  upsertShortUrl: () => void
  loading?: boolean
}

const TitleContainer = styled(View)`
  ${({ theme: { space } }) => `
  margin-bottom: ${space[3]}px;
`}
`

const TextInputTitle = styled(Text)`
  ${({ theme: { colors, space } }) => `
    font-size: 14px;
    margin-bottom: ${space[1]}px;
    color: ${colors.primary};
  `}
`

const NewShortUrlModal = ({
  isOn,
  turnOff,
  targetUrl,
  setTargetUrl,
  shortUrl,
  upsertShortUrl,
  loading
}: NewShortUrlModalProps) => {
  return (
    <Modal open={isOn} close={turnOff} showCloseIcon>
      <Flex>
        <TitleContainer>
          <H4>{'New Short Url'}</H4>
        </TitleContainer>
        <TextInputTitle>{'Shorten a Long URL'}</TextInputTitle>
        <TextInput
          value={targetUrl}
          onChangeText={setTargetUrl}
          placeholder="Long link here!"
          autoFocus
        />
        <Flex flexDirection={'row'} alignItems={'center'}>
          <Flex>
            <TextInputTitle>{'Short URL'}</TextInputTitle>
            <TextInput
              key={shortUrl}
              editable={false}
              value={shortUrl}
              placeholder="Short URL"
            />
          </Flex>
          <CopyShortUrlButton url={shortUrl} />
        </Flex>
        <Button
          disabled={loading}
          onPress={upsertShortUrl}
          label="Shorten URL"
        />
      </Flex>
    </Modal>
  )
}

export default NewShortUrlModal
