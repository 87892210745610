import React, { FC, useEffect, useState } from 'react'

import { Foundation } from '@expo/vector-icons'
import EditorButton from './EditorButton'
import { H3 } from '../common/Text'

import { Flex } from '../FlexBox'
import Button from '../common/Button'
import Modal from '../common/Modal'
import useTranslation from '../../hooks/useTranslation'

import { Text } from 'react-native'
import useConfigQuery from '../../screens/media/queries/useConfigQuery'
import { getVideoDetails } from '../../utils/uploadToVimeo'
import TextInput from '../../ui-library/TextInput'
import { MEDIA_REGEX_MAP } from '../../screens/media/constants/media'
import styled from 'styled-components/native'
import useMediaUploader from '../../screens/media/components/useMediaUploader'

interface InlineVideoUploadProps {
  onAddVideo: (uri) => void
}

const InlineVideoUpload: FC<InlineVideoUploadProps> = ({ onAddVideo }) => {
  const { t } = useTranslation()
  const [data, setData] = useState<any>()

  const [modalOpen, setModalOpen] = useState(false)
  const { documentFileSizeLimit, audioFileSizeLimit } = useConfigQuery()
  const [error, setError] = useState<string | null>(null)
  const [formValue, setFormValue] = useState<string>('')
  const [isProcessing, setIsProcessing] = useState<boolean>(false)
  const [isUploading, setIsUploading] = useState(false)
  const [_progress, setProgress] = useState<Number | null>(null)

  const { validateUploadedMediaUrl } = useMediaUploader(
    setData,
    documentFileSizeLimit,
    audioFileSizeLimit,
    [isUploading, setIsUploading],
    [_progress, setProgress]
  )

  const onClose = () => {
    setFormValue('')
    setIsProcessing(false)
    setIsUploading(false)
    setModalOpen(false)
    setData(null)
    setError(null)
  }

  useEffect(() => {
    let media
    if (data) {
      media = { ...data }
      if (data?.type === 'video') {
        if (data?.status === 'complete') {
          if (data?.hostId) {
            getVideoDetails(data?.hostId)
              .then(res => {
                const thumbnail =
                  res?.pictures['base_link'] ||
                  `https://vumbnail.com/${data?.hostId}.png`

                media = {
                  ...media,
                  thumbnail
                }
                if (res['player_embed_url']) {
                  media = {
                    ...media,
                    embedUrl: res['player_embed_url']
                  }
                }
              })
              .finally(() => {
                onAddVideo({
                  hostId: media?.hostId,
                  thumbnail: media?.thumbnail,
                  src: media.url,
                  embedUrl: media?.embedUrl,
                  type: media?.type
                })
                onClose()
              })
          }
        }
      } else if (data?.type === 'audio' && media) {
        onAddVideo({
          src: media.url,
          embedUrl: media?.embedUrl,
          type: media?.type
        })
        onClose()
      }
    }
  }, [data])

  return (
    <>
      <EditorButton
        icon={<Foundation name="play-circle" size={28} color="black" />}
        onPress={() => {
          setModalOpen(true)
        }}
      />
      <Modal open={modalOpen} close={onClose}>
        <H3 styles={{ paddingBottom: 10 }}>
          {t('richTextEditor:addInlineVideoOrAudio')}
        </H3>

        <Flex>
          <TextInput
            placeholder={t('forms:mediaUrl')}
            value={formValue}
            onChangeText={value => {
              // Front end regex validation
              const validUrl = MEDIA_REGEX_MAP.mediaUrl.test(value)
              if (!validUrl && !error) {
                setError(t('media:invalidMediaUrl'))
              } else if (validUrl && error) {
                setError('')
              }
              setFormValue(value)
            }}
            hasError={!!error}
            autoFocus
            style={{ minWidth: '648px', marginTop: '10px' }}
          />
          <ErrorText>{error}</ErrorText>
        </Flex>

        <Flex
          flexDirection="row"
          justifyContent="flex-end"
          style={{ paddingTop: 10 }}
        >
          <Button
            label="Cancel"
            onPress={onClose}
            style={{ marginRight: 10 }}
          />
          <Button
            label="Add Inline Media"
            disabled={isProcessing || !formValue}
            onPress={async () => {
              // Validate with host onPress
              if (error) setError(null)
              setIsProcessing(true)

              try {
                const valid = await validateUploadedMediaUrl(formValue, true)
                if (!valid) {
                  setIsProcessing(false)
                  setError(t('media:invalidMediaUrl'))
                }
              } catch (e: any) {
                setIsProcessing(false)
                setError(e?.message ?? t('media:invalidMediaUrl'))
              }
            }}
          />
        </Flex>
      </Modal>
    </>
  )
}

const ErrorText = styled(Text)`
  ${({ theme }) => `
    color: ${theme.colors.inputTextError};
  `}
`

export default InlineVideoUpload
