import React, { useCallback, useRef } from 'react'
import { View } from 'react-native'
import { Text } from 'react-native-elements'
import { Flex } from '../../components/FlexBox'
import { H4 } from '../../components/common/Text'
import Button from '../../components/common/Button'
import Table, { CellRendererProps } from '../../components/common/Table'
import useTranslation from '../../hooks/useTranslation'
import gql from 'graphql-tag'
import theme from '../../constants/Theme'
import styled from 'styled-components/native'
import { MediaCategoryEnum } from '../../types'
import Constants from 'expo-constants'
import { ScreenContainerWithMenuHeader } from '../../components/layout/ScreenContainer'
import AudioIcon from '../../images/audio.svg'
import VideoIcon from '../../images/video.svg'
import PublishedCellContent from '../../components/cell/PublishedCellContent'
import TitleCell from '../../components/cell/TitleCellContent'
import AudienceCellContent from '../../components/cell/AudienceCellContent'
import { formatDate } from '../../utils/format'
import NoWrapText from '../../components/cell/NoWrapText'
import useLinkToScreen from '../../hooks/useLinkToScreen'
import useTableRefetch from '../../hooks/useTableRefetch'
import MultiValueCellContent from '../../components/cell/MultiValueCellContent'

const MEMBER_WEB_PROTOCOL = Constants.manifest!.extra!.MEMBER_WEB_PROTOCOL
const MEMBER_WEB_URL = Constants.manifest!.extra!.MEMBER_WEB_URL

const getMedias = gql`
  query searchMedias(
    $size: Int
    $offset: Int
    $sortBy: SearchEntitySortBy
    $sortDir: SearchEntitySortDir
    $query: String!
    $publishingState: PublishingState
    $communityId: String
    $groupId: String
    $eventId: String
    $topicIds: [String!]
    $categories: [MediaCategoryEnum!]
  ) {
    searchEntity(
      entity: media
      includeUnpublished: true
      size: $size
      offset: $offset
      sortBy: $sortBy
      sortDir: $sortDir
      query: $query
      publishingState: $publishingState
      communityId: $communityId
      groupId: $groupId
      eventId: $eventId
      topicIds: $topicIds
      categories: $categories
    ) {
      total
      hits {
        __typename
        ... on Media {
          id
          title
          subtitle
          description
          hostMedia {
            id
            type
            duration
          }
          published
          publishDate
          createdAt
          createdAtOverride
          updatedAt
          category
          communities {
            id
            name
            type
            groups {
              id
              name
            }
          }
          topics {
            id
            name
          }
          groups {
            id
            name
          }
          events {
            id
            name
          }
          people {
            id
            firstName
            lastName
          }
        }
      }
    }
  }
`
const MediaScreen = () => {
  const { t } = useTranslation()
  const linkToScreen = useLinkToScreen()
  const tableRef = useRef()

  // @ts-ignore tableRef.current isn't undefined
  useTableRefetch(tableRef?.current?.refetch)

  const onPress = useCallback(() => {
    linkToScreen('MediaCreate')
  }, [linkToScreen])
  return (
    <ScreenContainerWithMenuHeader screenCategory="media">
      <Flex flexDirection="row" mx={4} my={3}>
        <Flex flex={1}>
          <H4>{t('media:title')}</H4>
        </Flex>
        <Button label={t('common:buttons:new')} onPress={onPress} />
      </Flex>
      <Flex flex={1} flexDirection="row" mx={4} my={3}>
        <Flex flex={1} style={{ maxHeight: 650, minWidth: 760 }}>
          <Table columns={columns} query={getMedias} ref={tableRef} />
        </Flex>
      </Flex>
    </ScreenContainerWithMenuHeader>
  )
}

const columns = [
  {
    header: 'Type',
    headerStyle: { textAlign: 'center' },
    width: 70,
    sort: {
      key: 'type'
    },
    Cell: ({ item }: CellRendererProps<any>) => (
      <AVIcon>
        {item.hostMedia ? (
          item.hostMedia.type === 'audio' ? (
            <AudioIcon
              color1={theme.colors.subduedIcon}
              width={16}
              height={13}
            />
          ) : (
            <VideoIcon
              color1={theme.colors.subduedIcon}
              width={20}
              height={16}
            />
          )
        ) : undefined}
      </AVIcon>
    )
  },
  {
    header: 'Created',
    width: 117,
    sort: {
      key: 'createdAt',
      defaultDir: 'desc',
      isDefault: true
    },
    Cell: ({ item }: CellRendererProps<any>) => (
      <NoWrapText>
        {formatDate(item.createdAtOverride || item.createdAt)}
      </NoWrapText>
    )
  },
  {
    header: 'Title',
    sort: {
      key: 'title'
    },
    filter: {
      key: 'query',
      type: 'search',
      defaultValue: ''
    },
    Cell: ({ item }: CellRendererProps<any>) => {
      const linkToScreen = useLinkToScreen()
      const onPress = useCallback(() => {
        linkToScreen('MediaEdit', { id: item.id })
      }, [linkToScreen, item.id])
      return <TitleCell item={item} onPress={onPress} />
    }
  },
  {
    header: 'Audience',
    filter: [
      {
        key: 'communityId',
        type: 'filter',
        placeholder: 'Community'
      },
      {
        key: 'groupId',
        type: 'filter',
        placeholder: 'Group'
      },
      {
        key: 'eventId',
        type: 'filter',
        placeholder: 'Event'
      }
    ],
    // Todo: Clicking a community should filter results to that community?
    Cell: ({ item }: CellRendererProps<any>) => (
      <AudienceCellContent
        communities={item.communities}
        groups={item.groups}
        events={item.events}
        people={item.people}
      />
    )
  },
  {
    header: 'Category',
    filter: {
      key: 'categories',
      type: 'filter'
    },
    sort: {
      key: 'category'
    },
    Cell: ({ item }: CellRendererProps<any>) => {
      const { t } = useTranslation()
      return <Text>{getCategoryText(item.category, t)}</Text>
    }
  },
  {
    header: 'Topics',
    width: 250,
    Cell: ({ item }: CellRendererProps<any>) => (
      <MultiValueCellContent values={item.topics} />
    ),
    filter: {
      key: 'topicIds',
      type: 'filter'
    }
  },
  {
    header: 'Length',
    width: 85,
    Cell: ({ item }: CellRendererProps<any>) => (
      <Text>{getMediaDuration(item?.hostMedia?.duration)}</Text>
    )
  },
  {
    header: 'Published',
    width: 140,
    sort: {
      key: 'publishDate',
      defaultDir: 'desc'
    },
    Cell: ({ item }: CellRendererProps<any>) => {
      const url = `${MEMBER_WEB_PROTOCOL}://${MEMBER_WEB_URL}/media/${item.id}`
      return <PublishedCellContent item={item} url={url} />
    },
    filter: {
      key: 'publishingState',
      type: 'filter'
    }
  }
]

const getCategoryText = (category, t) => {
  switch (category) {
    case MediaCategoryEnum.EngageCalls:
      return t('media:category:engageCalls')
    case MediaCategoryEnum.HuddleCalls:
      return t('media:category:huddleCalls')
    case MediaCategoryEnum.ExecutiveSummaries:
      return t('media:category:executiveSummaries')
    case MediaCategoryEnum.Compilations:
      return t('media:category:compilations')
    case MediaCategoryEnum.SummitHighlights:
      return t('media:category:summitHighlights')
    default:
      return ''
  }
}

// converts numeric seconds count to truncated mm:ss representation
const getMediaDuration = duration => {
  if (!duration) return ''
  const mins = Math.floor(duration / 60)
  const secs = ('' + Math.floor(duration % 60)).padStart(2, '0')
  return `${mins}:${secs}`
}

const AVIcon = styled(View)`
  display: flex;
  flex-direction: column;
  align-items: center;
`
export default MediaScreen
