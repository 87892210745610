import { atom, RecoilState } from 'recoil'

// Note: This recoil atom is an implementation detail
// for communicating between ScreenContainer.tsx and
// AppViewArea in AppNavigator.tsx
// Do not use this atom directly!
// A screen can specify a custom header via the Header prop
// <ScreenContainer Header={...}>
const appHeaderAtom: RecoilState<any> = atom({
  key: 'appHeaderAtom',
  default: null
})

export default appHeaderAtom
