import React from 'react'
import { View } from 'react-native'
import MediaIcon from '../../../images/media-nav.svg'
import LibraryIcon from '../../../images/library-nav.svg'
import LogOutIcon from '../../../images/log-out.svg'
import AnonymIcon from '../../../images/anonym.svg'
import styled from 'styled-components/native'
import Firebase from '../../../Firebase'
import { getAuth as getFBAuth } from 'firebase/auth'
import client from '../../../API'
import useTranslation from '../../../hooks/useTranslation'
import Header from './Header'
import useLinkToScreen from '../../../hooks/useLinkToScreen'
import useFeatureFlag from '../../../hooks/useFeatureFlag'
import EditIcon from '../../icon/EditIcon'
import MenuItem, { MenuItemWithPermission } from './MenuItem'
import { Foundation } from '@expo/vector-icons'

const Container = styled(View)`
  flex: 1;
`

const HorizontalSeparator = styled(View)`
  ${({ theme: { space } }) => `
    height: 1px;
    background-color: rgba(0, 0, 0, 0.1);
    margin: 0px ${space[1]}px;
`}
`

const NavMenu = styled(View)`
  ${({ theme: { space } }) => `
    margin: ${space[3]}px ${space[4]}px;
    align-items: flex-start;
  `}
`

const BottomContainer = styled(NavMenu)``

const SideBar = () => {
  const { t } = useTranslation()
  const linkToScreen = useLinkToScreen()
  const summitSiteEnabled = useFeatureFlag('inAppSummitSite')
  const imageUploadEnabled = useFeatureFlag('imageUploadMI6')
  const userManagementEnabled = useFeatureFlag('mi6UserManagement')
  const mi6CommunitiesMicrositesPermissionsEnabled = useFeatureFlag(
    'mi6CommunitiesMicrositesPermissions'
  )
  const logout = async () => {
    await getFBAuth(Firebase).signOut()
    client.stop()
    client.clearStore()
  }

  return (
    <Container>
      <Header />
      <NavMenu>
        <MenuItemWithPermission
          label={t('media:title')}
          Icon={<MediaIcon />}
          onPress={() => linkToScreen('Media')}
          permission="media"
        />
        {imageUploadEnabled && (
          <MenuItemWithPermission
            label={t('image:title')}
            Icon={<MediaIcon />}
            onPress={() => linkToScreen('Image')}
            permission="images"
          />
        )}
        <MenuItemWithPermission
          label={t('library:title')}
          Icon={<LibraryIcon />}
          onPress={() => linkToScreen('Library')}
          permission="library"
        />
        {summitSiteEnabled && (
          <MenuItemWithPermission
            label={t('summits:title')}
            Icon={<EditIcon />}
            onPress={() => linkToScreen('PageBuilder')}
            permission="pageBuilder"
          />
        )}
        {userManagementEnabled && (
          <MenuItemWithPermission
            label={t('users:title')}
            Icon={<AnonymIcon />}
            onPress={() => linkToScreen('Users')}
            permission="userManagement"
          />
        )}
        {mi6CommunitiesMicrositesPermissionsEnabled && (
          <MenuItemWithPermission
            label={'Communities Microsites'}
            Icon={<Foundation name="link" size={24} />}
            onPress={() => linkToScreen('CommunitiesMicrositesPermissions')}
            permission="communitiesMicrositesPermissions"
          />
        )}
        <MenuItemWithPermission
          label="Url Shortener"
          Icon={<Foundation name="link" size={24} />}
          onPress={() => linkToScreen('UrlShortener')}
          permission="urlShortener"
        />
        <MenuItemWithPermission
          label="Data Extraction"
          Icon={<Foundation name="database" size={24} />}
          onPress={() => linkToScreen('DataExtraction')}
          permission="userManagement"
        />
        <MenuItemWithPermission
          label="Message Log"
          Icon={<Foundation name="list" size={24} />}
          onPress={() => linkToScreen('MessageLog')}
          permission="userManagement"
        />
      </NavMenu>
      <HorizontalSeparator />
      <BottomContainer>
        <MenuItem
          label={t('common:buttons:logout')}
          Icon={<LogOutIcon />}
          onPress={logout}
        />
      </BottomContainer>
    </Container>
  )
}

export default SideBar
