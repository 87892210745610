import { useCallback } from 'react'
import { FormikProps } from 'formik'
import { DocumentTypeEnum } from '../types'

const useFormikHelper = (formik: FormikProps<any>, touchFields) => {
  const initialPublishDateTime = new Date()
  initialPublishDateTime.setHours(9)
  initialPublishDateTime.setMinutes(0)
  initialPublishDateTime.setMilliseconds(0)

  const handleChangeHeroImage = useCallback(media => {
    formik.setTouched(touchFields, false)
    formik.setFieldValue('heroImage', media)
  }, [])

  const handleHeroImageError = useCallback(err => {
    formik.setFieldError('heroImage', err)
  }, [])

  const handleChangeSharedFile = useCallback(file => {
    formik.setTouched(touchFields, false)
    formik.setFieldValue('sharedFile', file)
    formik.setFieldValue('type', file ? DocumentTypeEnum.SharedFile : null)
  }, [])

  const handleSharedFileError = useCallback(err => {
    formik.setFieldError('sharedFile', err)
  }, [])

  const handleSelectGathering = useCallback(
    value => formik.setFieldValue('gathering', value || null),
    []
  )

  const handleSelectGatherings = useCallback(
    value => formik.setFieldValue('gatherings', value || null),
    []
  )

  const handleSelectPeople = useCallback(
    value => formik.setFieldValue('people', value || null),
    []
  )

  const handleChangePublishDate = useCallback(date => {
    formik.setTouched(touchFields, false)
    formik.setFieldValue('publishDate', date)
  }, [])

  const handleChangePublished = useCallback(() => {
    formik.setTouched(touchFields, false)

    const newPublished = !formik.values.published
    formik.setFieldValue('published', newPublished)

    if (newPublished && !formik.values.publishDate) {
      formik.setFieldValue('publishDate', initialPublishDateTime)
    }
  }, [formik?.values?.published, formik?.values?.publishDate])

  const handleChangeShareable = useCallback(() => {
    formik.setTouched(touchFields, false)

    const newShareable = !formik.values.shareable
    formik.setFieldValue('shareable', newShareable)
  }, [formik?.values?.shareable])

  const handleSelectCommunities = useCallback(
    value => formik.setFieldValue('communities', value),
    []
  )

  const handleSelectGroups = useCallback(
    value => formik.setFieldValue('groups', value),
    []
  )

  const handleSelectContentTopic = useCallback(value => {
    formik.setFieldValue('topics', value)
  }, [])

  const handleTagPeople = useCallback(
    values => formik.setFieldValue('taggedPeople', values),
    []
  )

  return {
    handleChangeHeroImage,
    handleHeroImageError,
    handleChangeSharedFile,
    handleSharedFileError,
    handleSelectGathering,
    handleChangePublishDate,
    handleChangePublished,
    handleChangeShareable,
    handleSelectCommunities,
    handleSelectGroups,
    handleSelectContentTopic,
    handleTagPeople,
    initialPublishDateTime,
    handleSelectGatherings,
    handleSelectPeople
  }
}

export default useFormikHelper
