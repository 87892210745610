import { useRecoilState } from 'recoil'

// Atoms
import copyContentAtom from '../recoil/atoms/copyContentAtom'

interface DefaultFields {
  id: string | null
  title: string
  shareable: boolean
  published: boolean
  publishDate: any | null
  [key: string]: any
}

export enum ContentTypeEnum {
  media = 'media',
  document = 'document'
}

export interface CopyContentHook {
  contentCopied: any
  isContentCopied: boolean
  setValuesToCopy: (content: DefaultFields, type: ContentTypeEnum) => void
  resetCopyContent: () => void
}

const useCopyContent = (): CopyContentHook => {
  const [currentCopyContentState, setCopyContentState] = useRecoilState(
    copyContentAtom
  )

  const isContentCopied = !!currentCopyContentState.content

  // Events
  const setValuesToCopy = (content: DefaultFields, type: ContentTypeEnum) => {
    if (content?.[type]) {
      let newContent = {
        ...content,
        [type]: {
          ...content[type],
          id: null,
          shareable: false,
          published: false,
          publishDate: null
        }
      }
      setCopyContentState({ content: newContent })
    }
  }

  const resetCopyContent = () => {
    setCopyContentState({ content: null })
  }

  // Effects

  return {
    setValuesToCopy,
    resetCopyContent,
    isContentCopied,
    contentCopied: isContentCopied ? currentCopyContentState.content : null
  }
}

export default useCopyContent
