import { useState, useEffect } from 'react'
import gql from 'graphql-tag'
import { useSubscription } from '@apollo/client'

const documentUpdatedSubscription = gql`
  subscription($id: String!) {
    documentUpdated(documentId: $id) {
      id
      updatedBy {
        id
        firstName
        lastName

        user {
          id
        }
      }
    }
  }
`

// TODO: Dig into Apollo to see if there is a way around the initialSkip hack.
const useDocumentUpdatedSubscription = (
  id: string | null | undefined,
  onData
) => {
  const [initialSkip, setInitialSkip] = useState(true)

  useEffect(() => {
    requestAnimationFrame(() => {
      setInitialSkip(false)
    })
  }, [])

  useSubscription(documentUpdatedSubscription, {
    variables: { id },
    skip: !id || initialSkip,
    fetchPolicy: 'no-cache',
    onData
  })
}

export default useDocumentUpdatedSubscription
