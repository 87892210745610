import React from 'react'
import Audio from './media/Audio'
import Video from './media/Video'
import Image from './media/Image'

export const Media = props => {
  const entityBlock = props.block.getEntityAt(0)

  if (!entityBlock) {
    return null
  }

  const entity = props.contentState.getEntity(entityBlock)
  const data = entity.getData()
  const { src } = data

  const type = entity.getType()
  let media
  if (type === 'audio') {
    media = <Audio src={src} />
  } else if (type === 'image') {
    media = <Image {...{ ...props, entity }} />
  } else if (type === 'video') {
    media = <Video {...data} src={src} />
  }
  return media
}
