import React from 'react'
import { View } from 'react-native'
import { format } from 'date-fns'
import {
  DropdownOption,
  DropdownTitle,
  DropdownSubtitle
} from '../../../components/common/Dropdown'
import useTranslation from '../../../hooks/useTranslation'

const GatheringSelectOption = ({ label, custom }: DropdownOption) => {
  const { t } = useTranslation()

  const getSubtitle = () => {
    const groups = custom?.groups
    let groupsSubtitle

    if (!groups || !Array.isArray(groups) || !groups.length) {
      groupsSubtitle = t('forms:select:noGroups')
    } else if (groups.length <= 3) {
      groupsSubtitle = groups.reduce((acc, group, idx) => {
        if (idx === groups.length - 1) {
          acc += group.name
        } else {
          acc += `${group.name}, `
        }
        return acc
      }, '')
    } else {
      groupsSubtitle = t('forms:select:manyGroups', {
        numGroups: groups.length
      })
    }

    let eventDate = custom?.date
    if (eventDate) {
      eventDate = new Date(eventDate)
      eventDate = format(eventDate, 'MMM dd, yyyy')
    }

    return eventDate ? `${groupsSubtitle} - ${eventDate}` : groupsSubtitle
  }

  return (
    <View>
      <DropdownTitle>{label}</DropdownTitle>
      <DropdownSubtitle numberOfLines={1} ellipsizeMode={'middle'}>
        {getSubtitle()}
      </DropdownSubtitle>
    </View>
  )
}

export default GatheringSelectOption
