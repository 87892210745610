import React, { FC } from 'react'
import { FormikProps } from 'formik'
import { useTheme } from 'styled-components/native'
import {
  IBlockState,
  ISectionState,
  IEditorState,
  ColumnStateType,
  InternalColumnState,
  ITextColumnValue,
  IMediaState,
  ColumnType
} from './types'
import { Flex } from '../../components/FlexBox'
import useTranslation from '../../hooks/useTranslation'
import { EditorState } from 'draft-js'
import HeroImageDrop from '../../components/common/form/HeroImageDrop'
import { ContentValue } from '../../components/common/form/ContentDrop'
import { Divider } from 'react-native-elements'
import { View } from 'react-native'
import { SummitMediaUploader } from './MediaUploader'
import { ISummitFormErrors } from './validation'
import { ErrorText } from './Common'
import { BlocksController } from './sections/ProgramGuests/BlocksController'
import { SummitRichTextEditor } from './editors/SummitRichTextEditor'
import SummitTextInput from './editors/SummitTextInput'

interface IBlockProps {
  section: ISectionState
  form: FormikProps<IEditorState>
  sectionIndex: number
  blockIndex: number
  block: IBlockState
}

interface IColumnProps {
  type: string
  sectionIndex: number
  blockIndex: number
  columnIndex: number
  form: FormikProps<IEditorState>
  state: InternalColumnState
  block: IBlockState
}

const Column: FC<IColumnProps> = ({
  type,
  block,
  blockIndex,
  columnIndex,
  sectionIndex,
  form,
  state
}) => {
  const isTitleBlock = block.uid === 'title'
  const { t } = useTranslation()
  switch (type) {
    case ColumnStateType.Text:
      return (
        <>
          <SummitTextInput
            multiline={!isTitleBlock}
            placeholder={t('forms:title')}
            value={(state as ITextColumnValue)?.text}
            onChangeText={x =>
              form.setFieldValue(
                `sections.${sectionIndex}.blocks.${blockIndex}.columns.${columnIndex}.state`,
                { text: x }
              )
            }
            hasError={
              !!(
                isTitleBlock &&
                ((form.errors as unknown) as ISummitFormErrors).pageTitleMissing
              )
            }
            style={{
              flex: 2,
              minWidth: 512,
              minHeight: isTitleBlock ? undefined : 80,
              marginRight: 10,
              marginBottom: 0
            }}
          />

          <ErrorText error={form.errors[`sections.${sectionIndex}`]} />
        </>
      )
    case ColumnStateType.Markdown:
      return (
        <SummitRichTextEditor
          onChange={state =>
            form.setFieldValue(
              `sections.${sectionIndex}.blocks.${blockIndex}.columns.${columnIndex}.state`,
              state
            )
          }
          onBlur={() => {
            form.setFieldTouched(
              `sections.${sectionIndex}.blocks.${blockIndex}.columns.${columnIndex}`
            )
          }}
          editorState={state as EditorState}
          placeholder={t('forms:description')}
          style={{
            flex: 1,
            marginRight: 20,
            height: '250px',
            width: '100%'
          }}
        />
      )
    case ColumnStateType.Image:
      if (block.uid === 'hero-image') {
        return (
          <HeroImageDrop
            value={(state as IMediaState)?.value as ContentValue}
            setValue={x => {
              form.setFieldValue(
                `sections.${sectionIndex}.blocks.${blockIndex}.columns.${columnIndex}.state.value`,
                x
              )
            }}
            setError={() => {}}
            cloudinaryDir="summitSites"
            style={{
              padding: 32
            }}
          />
        )
      }
    /* tslint:disable:no-fallthrough */
    case ColumnStateType.Media:
      return (
        <SummitMediaUploader
          disabled={block.columns.length > 2}
          onTypeChange={type => {
            form.setFieldValue(
              `sections.${sectionIndex}.blocks.${blockIndex}.columns.${columnIndex}.type`,
              type
            )
          }}
          onSetValue={x => {
            form.setFieldValue(
              `sections.${sectionIndex}.blocks.${blockIndex}.columns.${columnIndex}.state.value`,
              x
            )
          }}
          onPositionChange={position => {
            form.setFieldValue(
              `sections.${sectionIndex}.blocks.${blockIndex}.columns.${columnIndex}.state.position`,
              position
            )
          }}
          onShapeChange={shape => {
            form.setFieldValue(
              `sections.${sectionIndex}.blocks.${blockIndex}.columns.${columnIndex}.state.shape`,
              shape
            )
          }}
          state={state as IMediaState}
        />
      )
    default:
      return null
  }
}

export const ContentBlock: FC<IBlockProps> = ({
  section,
  form,
  sectionIndex,
  block,
  blockIndex
}) => {
  const { colors } = useTheme()

  const touched = !!form.touched?.sections?.[sectionIndex]?.blocks?.[blockIndex]
  const error =
    touched && form.errors[`sections.${sectionIndex}.blocks.${blockIndex}`]
  return (
    <div
      key={`block-${blockIndex}`}
      style={{
        marginBottom: 8, // blockIndex === section.blocks.length - 1 ? 0 : 8,
        border: `1px solid ${error ? colors.danger : colors.inputBorder}`,
        borderRadius: 4,
        padding: 8
      }}
    >
      <BlocksController
        section={section}
        blockIndex={blockIndex}
        sectionIndex={sectionIndex}
        form={form}
        block={block}
      />

      <Flex
        justifyContent={block.uid === 'hero-image' ? 'center' : 'start'}
        flexDirection="row"
      >
        {block.type === 'divider' && (
          <View style={{ marginTop: 8, marginBottom: 8, width: '100%' }}>
            <Divider style={{ maxWidth: 512 }} />
          </View>
        )}

        {block.columns.length > 0 &&
          block.columns.map((column, columnIndex) => (
            <View
              key={`${section.uid}-${block.uid}-${columnIndex}`}
              style={{
                maxWidth: ColumnType.Markdown ? '100%' : undefined,
                marginRight: columnIndex === block.columns.length - 1 ? 0 : 8,
                flexGrow:
                  (column.type !== ColumnStateType.Image &&
                    column.type !== ColumnStateType.Media) ||
                  (block.uid === 'hero-image' &&
                    !((column.state as unknown) as ContentValue)?.url)
                    ? 1
                    : 0
              }}
            >
              <Column
                type={column.type}
                sectionIndex={sectionIndex}
                blockIndex={blockIndex}
                columnIndex={columnIndex}
                form={form}
                state={column.state}
                block={block}
              />
            </View>
          ))}
      </Flex>
      <ErrorText error={error} />
    </div>
  )
}
