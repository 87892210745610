import React from 'react'
import { View } from 'react-native'
import { Svg, Path } from 'react-native-svg'

interface IconProps {
  color: string
  size?: number
  style?: object | object[]
}

const ArrowLeftIcon = React.memo(
  ({ color, size = 24, style, ...rest }: IconProps) => (
    <View pointerEvents="none" style={style}>
      <Svg width={size} height={size} viewBox="0 0 128 128" {...rest}>
        <Path
          d="M126,59a6.61,6.61,0,0,1,2,5.24,7.47,7.47,0,0,1-2.16,5.07,6.76,6.76,0,0,1-5,2H25.6l35.9,34.58A7.32,7.32,0,0,1,63.74,111a7,7,0,0,1-2.07,5.24,6.93,6.93,0,0,1-5.16,2.24,7.34,7.34,0,0,1-5.15-2L2.16,69.15A7.12,7.12,0,0,1,0,63.92a7.73,7.73,0,0,1,2.16-5.41L51,11.48a7.84,7.84,0,0,1,5.23-2,6.83,6.83,0,0,1,5.07,2.24A7.31,7.31,0,0,1,63.5,17a6.8,6.8,0,0,1-2.33,5.15L25.27,56.69h95.4A7.83,7.83,0,0,1,126,59Z"
          fill={color}
        />
      </Svg>
    </View>
  )
)

export default ArrowLeftIcon
