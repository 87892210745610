import { gql, useMutation, useQuery } from '@apollo/client'
import useToast, { ToastType } from '../../../hooks/useToast'

interface IQueueStats {
  completed: number
  failed: number
  waiting: number
  active: number
  delayed: number
  all: number
}

export interface IQueueGraph {
  contexts: {
    key: string
    value: {
      [key: string]: any
    }
  }[]
  listeners: {
    key: string
    value: {
      config: {
        once: boolean
      }
      ref: {
        type: string
        args: any
      }
    }[]
  }[]
  dependencies: {
    key: string
    value: {
      type: string
      args: any
    }[]
  }[]
}

export interface IQueue {
  name: string
  stats: IQueueStats
  graph: IQueueGraph
}

export interface IQueueJob {
  id: string
  name: string
  data: any
  priority: number
  timestamp: number
  failedReason?: string
}

export const queuesQuery = gql`
  query queues {
    queues {
      name
      stats {
        completed
        failed
        waiting
        active
        delayed
        all
      }
      graph {
        contexts
        listeners
        dependencies
      }
    }
  }
`

export const listQueueJobsQuery = gql`
  query listQueueJobs(
    $queueName: String!
    $state: JobState
    $start: Int
    $end: Int
  ) {
    listQueueJobs(
      queueName: $queueName
      state: $state
      start: $start
      end: $end
    ) {
      jobs {
        id
        name
        data
        priority
        timestamp
        failedReason
      }
      stats {
        completed
        failed
        waiting
        active
        delayed
        all
      }
      graph {
        contexts
        listeners
        dependencies
      }
    }
  }
`

export function useQueues() {
  const { data, refetch, loading } = useQuery<{ queues: IQueue[] }>(queuesQuery)
  return { queues: data?.queues, refetch, loading }
}

const LOAD_N_JOBS = 20

export const useQueueJobs = (
  queueName?: string,
  opts?: {
    state?: string
    start?: number
    end?: number
  }
) => {
  const { data, refetch, loading, fetchMore, error } = useQuery<{
    listQueueJobs: {
      jobs: IQueueJob[]
      stats: IQueueStats
      graph: IQueueGraph
    }
  }>(listQueueJobsQuery, {
    variables: {
      queueName,
      state: opts?.state,
      start: opts?.start,
      end: opts?.end || LOAD_N_JOBS - 1
    },
    skip: !queueName
  })

  const jobsLoaded = data?.listQueueJobs.jobs.length || 0
  const loadMore = () => {
    fetchMore({
      variables: {
        queueName,
        state: opts?.state,
        start: jobsLoaded,
        end: jobsLoaded + LOAD_N_JOBS - 1
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        const allJobs = [
          ...prev.listQueueJobs.jobs,
          ...fetchMoreResult.listQueueJobs.jobs
        ]
        return {
          ...prev,
          listQueueJobs: { ...prev.listQueueJobs, jobs: allJobs }
        }
      }
    })
  }

  const refetchFn = (state?: string) => {
    refetch({
      queueName,
      state
    })
  }

  const allTotal = data?.listQueueJobs.stats.all || 0

  const currentTotal =
    data?.listQueueJobs.stats[
      opts?.state?.toLowerCase() as keyof IQueueStats
    ] || 0

  const allResultsLoaded =
    opts?.state === 'ALL' ? jobsLoaded >= allTotal : jobsLoaded >= currentTotal

  return {
    jobs: data?.listQueueJobs.jobs || [],
    stats: data?.listQueueJobs.stats || {},
    graph: data?.listQueueJobs.graph,
    refetch: refetchFn,
    loading,
    loadMore,
    error,
    allResultsLoaded
  }
}

const obliterateQueueMutation = gql`
  mutation obliterateQueue($queueName: String) {
    obliterateQueue(queueName: $queueName)
  }
`

interface IObliterateQueue {
  obliterateQueue: IQueueJob
}

// we are using 2 different params here, because we want to allow obliterate all queues
// but that shouldn't be the default if we forgeto pass a queueName
interface IObliterateQueueParams {
  queueName?: string
  all?: boolean
}

export const useObliterateQueue = ({
  queueName,
  all
}: IObliterateQueueParams) => {
  const { showToast } = useToast()

  const [obliterateQueue] = useMutation<
    IObliterateQueue,
    IObliterateQueueParams
  >(obliterateQueueMutation, {
    variables: {
      queueName
    },
    onError: error => {
      showToast(
        `Error cancelling queue jobs - \n${error.toString()}`,
        ToastType.ERROR
      )
    }
  })

  if (!queueName && !all) {
    showToast('Queue name is required', ToastType.ERROR)
    return () => new Promise(() => {})
  }

  return obliterateQueue
}
