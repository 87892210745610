import gql from 'graphql-tag'

const getCommunities = gql`
  query communities {
    communities(types: [group]) {
      id
      name
    }
  }
`

export default getCommunities
