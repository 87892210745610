import React from 'react'
import { View } from 'react-native'
import { Svg, Line, G, SvgProps } from 'react-native-svg'

const DEFAULT_STROKE_WIDTH = 4
const DEFAULT_SIZE = 24

interface Props {
  size?: number
  strokeWidth?: number
  rotate?: number
}

const DismissIcon: React.FC<Props & SvgProps> = React.memo(
  ({
    size = DEFAULT_SIZE,
    strokeWidth = DEFAULT_STROKE_WIDTH,
    rotate,
    ...rest
  }) => {
    return (
      <View style={{ transform: [{ rotate: `${rotate ?? 0}deg` }] }}>
        <Svg width={size} height={size} viewBox="0 0 72 72" {...rest}>
          <G id="color" />
          <G id="hair" />
          <G id="skin" />
          <G id="skin-shadow" />
          <G id="line">
            <Line
              x1="17.5"
              x2="54.5"
              y1="17.5"
              y2="54.5"
              fill="none"
              stroke="#000000"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeMiterlimit="10"
              strokeWidth={strokeWidth}
            />
            <Line
              x1="54.5"
              x2="17.5"
              y1="17.5"
              y2="54.5"
              fill="none"
              stroke="#000000"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeMiterlimit="10"
              strokeWidth={strokeWidth}
            />
          </G>
        </Svg>
      </View>
    )
  }
)

export default DismissIcon
