import React, { useState } from 'react'
import Close from '../../../../images/close.svg'
import { View, Text } from 'react-native'
import Button from '../../../../ui-library/Button'
import Modal from 'react-modal'
import RolesList from './list'
import AddRoles from './addRoles'
import useAddUserRoleMutation from './useAddRoleMutation'
import useToast, { ToastType } from '../../../../hooks/useToast'
import Colors from '../../../../constants/Colors'
import capitalizeFirstLetter from '../../../../utils/capitalize'
import { groupRoleLabel } from './utils'

const ManageRolesModal = ({ isOpen, onClose, person }) => {
  const { firstName, lastName } = person
  const name = `${firstName} ${lastName}`
  const { emailAddresses, user, currentMemberships } = person

  const { showToast } = useToast()

  const [newRole, setNewRole] = useState({ app: '', role: '' })
  const [isAddingNewRole, setIsAddingNewRole] = useState(() => {
    const { app, role } = newRole
    return Boolean(app && role)
  })
  const [addUserRole, addRoleLoading] = useAddUserRoleMutation({
    variables: {
      userId: user.id,
      app: newRole.app,
      role: newRole.role
    },
    onCompleted: () => {
      showToast(
        `Success! ${capitalizeFirstLetter(newRole.role).replace(
          /_/g,
          ' '
        )} role has been added to ${name}`,
        ToastType.INFO
      )
      setNewRole({ app: '', role: '' })
      setIsAddingNewRole(false)
    }
  })
  const closeModal = () => {
    setIsAddingNewRole(false)
    setNewRole({ app: '', role: '' })
    onClose()
  }
  const onAddNewRole = () => {
    const { role, app } = newRole
    if (app && role) {
      addUserRole()
    }
    setIsAddingNewRole(true)
  }
  const userMemberships = currentMemberships
    .filter(({ group }) => !!group.active)
    .map(membership => ({
      type: groupRoleLabel[membership.type],
      name: membership.group.name
    }))

  return (
    <Modal style={customStyles} isOpen={isOpen} contentLabel="Example Modal">
      <View
        style={{
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginBottom: 8
        }}
      >
        <Text style={{ fontSize: 20, fontWeight: 'bold' }}>{name}</Text>
        <Button type="clear" onPress={closeModal} icon={<Close />} />
      </View>
      <View
        style={{
          marginBottom: 40
        }}
      >
        <Text>{emailAddresses[0].email}</Text>
      </View>
      {!isAddingNewRole ? (
        <>
          <RolesList user={{ name, ...user }} memberships={userMemberships} />
        </>
      ) : (
        <AddRoles
          onSelection={selections => setNewRole(selections)}
          existingRoles={user.appRoles}
        />
      )}
      <View style={{ justifyContent: 'flex-end' }}>
        <View
          style={{
            flexDirection: 'row',
            justifyContent: 'center',
            // @ts-ignore - gap is definately a thing
            gap: '5%'
          }}
        >
          <Button
            onPress={closeModal}
            type="outline"
            title="Close"
            buttonStyle={{ borderRadius: 30, padding: 16, width: 144 }}
          />
          <Button
            onPress={onAddNewRole}
            type="solid"
            title={addRoleLoading ? 'Adding Role...' : 'Add New Role'}
            disabled={addRoleLoading}
            disabledTitleStyle={{ color: Colors.light.buttonText }}
            buttonStyle={{ borderRadius: 30, padding: 16 }}
          />
        </View>
      </View>
    </Modal>
  )
}

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    height: '70%',
    width: '50%',
    minHeight: 450,
    borderRadius: 8,
    borderWidth: 1,
    borderColor: '#ECECEC',
    borderStyle: 'solid',
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    flex: 1,
    minWidth: 500
  }
}

export default ManageRolesModal
