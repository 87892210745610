import { convertToRaw, EditorState } from 'draft-js'
import {
  ColumnStateType,
  IBlockState,
  IEditorState,
  IMediaState,
  ITextColumnValue,
  ProgramGuestState
} from './types'

export interface ISummitFormErrors {
  [key: string]: string
}

export function isRawStateEmpty(editorState?: EditorState) {
  if (!editorState) {
    return true
  }
  return !(
    convertToRaw(editorState.getCurrentContent()).blocks.length &&
    convertToRaw(editorState.getCurrentContent()).blocks[0].text !== ''
  )
}

export const isEmptyBlock = (block: IBlockState) => {
  if (
    ['divider', 'container'].includes(block.type as string) ||
    ['hero-image', 'overview'].includes(block.uid as string)
  ) {
    return false
  }
  let isEmpty = true
  if (block.type === 'program-guest') {
    const ie = isRawStateEmpty(
      (block.columns[0].state as ProgramGuestState).bio
    )
    const isPhotoEmpty = !(block.columns[0].state as ProgramGuestState).photo
    return ie || isPhotoEmpty
  }
  for (const column of block.columns) {
    if (column.type === ColumnStateType.Text) {
      isEmpty = false
      break
    }
    if (
      column.type === ColumnStateType.Image ||
      column.type === ColumnStateType.Media
    ) {
      if ((column.state as IMediaState)?.value?.url) {
        isEmpty = false
        break
      }
    } else if (column.type === ColumnStateType.Markdown) {
      const editorState = column.state as EditorState
      if (!isRawStateEmpty(editorState)) {
        isEmpty = false
        break
      }
    }
  }
  return isEmpty
}

export const validateSummitForm = (values: IEditorState) => {
  const errors: ISummitFormErrors = {} as ISummitFormErrors
  if (
    !(values.sections[0].blocks[0].columns[0].state as ITextColumnValue).text
  ) {
    errors[`sections.0`] = 'Page title is required'
  }
  for (const [si, s] of values.sections.entries()) {
    if (s.uid !== 'pageBreak') {
      for (const [bi, b] of s.blocks.entries()) {
        if (b.type !== 'pageBreak' && isEmptyBlock(b)) {
          errors[`sections.${si}.blocks.${bi}`] = 'Block is empty'
        }
      }
    }
  }
  return errors
}
