import React, { useCallback, FC } from 'react'
import { ViewStyle, Text, View } from 'react-native'
import Button from '../ui-library/Button'
import { useDropzone } from 'react-dropzone'
// This import error is being ignored because the drag and drop
// feature only works that way
// eslint-disable-next-line no-restricted-imports
import styledWeb from 'styled-components'
import useTranslation from '../hooks/useTranslation'
import { useTheme } from 'styled-components/native'

export const getDataFromFile = (file): Promise<string> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()

    reader.addEventListener('load', e => {
      if (!e || !e.target) {
        reject(new Error('Failed to load file data'))
        return
      }

      resolve((e as any).target.result)
    })

    reader.readAsDataURL(file)
  })
}

export interface DropZoneContentProps {
  selectFileButton: JSX.Element
  validator?: (url: string) => boolean
  isOnlyUrlUpload?: boolean
}

export interface FileDropzoneValue {
  fileName: string
  url: string
  publicId?: string | null
  id?: string | null
  width?: number | null
  height?: number | null
}

export interface FileDropzoneFile {
  name: string
  type: string
  size?: number
}

export interface FileDropzoneProps {
  Content?: FC<DropZoneContentProps>
  hasError?: boolean
  upload: (file: FileDropzoneFile) => void
  accept: string
  multiple?: boolean
  style?: ViewStyle
}

export default function FileDropzone({
  hasError,
  upload,
  Content,
  accept,
  multiple = false,
  ...rest
}: FileDropzoneProps) {
  const { t } = useTranslation()
  const { colors } = useTheme()

  const onDrop = useCallback(async acceptedFiles => {
    // Original logic supported a single file. This was updated to support multiple files,
    // but the support for multiple files was not propegated everywhere, and the multiple
    // flag was not used to determine logic. This fixes it, allowing for multiple files
    // or individual files to be uploaded and logic to remain unchanged.
    const file = multiple ? acceptedFiles : acceptedFiles[0]
    await upload(file)
  }, [])

  const { getRootProps, getInputProps, open, isDragActive } = useDropzone({
    onDrop,
    multiple,
    accept
  })

  const inputProps: any = getInputProps()
  const rootProps = getRootProps({
    // avoids double-opening of the file selector dialog
    onClick: e => e.stopPropagation()
  })
  const selectFileButton = (
    <Button
      title={t('fileDropZone:selectFile')} // TODO: i18n-ify
      type="clear"
      onPress={open}
      titleStyle={{
        color: colors.brightPrimary
      }}
    />
  )

  return (
    <Container
      isDragActive={isDragActive}
      hasError={hasError}
      {...rest}
      {...rootProps}
    >
      <input {...inputProps} />
      {Content && <Content selectFileButton={selectFileButton} />}
      {!Content && (
        <>
          <DZHeaderText>{t('fileDropZone:dropFileHere')}</DZHeaderText>
          <Text>{t('fileDropZone:or')} </Text>
          <DZSubText>{selectFileButton}</DZSubText>
        </>
      )}
    </Container>
  )
}

const Container = styledWeb.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 4px dashed transparent;
  border-color: ${({ hasError, isDragActive, theme }) => {
    return isDragActive
      ? theme.colors.primary
      : hasError
      ? theme.colors.danger
      : 'rgba(0,0,0,0.24)' // TODO: theme-ify this? it's the normal dropzone dashed border color
  }};
  border-radius: 10px;
  box-sizing: border-box;
`
export const DZHeaderText = styledWeb(Text)`
  font-size: 24px;
  line-height: 29px;
  padding-bottom: 5px;
`
export const DZSubText = styledWeb(View)`
  display: flex; 
  flex-direction: row; 
  align-items: baseline; 
  justify-content: center;
`

export const DZIconContainer = styledWeb(View)`
  ${({ theme: { space } }) => `
    margin: ${space[3]}px;
  `}
`
