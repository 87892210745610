import React, { useCallback } from 'react'
import { Platform, View, TouchableOpacity } from 'react-native'
import styled from 'styled-components/native'
import * as Linking from 'expo-linking'
import useTranslation from '../../hooks/useTranslation'
import { formatDate } from '../../utils/format'
import { Text } from 'react-native-elements'
import PreviewIcon from '../../images/preview.svg'
import theme from '../../constants/Theme'
import NoWrapText from './NoWrapText'

const PublishContainer = styled(View)`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`

const PublishDateTextContainer = styled(Text)`
  margin-top: -${props => props.theme.space[1]}px;
  flex: 1;
`

const NonPublishedDate = styled(Text)`
  font-style: italic;
  color: ${props => props.theme.colors.disabled};
`

const PastPublishDate = styled(Text)``

const FuturePublishDate = styled(Text)`
  color: ${props => props.theme.colors.disabled};
`
const Link = ({ to, children, ...rest }) => {
  const onPress = useCallback(() => {
    Linking.openURL(to)
  }, [to])

  if (Platform.OS === 'web') {
    return (
      <a href={to} {...rest}>
        {children}
      </a>
    )
  } else {
    return (
      <TouchableOpacity onPress={onPress} {...rest}>
        {children}
      </TouchableOpacity>
    )
  }
}

interface PublishedCellItem {
  published?: boolean
  publishDate?: string
}
const PublishedCellContent = ({
  item,
  url
}: {
  item: PublishedCellItem
  url: string
}) => {
  const { t } = useTranslation()

  // not published, no date
  if (!item.publishDate) return null

  const publishDate = new Date(item.publishDate)
  const now = new Date()
  const formattedDate = formatDate(publishDate)

  const isFuture = publishDate > now

  const showIcon = item.published && !isFuture

  return (
    <PublishContainer>
      <PublishDateTextContainer>
        <NoWrapText>
          {!item.published && (
            <NonPublishedDate>{formattedDate}</NonPublishedDate>
          )}
          {item.published &&
            (isFuture ? (
              <FuturePublishDate>{formattedDate}</FuturePublishDate>
            ) : (
              <PastPublishDate>{formattedDate}</PastPublishDate>
            ))}
        </NoWrapText>
      </PublishDateTextContainer>
      {showIcon && (
        <Link to={url} title={t('common:labels:preview')} target="_blank">
          <PreviewIcon
            color1={theme.colors.subduedIcon}
            width={22}
            height={15}
          />
        </Link>
      )}
    </PublishContainer>
  )
}

export default PublishedCellContent
