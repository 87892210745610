import React from 'react'

const Audio = props => {
  return (
    <audio
      controls
      controlsList="nodownload"
      src={props.src}
      style={{
        width: '100%'
      }}
    />
  )
}

export default Audio
