export const ACCEPTED_MEDIA_VIDEO_FORMATS = ['video/*']

export const ACCEPTED_MEDIA_AUDIO_FORMATS = ['audio/*']

export const VIMEO_TRANSCODE_STATUS_MAP = {
  complete: 'complete',
  error: 'error',
  in_progress: 'processing'
}

export const MEDIA_REGEX_MAP = {
  mediaUrl: /https?:\/\/(.+?\.)?(world50|everest|cloudinary|vimeo)\.(?:com|io).*/i,
  vimeoHostId: /vimeo.*\/(\d+)/i,
  cloudinaryHostId: /(?:cloudinary|everest|world50).*\/v\d+\/(.+)\.[a-z0-9]+$/i
}
