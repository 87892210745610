import React, { useContext } from 'react'
import Dropdown, { DropdownOption } from '../common/Dropdown'
import { ThemeContext } from 'styled-components/native'

interface EditorDropdownProps {
  value: string
  placeholder: string
  options: DropdownOption[]
  disabled?: boolean
  onSelect: (value) => void
}

const EditorDropdown = ({
  options,
  value,
  onSelect,
  placeholder
}: EditorDropdownProps) => {
  const theme: any = useContext(ThemeContext)

  return (
    <Dropdown
      options={options}
      onSelect={onSelect}
      value={value}
      placeholder={placeholder}
      style={{
        backgroundColor: theme.colors.background,
        marginBottom: 0,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'space-between'
      }}
    />
  )
}

export default EditorDropdown
