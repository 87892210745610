const truncate = (str: string, maxLength: number, useEllipse?: boolean) => {
  let truncatedStr = str.slice(0, maxLength - 1)
  if (useEllipse && str.length > maxLength) {
    truncatedStr += ' ...'
  }

  return truncatedStr
}

export default truncate
