import { format } from 'date-fns'
import { ByteFactor } from '../constants/Utils'
import { Person } from '../types'

export const formatDate = (d: string | number | Date) => {
  const mediaDate = new Date(d)
  return format(mediaDate, 'MMM dd, yyyy')
}

export const formatPerson = (person: Person): string => {
  const employer = person.employerName
  const email = person.emailAddresses?.[0]?.email
  const sfId = !email && !employer ? `${person.salesforceId}` : ''

  return `${person.firstName} ${person.lastName} (${employer || email || sfId})`
}

export const formatBytesToMB = (bytes: number) =>
  bytes !== 0 ? Math.floor(bytes / ByteFactor / ByteFactor) : 0

export const formatStringWithArgs = (string, ...args) => {
  let result = string
  for (let i = 0; i < args.length; i++) {
    result = result.split(`{${i}}`).join(args[i])
  }
  return result
}
