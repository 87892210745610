import React, { useRef } from 'react'
import { Flex } from '../../components/FlexBox'
import { H1 } from '../../components/common/Text'
import Table, { CellRendererProps } from '../../components/common/Table'
import useTranslation from '../../hooks/useTranslation'
import gql from 'graphql-tag'
import { ScreenContainerWithMenuHeader } from '../../components/layout/ScreenContainer'
import TitleCell from '../../components/cell/TitleCellContent'
import useTableRefetch from '../../hooks/useTableRefetch'
import MultiValueCellContent, {
  CellValue
} from '../../components/cell/MultiValueCellContent'
import interpretDateTime from '../../utils/interpretDateTime'
import capitalizeFirstLetter, {
  capitalizeEachLetter
} from '../../utils/capitalize'
import UserManageMenu from './manage'
import { groupRoleLabel } from './manage/roles/utils'
import useFeatureFlag from '../../hooks/useFeatureFlag'

export const searchPersonas = gql`
  fragment personFragment on Person {
    id
    firstName
    lastName
    anonymousTitle
    photo {
      id
      cloudinaryId
    }
    user {
      id
      lastLogin
      roles
      appRoles {
        app
        role
      }
    }
    emailAddresses {
      email
    }
    jobTitle
    employerName
    currentMemberships {
      id
      type
      group {
        id
        name
        active
      }
      startDate
      endDate
    }
  }

  query searchAudience(
    $query: String!
    $offset: Int
    $size: Int
    $memberAppRoles: [String!]
    $mi6Roles: [String!]
    $innovationRoles: [String!]
    $group: [String!]
  ) {
    searchUsers(
      query: $query
      size: $size
      offset: $offset
      memberAppRoles: $memberAppRoles
      mi6Roles: $mi6Roles
      innovationRoles: $innovationRoles
      groups: $group
    ) {
      requestId
      total
      hits {
        __typename
        ... on Person {
          ...personFragment
        }
      }
    }
  }
`

const UserListScreen = () => {
  const { t } = useTranslation()
  const tableRef = useRef()
  const mi6ManageUsersUpdate = useFeatureFlag('mi6ManageUsersUpdate')

  // @ts-ignore tableRef.current isn't undefined
  useTableRefetch(tableRef?.current?.refetch)

  return (
    <ScreenContainerWithMenuHeader screenCategory="users">
      <Flex flexDirection="row" mx={4} my={3}>
        <Flex flex={1}>
          <H1>{t('users:title')}</H1>
        </Flex>
      </Flex>
      <Flex flex={1} flexDirection="row" mx={4} my={3}>
        <Flex flex={1} style={{ maxHeight: 650, minWidth: 760 }}>
          {/* @ts-ignore */}
          <Table
            columns={columns(t, mi6ManageUsersUpdate)}
            query={searchPersonas}
            ref={tableRef}
            plainOffset
          />
        </Flex>
      </Flex>
    </ScreenContainerWithMenuHeader>
  )
}

function columns(t, mi6ManageUsersUpdate) {
  let columns = [
    {
      width: 50,
      header: '',
      Cell: ({ item }: CellRendererProps<any>) => {
        return <UserManageMenu person={item} />
      }
    },
    {
      header: 'Name',
      filter: {
        key: 'query',
        type: 'search',
        defaultValue: ''
      },
      Cell: ({ item }: CellRendererProps<any>) => {
        const titleItem = {
          title: `${item.firstName} ${item.lastName}` || 'Unknown'
        }
        return <TitleCell item={titleItem} />
      }
    },
    {
      header: 'Email',
      Cell: ({ item }: CellRendererProps<any>) => {
        let groupCellValues: CellValue[] =
          item?.emailAddresses?.map(emailAddress => ({
            id: emailAddress?.id,
            name: emailAddress?.email
          })) || []
        let groupMap: Map<string, boolean> = new Map()
        let uniqueGroupCellValues: CellValue[] = []
        groupCellValues.forEach(groupCellValue => {
          if (!groupMap.has(groupCellValue.name)) {
            groupMap.set(groupCellValue.name, true)
            uniqueGroupCellValues.push(groupCellValue)
          }
        })

        return <MultiValueCellContent values={uniqueGroupCellValues} />
      }
    },
    {
      header: 'MI-6',
      filter: [
        {
          key: 'mi6Roles',
          type: 'select',
          placeholder: t('users:selectRole')
        }
      ],
      Cell: ({ item }: CellRendererProps<any>) => {
        const roles: CellValue[] =
          item?.user?.appRoles
            .filter(({ app }) => app === 'mi6')
            .map(({ role }, index) => ({
              id: `mi6-role-${index}`,
              name: `${capitalizeEachLetter(role.replace(/_/g, ' '))} for MI6`
            })) ?? []

        return <MultiValueCellContent values={roles} />
      }
    },
    {
      header: 'World 50',
      filter: [
        {
          key: 'memberAppRoles',
          type: 'select',
          isMulti: mi6ManageUsersUpdate,
          placeholder: t('users:selectRole')
        },
        {
          key: 'group',
          type: 'select',
          isMulti: mi6ManageUsersUpdate,
          placeholder: t('users:selectGroup')
        }
      ],
      Cell: ({ item }: CellRendererProps<any>) => {
        const roles: CellValue[] =
          item?.user?.appRoles
            .filter(({ app }) => app === 'member_app')
            .map(({ role }, index) => ({
              id: `w50-role-${index}`,
              name: `${capitalizeFirstLetter(role).replace(
                /_/g,
                ' '
              )} of World 50`
            })) ?? []
        const currentMemberships =
          item?.currentMemberships
            .filter(({ group }) => !!group.active)
            .map((membership, index) => ({
              id: `w50-membership${index}`,
              name: `${groupRoleLabel[membership.type]} of ${
                membership.group.name
              }`
            })) ?? []
        return (
          <MultiValueCellContent values={[...roles, ...currentMemberships]} />
        )
      }
    },
    {
      header: 'Innovation',
      filter: {
        key: 'innovationRoles',
        type: 'select',
        isMulti: mi6ManageUsersUpdate,
        placeholder: t('users:selectRole')
      },
      Cell: ({ item }: CellRendererProps<any>) => {
        const roles: CellValue[] =
          item?.user?.appRoles
            .filter(({ app }) => app === 'innovation')
            .map(({ role }, index) => ({
              id: `innovation-role-${index}`,
              name: `${capitalizeEachLetter(
                role.replace(/_/g, ' ')
              )} for Innovation`
            })) ?? []

        return <MultiValueCellContent values={roles} />
      }
    },
    {
      header: 'Last Login',
      Cell: ({ item }: CellRendererProps<any>) => {
        const { t } = useTranslation()
        const lastLogin = item?.user?.lastLogin
        interpretDateTime(lastLogin, t)
        const titleItem = {
          title: lastLogin ? interpretDateTime(lastLogin, t) : 'Never'
        }
        return <TitleCell item={titleItem} />
      }
    }
  ]
  return columns
}

export default UserListScreen
