import { useEffect } from 'react'
import useCurrentUser, { CurrentUserHook } from '../../hooks/useCurrentUser'
import Firebase from '../../Firebase'
import { getAuth as getFBAuth } from 'firebase/auth'
import currentUserQuery from './currentUserQuery'
import { CurrentUser } from '../../types'
import { useQuery } from '@apollo/client'

export enum UserAccountStatusEnum {
  Active = 'active',
  Inactive = 'inactive'
}

const CurrentUserController = () => {
  const {
    firebaseToken,
    currentUserId,
    setCurrentUser
  }: CurrentUserHook = useCurrentUser()

  const { data } = useQuery(currentUserQuery, {
    skip: !getFBAuth(Firebase).currentUser,
    fetchPolicy: 'no-cache'
  })

  // Set current user metadata
  useEffect(() => {
    const queryUser: CurrentUser | null = data?.currentUser || null
    if (currentUserId !== queryUser?.id) {
      setCurrentUser(queryUser)
    }
  }, [data])

  useEffect(() => {
    if (!firebaseToken) {
      setCurrentUser(null)
    }
  }, [firebaseToken])

  return null
}

export default CurrentUserController
