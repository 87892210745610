import React, { useState } from 'react'
import {
  Text,
  TouchableOpacity,
  View,
  ViewStyle,
  Image,
  Platform
} from 'react-native'
import styled from 'styled-components/native'
import ContentDrop, {
  ContentValue
} from '../../components/common/form/ContentDrop'
import { FileDropzoneValue } from '../../components/FileDropzone'
import useTranslation from '../../hooks/useTranslation'
import {
  ColumnStateType,
  ImageShape,
  IMediaState,
  MediaPosition
} from './types'
import DropImageIcon from '../../images/drop-image.svg'
import useConfigQuery from '../media/queries/useConfigQuery'
import Dropdown from '../../components/common/Dropdown'
import { Flex } from '../../components/FlexBox'
import useToast from '../../hooks/useToast'
import { MediaUploaderValue } from '../media/components/MediaUploader'
import { MediaURLPaste } from './MediaURLPaste'

type UploadType = 'image' | 'media'

const PreviewImage = styled(Image)`
  ${({ theme: { sizes } }) => `
    height: ${sizes[6] + sizes[5]};
    width: 100%;
  `}
`

const UploaderContainer = styled(View)`
  ${({ theme: { radii, borderWidths, colors } }) => `

   
        min-width: 320px;
        min-height: 223px;
        background-color: ${colors.background};
        border-width: ${borderWidths[1]}px;
        border-top-width: 0px;
        border-color: ${colors.border};
        border-bottom-left-radius: ${radii[2]};
        border-bottom-right-radius: ${radii[2]};
        justify-content: center;
        align-items: center;
    `}
`

const TabsContainer = styled(View)`
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
`

const Tab = styled(TouchableOpacity).attrs(() => ({
  activeOpacity: 1
}))`
  ${({ active, disabled, theme: { radii, borderWidths, colors } }) => `
    border-width: ${borderWidths[1]}px;
    border-bottom-width: ${active ? 0 : borderWidths[1]}px;
    border-top-left-radius: ${radii[2]}px;
    border-top-right-radius: ${radii[2]}px;
    border-color: ${colors.border};
    justify-content: center;
    align-items: center;
    display: flex;
    flex-grow: 1;
    ${Platform.OS === 'web' && disabled ? 'cursor: not-allowed;' : ''}
  `}
`

const TabName = styled(Text)`
  ${({ active, theme: { space, colors } }) => `
    margin-vertical: ${space[1]}px;
    color: ${active ? colors.text : colors.disabled};
  `}
`

const dropZoneStyle: ViewStyle = {
  padding: '12px 64px'
}

interface IProps {
  onTypeChange: (type: ColumnStateType) => void
  onPositionChange?: (position: MediaPosition) => void
  onShapeChange?: (shape: ImageShape) => void
  onSetValue: (value: FileDropzoneValue | MediaUploaderValue | null) => void
  style?: ViewStyle
  state?: IMediaState
  disabled?: boolean
}

export const SummitMediaUploader: React.FC<IProps> = ({
  onTypeChange,
  onSetValue,
  onPositionChange,
  onShapeChange,
  state,
  disabled
}) => {
  const value = state?.value
  const initialState = (value as MediaUploaderValue)?.hostId ? 'media' : 'image'
  const { t } = useTranslation()
  const { documentFileSizeLimit, audioFileSizeLimit } = useConfigQuery()
  const [tab, setTab] = useState<UploadType>(initialState)
  const [isUploading, setUploading] = useState(false)
  const { showToast } = useToast()
  const [position, setPosition] = useState<MediaPosition>(
    state?.position || 'left'
  )
  const [shape, setShape] = useState<ImageShape>(state?.shape || 'rectangle')

  const url = (value as ContentValue)?.url

  const changeTab = (type: UploadType) => {
    setTab(type)
    onTypeChange(type as ColumnStateType)
  }

  return (
    <View
      style={{ opacity: disabled ? 0.4 : 1 }}
      pointerEvents={disabled ? 'none' : 'auto'}
    >
      <TabsContainer>
        <Tab
          active={tab === 'image'}
          disabled={tab === 'media' && url}
          onPress={() => (!value || !value.url) && changeTab('image')}
        >
          <TabName active={tab === 'image'}>
            {t('summits:editor:mediaUploader:image')}
          </TabName>
        </Tab>
        <Tab
          active={tab === 'media'}
          disabled={(tab === 'image' && url) || isUploading}
          onPress={() => (!value || !value.url) && changeTab('media')}
        >
          <TabName active={tab === 'media'}>
            {t('summits:editor:mediaUploader:media')}
          </TabName>
        </Tab>
      </TabsContainer>
      <UploaderContainer>
        {tab === 'image' && (
          <ContentDrop
            value={
              value && (value as ContentValue).url
                ? (value as ContentValue)
                : null
            }
            setValue={onSetValue}
            acceptFormat={'image/*'}
            dropZoneContent={{
              imageIcon: <DropImageIcon />,
              title: t('media:dropImage')
            }}
            removeContentModal={{
              title: t('media:removeImage'),
              description: t('media:removeImageConfirmation')
            }}
            onRenderUrl={uri => (
              <PreviewImage resizeMode="contain" source={{ uri }} />
            )}
            setError={error => {
              showToast(error)
              onSetValue(null)
            }}
            cloudinaryDir="summitSites"
            style={dropZoneStyle}
          />
        )}

        {tab === 'media' && (
          <MediaURLPaste
            documentFileSizeLimit={documentFileSizeLimit}
            audioFileSizeLimit={audioFileSizeLimit}
            setError={error => {
              showToast(error)
              onSetValue(null)
            }}
            setUploading={setUploading}
            value={value as MediaUploaderValue}
            setValue={value => {
              onSetValue(value as MediaUploaderValue)
            }}
            style={dropZoneStyle}
          />
        )}
        <Flex
          alignItems="center"
          justifyContent="space-between"
          flexDirection="row"
        >
          {(value as MediaUploaderValue)?.type !== 'audio' && (
            <Dropdown
              style={{ maxWidth: 160 }}
              value={position}
              placeholder="Position"
              onSelect={p => {
                setPosition(p)
                onPositionChange && onPositionChange(p)
              }}
              options={[
                { label: 'Left', value: 'left' },
                { label: 'Centered', value: 'centered' },
                { label: 'Right', value: 'right' }
              ]}
            />
          )}
          {tab === 'image' && (
            <Dropdown
              style={{ maxWidth: 160 }}
              value={shape}
              placeholder="Shape"
              onSelect={s => {
                setShape(s)
                onShapeChange && onShapeChange(s)
              }}
              options={[
                { label: 'Rectangle', value: 'rectangle' },
                { label: 'Circle', value: 'circle' }
              ]}
            />
          )}
        </Flex>
      </UploaderContainer>
    </View>
  )
}
