import React, { useState } from 'react'
import { View } from 'react-native'
import { useLazyQuery } from '@apollo/client'
import PersonTagDropDown from '../../../../components/common/form/PersonTagDropdown'
import useToast, { ToastType } from '../../../../hooks/useToast'
import Button from '../../../../ui-library/Button'
import styled from 'styled-components/native'
import { currentPersonQuery } from './queries'
import { ImageType } from './queries/imageFragment'
import { ProfilePictureType } from './queries/profilePictureFragment'
import { Flex } from '../../../../components/FlexBox'

interface IProps {
  onSelected: (selection: IQueryPerson) => void
  addPageBreak: () => void
}

export interface IQueryResponse {
  person: IQueryPerson
}

interface IQueryPerson {
  id: string
  bio: string
  employerName: string
  firstName: string
  jobTitle: string
  lastName: string
  salesforceId: string
  profilePicture: ImageType | ProfilePictureType
}

const Container = styled(View)`
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
`

const AddProgramGuest = ({ onSelected, addPageBreak }: IProps) => {
  const [getUser] = useLazyQuery<IQueryResponse>(currentPersonQuery)
  const [selected, setSelected] = useState<{ personId: string }>()

  const { showToast } = useToast()
  return (
    <Container>
      <Flex flexDirection="row" width="100%" alignItems="center">
        <PersonTagDropDown
          personId={selected?.personId}
          containerStyle={{
            width: 'auto',
            marginTop: 8
          }}
          excludeIds={[]}
          hideSourceId
          onValueChange={a => {
            if (a) {
              setSelected(a)
            } else {
              setSelected(undefined)
            }
          }}
        />
        <Button
          title="Add Speaker"
          disabled={!selected}
          style={{ marginLeft: 8, marginBottom: 14 }}
          onPress={() => {
            getUser({
              variables: { id: selected?.personId },
              onCompleted(data) {
                onSelected(data.person)
              },
              onError() {
                showToast('Error loading profile', ToastType.ERROR)
              }
            })
            setSelected(undefined)
          }}
        />
      </Flex>
      <Flex
        flexDirection="row"
        width="100%"
        alignItems="center"
        justifyContent="flex-end"
      >
        <Button
          title="Add Page break"
          style={{ marginLeft: 8, marginBottom: 14 }}
          onPress={() => {
            addPageBreak()
          }}
        />
      </Flex>
    </Container>
  )
}

export default AddProgramGuest
