import React from 'react'
import { View, TouchableOpacity } from 'react-native'
import styled from 'styled-components/native'
import theme from '../../constants/Theme'
import EllipticalText from './EllipticalText'

const TitleContainer = styled(View)`
  flex-direction: column;
  width: 100%;
  min-height: 44px;
  justify-content: center;
`

interface TitleCellItem {
  title: string
  subtitle?: string
}

interface TitleCellProps {
  item: TitleCellItem
  onPress?: () => void
}

const FormatTitle = ({ title, onPress }: any) => {
  if (onPress) {
    return (
      <TouchableOpacity onPress={onPress}>
        <EllipticalText style={{ color: theme.colors.brightPrimary }}>
          {title}
        </EllipticalText>
      </TouchableOpacity>
    )
  }

  return <EllipticalText>{title}</EllipticalText>
}

const TitleCell = ({ item, onPress }: TitleCellProps) => {
  return (
    <TitleContainer>
      <FormatTitle title={item.title} onPress={onPress} />
      <EllipticalText>{item.subtitle}</EllipticalText>
    </TitleContainer>
  )
}

export default TitleCell
