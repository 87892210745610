import { useMemo } from 'react'

export const useInitialPeopleOptions = (item: any) => {
  const initialPeopleOptions = useMemo(() => {
    let initialOptions = {}
    if (item?.people?.length) {
      item.people.forEach(person => {
        initialOptions = {
          ...initialOptions,
          [person.id]: {
            value: person.id,
            label: `${person.firstName} ${person.lastName}`,
            custom: { employerName: person.employerName || '' }
          }
        }
      })
    }
    return initialOptions
  }, [item])
  return { initialPeopleOptions }
}

export const useInitialGatheringOptions = (item: any) => {
  const initialGatheringOptions = useMemo(() => {
    let initialOptions = {}
    if (item?.events?.length) {
      item.events.forEach(event => {
        initialOptions = {
          ...initialOptions,
          [event.id]: {
            value: event.id,
            label: event.name,
            custom: {
              date: event.startDate,
              groups: event.groups
            }
          }
        }
      })
    }
    return initialOptions
  }, [item])
  return { initialGatheringOptions }
}
