import { useFormik } from 'formik'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { ButtonGroup, Icon, Text } from 'react-native-elements'
import Button from '../../components/common/Button'
import Dropdown from '../../components/common/Dropdown'
import { H4 } from '../../components/common/Text'
import { Flex } from '../../components/FlexBox'
import { ScreenContainerWithMenuHeader } from '../../components/layout/ScreenContainer'
import useTranslation from '../../hooks/useTranslation'
import styled from 'styled-components/native'
import GatheringSelect from '../../components/common/form/GatheringSelect'
import useSearchPeopleQuery from '../../components/common/form/hooks/useSearchPeopleQuery'
import useDebounce from '../../hooks/useDebounce'
import { Image, View } from 'react-native'
import useToast, { ToastType } from '../../hooks/useToast'
import theme from '../../constants/Theme'
import ContentDrop from '../../components/common/form/ContentDrop'
import DropImageIcon from '../../images/drop-image.svg'
import CloudinaryImage from '../../components/CloudinaryImage'
import useGetImagesByPerson from './queries/useGetImagesByPerson'
import useGetImagesByEvent from './queries/useGetImagesByEvent'
import useCreateImageMutation from './mutations/useCreateImageMutation'
import useTagPersonMutation from './mutations/useTagPersonMutation'
import useTagEventMutation from './mutations/useTagEventMutation'
import useDeletePersonTagMutation from './mutations/useDeletePersonTagMutation'
import useDeleteEventTagMutation from './mutations/useDeletePersonTagMutation copy'

const HeaderText = styled(Text)`
  font-weight: bold;
  font-size: 20px;
  white-space: nowrap;
`

const ImageItem = styled(CloudinaryImage)`
  ${({ theme: { sizes } }) => `
  height: ${sizes[6]}px;
  width: ${sizes[6]}px;
`}
`

const ImageGallery = styled(Flex)`
  ${({ theme: { space } }) => `
  margin-left: ${space[4]}px;
  align-content: flex-start;
  height: 100%;
  overflow: auto;
`}
`

const ImageWrapper = styled(View)`
  ${({ theme: { space } }) => `
  margin: ${space[2]}px;
`}
`

const PreviewImage = styled(Image)`
  height: 50px;
  width: 50px;
`

interface ImageEntity {
  id: string
  cloudinaryId: string
}
interface ImageTag {
  id: string
  image: ImageEntity
}

const ImageScreen = () => {
  const { t } = useTranslation()
  const [userSearchTerm, setUserSearchTerm] = useState(null)
  const debouncedUsersTerm = useDebounce(userSearchTerm, 300)
  const { data: userList } = useSearchPeopleQuery({ query: debouncedUsersTerm })
  const [images, setImages] = useState<ImageTag[]>([])
  const { showToast } = useToast()
  const [taggedImagesCount, setTaggedImagesCount] = useState(0)
  const [communityId, setCommunityId] = useState('')

  const SELECTOR_OPTIONS = ['Gatherings', 'Member']

  const DEFAULT_FORM_VALUES: any = {
    gatheringsOrMember: SELECTOR_OPTIONS[0],
    gathering: '',
    member: '',
    imageToUpload: []
  }

  const formik = useFormik({
    initialValues: DEFAULT_FORM_VALUES,
    validate: values => {
      let errors = {}
      if (!values.imageToUpload) {
        errors['imageToUpload'] = 'Required'
      }
      if (
        values.gatheringsOrMember === SELECTOR_OPTIONS[0] &&
        !values.gathering
      ) {
        errors['gathering'] = 'Required'
      }

      if (values.gatheringsOrMember === SELECTOR_OPTIONS[1] && !values.member) {
        errors['member'] = 'Required'
      }
      return errors
    },
    onSubmit: async values => {
      values.imageToUpload.forEach(image =>
        createImage({
          variables: { cloudinaryId: image.publicId }
        })
      )
    }
  })
  const {
    data: getImagesByPersonData,
    refetch: refetchImagesByPerson
  } = useGetImagesByPerson(formik.values.member)

  const {
    data: getImagesByEventData,
    refetch: refetchImagesByEvent
  } = useGetImagesByEvent(formik.values.gathering)

  const tagPerson = useTagPersonMutation({
    onCompleted: async results => {
      const resultId = results?.createPersonTag?.id
      if (resultId) {
        if (taggedImagesCount + 1 === formik.values.imageToUpload.length) {
          await refetchImagesByPerson({
            personId: formik.values.member
          })
          setTaggedImagesCount(0)
          showToast(t('forms:saveSuccess'))
          formik.setFieldValue('imageToUpload', [])
        } else {
          setTaggedImagesCount(count => count + 1)
        }
      }
    },
    onError: () => showToast(t('forms:saveFail'), ToastType.ERROR)
  })

  const tagEvent = useTagEventMutation({
    onCompleted: async results => {
      const resultId = results?.createCommunityTag?.id
      if (resultId) {
        if (taggedImagesCount + 1 === formik.values.imageToUpload.length) {
          await refetchImagesByEvent({
            eventId: formik.values.gathering
          })
          setTaggedImagesCount(0)
          showToast(t('forms:saveSuccess'))
          formik.setFieldValue('imageToUpload', [])
        } else {
          setTaggedImagesCount(count => count + 1)
        }
      }
    },
    onError: () => showToast(t('forms:saveFail'), ToastType.ERROR)
  })

  const createImage = useCreateImageMutation({
    onCompleted: results => {
      const imageId = results?.createImage?.id
      if (!imageId) return
      if (formik.values.gatheringsOrMember === SELECTOR_OPTIONS[0]) {
        tagEvent({
          variables: {
            communityId: communityId,
            imageId,
            xCoord: 0,
            yCoord: 0
          }
        })
      } else {
        tagPerson({
          variables: {
            personId: formik.values.member,
            imageId,
            xCoord: 0,
            yCoord: 0
          }
        })
      }
    },
    onError: () => showToast(t('forms:saveFail'), ToastType.ERROR)
  })

  const deletePersonTag = useDeletePersonTagMutation({
    onCompleted: result => {
      const success = result?.deletePersonTag
      if (success) {
        refetchImagesByPerson({
          personId: formik.values.member
        })
        showToast(t('forms:deleteSuccess'))
      }
    },
    onError: () => showToast(t('forms:deleteFail'), ToastType.ERROR)
  })

  const deleteEventTag = useDeleteEventTagMutation({
    onCompleted: result => {
      const success = result?.deleteCommunityTag
      if (success) {
        refetchImagesByEvent({
          eventId: formik.values.gathering
        })
        showToast(t('forms:deleteSuccess'))
      }
    },
    onError: () => showToast(t('forms:deleteFail'), ToastType.ERROR)
  })

  const selectedOption = useMemo(
    () => SELECTOR_OPTIONS.indexOf(formik.values.gatheringsOrMember),
    [formik.values.gatheringsOrMember]
  )
  const onPress = useCallback(e => {
    formik.handleSubmit(e)
  }, [])

  useEffect(() => {
    if (!getImagesByEventData?.taggedImages) return
    setImages(getImagesByEventData.taggedImages || [])
    setCommunityId(getImagesByEventData.id)
  }, [getImagesByEventData?.taggedImages])

  useEffect(() => {
    if (!getImagesByPersonData?.taggedImages) return
    setImages(getImagesByPersonData.taggedImages || [])
  }, [getImagesByPersonData?.taggedImages])

  const deleteImage = (id: string) => {
    if (confirm(t('image:confirmDelete'))) {
      if (formik.values.gatheringsOrMember === SELECTOR_OPTIONS[0]) {
        deleteEventTag({ variables: { id } })
      } else {
        deletePersonTag({ variables: { id } })
      }
    }
  }

  return (
    <ScreenContainerWithMenuHeader screenCategory="image">
      <Flex flexDirection="row" mx={4} my={3}>
        <Flex flex={1}>
          <H4>{t('image:title')}</H4>
        </Flex>
      </Flex>
      <Flex flex={1} flexDirection="row" mx={4} my={3}>
        <Flex flexBasis="30%">
          <ButtonGroup
            buttons={SELECTOR_OPTIONS}
            selectedIndex={selectedOption}
            onPress={value => {
              formik.setFieldValue(
                'gatheringsOrMember',
                SELECTOR_OPTIONS[value]
              )
            }}
            containerStyle={{ marginBottom: 20, marginLeft: 0, marginRight: 0 }}
            selectedTextStyle={{ color: '#21201B' }}
          />
          {formik.values.gatheringsOrMember === SELECTOR_OPTIONS[0] ? (
            <>
              <HeaderText>Gathering</HeaderText>
              <GatheringSelect
                onBlur={() => {}}
                placeholder="Choose a gathering..."
                onSelect={value => formik.setFieldValue('gathering', value)}
                value={formik.values.gathering}
                hasError={!!formik.errors.gathering}
              />
            </>
          ) : (
            <>
              <HeaderText>Person</HeaderText>
              <Dropdown
                value={null}
                options={userList}
                placeholder={'Choose a person...'}
                closeMenuOnSelect
                onInputChange={setUserSearchTerm}
                onSelect={el => formik.setFieldValue('member', el)}
                hasError={!!formik.errors.member && !!formik.touched.member}
                style={{ width: '100%' }}
              />
            </>
          )}

          <ContentDrop
            value={formik.values.imageToUpload}
            setValue={value => formik.setFieldValue('imageToUpload', value)}
            acceptFormat="image/png, image/jpeg"
            style={{
              minWidth: '300px',
              marginBottom: '15px'
            }}
            dropZoneContent={{
              imageIcon: <DropImageIcon />,
              title: t('image:dropImage')
            }}
            setError={error => formik.setFieldError('imageToUpload', error)}
            removeContentModal={{
              title: t('image:removeImagesTitle'),
              description: t('image:removeImagesDescription')
            }}
            cloudinaryDir="legacy/gallery"
            onRenderUrl={uri => (
              <PreviewImage resizeMode="cover" source={{ uri }} />
            )}
            multiple
          />
          <Button label={t('image:uploadButtonText')} onPress={onPress} />
        </Flex>
        <ImageGallery flexBasis="70%" flexDirection="row" flexWrap="wrap">
          {images &&
            images.map(({ image, id }) => (
              <ImageWrapper key={image.id}>
                <Icon
                  containerStyle={{
                    zIndex: 1,
                    position: 'absolute',
                    right: 0,
                    margin: theme.space[1],
                    backgroundColor: theme.colors.background
                  }}
                  name="trash"
                  type="font-awesome"
                  solid
                  raised
                  size={16}
                  color={theme.colors.subduedIcon}
                  onPress={() => deleteImage(id)}
                />
                <ImageItem
                  key={image.id}
                  publicId={image.cloudinaryId}
                  transforms={{
                    width: theme.sizes[6],
                    height: theme.sizes[6],
                    crop: 'fill'
                  }}
                />
              </ImageWrapper>
            ))}
        </ImageGallery>
      </Flex>
    </ScreenContainerWithMenuHeader>
  )
}

export default ImageScreen
