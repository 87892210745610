import styled from 'styled-components/native'
import { View } from 'react-native'

export const BorderBox = styled(View)`
  background-color: ${props => props.theme.colors.primary};
  border: 1px solid ${props => props.theme.colors.border};
  padding: 10px;
  border-radius: 8px;
  margin-right: 8px;
`
