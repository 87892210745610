import React, { useEffect, useState } from 'react'
import { StyleProp, View, ViewStyle } from 'react-native'
import Modal from '../../../components/common/Modal'
import { Text } from '../../../components/common/Text'
import { Flex } from '../../../components/FlexBox'
import Dropdown from '../../../components/common/Dropdown'
import { extractionQueues } from '../queries/runExtraction'
import { useLastExtraction } from '../queries/lastExtraction'
import Button from '../../../components/common/Button'
import TextInput from '../../../components/common/TextInput'

interface IProps {
  open: boolean
  close: () => void
  onSubmit: (context?: Record<string, unknown>) => void
  overlayStyle?: StyleProp<ViewStyle>
  selectedExtractor: string
  setSelectedExtractor: (value: string) => void
  isLoading: boolean
}

export const RunExtractionModal: React.FC<IProps> = ({
  open,
  close,
  onSubmit,
  selectedExtractor,
  setSelectedExtractor,
  isLoading
}) => {
  const [extractionMethod, setExtractionMethod] = useState<'full' | 'specific'>(
    'full'
  )
  const [inputText, setInputText] = useState<string[]>([])

  const { lastExtraction, loading: leLoading } = useLastExtraction(
    extractionQueues[selectedExtractor]?.type
  )

  const current = extractionQueues[selectedExtractor]

  useEffect(() => {
    if (current?.specificExtractions) {
      setInputText(new Array(current.specificExtractions.length).fill(''))
    }
  }, [current])

  return (
    <Modal open={open} close={close}>
      <View style={{ minWidth: 480 }}>
        {selectedExtractor ? (
          <Text>
            <b>{current.name} Extraction</b>
          </Text>
        ) : null}

        <Text styles={{ marginTop: 8 }}>
          Last extraction time:{' '}
          {leLoading
            ? 'Loading...'
            : lastExtraction?.date
            ? new Date(lastExtraction.date).toLocaleString()
            : 'Never'}
        </Text>

        <Flex flexDirection="column" alignItems="center" />
        <Text styles={{ marginTop: 16, marginBottom: 4 }}>
          <b>Extraction Type</b>
        </Text>
        <Dropdown
          placeholder={'Extraction Type'}
          options={Object.keys(extractionQueues).map(key => ({
            label: extractionQueues[key].name,
            value: key
          }))}
          onSelect={value => {
            if (!extractionQueues[value].specificExtractions) {
              setExtractionMethod('full')
            }
            setSelectedExtractor(value)
          }}
          value={selectedExtractor}
          style={{ width: '100%' }}
        />
        <Text styles={{ marginBottom: 4 }}>
          <b>Extraction Method</b>
        </Text>
        <Dropdown
          placeholder={'Extraction Method'}
          options={
            current?.specificExtractions
              ? [
                  { label: 'Full Extraction', value: 'full' },
                  { label: 'Specific IDs', value: 'specific' }
                ]
              : [{ label: 'Full Extraction', value: 'full' }]
          }
          value={current?.specificExtractions ? extractionMethod : 'full'}
          onSelect={value => {
            setExtractionMethod(value)
          }}
          style={{ width: '100%' }}
        />
        {extractionMethod === 'specific' && current?.specificExtractions
          ? current.specificExtractions.map((se, index) => {
              return (
                <View key={se.description}>
                  <Text>{se.description}</Text>
                  <TextInput
                    placeholder="..."
                    style={{
                      width: '100%',
                      marginTop: 8,
                      paddingLeft: 0,
                      paddingRight: 0
                    }}
                    value={inputText[index]}
                    onChangeText={(text: string) => {
                      const inputTextCopy = [...inputText]
                      inputTextCopy.splice(index, 1, text)
                      setInputText(inputTextCopy)
                    }}
                  />
                </View>
              )
            })
          : null}
        <Button
          label="🚀 Run Extraction"
          disabled={!selectedExtractor || leLoading || isLoading}
          onPress={() =>
            onSubmit(
              extractionMethod === 'specific'
                ? current?.specificExtractions?.reduce((pv, cv, index) => {
                    if (!inputText[index]) {
                      return pv
                    }
                    return {
                      ...pv,
                      ...cv.buildContext(inputText[index])
                    }
                  }, {})
                : undefined
            )
          }
          style={{ marginTop: 16 }}
        />
      </View>
    </Modal>
  )
}
