const light = {
  appBackground: '#FFF',
  danger: '#ED435C',
  primary: '#f0f7ff',
  logo_onAppBackground: 'rgba(196,196,196,0.8)',
  inputBackground: 'rgba(229, 229, 229, 0.54)',
  inputText: 'rgba(0, 0, 0, 0.74)',
  inputBorder: 'rgba(0, 0, 0, 0.34)',
  inputTextError: 'rgba(230, 0, 34, 0.54)',
  inputBorderError: 'rgba(230, 0, 34, 0.54)',
  placeholderText: 'rgba(0, 0, 0, 0.34)',
  buttonText: '#FFF',
  buttonBackground: '#21201B',
  buttonBorder: 'transparent',
  headerText: '#21201B',
  background: '#FFF',
  subduedIcon: '#A8A8A8',
  brightPrimary: '#109eda',
  accent: '#cb7a16',
  text: '#333333',
  border: '#d3d2d1',
  borderDark: '#21201B',
  attention: '#F35F75',
  enabled: '#f0f7ff',
  disabled: '#A4A6A4',
  success: '#33A51F',
  info: '#06A4CC',
  warning: '#FCDA00',
  lightGrey: '#E5E5E5',
  failure: '#ffcad3'
}

const dark = {
  ...light,
  appBackground: '#000'
}

export default { light, dark }
