import React, { useState } from 'react'
import { ScreenContainerWithMenuHeader } from '../../components/layout/ScreenContainer'
import { Flex } from '../../components/FlexBox'
import { H4, Text } from '../../components/common/Text'
import {
  TableCol,
  TableContainer,
  TableHeader,
  TableRow
} from '../dataExtraction/QueueTable'

import { useQuery } from '@apollo/client'
import { IMessageLog, messageLogsQuery } from './queries/getMessageLogs'
import { TableSkeleton } from './components/TableSkeleton'
import { MessageLogFilter } from './components/MessageLogFilter'
import { Pagination } from './components/Pagination'
import { BorderBox } from './components/BorderBox'

const LIMIT_PER_PAGE = 20

interface IMessageLogResponse {
  messageLogs: {
    totalCount: number
    messages: IMessageLog[]
  }
}

export interface IMessageLogFilter {
  sendTo?: string
  sendFrom?: string
  type?: string
  status?: string
  verified?: boolean
}

export const MessageLogScreen = () => {
  const [page, setPage] = useState(0)
  const [lastTotalCount, setLastTotalCount] = useState(0)

  const [filter, setFilter] = useState<IMessageLogFilter>({})

  const { data, loading, error } = useQuery<IMessageLogResponse>(
    messageLogsQuery,
    {
      variables: {
        limit: LIMIT_PER_PAGE,
        offset: page * LIMIT_PER_PAGE,
        filter
      },
      onCompleted: data => {
        if (data.messageLogs.totalCount !== lastTotalCount) {
          setLastTotalCount(data.messageLogs.totalCount)
        }
      }
    }
  )

  const logs = data?.messageLogs.messages || []

  return (
    <ScreenContainerWithMenuHeader screenCategory={'messageLog'}>
      <Flex
        flexDirection="row"
        mx={4}
        my={3}
        alignItems="center"
        style={{ minWidth: 'fit-content' }}
      >
        <Flex flex={1}>
          <H4>{'Message Log'}</H4>
        </Flex>
      </Flex>
      <Flex flexDirection="row" mx={4} my={3}>
        <BorderBox>
          <MessageLogFilter setFilter={setFilter} setPage={setPage} />
        </BorderBox>
      </Flex>
      <Flex my={3} mx={4} flexDirection="row" minWidth={'fit-content'}>
        <TableContainer>
          <TableHeader>
            <TableCol>
              <Text>From</Text>
            </TableCol>
            <TableCol style={{ minWidth: 320 }}>
              <Text>To</Text>
            </TableCol>
            <TableCol>
              <Text>Type</Text>
            </TableCol>
            <TableCol>
              <Text>Status</Text>
            </TableCol>
            <TableCol>
              <Text>Created At</Text>
            </TableCol>
            <TableCol>
              <Text>Verified At</Text>
            </TableCol>
          </TableHeader>
          {!loading &&
            logs.map(log => (
              <TableRow key={log.id}>
                <TableCol>
                  <Text>{log.messageData.sendFrom}</Text>
                </TableCol>
                <TableCol style={{ minWidth: 320 }}>
                  <Text>{log.messageData.sendTo}</Text>
                </TableCol>
                <TableCol>
                  <Text>{log.messageData.type}</Text>
                </TableCol>
                <TableCol>
                  <Text>{log.status}</Text>
                </TableCol>
                <TableCol>
                  <Text>{new Date(log.createdAt).toLocaleString()}</Text>
                </TableCol>
                <TableCol>
                  <Text>
                    {log.verifiedAt
                      ? new Date(log.verifiedAt).toLocaleString()
                      : 'Not verified'}
                  </Text>
                </TableCol>
              </TableRow>
            ))}
          {loading && <TableSkeleton rows={LIMIT_PER_PAGE} />}
          {!loading && logs.length === 0 && (
            <TableRow>
              <TableCol>
                <Text>No results found</Text>
              </TableCol>
            </TableRow>
          )}
          {error && (
            <TableRow>
              <TableCol>
                <Text>Error loading data</Text>
              </TableCol>
            </TableRow>
          )}
        </TableContainer>
      </Flex>
      <Flex flexDirection="row" justifyContent="center" my={3}>
        <Pagination
          page={page}
          setPage={setPage}
          totalCount={lastTotalCount}
          limitPerPage={LIMIT_PER_PAGE}
        />
      </Flex>
    </ScreenContainerWithMenuHeader>
  )
}
