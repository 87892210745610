import { FormikProps } from 'formik'
import React from 'react'
import { View } from 'react-native'
import { useTheme } from 'styled-components/native'
import { Text } from '../../components/common/Text'
import useTranslation from '../../hooks/useTranslation'
import SummitTextInput from './editors/SummitTextInput'
import { IEditorState, ISectionState } from './types'
import { lighten } from 'polished'

interface IProps {
  section: ISectionState
  sectionIndex: number
  form: FormikProps<IEditorState>
}

export const SectionTitleInput: React.FC<IProps> = ({
  form,
  section,
  sectionIndex
}) => {
  const { colors, sizes } = useTheme()
  const { t } = useTranslation()

  return section.uid === 'pageBreak' ? (
    <View
      style={{
        padding: 8,
        borderWidth: 1,
        borderColor: colors.inputBorder,
        borderRadius: 4,
        marginTop: 8
      }}
    >
      <View
        style={{
          marginRight: 'auto',
          paddingVertical: sizes[1],
          paddingHorizontal: sizes[2],
          borderRadius: sizes[1],
          backgroundColor: lighten(0.3, colors.brightPrimary)
        }}
      >
        <Text>{t('summits:editor:sections:pageBreak')}</Text>
      </View>
    </View>
  ) : (
    <View
      style={{
        padding: 8,
        borderWidth: 1,
        borderColor: colors.inputBorder,
        borderRadius: 4,
        marginTop: 8
      }}
    >
      <Text styles={{ marginBottom: 8 }}>Section Title</Text>
      <SummitTextInput
        placeholder={t('forms:titleOptional')}
        value={section.title}
        onChangeText={x =>
          form.setFieldValue(`sections.${sectionIndex}.title`, x)
        }
        onBlur={form.handleBlur(`sections.${sectionIndex}.title`)}
        style={{
          flex: 2,
          minWidth: 512,
          marginRight: 10,
          marginBottom: 0
        }}
      />
    </View>
  )
}
