import React from 'react'
import styled from 'styled-components/native'

import { Flex } from '../../FlexBox'
import Button from '../../../ui-library/Button'
// Hooks
import useTranslation from '../../../hooks/useTranslation'
import useFeatureFlag from '../../../hooks/useFeatureFlag'

// Styled components
const Container = styled(Flex).attrs(() => ({
  flexDirection: 'row',
  pt: '16px',
  flexWrap: 'wrap'
}))``
const BtnContainer = styled(Flex).attrs(() => ({
  flexDirection: 'row',
  flexWrap: 'wrap'
}))``

const DeleteButton = styled(Button).attrs(() => ({
  containerStyle: { marginRight: 'auto' }
}))``
const PreviewButton = styled(Button).attrs(({ theme: { sizes } }) => ({
  containerStyle: { marginHorizontal: sizes[2] }
}))``

interface FooterProps {
  isDeleteDisabled: boolean
  handlePressDelete: () => void
  isPreviewDisabled: boolean
  handlePressPreview: () => void
  isSaveDisabled: boolean
  handlePressSave: (e: any) => void
  isCopyContentDisabled?: boolean
  handlePressCopyContent?: (e: any) => void
}

const Footer = ({
  isDeleteDisabled,
  handlePressDelete,
  isPreviewDisabled,
  handlePressPreview,
  isSaveDisabled,
  handlePressSave,
  isCopyContentDisabled,
  handlePressCopyContent
}: FooterProps) => {
  const { t } = useTranslation()

  const isCopyMI6ContentEnabled = useFeatureFlag('copyMI6Content')

  return (
    <Container>
      <BtnContainer flexGrow={1}>
        <DeleteButton
          disabled={isDeleteDisabled}
          title={t('common:labels:delete')}
          type="outline"
          onPress={handlePressDelete}
        />
      </BtnContainer>
      <BtnContainer flexGrow={2} justifyContent="right">
        {isCopyMI6ContentEnabled && (
          <Button
            title={t('common:labels:makeCopy')}
            disabled={isCopyContentDisabled}
            onPress={handlePressCopyContent}
          />
        )}
        <PreviewButton
          title={t('common:labels:preview')}
          disabled={isPreviewDisabled}
          onPress={handlePressPreview}
        />
        <Button
          title={t('common:labels:save')}
          disabled={isSaveDisabled}
          onPress={handlePressSave}
        />
      </BtnContainer>
    </Container>
  )
}

export default Footer
